import { addSeconds } from 'date-fns';
var AuthInfoDto = /** @class */ (function () {
    function AuthInfoDto(userMapper, authInfoDto) {
        this.userMapper = userMapper;
        this.expires = undefined;
        this.groupAssetPairs = [];
        Object.assign(this, authInfoDto);
    }
    AuthInfoDto.prototype.getExpirationDateFromAccessToken = function (token) {
        // exp - the number of seconds (not milliseconds) since Epoch
        var expirationDateTime = JSON.parse(atob(token.split('.')[1])).exp;
        return new Date(expirationDateTime * 1000);
    };
    AuthInfoDto.prototype.applyTokenRequestResponse = function (trr) {
        this.access_token = trr.access_token;
        this.refresh_token = trr.refresh_token;
        this.expires = trr.autoLogin ? this.getExpirationDateFromAccessToken(trr.access_token) : addSeconds(new Date(), trr.expires_in);
        this.roles = trr.roles;
        this.userInfo = this.userMapper.userInfoToUserInfoDto(trr.userInfo);
    };
    return AuthInfoDto;
}());
export { AuthInfoDto };
