import { ToolbarMenu } from './toolbar-menu';
/**
 * ToolbarMenuComponent
 */
var ToolbarMenuComponent = /** @class */ (function () {
    function ToolbarMenuComponent() {
    }
    return ToolbarMenuComponent;
}());
export { ToolbarMenuComponent };
