var TimeWindowConfigDto = /** @class */ (function () {
    function TimeWindowConfigDto(timeWindowConfigDto) {
        this.monday = undefined;
        this.tuesday = undefined;
        this.wednesday = undefined;
        this.thursday = undefined;
        this.friday = undefined;
        this.saturday = undefined;
        this.sunday = undefined;
        this.dstOffset = 0;
        Object.assign(this, timeWindowConfigDto);
    }
    return TimeWindowConfigDto;
}());
export { TimeWindowConfigDto };
