import { EventEmitter } from '@angular/core';
/**
 * ToolbarMenuItemComponent
 */
var ToolbarMenuItemComponent = /** @class */ (function () {
    function ToolbarMenuItemComponent() {
        this.clicked = new EventEmitter();
    }
    /**
     * hands up the clickEvent
     */
    ToolbarMenuItemComponent.prototype.entryClicked = function () {
        this.clicked.emit(this.entry);
    };
    return ToolbarMenuItemComponent;
}());
export { ToolbarMenuItemComponent };
