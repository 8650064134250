import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';
var PhoenixTaskConfigDialogOnceComponent = /** @class */ (function () {
    function PhoenixTaskConfigDialogOnceComponent(data) {
        this.data = data;
        this.today = new Date();
        this.group = new FormGroup({
            start: new FormControl(this.today),
            end: new FormControl(),
        });
    }
    PhoenixTaskConfigDialogOnceComponent.prototype.getStartDate = function () {
        return this.group.get('start').value;
    };
    PhoenixTaskConfigDialogOnceComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(PhoenixTaskConfigDialogOnceComponent.prototype, "assignedUserGroupIds", {
        get: function () {
            return this.data.formGroup.get('assignedUserGroupIds').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixTaskConfigDialogOnceComponent.prototype, "userGroups", {
        get: function () {
            return this.data.usergroups.sort(function (a, b) { return a.name.localeCompare(b.name); });
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixTaskConfigDialogOnceComponent;
}());
export { PhoenixTaskConfigDialogOnceComponent };
