import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import { TimeWindowConfigIntervallDto } from '@phoenix/gapicon/asset/dto/time-window-config-intervall-dto';
import _ from 'lodash';
import { runInThisContext } from 'vm';

import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-time-window-interval',
  templateUrl: './phoenix-dynamic-time-window-interval.component.html',
  styleUrls: ['./phoenix-dynamic-time-window-interval.component.scss'],
})
export class PhoenixDynamicTimeWindowIntervalComponent {
  private timeRegex: RegExp = /([0-9]|1[0-9]|2[0-3]):[0-5][0-9]/

  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<TimeWindowConfigIntervallDto>;

  @Input()
  public form: FormGroup;

  public hours: number[] = _.range(0, 24);
  public minutes: number[] = _.range(0, 60);
  public statusIcon: String = PhoenixIcon.LENS;

  private checkTimeFormat(timeString: string): boolean {
    let valid = this.timeRegex.test(timeString);
    return valid;
  }

  public getStatusIconStyle(): Object {
    if (this.phoenixFormControl && this.phoenixFormControl.value
      && this.phoenixFormControl.value.startTime && this.phoenixFormControl.value.endTime
      && this.phoenixFormControl.value.startTime == '0:00' && this.phoenixFormControl.value.endTime == '0:00') {
        this.statusIcon = PhoenixIcon.INCOMPLETE_CIRCLE;
        return {'color': PhoenixStatusColor.NEGATIVE};
    } else {
      this.statusIcon = PhoenixIcon.LENS;
      return {'color': PhoenixStatusColor.POSITIVE};
    }
  }

  public getStartTimeHours(): number {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.startTime
      && this.checkTimeFormat(this.phoenixFormControl.value.startTime)) {
      return +this.phoenixFormControl.value.startTime.split(':')[0];
    } else {
      return 0;
    }
  }

  public startTimeHoursOnChange(hours: number): void {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.startTime
      && this.checkTimeFormat(this.phoenixFormControl.value.startTime)) {
      this.phoenixFormControl.value.startTime = hours.toString() + ':' + this.phoenixFormControl.value.startTime.split(':')[1];
      this.onChangeCallbackFromOptions();
    }
  }

  public getStartTimeMinutes(): number {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.startTime
      && this.checkTimeFormat(this.phoenixFormControl.value.startTime)) {
      return +this.phoenixFormControl.value.startTime.split(':')[1];
    } else {
      return 0;
    }
  }

  public startTimeMinutesOnChange(minutes: number): void {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.startTime
      && this.checkTimeFormat(this.phoenixFormControl.value.startTime)) {
      if (minutes < 10) {
        this.phoenixFormControl.value.startTime = this.phoenixFormControl.value.startTime.split(':')[0] + ':0' + minutes.toString();
        this.onChangeCallbackFromOptions();
      } else {
        this.phoenixFormControl.value.startTime = this.phoenixFormControl.value.startTime.split(':')[0] + ':' + minutes.toString();
        this.onChangeCallbackFromOptions();
      }
    }
  }

  public getEndTimeHours(): number {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.endTime
      && this.checkTimeFormat(this.phoenixFormControl.value.endTime)) {
      return +this.phoenixFormControl.value.endTime.split(':')[0];
    } else {
      return 0;
    }
  }

  public endTimeHoursOnChange(hours: number): void {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.endTime
      && this.checkTimeFormat(this.phoenixFormControl.value.endTime)) {
      this.phoenixFormControl.value.endTime = hours.toString() + ':' + this.phoenixFormControl.value.endTime.split(':')[1];
      this.onChangeCallbackFromOptions();
    }
  }

  public getEndTimeMinutes(): number {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.endTime
      && this.checkTimeFormat(this.phoenixFormControl.value.endTime)) {
      return +this.phoenixFormControl.value.endTime.split(':')[1];
    } else {
      return 0;
    }
  }

  public endTimeMinutesOnChange(minutes: number): void {
    if (this.phoenixFormControl && this.phoenixFormControl.value && this.phoenixFormControl.value.endTime
      && this.checkTimeFormat(this.phoenixFormControl.value.endTime)) {
      if (minutes < 10) {
        this.phoenixFormControl.value.endTime = this.phoenixFormControl.value.endTime.split(':')[0] + ':0' + minutes.toString();
        this.onChangeCallbackFromOptions();
      } else {
        this.phoenixFormControl.value.endTime = this.phoenixFormControl.value.endTime.split(':')[0] + ':' + minutes.toString();
        this.onChangeCallbackFromOptions();
      }
    }
  }

  public async deleteCallbackFromOptions(): Promise<void> {
    await this.phoenixFormControl.options['deleteCallback'](this.form);
  }

  public async onChangeCallbackFromOptions(): Promise<void> {
    await this.phoenixFormControl.options['onChangeCallback'](this.form);
  }

}