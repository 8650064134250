/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-page-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./phoenix-page-content.component";
var styles_PhoenixPageContentComponent = [i0.styles];
var RenderType_PhoenixPageContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixPageContentComponent, data: {} });
export { RenderType_PhoenixPageContentComponent as RenderType_PhoenixPageContentComponent };
export function View_PhoenixPageContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "content-card fuse-white-bg phoenix-border-radius": 0, "h-100-p": 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isLoggedIn, !_co.isLoggedIn); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PhoenixPageContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-page-content", [], null, null, null, View_PhoenixPageContentComponent_0, RenderType_PhoenixPageContentComponent)), i1.ɵdid(1, 49152, null, 0, i3.PhoenixPageContentComponent, [], null, null)], null, null); }
var PhoenixPageContentComponentNgFactory = i1.ɵccf("phoenix-page-content", i3.PhoenixPageContentComponent, View_PhoenixPageContentComponent_Host_0, { isLoggedIn: "isLoggedIn" }, {}, ["*"]);
export { PhoenixPageContentComponentNgFactory as PhoenixPageContentComponentNgFactory };
