var StatisticsSearchCriteriaDto = /** @class */ (function () {
    function StatisticsSearchCriteriaDto(statisticsSearchCriteriaDto) {
        this.measurementPointIds = undefined;
        this.assetIds = undefined;
        this.thresholdIds = undefined;
        this.dateFrom = undefined;
        this.dateTo = undefined;
        Object.assign(this, statisticsSearchCriteriaDto);
    }
    return StatisticsSearchCriteriaDto;
}());
export { StatisticsSearchCriteriaDto };
