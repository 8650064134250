var TenantJob = /** @class */ (function () {
    function TenantJob(tenantJob) {
        this.tenantId = undefined;
        this.tenantName = undefined;
        this.jobType = undefined;
        this.interval = undefined;
        this.nextExecutionTime = undefined;
        this.previousExecutionTime = undefined;
        this.triggerState = undefined;
        Object.assign(this, tenantJob);
    }
    TenantJob.prototype.toJSON = function () {
        return {
            tenantId: this.tenantId,
            tenantName: this.tenantName,
            jobType: this.jobType,
            interval: this.interval,
            nextExecutionTime: this.nextExecutionTime,
            previousExecutionTime: this.previousExecutionTime,
            triggerState: this.triggerState
        };
    };
    return TenantJob;
}());
export { TenantJob };
