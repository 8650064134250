import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixCloneConfirmationDialogData } from './classes/PhoenixCloneConfirmationDialogData';
var PhoenixCloneConfirmationDialogComponent = /** @class */ (function () {
    function PhoenixCloneConfirmationDialogComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
    }
    PhoenixCloneConfirmationDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttons = [
            new PhoenixDialogButton({
                label: 'WIZARDS.CLONE.CANCEL',
                click: function () { return _this.dialogRef.close(false); },
            }),
            new PhoenixDialogButton({
                label: 'WIZARDS.CLONE.YESBUTTON',
                click: function () { return _this.dialogRef.close(true); },
            }),
        ];
    };
    return PhoenixCloneConfirmationDialogComponent;
}());
export { PhoenixCloneConfirmationDialogComponent };
