import { format, isValid } from 'date-fns';
var AccountInfo = /** @class */ (function () {
    function AccountInfo(accountInfo) {
        this.blocked = false;
        this.blockedUntil = undefined;
        Object.assign(this, accountInfo);
    }
    AccountInfo.prototype.toJSON = function () {
        return {
            blocked: this.blocked,
            blockedUntil: (this.blockedUntil && isValid(this.blockedUntil)) ? format(this.blockedUntil) : undefined,
        };
    };
    return AccountInfo;
}());
export { AccountInfo };
