import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { SharedService } from '../../shared/services/shared.service';
import { UserDto } from '../../user/dto/user-dto';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { RegistrationResponse } from '../api/registration-response';
import { PasswordChangeDto } from '../dto/password-change-dto';
import { PasswordResetDto } from '../dto/password-reset-dto';
import { PasswordResetRequestDto } from '../dto/password-reset-request-dto';
import { RegistrationConfirmationDto } from '../dto/registration-confirmation-dto';
import { RegistrationRequestDto } from '../dto/registration-request-dto';
import { RegistrationResponseDto } from '../dto/registration-response-dto';
import { AccountMapperService } from '../mapper/account-mapper.service';

const ACCOUNTS: string = 'accounts';
const REGISTRATIONS: string = 'registrations';
const CONFIRMATIONS: string = 'confirmations';
const PASSWORDRESETREQUESTS: string = 'passwordResetRequests';
const PASSWORDRESETS: string = 'passwordResets';
const INFO: string = 'info';
const PASSWORD: string = 'passwords';

/**
 * ## Account API
 * Methods to access CHECK CLOUD account api to create and manage user accounts.
 *
 * @usage
 * ```javascript
 *
 * import {AccountApiService} from 'gapicon/account-api/account-api.service';
 * import {PasswordResetRequestDto} from 'gapicon/dto/password-reset-request-dto';
 *
 * @Component({
 *   ...
 * })
 * export class YourComponent implements OnInit {
 *   constructor(private accountApi: AccountApiService) {}
 *   ngOnInit() {
 *     const passwordResetRequest = new PasswordResetRequestDto();
 *     passwordResetRequest.email = 'test@example.org';
 *
 *     this.accountApi.requestPasswordReset(passwordResetRequest)
 *       .subscribe(() => {
 *         alert('password reset successful');
 *       });
 *   }
 * }
 * ```
 */
@Injectable()
export class AccountApiService {
  /**
   *
   * @param http
   * @param shared
   * @param accountMapperService
   * @param userMapper
   */
  public constructor(private http: HttpClient,
    private shared: SharedService,
    private accountMapperService: AccountMapperService,
    private userMapper: UserMapperService) {
  }

  /**
   * Start a new user registration. User will get an email to complete registration.
   * @example
   * ```javascript
   *
   * const registrationRequestDto = new RegistrationRequestDto();
   * registrationRequestDto.email = 'test@example.org';
   * registrationRequestDto.roles = ['user'];
   *
   * accountApi.createUserRegistration(registrationRequestDto)
   *  .subscribe((response: RegistrationResponseDto) => {
   *    console.log('User Registration Response:', response);
   *  });
   *  ```
   * @param registrationRequestDto
   * @returns
   */
  public createUserRegistration(registrationRequestDto: RegistrationRequestDto): Observable<RegistrationResponseDto> {
    return new Observable<RegistrationResponseDto>((observer: Observer<RegistrationResponseDto>): void => {
      this.http.post<RegistrationResponse>(
        this.shared.buildApiUrl(ACCOUNTS, REGISTRATIONS),
        this.accountMapperService.registrationRequestDtoToRegistrationRequest(registrationRequestDto),
      ).subscribe((registrationResponse: RegistrationResponse) => {
        observer.next(this.accountMapperService.registratrionResponseToRegistratrionResponseDto(registrationResponse));
        observer.complete();
      });
    });
  }

  /**
   * Confirm email address for an open user registration and send required user data to the backend.
   * @param registrationConfirmationDto
   * @returns
   */
  public confirmUserRegistration(registrationConfirmationDto: RegistrationConfirmationDto): Observable<RegistrationResponseDto> {
    return new Observable<RegistrationResponseDto>((observer: Observer<RegistrationResponseDto>): void => {
      this.http.post<RegistrationResponse>(
        this.shared.buildApiUrl(ACCOUNTS, CONFIRMATIONS),
        this.accountMapperService.registrationConfirmationDtoToRegistrationConfirmation(registrationConfirmationDto),
      ).subscribe((registrationResponse: RegistrationResponse) => {
        observer.next(this.accountMapperService.registratrionResponseToRegistratrionResponseDto(registrationResponse));
        observer.complete();
      });
    });
  }

  /**
   * Request a password reset for user self service. The user will receive an email to set his own password.
   * @param passwordResetRequest
   * @returns
   */
  public requestPasswordReset(passwordResetRequest: PasswordResetRequestDto): Observable<void> {
    return this.http.post<void>(
      this.shared.buildApiUrl(ACCOUNTS, PASSWORDRESETREQUESTS),
      this.accountMapperService.passwordResetRequestDtoToPasswordResetRequest(passwordResetRequest),
    );
  }

  /**
   * Reset password with token after user has requested an password reset.
   * @param passwordResetDto
   * @returns
   */
  public resetPassword(passwordResetDto: PasswordResetDto): Observable<void> {
    return this.http.post<void>(
      this.shared.buildApiUrl(ACCOUNTS, PASSWORDRESETS),
      this.accountMapperService.passwordResetDtoToPasswordReset(passwordResetDto),
    );
  }

  /**
   * Save account info for a user. You can lock and unlock users (forever and for a specific time span)
   * @returns
   * @param userDto
   */
  public saveAccountInfo(userDto: UserDto): Observable<void> {
    return this.http.post<void>(
      this.shared.buildApiUrl(ACCOUNTS, INFO, userDto.id),
      this.userMapper.accountInfoDtoToAccountInfo(userDto.accountInfo),
    );
  }

  /**
   * Password change for user self service. User can change his own password (requires both the old and new password)
   * @param passwordChangeDto
   * @returns
   */
  public changePassword(passwordChangeDto: PasswordChangeDto): Observable<void> {
    return this.http.post<void>(
      this.shared.buildApiUrl(ACCOUNTS, PASSWORD),
      this.accountMapperService.passwordChangeDtoToPasswordChange(passwordChangeDto),
    );
  }

}
