var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../../components/phoenix-snackbar/phoenix-snackbar.service';
import { AssetApiService } from '../../../gapicon/asset/services/asset-api.service';
import { PhoenixCommunicationSubject } from '../../../services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixWizardHelperService } from '../../../services/phoenix-wizards/phoenix-wizard-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../gapicon/asset/services/asset-api.service";
import * as i3 from "../../../services/phoenix-communication-service/phoenix-communication.service";
import * as i4 from "../../../services/phoenix-wizards/phoenix-wizard-helper.service";
import * as i5 from "../../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i6 from "@ngx-translate/core";
var PhoenixSensormanagementDeleteDialogService = /** @class */ (function () {
    function PhoenixSensormanagementDeleteDialogService(dialog, assetApiService, communicationService, wizardHelper, snackbarService, translateService) {
        this.dialog = dialog;
        this.assetApiService = assetApiService;
        this.communicationService = communicationService;
        this.wizardHelper = wizardHelper;
        this.snackbarService = snackbarService;
        this.translateService = translateService;
    }
    PhoenixSensormanagementDeleteDialogService.prototype.openDeleteSensorDialog = function (assetDto) {
        var _this = this;
        var randomString = this.wizardHelper.getRandomString();
        this.dialog.open(PhoenixDialogComponent, {
            width: this.wizardHelper.DIALOGWIDTH,
            autoFocus: false,
            data: {
                headline: 'WIZARDS.SENSOR.DELETE.TITLE',
                subHeadline: assetDto.sensor.sensorId,
                buttons: {
                    cancel: true,
                    delete: 'WIZARDS.SENSOR.DELETE.SUBMIT',
                },
                translationParams: {
                    deleteCode: randomString,
                },
                color: 'warn',
                formControls: [
                    _.concat(this.createTextFormControls(), new PhoenixDynamicFormControl('delete-code', PhoenixDynamicFormControlType.Text, 'WIZARDS.SENSOR.DELETE.DELETECODE', undefined, undefined, randomString), new PhoenixDynamicFormControl('delete', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SENSOR.DELETE.DELETECODEINPUT', true, { hint: 'WIZARDS.SENSOR.DELETE.DELETECODEINPUTHINT' }, undefined, undefined, [Validators.required, Validators.pattern(randomString)])),
                ],
                onClose: function (r) { return _this.onDeleteCloseCallback(r, assetDto); },
            },
        });
    };
    PhoenixSensormanagementDeleteDialogService.prototype.onDeleteCloseCallback = function (dialogResult, assetDto) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'delete')) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.assetApiService.deleteAsset(assetDto).toPromise()];
                    case 2:
                        _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.SENSOR.DELETE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadSensorPage);
                        _a.label = 5;
                    case 5:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixSensormanagementDeleteDialogService.prototype.createTextFormControls = function () {
        var hintTextOptions = {
            classes: {
                'font-size-12': true,
                'hint-text': true,
                'primary-300-fg': true,
                'mt-4': true,
                'mb-4': true,
            },
        };
        var formControls = [];
        var textToDisplay = [
            'WIZARDS.SENSOR.DELETE.LINE1',
            'WIZARDS.SENSOR.DELETE.LINE2',
            'WIZARDS.SENSOR.DELETE.CONFIRMATION',
        ];
        _.forEach(textToDisplay, function (text) {
            formControls.push(new PhoenixDynamicFormControl('text', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, text));
        });
        return formControls;
    };
    PhoenixSensormanagementDeleteDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixSensormanagementDeleteDialogService_Factory() { return new PhoenixSensormanagementDeleteDialogService(i0.inject(i1.MatDialog), i0.inject(i2.AssetApiService), i0.inject(i3.PhoenixCommunicationService), i0.inject(i4.PhoenixWizardHelperService), i0.inject(i5.PhoenixSnackbarService), i0.inject(i6.TranslateService)); }, token: PhoenixSensormanagementDeleteDialogService, providedIn: "root" });
    return PhoenixSensormanagementDeleteDialogService;
}());
export { PhoenixSensormanagementDeleteDialogService };
