var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Routes } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PhoenixIcon } from '../../../@phoenix/enums/phoenix-icon.enum';
import { PhoenixCommunicationSubject } from '../../../@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { CanLoadGuard } from '../../guards/can-load.guard';
import { Permission, permissionIds } from '../../guards/permission';
import { navbarGroups } from '../../layout/components/navbar/navbar-groups/navbar-groups.module';
import { NavigationItem } from '../../navigation/classes/navigation-item';
import { NavigationService } from '../../navigation/navigation.service';
import { MainModule } from '../classes/main-module';
var permission = new Permission(permissionIds.TENANTADMINISTRATION.THRESHOLD.read);
var ɵ0 = { permission: permission };
var routes = [
    {
        path: 'thresholds',
        loadChildren: './thresholdmanagement-content/thresholdmanagement-content.module#ThresholdmanagementContentModule',
        canLoad: [CanLoadGuard],
        data: ɵ0,
    },
];
var ThresholdmanagementModule = /** @class */ (function (_super) {
    __extends(ThresholdmanagementModule, _super);
    function ThresholdmanagementModule(navigationService, communicationService) {
        var _this = _super.call(this) || this;
        _this.navigationService = navigationService;
        _this.communicationService = communicationService;
        _this.translationKey = 'NAV.THRESHOLDMANAGEMENT';
        _this.navItem = new NavigationItem('thresholds', 0, false, _this.translationKey, '/thresholds', PhoenixIcon.PLAY_ARROW);
        _this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function () {
            _this.navigationService.refreshNavigation(_this.navItem, navbarGroups.tenantAdministration.id, permission);
        });
        return _this;
    }
    return ThresholdmanagementModule;
}(MainModule));
export { ThresholdmanagementModule };
export { ɵ0 };
