import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { ThresholdMapperService } from '../../../../threshold/mapper/threshold-mapper.service';
import { MeasurementPoint } from '../api/measurement-point';
import { MeasurementPointDto } from '../dto/measurement-point-dto';
import * as i0 from "@angular/core";
import * as i1 from "../../../../threshold/mapper/threshold-mapper.service";
var MeasurementPointMapperService = /** @class */ (function () {
    function MeasurementPointMapperService(thresholdMapperService) {
        this.thresholdMapperService = thresholdMapperService;
    }
    MeasurementPointMapperService.prototype.measurementPointDtoToMeasurementPoint = function (measurementPointDto) {
        var measurementPoint = plainToClass(MeasurementPoint, measurementPointDto);
        measurementPoint.threshold = measurementPointDto.threshold ? this.thresholdMapperService.thresholdDtoToThreshold(measurementPointDto.threshold) : undefined;
        return measurementPoint;
    };
    MeasurementPointMapperService.prototype.measurementPointToMeasurementPointDto = function (measurementPoint) {
        var measurementPointDto = plainToClass(MeasurementPointDto, measurementPoint);
        measurementPointDto.threshold = measurementPoint.threshold ? this.thresholdMapperService.thresholdToThresholdDto(measurementPoint.threshold) : undefined;
        return measurementPointDto;
    };
    MeasurementPointMapperService.prototype.measurementPointArrayToMeasurementPointDtoArray = function (measurementPoints) {
        var _this = this;
        return _.map(measurementPoints, function (measurementPoint) { return _this.measurementPointToMeasurementPointDto(measurementPoint); });
    };
    MeasurementPointMapperService.prototype.measurementPointDtoArrayToMeasurementPointArray = function (measurementPointDtos) {
        var _this = this;
        return _.map(measurementPointDtos, function (measurementPointDto) { return _this.measurementPointDtoToMeasurementPoint(measurementPointDto); });
    };
    MeasurementPointMapperService.ngInjectableDef = i0.defineInjectable({ factory: function MeasurementPointMapperService_Factory() { return new MeasurementPointMapperService(i0.inject(i1.ThresholdMapperService)); }, token: MeasurementPointMapperService, providedIn: "root" });
    return MeasurementPointMapperService;
}());
export { MeasurementPointMapperService };
