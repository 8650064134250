import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixTenantSelectorDialogData } from './classes/PhoenixTenantSelectorDialogData';
var PhoenixTenantSelectorDialogComponent = /** @class */ (function () {
    function PhoenixTenantSelectorDialogComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
    }
    PhoenixTenantSelectorDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data.cancelButton) {
            this.buttons = [
                new PhoenixDialogButton({
                    label: 'WIZARDS.TENANT.CANCEL',
                    click: function () { return _this.dialogRef.close(undefined); },
                }),
            ];
        }
    };
    PhoenixTenantSelectorDialogComponent.prototype.selectTenant = function (tenant) {
        this.dialogRef.close([tenant]);
    };
    return PhoenixTenantSelectorDialogComponent;
}());
export { PhoenixTenantSelectorDialogComponent };
