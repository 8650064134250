import { OnInit } from '@angular/core';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';
var PhoenixTaskConfigDialogDailyComponent = /** @class */ (function () {
    function PhoenixTaskConfigDialogDailyComponent(data) {
        this.data = data;
        this.today = new Date();
    }
    Object.defineProperty(PhoenixTaskConfigDialogDailyComponent.prototype, "startDate", {
        get: function () {
            return this.data.formGroup.get('start').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixTaskConfigDialogDailyComponent.prototype, "assignedUserGroupIds", {
        get: function () {
            return this.data.formGroup.get('assignedUserGroupIds').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixTaskConfigDialogDailyComponent.prototype, "userGroups", {
        get: function () {
            return this.data.usergroups.sort(function (a, b) { return a.name.localeCompare(b.name); });
        },
        enumerable: true,
        configurable: true
    });
    PhoenixTaskConfigDialogDailyComponent.prototype.ngOnInit = function () {
    };
    return PhoenixTaskConfigDialogDailyComponent;
}());
export { PhoenixTaskConfigDialogDailyComponent };
