var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NavigationType } from '../enums/navigation-type.enum';
import { NavigationBaseCollector } from './navigation-base-collector';
/**
 * Class for Group in Navigation. The element can have children.
 */
var NavigationGroup = /** @class */ (function (_super) {
    __extends(NavigationGroup, _super);
    function NavigationGroup(id, priority, title, translate, children) {
        if (children === void 0) { children = []; }
        var _this = _super.call(this) || this;
        _this.hidden = true;
        _this.type = NavigationType.GROUP;
        _this.id = id;
        _this.priority = priority;
        _this.title = title;
        _this.translate = translate;
        _this.children = children;
        return _this;
    }
    return NavigationGroup;
}(NavigationBaseCollector));
export { NavigationGroup };
