var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixFormDropdown } from '../../../components/phoenix-form/phoenix-form-dropdown/classes/phoenix-form-dropdown';
import { PhoenixFormText } from '../../../components/phoenix-form/phoenix-form-text/classes/phoenix-form-text';
import { PhoenixFormTextarea } from '../../../components/phoenix-form/phoenix-form-textarea/classes/phoenix-form-textarea';
import { PhoenixSnackbarService } from '../../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixTableLink } from '../../../components/phoenix-table/classes/phoenix-table-link';
import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';
import { AssetTags } from '../../../gapicon/asset/dto/asset-tags';
import { SensorTypeDto } from '../../../gapicon/dto.module';
import { SensorConfigDto } from '../../../gapicon/sensor/dto/sensor-config-dto';
import { SensorConfigValueDto } from '../../../gapicon/sensor/dto/sensor-config-value-dto';
import { SensorDto } from '../../../gapicon/sensor/dto/sensor-dto';
import { SensorRegistrationDto } from '../../../gapicon/sensor/dto/sensor-registration-dto';
import { SensorSearchCriteriaDto } from '../../../gapicon/sensor/dto/sensor-search-criteria-dto';
import { SensorApiService } from '../../../gapicon/sensor/services/sensor-api.service';
import { PhoenixCommunicationSubject } from '../../../services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixNavigationService } from '../../../services/phoenix-navigation-service/phoenix-navigation.service';
import { PhoenixSensormanagementDialogComponent } from './phoenix-sensormanagement-dialog.component';
import { PhoenixSensormanagementInformationDialogComponent } from './phoenix-sensormanagement-information-dialog/phoenix-sensormanagement-information-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../gapicon/sensor/services/sensor-api.service";
import * as i3 from "../../../services/phoenix-communication-service/phoenix-communication.service";
import * as i4 from "../../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../services/phoenix-navigation-service/phoenix-navigation.service";
var PhoenixSensormanagementDialogService = /** @class */ (function () {
    function PhoenixSensormanagementDialogService(dialog, sensorApiService, communicationService, snackbarService, translateService, navigationService) {
        this.dialog = dialog;
        this.sensorApiService = sensorApiService;
        this.communicationService = communicationService;
        this.snackbarService = snackbarService;
        this.translateService = translateService;
        this.navigationService = navigationService;
    }
    PhoenixSensormanagementDialogService.prototype.openSensormanagementInformationDialog = function (assets) {
        var dialogRef = this.dialog.open(PhoenixSensormanagementInformationDialogComponent, {
            autoFocus: false,
            maxWidth: 800,
            data: {
                title: this.translateService.instant('MEASUREMENTTYPEINFORMATIONDIALOG.TITLE'),
                subtitle: 'MEASUREMENTTYPEINFORMATIONDIALOG.SUBTITLE',
                buttons: [
                    new PhoenixDialogButton({
                        label: 'MEASUREMENTTYPEINFORMATIONDIALOG.CLOSEBUTTON',
                        click: function () { return dialogRef.close(); },
                    }),
                ],
                links: this.mapAssetsToLinks(assets, function () { return dialogRef.close(); }),
            },
        });
        return dialogRef;
    };
    PhoenixSensormanagementDialogService.prototype.openSensorDialog = function (assetDto, sensorType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var sensorTypes, _a, formElements, isAutomaticCounter, dialogRef;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.formGroup = new FormGroup({
                            sensorName: new FormControl(assetDto ? assetDto.name : '', [Validators.required]),
                            sensorDescription: new FormControl(assetDto ? assetDto.description : ''),
                            sensorType: new FormControl(sensorType, [Validators.required]),
                            sensorId: new FormControl(assetDto ? assetDto.sensor.sensorId : '', [Validators.required], this.forbiddenSensorIdValidator(_.get(assetDto, 'sensor.sensorId'))),
                        });
                        if (!assetDto) return [3 /*break*/, 1];
                        _a = [sensorType];
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.sensorApiService.getSensorTypes().toPromise()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        sensorTypes = _a;
                        if (assetDto) {
                            this.formGroup.get('sensorType').disable();
                        }
                        else if (!assetDto && sensorTypes.length > 0) {
                            this.formGroup.get('sensorType').setValue(sensorTypes[0]);
                        }
                        formElements = this.getElements(sensorTypes);
                        isAutomaticCounter = sensorType ? sensorType.measurementTypes.includes("automaticCounter") : false;
                        dialogRef = this.dialog.open(PhoenixSensormanagementDialogComponent, {
                            width: '550px',
                            autoFocus: false,
                            data: {
                                color: isAutomaticCounter ? "warn" : 'accent',
                                title: 'WIZARDS.SENSOR' + (assetDto ? '.EDIT' : '') + '.HEADLINE',
                                subtitle: 'WIZARDS.SENSOR' + (assetDto ? '.EDIT' : '') + '.SUBHEADLINE',
                                buttons: [
                                    new PhoenixDialogButton({ label: 'WIZARDS.SENSOR.CANCEL', click: function () { return dialogRef.close(undefined); } }),
                                    new PhoenixDialogButton({
                                        label: 'WIZARDS.SENSOR.SAVE', click: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!assetDto) return [3 /*break*/, 2];
                                                        return [4 /*yield*/, this.updateSensor(this.formGroup, assetDto)];
                                                    case 1:
                                                        assetDto = _a.sent();
                                                        return [3 /*break*/, 4];
                                                    case 2: return [4 /*yield*/, this.createSensorRegistration(this.formGroup)];
                                                    case 3:
                                                        assetDto = _a.sent();
                                                        _a.label = 4;
                                                    case 4:
                                                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadSensorPage);
                                                        dialogRef.close(assetDto);
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }, disabled: function () {
                                            return _this.formGroup.invalid || _this.formGroup.pending;
                                        }, color: 'accent', raised: true,
                                    })
                                ],
                                formGroup: this.formGroup,
                                elements: formElements,
                                sensorConfigPreset: assetDto ? assetDto.sensor.wantedConfiguration.configurations : undefined,
                                sensorTypeDropdown: formElements[3][0],
                                showRegistrationParams: assetDto === undefined,
                            },
                        });
                        return [2 /*return*/, dialogRef];
                }
            });
        });
    };
    PhoenixSensormanagementDialogService.prototype.mapAssetsToLinks = function (assets, closeDialog) {
        var _this = this;
        var linkList = [];
        var _loop_1 = function (asset) {
            linkList.push(new PhoenixTableLink(asset.name, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.navigationService.navigateToAsset(asset)];
                        case 1:
                            _a.sent();
                            closeDialog();
                            return [2 /*return*/];
                    }
                });
            }); }));
        };
        for (var _i = 0, assets_1 = assets; _i < assets_1.length; _i++) {
            var asset = assets_1[_i];
            _loop_1(asset);
        }
        return linkList;
    };
    PhoenixSensormanagementDialogService.prototype.createSensorRegistration = function (formGroup) {
        return __awaiter(this, void 0, void 0, function () {
            var sensorRegistrationDto, sensor;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sensorRegistrationDto = new SensorRegistrationDto({
                            registrationParams: this.applyFormValuesToSensorConfig(formGroup.get('REGISTRATIONCONFIG'), new SensorTypeDto(formGroup.get('sensorType').value).registrationParams),
                            sensorAsset: this.applyFormValuesToSensorDto(formGroup),
                        });
                        return [4 /*yield*/, this.sensorApiService.registerSensor(sensorRegistrationDto).toPromise()];
                    case 1:
                        sensor = _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.SENSOR.CREATE.SUCCESS'));
                        return [2 /*return*/, sensor];
                }
            });
        });
    };
    PhoenixSensormanagementDialogService.prototype.updateSensor = function (formGroup, assetDto) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.applyFormValuesToSensorConfig(formGroup.get('SENSORCONFIG'), assetDto.sensor.wantedConfiguration.configurations);
                        this.applyFormValuesToSensorDto(formGroup, assetDto);
                        return [4 /*yield*/, this.sensorApiService.updateSensor(assetDto).toPromise()];
                    case 1:
                        assetDto = _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.SENSOR.EDIT.SUCCESS'));
                        return [2 /*return*/, assetDto];
                }
            });
        });
    };
    PhoenixSensormanagementDialogService.prototype.applyFormValuesToSensorDto = function (formGroup, assetDto) {
        if (assetDto === void 0) { assetDto = new AssetDto(); }
        console.log('applyFormValuesToSensorDt', assetDto);
        assetDto.name = formGroup.get('sensorName').value;
        assetDto.description = formGroup.get('sensorDescription').value;
        if (!assetDto.sensor) {
            assetDto.tags = [AssetTags.sensor];
            assetDto.sensor = new SensorDto();
            assetDto.sensor.wantedConfiguration = new SensorConfigDto({ configurations: new SensorTypeDto(formGroup.get('sensorType').value).configurations });
            assetDto.sensor.currentConfiguration = new SensorConfigDto({ configurations: [] });
        }
        assetDto.sensor.sensorId = formGroup.get('sensorId').value;
        assetDto.sensor.type = formGroup.get('sensorType').value.id;
        this.applyFormValuesToSensorConfig(formGroup.get('SENSORCONFIG'), assetDto.sensor.wantedConfiguration.configurations);
        return assetDto;
    };
    PhoenixSensormanagementDialogService.prototype.applyFormValuesToSensorConfig = function (formGroup, sensorConfig) {
        if (sensorConfig === void 0) { sensorConfig = []; }
        _.forEach(formGroup.controls, function (formControl, key) {
            var foundSensorConfig = _.find(sensorConfig, function (sensorConfigValue) { return sensorConfigValue.key === key; });
            if (foundSensorConfig) {
                foundSensorConfig.value = formGroup.get(key).value;
            }
            else {
                sensorConfig.push(new SensorConfigValueDto({
                    value: formGroup.get(key).value,
                    key: key,
                }));
            }
        });
        return sensorConfig;
    };
    PhoenixSensormanagementDialogService.prototype.createSensorTypeField = function (sensorTypes) {
        var entries = _.map(sensorTypes, function (sensorType) { return ({
            value: sensorType,
            viewValue: sensorType.name,
        }); });
        //console.log('entries', entries)
        return new PhoenixFormDropdown(this.formGroup, 'sensorType', false, 'WIZARDS.SENSOR.TYPE', entries, function (e1, e2) { return (e1 && e2) ? e1.id === e2.id : false; });
    };
    PhoenixSensormanagementDialogService.prototype.getElements = function (sensorTypes) {
        var elements = [
            [new PhoenixFormText(this.formGroup, 'sensorName', 'WIZARDS.SENSOR.SENSORNAME', '', 'WIZARDS.SENSOR.SENSORNAMEHINT', 1, 64, true, false)],
            [new PhoenixFormTextarea(this.formGroup, 'sensorDescription', 'WIZARDS.SENSOR.SENSORDESCRIPTION', '', 'WIZARDS.SENSOR.SENSORDESCRIPTIONHINT', 1, 100, false, false)],
            [new PhoenixFormText(this.formGroup, 'sensorId', 'WIZARDS.SENSOR.SENSORID', '', 'WIZARDS.SENSOR.SENSORIDHINT', 1, 64, true, false)],
            [this.createSensorTypeField(sensorTypes)],
        ];
        return elements;
    };
    PhoenixSensormanagementDialogService.prototype.forbiddenSensorIdValidator = function (ownSensorId) {
        var _this = this;
        return function (control) {
            clearTimeout(_this.debouncer);
            return Observable.create(function (observer) {
                _this.debouncer = setTimeout(function () {
                    var sc = new SensorSearchCriteriaDto({ sensorIds: [control.value] });
                    _this.sensorApiService.getSensors(sc).subscribe(function (next) {
                        var isOwnSensorId = _.find(next, { sensor: { sensorId: ownSensorId } });
                        // tslint:disable-next-line
                        var result = next.length === 0 || !_.isUndefined(isOwnSensorId) ? null : { 'forbiddenName': { value: control.value } };
                        if (!_.isNull(result)) {
                            _this.snackbarService.openPhoenixSnackbar(4000, 'right', 'top', _this.translateService.instant('WIZARDS.SENSOR.IDALREADYTAKEN'), 'red-bg');
                        }
                        observer.next(result);
                        observer.complete();
                    });
                }, 1000);
            });
        };
    };
    PhoenixSensormanagementDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixSensormanagementDialogService_Factory() { return new PhoenixSensormanagementDialogService(i0.inject(i1.MatDialog), i0.inject(i2.SensorApiService), i0.inject(i3.PhoenixCommunicationService), i0.inject(i4.PhoenixSnackbarService), i0.inject(i5.TranslateService), i0.inject(i6.PhoenixNavigationService)); }, token: PhoenixSensormanagementDialogService, providedIn: "root" });
    return PhoenixSensormanagementDialogService;
}());
export { PhoenixSensormanagementDialogService };
