var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var SensorTypeSearchCriteria = /** @class */ (function (_super) {
    __extends(SensorTypeSearchCriteria, _super);
    function SensorTypeSearchCriteria(sensorTypeSearchCriteria) {
        var _this = _super.call(this, sensorTypeSearchCriteria) || this;
        _this.measurementTypes = undefined;
        _this.types = undefined;
        Object.assign(_this, sensorTypeSearchCriteria);
        return _this;
    }
    SensorTypeSearchCriteria.prototype.toJSON = function () {
        return {
            measurementTypes: this.measurementTypes,
            types: this.types,
            page: this.page,
            size: this.size,
        };
    };
    return SensorTypeSearchCriteria;
}(SearchCriteriaPageable));
export { SensorTypeSearchCriteria };
