import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExtendedInformationTypeDto } from '../../../../gapicon/dto.module';
import { PhoenixMeasurementTypeExtendedInformationsDialog } from './phoenix-measurement-type-extended-informations-dialog';
var PhoenixMeasurementTypeExtendedInformationsDialogComponent = /** @class */ (function () {
    function PhoenixMeasurementTypeExtendedInformationsDialogComponent(data) {
        this.data = data;
        this.types = [
            {
                label: ExtendedInformationTypeDto.TEXT,
                value: ExtendedInformationTypeDto.TEXT,
            },
            {
                label: ExtendedInformationTypeDto.URL,
                value: ExtendedInformationTypeDto.URL,
            },
        ];
    }
    PhoenixMeasurementTypeExtendedInformationsDialogComponent.prototype.addElement = function () {
        this.data.extendedInformationForms.push(new FormGroup({
            key: new FormControl(this.data.checklistElementKey, Validators.required),
            name: new FormControl(undefined, Validators.required),
            type: new FormControl(ExtendedInformationTypeDto.TEXT, Validators.required),
            value: new FormControl(undefined, Validators.required),
        }));
    };
    PhoenixMeasurementTypeExtendedInformationsDialogComponent.prototype.ngOnInit = function () {
    };
    PhoenixMeasurementTypeExtendedInformationsDialogComponent.prototype.removeElement = function (i) {
        this.data.extendedInformationForms.removeAt(i);
    };
    return PhoenixMeasurementTypeExtendedInformationsDialogComponent;
}());
export { PhoenixMeasurementTypeExtendedInformationsDialogComponent };
