import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Observer, Subscription} from 'rxjs';

import {FuseProgressBarService} from '@fuse/components/progress-bar/progress-bar.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private requests: number[] = [];
  private reqId: number = 0;

  public constructor(
    private progressBar: FuseProgressBarService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentRequest: number = this.reqId++;
    this.addRequest(currentRequest);

    return Observable.create((observer: Observer<any>) => {
      const subscription: Subscription = next.handle(req).subscribe((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            observer.next(event);
          }
        },
        (err: HttpErrorResponse) => {
          this.removeRequest(currentRequest);
          observer.error(err);
        },
        () => {
          this.removeRequest(currentRequest);
          observer.complete();
        });
      // teardown logic in case of cancelled requests
      return (): void => {
        this.removeRequest(currentRequest);
        subscription.unsubscribe();
      };
    });
  }

  private addRequest(reqId: number): void {
    this.requests.push(reqId);
    this.progressBar.show();
  }

  private removeRequest(reqId: number): void {
    if (this.requests.indexOf(reqId) >= 0) {
      this.requests.splice(this.requests.indexOf(reqId), 1);
    }

    if (this.requests.length === 0) {
      this.progressBar.hide();
    }
  }
}
