var AlarmDto = /** @class */ (function () {
    function AlarmDto(alarmDto) {
        this.status = undefined;
        this.measurement = undefined;
        this.id = undefined;
        this.timestamp = undefined;
        this.comment = undefined;
        this.history = undefined;
        this.updateTimestamp = undefined;
        this.updateUser = undefined;
        this.type = undefined;
        Object.assign(this, alarmDto);
    }
    return AlarmDto;
}());
export { AlarmDto };
