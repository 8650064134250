import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PasswordChangeDto} from './account/dto/password-change-dto';
import {PasswordResetDto} from './account/dto/password-reset-dto';
import {PasswordResetRequestDto} from './account/dto/password-reset-request-dto';
import {RegistrationConfirmationDto} from './account/dto/registration-confirmation-dto';
import {RegistrationRequestDto} from './account/dto/registration-request-dto';
import {RegistrationResponseDto} from './account/dto/registration-response-dto';
import {RegistrationResultDto} from './account/dto/registration-result-dto.enum';
import {AlarmDto} from './alarm/dto/alarm-dto';
import {AlarmHistoryDto} from './alarm/dto/alarm-history-dto';
import {AlarmSearchCriteriaDto} from './alarm/dto/alarm-search-criteria-dto';
import {AlarmStatusDto} from './alarm/dto/alarm-status-dto.enum';
import {MeasurementPointDto} from './asset/asset-types/measurement-point/dto/measurement-point-dto';
import {TenantDto} from './asset/asset-types/tenant/dto/tenant-dto';
import {AssetDto} from './asset/dto/asset-dto';
import {AssetSearchCriteriaDto} from './asset/dto/asset-search-criteria-dto';
import {AssetTags} from './asset/dto/asset-tags';
import {AttachmentsDto} from './asset/dto/attachments-dto';
import {FileAttachmentDto} from './asset/dto/file-attachment-dto';
import {TaskConfigDto} from './asset/dto/task-config-dto';
import {TaskConfigRepeatConfigTypeDto} from './asset/dto/task-config-repeat-config-type-dto.enum';
import {TaskConfigRepeatDto} from './asset/dto/task-config-repeat-dto';
import {TaskConfigRepeatTypeDto} from './asset/dto/task-config-repeat-type-dto.enum';
import {UrlAttachmentDto} from './asset/dto/url-attachment-dto';
import {AuthInfoDto} from './auth/dto/auth-info-dto';
import {EmailDto} from './email/dto/email-dto';
import {EmailRequestDto} from './email/dto/email-request-dto';
import {FileItemDto} from './file/dto/file-item-dto';
import {FileItemTypeDto} from './file/dto/file-item-type-dto.enum';
import {FileStorageInformationDto} from './file/dto/file-storage-information-dto';
import {ExtendedInformationDto} from './measurement-type/dto/extended-information-dto';
import {ExtendedInformationTypeDto} from './measurement-type/dto/extended-information-type-dto.enum';
import {MeasurementTypeDto} from './measurement-type/dto/measurement-type-dto';
import {MeasurementTypeSearchCriteriaDto} from './measurement-type/dto/measurement-type-search-criteria-dto';
import {MeasurementValueTypeDto} from './measurement-type/dto/measurement-value-type-dto';
import {ValueTypeDto} from './measurement-type/dto/value-type-dto.enum';
import {LatestMeasurementFilterDto} from './measurement/dto/latest-measurement-filter-dto';
import {MeasurementDto} from './measurement/dto/measurement-dto';
import {MeasurementSearchCriteriaDto} from './measurement/dto/measurement-search-criteria-dto';
import {MeasurementValueDto} from './measurement/dto/measurement-value-dto';
import {NotificationConfigDto} from './notification-config/dto/notification-config-dto';
import {NotificationConfigSearchCriteriaDto} from './notification-config/dto/notification-config-search-criteria-dto';
import {NotificationTypeDto} from './notification-config/dto/notification-type-dto.enum';
import {PropertyDto} from './property/dto/property-dto';
import {RoleDto} from './role/dto/role-dto';
import {RoleSearchCriteriaDto} from './role/dto/role-search-criteria-dto';
import {SensorTypeDto} from './sensor/dto/sensor-type-dto';
import {StatisticsSearchCriteriaDto} from './statistics/dto/statistics-search-criteria-dto';
import {TaskDto} from './task/dto/task-dto';
import {TaskSearchCriteriaDto} from './task/dto/task-search-criteria-dto';
import {TaskStatus} from './task/enums/task-status.enum';
import {ThresholdDto} from './threshold/dto/threshold-dto';
import {ThresholdSearchCriteriaDto} from './threshold/dto/threshold-search-criteria-dto';
import {ThresholdStatusDto} from './threshold/dto/threshold-status-dto.enum';
import {ThresholdViolationStatisticsDto} from './threshold/dto/threshold-violation-statistics-dto';
import {AccountInfoDto} from './user/dto/account-info-dto';
import {UserConfigDto} from './user/dto/user-config-dto';
import {UserDto} from './user/dto/user-dto';
import {UserInfoDto} from './user/dto/user-info-dto';
import {UserSearchCriteriaDto} from './user/dto/user-search-criteria-dto';


export {
  AccountInfoDto,
  AlarmDto,
  AlarmHistoryDto,
  AlarmSearchCriteriaDto,
  AlarmStatusDto,
  AssetDto,
  AssetSearchCriteriaDto,
  AuthInfoDto,
  EmailDto,
  EmailRequestDto,
  ExtendedInformationDto,
  FileAttachmentDto,
  FileItemDto,
  FileItemTypeDto,
  LatestMeasurementFilterDto,
  MeasurementDto,
  MeasurementPointDto,
  MeasurementSearchCriteriaDto,
  MeasurementTypeDto,
  MeasurementTypeSearchCriteriaDto,
  MeasurementValueDto,
  MeasurementValueTypeDto,
  NotificationConfigDto,
  NotificationConfigSearchCriteriaDto,
  NotificationTypeDto,
  PasswordChangeDto,
  PasswordResetDto,
  PasswordResetRequestDto,
  PropertyDto,
  RegistrationConfirmationDto,
  RegistrationRequestDto,
  RegistrationResponseDto,
  RegistrationResultDto,
  RoleDto,
  RoleSearchCriteriaDto,
  SensorTypeDto,
  StatisticsSearchCriteriaDto,
  TenantDto,
  ThresholdDto,
  ThresholdSearchCriteriaDto,
  ThresholdStatusDto,
  ThresholdViolationStatisticsDto,
  UrlAttachmentDto,
  UserDto,
  UserInfoDto,
  UserSearchCriteriaDto,
  ValueTypeDto,
  ExtendedInformationTypeDto,
  UserConfigDto,
  TaskDto,
  TaskStatus,
  TaskSearchCriteriaDto,
  AssetTags,
  TaskConfigDto,
  TaskConfigRepeatConfigTypeDto,
  TaskConfigRepeatDto,
  TaskConfigRepeatTypeDto,
  FileStorageInformationDto,
  AttachmentsDto,
};

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
})
export class DtoModule {
}
