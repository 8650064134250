export enum PhoenixCommunicationSubject {
  ReloadCheckpointPage,
  ReloadThresholdPage,
  ReloadUserPage,
  ReloadUserGroupPage,
  ReloadNotificationsPage,
  ReloadMeasurementTypePage,
  ReloadDeviceAccountPage,
  ReloadSensorPage,
  ReloadTenantmanagementPage,
  ReloadTaskPage,
  FilterChanged,
  MeasurementPointSelected,
  MeasurementPointSelectedWithTaskDuration,
  UpdateDateTimePickersSelectedPreset,
  UserChanged,
  UserGroupChanged,
  UserLogin,
  UserLogout,
  AttachmentDeleted,
  TenantChanged,
  AssetBranchChanged,
  UserUpdated,
  UserRegistered,
  UserUnblocked,
  UserBlocked,
  GenerateReportMeasurementPoint,
  ReloadSidebarMenu,
  ReloadTenantJobManagementPage

}
