import { plainToClass } from 'class-transformer';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { PasswordChange } from '../api/password-change';
import { PasswordReset } from '../api/password-reset';
import { PasswordResetRequest } from '../api/password-reset-request';
import { RegistrationConfirmation } from '../api/registration-confirmation';
import { RegistrationRequest } from '../api/registration-request';
import { RegistrationResponseDto } from '../dto/registration-response-dto';
import * as i0 from "@angular/core";
import * as i1 from "../../user/mapper/user-mapper.service";
var AccountMapperService = /** @class */ (function () {
    function AccountMapperService(userMapper) {
        this.userMapper = userMapper;
    }
    AccountMapperService.prototype.passwordChangeDtoToPasswordChange = function (passwordChangeDto) {
        return plainToClass(PasswordChange, passwordChangeDto);
    };
    AccountMapperService.prototype.passwordResetDtoToPasswordReset = function (passwordResetDto) {
        return plainToClass(PasswordReset, passwordResetDto);
    };
    AccountMapperService.prototype.registrationRequestDtoToRegistrationRequest = function (registrationRequestDto) {
        return plainToClass(RegistrationRequest, registrationRequestDto);
    };
    AccountMapperService.prototype.registratrionResponseToRegistratrionResponseDto = function (registrationResponse) {
        return plainToClass(RegistrationResponseDto, registrationResponse);
    };
    AccountMapperService.prototype.registrationConfirmationDtoToRegistrationConfirmation = function (registrationConfirmationDto) {
        var registrationConfirmation = plainToClass(RegistrationConfirmation, registrationConfirmationDto);
        registrationConfirmation.user = this.userMapper.userDtoToUser(registrationConfirmationDto.user);
        return registrationConfirmation;
    };
    AccountMapperService.prototype.passwordResetRequestDtoToPasswordResetRequest = function (passwortResetRequestDto) {
        return plainToClass(PasswordResetRequest, passwortResetRequestDto);
    };
    AccountMapperService.ngInjectableDef = i0.defineInjectable({ factory: function AccountMapperService_Factory() { return new AccountMapperService(i0.inject(i1.UserMapperService)); }, token: AccountMapperService, providedIn: "root" });
    return AccountMapperService;
}());
export { AccountMapperService };
