import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { UserGroupMapperService } from '../mapper/usergroup-mapper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/shared.service";
import * as i3 from "../mapper/usergroup-mapper.service";
var GroupApiService = /** @class */ (function () {
    function GroupApiService(http, sharedService, userGroupMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.userGroupMapperService = userGroupMapperService;
        this.groupsUrl = 'userGroups';
        this.group = 'userGroup';
    }
    GroupApiService.prototype.getGroups = function (allGroupsVisibility, userGroupSearchCriteriaDto) {
        var _this = this;
        if (allGroupsVisibility === void 0) { allGroupsVisibility = true; }
        var params = '';
        if (userGroupSearchCriteriaDto !== undefined && userGroupSearchCriteriaDto.partialName !== undefined && userGroupSearchCriteriaDto.partialName !== null) {
            params = '?partialName=' + userGroupSearchCriteriaDto.partialName;
        }
        return this.http.get(this.sharedService.buildApiUrl(this.groupsUrl) + params).pipe(map(function (groups) {
            return _this.userGroupMapperService.groupsToGroupsDto(groups, allGroupsVisibility);
        }));
    };
    /**
     * Creates a userGroup by given userGroupDto.
     * @returns
     * @param groupDto
     */
    GroupApiService.prototype.createNewGroup = function (userGroupDto) {
        return this.http.post(this.sharedService.buildApiUrl(this.groupsUrl, this.group), this.userGroupMapperService.groupDtoToGroup(userGroupDto));
        // .pipe(
        //     map((gr: Group) => this.groupsToGroupsDto([gr])),
        // );
    };
    /**
     * updates userGroup by given userGroupDto.
     * @returns
     * @param groupDto
     */
    GroupApiService.prototype.updateUserGroup = function (groupDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this.groupsUrl, this.group, groupDto.id.toString()), this.userGroupMapperService.groupDtoToGroup(groupDto)).pipe(map(function (gr) { return _this.userGroupMapperService.groupToGroupDto(gr); }));
    };
    /**
     * deleted a userGroup by given userGroupDto.
     * @returns
     * @param groupDto
     */
    GroupApiService.prototype.deleteUserGroup = function (groupDto) {
        return this.http.delete(this.sharedService.buildApiUrl(this.groupsUrl, this.group, groupDto.id.toString()));
    };
    GroupApiService.ngInjectableDef = i0.defineInjectable({ factory: function GroupApiService_Factory() { return new GroupApiService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService), i0.inject(i3.UserGroupMapperService)); }, token: GroupApiService, providedIn: "root" });
    return GroupApiService;
}());
export { GroupApiService };
