<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">

  <phoenix-form [formGroup]="data.formGroup">
    <div fxLayout="column" fxLayoutGap="10px">
      <div *ngFor="let row of data.elements" class="phoenixDialogFormControlWrapper" fxLayout="row" fxLayoutGap="10px">
        <div *ngFor="let element of row" [ngSwitch]="element.type" fxFlex="">
          <phoenix-form-text *ngSwitchCase="elementType.Text" [element]="element"></phoenix-form-text>
          <phoenix-form-textarea *ngSwitchCase="elementType.TextArea" [element]="element"></phoenix-form-textarea>
          <phoenix-form-switch *ngSwitchCase="elementType.Switch" [element]="element"></phoenix-form-switch>
          <phoenix-form-icon-button *ngSwitchCase="elementType.IconButton"
            [element]="element"></phoenix-form-icon-button>
        </div>
      </div>

      <!-- TODO: Diesen Teil ggf. zu einer eigenen Form-Komponente machen -->
      <div formArrayName="checklistelements">
        <div *ngFor="let element of data.checklistelementsForms.controls; let i=index" [formGroupName]="i"
          class="phoenixDialogFormControlWrapper" fxLayout="row" fxLayoutGap="10px">
          <!--<phoenix-dynamic-dropdown></phoenix-dynamic-dropdown>-->
          <mat-form-field>
            <input [formControlName]="'name'" [maxlength]="64"
              [placeholder]="'WIZARDS.MEASUREMENTTYPE.CHECKLISTELEMENT' | translate" matInput type="text">
            <mat-hint align="end">
              {{element.get('name').value?.length || 0}}/64
            </mat-hint>
            <mat-error *ngIf="element.invalid">
              <span data-translate="DIALOG.FORM.INVALID" translate></span>
            </mat-error>
          </mat-form-field>
          <!-- TODO: Einkommentieren für Typenselektor für variable Messlisten -->
          <mat-form-field>
            <mat-select [placeholder]="'WIZARDS.MEASUREMENTTYPE.ELEMENTTYPE' | translate" [formControlName]="'type'">
              <mat-option *ngFor="let entry of measurementType" [value]="entry">{{ 'WIZARDS.MEASUREMENTTYPE.' + entry |
                translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- /TODO -->
          <div *ngIf="element" class="mt-12 mb-12">
            <mat-slide-toggle formControlName="notification">
              {{'WIZARDS.MEASUREMENTTYPE.NOTIFICATION' | translate}}
            </mat-slide-toggle>
          </div>
          <div *ngIf="element" class="mt-12 mb-12">
            <button (click)="openExtendedInformationDialog(element)" [disabled]="data.disableEditing" mat-icon-button>
              <mat-icon [matBadge]="element.value.extendedInformations.length">attach_file</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- / TODO -->
    </div>
  </phoenix-form>

</phoenix-dialog-new>