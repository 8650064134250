import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { AuthInfoDto } from '../dto/auth-info-dto';
var AuthApiService = /** @class */ (function () {
    /**
     * Constructor
     * @param http
     * @param sharedService
     * @param userMapperService
     */
    function AuthApiService(http, sharedService, userMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.userMapperService = userMapperService;
    }
    /**
     * logging in a user by tenant, email and passwords.
     * @param email
     * @param password
     * @returns Resolves with true if login was successful
     */
    AuthApiService.prototype.login = function (email, password) {
        var _this = this;
        return Observable.create(function (subscriber) {
            var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
            var data = new HttpParams({ encoder: new CustomEncoderForHttpParameter() })
                .set('grant_type', 'password')
                .set('username', email)
                .set('password', password)
                .set('client_id', AuthApiService.clientId)
                .set('client_secret', AuthApiService.clientSecret)
                .set('scope', AuthApiService.scope);
            _this.http.post(_this.sharedService.buildAuthUrl('token'), data, { headers: headers }).subscribe(function (response) {
                AuthApiService.auth = new AuthInfoDto(_this.userMapperService);
                AuthApiService.auth.applyTokenRequestResponse(response);
                // save user Data to local storage
                localStorage.setItem('userData', JSON.stringify(response));
                subscriber.next(true);
                subscriber.complete();
                // tslint:disable-next-line
            }, function (error) {
                subscriber.next(false);
                subscriber.error(error);
            });
        });
    };
    /**
     * Automatic logging in a user by tenant, email and passwords based on user data from
     * local storage when user refresh the browser page
     * @returns Resolves with true if user was logged in before refershing page or not
     */
    AuthApiService.prototype.autoLogin = function () {
        // getting user Data from local storage
        var userData = JSON.parse(localStorage.getItem('userData'));
        var currentTenants = JSON.parse(localStorage.getItem('selectedTenants'));
        var currentAssetBranch = JSON.parse(localStorage.getItem('selectedAssetBranches'));
        // getting tenant data from local storage
        if (!userData || !currentTenants || currentTenants.length == 0 || !currentAssetBranch || currentAssetBranch.length == 0) {
            return false;
        }
        // apply user data for request
        userData.autoLogin = true;
        AuthApiService.auth = new AuthInfoDto(this.userMapperService);
        AuthApiService.auth.applyTokenRequestResponse(userData);
        return true;
    };
    /**
     * Requesting new access_token with stored refresh_token. User has to be logged in first.
     * @returns Returns observable which resolves with true if refreshing token was successful
     */
    AuthApiService.prototype.refresh = function () {
        var _this = this;
        return Observable.create(function (subscriber) {
            if (!AuthApiService.auth) {
                subscriber.error('User is not logged in');
                subscriber.complete();
            }
            else {
                var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
                var data = new HttpParams({ encoder: new CustomEncoderForHttpParameter() })
                    .set('grant_type', 'refresh_token')
                    .set('client_id', AuthApiService.clientId)
                    .set('client_secret', AuthApiService.clientSecret)
                    .set('scope', AuthApiService.scope)
                    .set('refresh_token', AuthApiService.auth.refresh_token);
                _this.http.post(_this.sharedService.buildAuthUrl('token'), data, { headers: headers }).subscribe(function (response) {
                    AuthApiService.auth.applyTokenRequestResponse(response);
                    localStorage.setItem('userData', JSON.stringify(response));
                    subscriber.next(true);
                    subscriber.complete();
                    // tslint:disable-next-line
                }, function (error) {
                    _this.logout();
                    subscriber.next(false);
                    subscriber.error(error);
                });
            }
        });
    };
    /**
     * Returns current auth info
     * @returns
     */
    AuthApiService.prototype.getAuth = function () {
        return AuthApiService.auth;
    };
    /**
     * Logout current user
     */
    AuthApiService.prototype.logout = function () {
        // delete data from local storage when user logout
        localStorage.removeItem('userData');
        localStorage.removeItem('selectedTenants');
        localStorage.removeItem('selectedAssetBranches');
        localStorage.removeItem('userSelection');
        localStorage.removeItem('selectedLanguage');
        localStorage.removeItem('availableTenants');
        AuthApiService.auth = null;
    };
    /**
     * Sets tenant for all requests
     * @param tenants
     */
    AuthApiService.prototype.setCurrentTenants = function (tenants) {
        // tenants added in header
        AuthApiService.auth = new AuthInfoDto(this.userMapperService, AuthApiService.auth);
        AuthApiService.auth.currentTenants = tenants;
    };
    AuthApiService.prototype.setGroupAssetPairs = function (groupApiPairs) {
        AuthApiService.auth.groupAssetPairs = groupApiPairs;
    };
    AuthApiService.prototype.getTenantsIdsFromGroupRole = function (roleArray) {
        if (roleArray) {
            return roleArray.map(function (item) {
                var items = item.split('.');
                return items.length === 2 ? items[1] : item;
            });
        }
        else {
            return [];
        }
    };
    AuthApiService.auth = undefined;
    AuthApiService.clientSecret = 'etFNu55RoCniu4UefUd3';
    AuthApiService.clientId = 'mcf_fe';
    AuthApiService.scope = 'mcf';
    return AuthApiService;
}());
export { AuthApiService };
