import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Image } from '@ks89/angular-modal-gallery';
import * as _ from 'lodash';
var PhoenixGalleryComponent = /** @class */ (function () {
    function PhoenixGalleryComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.color = 'accent';
    }
    PhoenixGalleryComponent.prototype.ngOnInit = function () {
        var modalImages = [];
        _.forEach(this.data.files, function (file, idx) {
            var imageData = "data:image/" + file.contentType + ";base64," + file.data;
            var image = { img: imageData };
            modalImages.push(new Image(idx, image));
        });
        this.images = modalImages;
    };
    PhoenixGalleryComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    return PhoenixGalleryComponent;
}());
export { PhoenixGalleryComponent };
