import {format, isValid} from 'date-fns';

import {JsonExportable} from '../../shared/classes/json-exportable';

import {FileItemType} from './file-item-type.enum';

export class FileItem implements JsonExportable {
  public childItems: string[] = undefined;
  public contentEncoding: string = undefined;
  public contentLength: number = undefined;
  public contentMd5: string = undefined;
  public contentType: string = undefined;
  public data: string = undefined;
  public lastModified: Date = undefined;
  public name: string = undefined;
  public owner: string = undefined;
  public parent: string = undefined;
  public type: FileItemType = undefined;

  public constructor(fileItem?: Partial<FileItem>) {
    Object.assign(this, fileItem);
  }

  public toJSON(): Object {
    return {
      owner: this.owner,
      type: this.type,
      parent: this.parent,
      childItems: this.childItems,
      lastModified: (this.lastModified && isValid(this.lastModified)) ? format(this.lastModified) : undefined,
      name: this.name,
      data: this.data,
      contentType: this.contentType,
      contentEncoding: this.contentEncoding,
      contentLength: this.contentLength,
      contentMd5: this.contentMd5,
    };
  }
}
