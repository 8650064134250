import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {PhoenixDialogButton} from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import {AssetDto} from '../../../gapicon/asset/dto/asset-dto';

import {PhoenixAssetBranchSelectorDialogData} from './classes/phoenix-asset-branchselector-dialog-data';


@Component({
    selector: 'phoenix-asset-branchselector-dialog',
    templateUrl: './phoenix-asset-branchselector-dialog.component.html',
    styleUrls: ['./phoenix-asset-branchselector-dialog.component.scss'],
})
export class PhoenixAsseBranchSelectorDialogComponent implements OnInit {

    public buttons: PhoenixDialogButton[];

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: PhoenixAssetBranchSelectorDialogData,
        private dialogRef: MatDialogRef<PhoenixAsseBranchSelectorDialogComponent>,
    ) {
    }

    public ngOnInit(): void {

        if (this.data.cancelButton) {
            this.buttons = [
                new PhoenixDialogButton({
                    label: 'WIZARDS.ASSETBRANCH.CANCEL',
                    click: (): void => this.dialogRef.close(undefined),
                }),
            ];
        }
    }

    public selectBranch(branch: AssetDto): void {
        this.dialogRef.close([branch]);
    }

}
