import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomEncoderForHttpParameter } from '@phoenix/classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';
var ReportApiService = /** @class */ (function () {
    function ReportApiService(http, sharedService) {
        this.http = http;
        this.sharedService = sharedService;
    }
    /**
     * Gets the hierarchy from an asset up to a root asset
     * @param asset
     */
    ReportApiService.prototype.getReportData = function (reportSearchCriteria) {
        var options = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
        options.params = reportSearchCriteria.getRequestQueryParams();
        return this.http.get(this.sharedService.buildApiUrl('report', 'data'), options);
    };
    return ReportApiService;
}());
export { ReportApiService };
