import { PhoenixFormElementType } from '../../enums/phoenix-form-element-type.enum';
var PhoenixFormIconButton = /** @class */ (function () {
    function PhoenixFormIconButton(formGroup, formControlName, icon, disabled, click) {
        this.type = PhoenixFormElementType.IconButton;
        this.formGroup = formGroup;
        this.formControlName = formControlName;
        this.icon = icon;
        this.disabled = disabled;
        this.click = click;
    }
    return PhoenixFormIconButton;
}());
export { PhoenixFormIconButton };
