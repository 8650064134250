import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { PhoenixAutocompleteEntry } from '../../components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetSearchCriteriaDto } from '../../gapicon/dto.module';
import { MeasurementTypeApiService } from '../../gapicon/measurement-type/services/measurement-type-api.service';
import { SensorSearchCriteriaDto } from '../../gapicon/sensor/dto/sensor-search-criteria-dto';
import { SensorApiService } from '../../gapicon/sensor/services/sensor-api.service';
import { ThresholdApiService } from '../../gapicon/threshold/services/threshold-api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../gapicon/threshold/services/threshold-api.service";
import * as i2 from "../../gapicon/measurement-type/services/measurement-type-api.service";
import * as i3 from "../../gapicon/asset/services/asset-api.service";
import * as i4 from "../../gapicon/sensor/services/sensor-api.service";
var PhoenixWizardHelperService = /** @class */ (function () {
    function PhoenixWizardHelperService(thresholdApi, measurementValueApi, assetApi, sensorApi) {
        this.thresholdApi = thresholdApi;
        this.measurementValueApi = measurementValueApi;
        this.assetApi = assetApi;
        this.sensorApi = sensorApi;
        this._DIALOGWIDTH = '448px';
        this._DIALOGWIDTHBIG = '550px';
    }
    Object.defineProperty(PhoenixWizardHelperService.prototype, "DIALOGWIDTH", {
        get: function () {
            return this._DIALOGWIDTH;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixWizardHelperService.prototype, "DIALOGWIDTHBIG", {
        get: function () {
            return this._DIALOGWIDTHBIG;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Generates a random string
     */
    PhoenixWizardHelperService.prototype.getRandomString = function () {
        return Math.random().toString(36).substring(7);
    };
    /**
     * Gets an AutoComplete array of all available thresholds
     */
    PhoenixWizardHelperService.prototype.getAutoCompleteThresholdArray = function () {
        return this.thresholdApi.getThresholds().pipe(map(function (thresholds) { return _.map(thresholds, function (threshold) { return new PhoenixAutocompleteEntry({
            label: threshold.name,
            object: threshold,
        }); }); }));
    };
    /**
     * Gets an array of auto complete entries for MeasurementTypes
     */
    PhoenixWizardHelperService.prototype.getAutoCompleteMeasurementTypeArray = function () {
        return this.measurementValueApi.getMeasurementTypes().pipe(map(function (measurementTypes) { return _.filter(measurementTypes, function (measurementType) { return measurementType.tags.includes(AssetTags.checklist); }); }), map(function (measurementTypes) {
            return _.map(measurementTypes, function (measurementType) { return new PhoenixAutocompleteEntry({
                label: measurementType.name,
                object: measurementType,
            }); });
        }));
    };
    /**
     * Gets an array of auto complete entries for Sensors
     */
    PhoenixWizardHelperService.prototype.getAutoCompleteSensorArray = function () {
        return this.sensorApi.getSensors(new SensorSearchCriteriaDto({ isAssigned: false })).pipe(map(function (assetDtos) {
            return _.map(assetDtos, function (assetDto) { return new PhoenixAutocompleteEntry({
                label: assetDto.name,
                object: assetDto,
            }); });
        }));
    };
    PhoenixWizardHelperService.prototype.getSensorById = function (sensorId) {
        return this.sensorApi.getSensors(new SensorSearchCriteriaDto({ sensorIds: [sensorId] })).pipe(map(function (sensors) {
            var autocompleteEntry = new PhoenixAutocompleteEntry();
            if (sensors.length === 1) {
                autocompleteEntry.label = sensors[0].name;
                autocompleteEntry.object = sensors[0];
            }
            return autocompleteEntry;
        }));
    };
    /**
     * Searches for assets
     * @param search part of the name the assets should have
     * @param filterAsset optional filter to filter out assets that have the id of filterAsset or are a child of filterAsset
     * @param tagFilter optional filter to filter out assets that have at least one of the given tags (defaults to measurement points)
     */
    PhoenixWizardHelperService.prototype.assetAutocompleteSearchFunction = function (search, filterAsset, tagFilter) {
        if (tagFilter === void 0) { tagFilter = [AssetTags.asset, AssetTags.checkpoint]; }
        return this.assetApi.getAssets(new AssetSearchCriteriaDto({
            partialName: search,
            tags: tagFilter,
            size: 10,
        }), false).pipe(
        // Filter assets matching filterAsset Argument
        map(function (assets) { return filterAsset ? _.filter(assets, function (asset) { return asset.id !== filterAsset.id && asset.parentId !== filterAsset.id; }) : assets; }), 
        // Convert to Autocomplete Format
        map(function (assets) { return _.map(assets, function (asset) { return new PhoenixAutocompleteEntry({ object: asset, label: asset.name }); }); }));
    };
    PhoenixWizardHelperService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixWizardHelperService_Factory() { return new PhoenixWizardHelperService(i0.inject(i1.ThresholdApiService), i0.inject(i2.MeasurementTypeApiService), i0.inject(i3.AssetApiService), i0.inject(i4.SensorApiService)); }, token: PhoenixWizardHelperService, providedIn: "root" });
    return PhoenixWizardHelperService;
}());
export { PhoenixWizardHelperService };
