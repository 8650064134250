/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-dynamic-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "@angular/forms";
import * as i14 from "./phoenix-dynamic-button.component";
var styles_PhoenixDynamicButtonComponent = [i0.styles];
var RenderType_PhoenixDynamicButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixDynamicButtonComponent, data: {} });
export { RenderType_PhoenixDynamicButtonComponent as RenderType_PhoenixDynamicButtonComponent };
function View_PhoenixDynamicButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixDynamicButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-button", ""], ["type", "submit"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonCallback((_co.phoenixFormControl.options["type"] ? _co.phoenixFormControl.options["style"] : "submit")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i4.Platform, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDynamicButtonComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = _co.buttonColor; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.showSpinner; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.phoenixFormControl.label)); _ck(_v, 4, 0, currVal_5); }); }
function View_PhoenixDynamicButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixDynamicButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-raised-button", ""], ["type", "submit"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonCallback((_co.phoenixFormControl.options["type"] ? _co.phoenixFormControl.options["style"] : "submit")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i4.Platform, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDynamicButtonComponent_5)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = _co.buttonColor; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.showSpinner; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.phoenixFormControl.label)); _ck(_v, 4, 0, currVal_5); }); }
function View_PhoenixDynamicButtonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "mini-fab"], ["mat-mini-fab", ""], ["type", "submit"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonCallback((_co.phoenixFormControl.options["type"] ? _co.phoenixFormControl.options["style"] : "submit")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i4.Platform, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-icon", [["class", "mini-fab-icon notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(3, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(4, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], { inline: [0, "inline"] }, null), (_l()(), i1.ɵted(5, 0, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = _co.buttonColor; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_5 = _co.getIconStyle(); _ck(_v, 3, 0, currVal_5); var currVal_6 = true; _ck(_v, 4, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 4).inline; _ck(_v, 2, 0, currVal_4); var currVal_7 = _co.phoenixFormControl.value; _ck(_v, 5, 0, currVal_7); }); }
function View_PhoenixDynamicButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_6)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.phoenixFormControl.options["style"]; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.phoenixFormControl.options["style"] === "raised"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.phoenixFormControl.options["style"] === "mini-fab-icon"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PhoenixDynamicButtonComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixDynamicButtonComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callbackFromOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i4.Platform, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDynamicButtonComponent_9)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = _co.buttonColor; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.showSpinner; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.phoenixFormControl.label)); _ck(_v, 4, 0, currVal_5); }); }
function View_PhoenixDynamicButtonComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixDynamicButtonComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callbackFromOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i4.Platform, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDynamicButtonComponent_11)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = _co.buttonColor; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.showSpinner; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.phoenixFormControl.label)); _ck(_v, 4, 0, currVal_5); }); }
function View_PhoenixDynamicButtonComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "mini-fab"], ["mat-mini-fab", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callbackFromOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i8.MatButton, [i1.ElementRef, i4.Platform, i9.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-icon", [["class", "mini-fab-icon notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(3, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(4, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], { inline: [0, "inline"] }, null), (_l()(), i1.ɵted(5, 0, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = _co.buttonColor; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_5 = _co.getIconStyle(); _ck(_v, 3, 0, currVal_5); var currVal_6 = true; _ck(_v, 4, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 4).inline; _ck(_v, 2, 0, currVal_4); var currVal_7 = _co.phoenixFormControl.value; _ck(_v, 5, 0, currVal_7); }); }
function View_PhoenixDynamicButtonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_8)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_10)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_12)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.phoenixFormControl.options["style"]; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.phoenixFormControl.options["style"] === "raised"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.phoenixFormControl.options["style"] === "mini-fab-icon"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PhoenixDynamicButtonComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-hint"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.phoenixFormControl.options["hint"])); _ck(_v, 1, 0, currVal_0); }); }
export function View_PhoenixDynamicButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "mat-caption mt-12 mb-4 text-center"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i13.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i13.ControlContainer, null, [i13.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i13.NgControlStatusGroup, [[4, i13.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_7)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicButtonComponent_13)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 1, 0, currVal_7); var currVal_8 = !_co.phoenixFormControl.options["callback"]; _ck(_v, 5, 0, currVal_8); var currVal_9 = _co.phoenixFormControl.options["callback"]; _ck(_v, 7, 0, currVal_9); var currVal_10 = _co.phoenixFormControl.options["hint"]; _ck(_v, 9, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PhoenixDynamicButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-dynamic-button", [], null, null, null, View_PhoenixDynamicButtonComponent_0, RenderType_PhoenixDynamicButtonComponent)), i1.ɵdid(1, 114688, null, 0, i14.PhoenixDynamicButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixDynamicButtonComponentNgFactory = i1.ɵccf("phoenix-dynamic-button", i14.PhoenixDynamicButtonComponent, View_PhoenixDynamicButtonComponent_Host_0, { form: "form", phoenixFormControl: "phoenixFormControl" }, {}, []);
export { PhoenixDynamicButtonComponentNgFactory as PhoenixDynamicButtonComponentNgFactory };
