var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormArray, FormControl, FormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixFormIconButton } from '../../../components/phoenix-form/phoenix-form-button/classes/phoenix-form-icon-button';
import { PhoenixFormSwitch } from '../../../components/phoenix-form/phoenix-form-switch/classes/phoenix-form-switch';
import { PhoenixFormText } from '../../../components/phoenix-form/phoenix-form-text/classes/phoenix-form-text';
import { PhoenixFormTextarea } from '../../../components/phoenix-form/phoenix-form-textarea/classes/phoenix-form-textarea';
import { PhoenixSnackbarService } from '../../../components/phoenix-snackbar/phoenix-snackbar.service';
import { MeasurementTypeDto, MeasurementTypeSearchCriteriaDto, MeasurementValueTypeDto, NotificationConfigDto, NotificationConfigSearchCriteriaDto, NotificationTypeDto, ValueTypeDto, } from '../../../gapicon/dto.module';
import { MeasurementTypeApiService } from '../../../gapicon/measurement-type/services/measurement-type-api.service';
import { NotificationConfigApiService } from '../../../gapicon/notification-config/services/notification-config-api.service';
import { PhoenixCommunicationSubject } from '../../../services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixSharedService } from '../../../services/phoenix-shared/phoenix-shared.service';
import { PhoenixWizardHelperService } from '../../../services/phoenix-wizards/phoenix-wizard-helper.service';
import { PhoenixMeasurementTypeDialogComponent } from './phoenix-measurement-type-dialog.component';
import { VariableValidator } from '@phoenix/Validators/variable-validator';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../gapicon/measurement-type/services/measurement-type-api.service";
import * as i3 from "../../../gapicon/notification-config/services/notification-config-api.service";
import * as i4 from "../../../services/phoenix-communication-service/phoenix-communication.service";
import * as i5 from "../../../services/phoenix-wizards/phoenix-wizard-helper.service";
import * as i6 from "../../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../services/phoenix-shared/phoenix-shared.service";
var PhoenixMeasurementTypeDialogService = /** @class */ (function () {
    function PhoenixMeasurementTypeDialogService(dialog, measurementTypeApi, notificationsConfigApi, communicationService, wizardHelper, snackbarService, translateService, phoenixShared) {
        this.dialog = dialog;
        this.measurementTypeApi = measurementTypeApi;
        this.notificationsConfigApi = notificationsConfigApi;
        this.communicationService = communicationService;
        this.wizardHelper = wizardHelper;
        this.snackbarService = snackbarService;
        this.translateService = translateService;
        this.phoenixShared = phoenixShared;
    }
    Object.defineProperty(PhoenixMeasurementTypeDialogService.prototype, "checklistelementsForms", {
        get: function () {
            return this.formGroup.get('checklistelements');
        },
        enumerable: true,
        configurable: true
    });
    PhoenixMeasurementTypeDialogService.prototype.openCreateMeasurementTypeDialog = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.openMeasurementTypeDialog()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.openEditMeasurementTypeDialog = function (measurementType, measurementTypeIsUsed, disableEditing) {
        if (disableEditing === void 0) { disableEditing = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.openMeasurementTypeDialog(measurementType, measurementTypeIsUsed, disableEditing)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.openMeasurementTypeDialog = function (measurementType, measurementTypeIsUsed, disableEditing) {
        if (measurementTypeIsUsed === void 0) { measurementTypeIsUsed = false; }
        if (disableEditing === void 0) { disableEditing = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var formData, notificationConfig, dialogRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!measurementType) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getNotificationConfigForMeasurementType(measurementType)];
                    case 1:
                        notificationConfig = _a.sent();
                        formData = this.convertMeasurementTypeToFormValues(measurementType, notificationConfig, disableEditing);
                        _a.label = 2;
                    case 2:
                        this.formGroup = new FormGroup({
                            name: new FormControl(formData ? { value: formData.name, disabled: disableEditing } : {
                                value: '',
                                disabled: disableEditing
                            }, [Validators.required, VariableValidator.noWhiteSpaceValidator, Validators.maxLength(64)], this.forbiddenNameValidator(_.get(formData, 'name'))),
                            description: new FormControl(formData ? { value: formData.description, disabled: disableEditing } : {
                                value: '',
                                disabled: disableEditing,
                            }, [Validators.maxLength(1024)]),
                            freetext: new FormControl(formData ? formData.freetext : true),
                            freetextNotification: new FormControl(formData ? formData.freetextNotification : true),
                            photo: new FormControl(formData ? formData.photo : true),
                            checklistelements: new FormArray(formData ? formData.checklistelements : [this.createChecklistElement()]),
                        });
                        dialogRef = this.dialog.open(PhoenixMeasurementTypeDialogComponent, {
                            width: '550px',
                            autoFocus: false,
                            data: {
                                title: 'WIZARDS.MEASUREMENTTYPE' + (measurementType ? '.EDIT' : '') + '.HEADLINE',
                                subtitle: 'WIZARDS.MEASUREMENTTYPE.SUBHEADLINE',
                                buttons: [
                                    new PhoenixDialogButton({
                                        label: 'WIZARDS.MEASUREMENTTYPE.CANCEL',
                                        click: function () { return dialogRef.close(); },
                                    }),
                                    new PhoenixDialogButton({
                                        label: 'WIZARDS.MEASUREMENTTYPE.SAVE',
                                        click: function () { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                                            switch (_c.label) {
                                                case 0:
                                                    _b = (_a = dialogRef).close;
                                                    return [4 /*yield*/, this.save(measurementType, measurementTypeIsUsed, notificationConfig)];
                                                case 1: return [2 /*return*/, _b.apply(_a, [_c.sent()])];
                                            }
                                        }); }); },
                                        disabled: function () { return _this.formGroup.invalid || _this.formGroup.pending; },
                                        color: 'accent',
                                        raised: true,
                                    })
                                ],
                                formGroup: this.formGroup,
                                elements: this.getElements(disableEditing),
                                checklistelementsForms: this.checklistelementsForms,
                                disableEditing: disableEditing,
                            },
                        });
                        return [2 /*return*/, dialogRef];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.clone = function (measurementType) {
        return __awaiter(this, void 0, void 0, function () {
            var notificationConfig, newMeasurementType, newNotificationConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNotificationConfigForMeasurementType(measurementType)];
                    case 1:
                        notificationConfig = _a.sent();
                        return [4 /*yield*/, this.measurementTypeApi.cloneMeasurementType(measurementType).toPromise()];
                    case 2:
                        newMeasurementType = _a.sent();
                        newNotificationConfig = notificationConfig;
                        newNotificationConfig.id = notificationConfig ? notificationConfig.id : undefined;
                        newNotificationConfig.measurementType = newMeasurementType.key;
                        newNotificationConfig.name = newMeasurementType.name;
                        return [4 /*yield*/, this.notificationsConfigApi.createNotificationConfig(newNotificationConfig).toPromise()];
                    case 3:
                        newNotificationConfig = _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.CREATE.SUCCESS'));
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadMeasurementTypePage);
                        return [2 /*return*/, { measurementType: newMeasurementType, notificationConfig: newNotificationConfig }];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.addChecklistElement = function (key, name, notification, type, extendedInformations) {
        if (key === void 0) { key = this.phoenixShared.generateKey(); }
        if (name === void 0) { name = ''; }
        if (notification === void 0) { notification = true; }
        if (type === void 0) { type = ValueTypeDto.BOOLEAN; }
        if (extendedInformations === void 0) { extendedInformations = []; }
        this.checklistelementsForms.push(this.createChecklistElement(key, name, notification, type, false, extendedInformations));
    };
    PhoenixMeasurementTypeDialogService.prototype.convertFormValuesToMeasurementType = function (values) {
        var measurementTypeDto = new MeasurementTypeDto({
            name: values.name,
            description: values.description,
            valueTypes: [],
        });
        if (values.freetext) {
            values.checklistelements.push({
                key: 'Comment',
                name: 'Comment',
                notification: values.freetextNotification,
                type: ValueTypeDto.STRING,
            });
        }
        if (values.photo) {
            values.checklistelements.push({
                key: 'Photo',
                name: 'Photo',
                type: ValueTypeDto.ATTACHMENT,
            });
        }
        _.forEach(values.checklistelements, function (element) {
            var measurementValueTypeDto = new MeasurementValueTypeDto({
                key: element.key,
                name: element.name,
                type: element.type,
            });
            measurementTypeDto.valueTypes.push(measurementValueTypeDto);
            measurementTypeDto.extendedInformations = _.union(measurementTypeDto.extendedInformations, element.extendedInformations);
        });
        return measurementTypeDto;
    };
    PhoenixMeasurementTypeDialogService.prototype.convertFormValuesToNotificationConfigExpression = function (values) {
        var notificationConfig = new NotificationConfigDto({
            notificationType: NotificationTypeDto.ALARM,
        });
        _.forEach(values.checklistelements, function (element) {
            if (element.notification) {
                console.log('element', element);
                console.log('notificationConfig.expression before', notificationConfig.expression);
                //notificationConfig.expression = ''
                switch (element.type) {
                    case 'STRING':
                        notificationConfig.expression = notificationConfig.expression + "||''.equals(#" + element.key + ")";
                        break;
                    case 'NUMBER':
                    case 'DECIMAL':
                        notificationConfig.expression = notificationConfig.expression + "||#" + element.key + " == null||!(#" + element.key + " matches '^[+-]?((d+(.d*)?)|(.d+))$')||#" + element.key + " eq ''";
                        break;
                    case 'BOOLEAN':
                    default:
                        notificationConfig.expression = notificationConfig.expression + "||!#" + element.key;
                        break;
                }
                //|| (#"+mvt.getKey(}+" eq 'null')"
            }
        });
        notificationConfig.expression = _.trim(notificationConfig.expression, '|');
        return notificationConfig;
    };
    PhoenixMeasurementTypeDialogService.prototype.convertMeasurementTypeToFormValues = function (dto, notificationConfig, disableEditing) {
        var _this = this;
        var returnData = {
            name: dto.name,
            description: dto.description,
            checklistelements: [],
            freetextNotification: false,
            freetext: false,
            photo: false,
        };
        var activeNotificationExpressionParts = this.parseNotificationExpression(notificationConfig.expression);
        console.log('activeNotificationExpressionParts', activeNotificationExpressionParts);
        var commentElement = _.find(dto.valueTypes, function (valueType) {
            return valueType.type === ValueTypeDto.STRING && valueType.key === 'Comment';
        });
        if (commentElement) {
            returnData.freetext = true;
            returnData.freetextNotification = activeNotificationExpressionParts.includes('Comment');
        }
        var photoElement = _.find(dto.valueTypes, function (valueType) {
            return valueType.type === ValueTypeDto.ATTACHMENT && valueType.key === 'Photo';
        });
        if (photoElement) {
            returnData.photo = true;
        }
        _.forEach(dto.valueTypes, function (element) {
            if (!(element.name === 'Photo' || element.name === 'Comment')) {
                returnData.checklistelements.push(_this.createChecklistElement(element.key, element.name, activeNotificationExpressionParts.includes(element.key), element.type, disableEditing, _.filter(dto.extendedInformations, { key: element.key })));
            }
        });
        return returnData;
    };
    PhoenixMeasurementTypeDialogService.prototype.createAddChecklistElementButton = function (isDisabled) {
        var _this = this;
        return new PhoenixFormIconButton(this.formGroup, '', 'add', function () {
            return _this.checklistelementsForms.length >= 20 || isDisabled;
        }, function () {
            _this.addChecklistElement();
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.createChecklistElement = function (key, name, notification, type, disabled, extendedInformations) {
        if (key === void 0) { key = this.phoenixShared.generateKey(); }
        if (name === void 0) { name = ''; }
        if (notification === void 0) { notification = true; }
        if (type === void 0) { type = ValueTypeDto.BOOLEAN; }
        if (disabled === void 0) { disabled = false; }
        if (extendedInformations === void 0) { extendedInformations = []; }
        return new FormGroup({
            key: new FormControl(key),
            name: new FormControl({ value: name, disabled: disabled }, [Validators.required, VariableValidator.noWhiteSpaceValidator]),
            notification: new FormControl(notification),
            type: new FormControl(type),
            extendedInformations: new FormControl(extendedInformations),
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.createDescriptionField = function (isDisabled) {
        return new PhoenixFormTextarea(this.formGroup, 'description', 'WIZARDS.MEASUREMENTTYPE.DESCRIPTION', '', 'WIZARDS.MEASUREMENTTYPE.DESCRIPTIONHINT', 0, 1024, false, isDisabled);
    };
    PhoenixMeasurementTypeDialogService.prototype.createFreetextNotificationSwitch = function () {
        return new PhoenixFormSwitch(this.formGroup, 'freetextNotification', 'WIZARDS.MEASUREMENTTYPE.NOTIFICATION', 'WIZARDS.MEASUREMENTTYPE.NOTIFICATIONHINT', false, false);
    };
    PhoenixMeasurementTypeDialogService.prototype.createFreetextSwitch = function (isDisabled) {
        return new PhoenixFormSwitch(this.formGroup, 'freetext', 'WIZARDS.MEASUREMENTTYPE.FREETEXT', 'WIZARDS.MEASUREMENTTYPE.FREETEXTHINT', isDisabled, true);
    };
    PhoenixMeasurementTypeDialogService.prototype.createNameField = function (isDisabled) {
        return new PhoenixFormText(this.formGroup, 'name', 'WIZARDS.MEASUREMENTTYPE.NAME', '', 'WIZARDS.MEASUREMENTTYPE.NAMEHINT', 1, 64, true, isDisabled);
    };
    PhoenixMeasurementTypeDialogService.prototype.createPhotoSwitch = function (isDisabled) {
        return new PhoenixFormSwitch(this.formGroup, 'photo', 'WIZARDS.MEASUREMENTTYPE.PHOTO', 'WIZARDS.MEASUREMENTTYPE.PHOTOHINT', isDisabled, true);
    };
    PhoenixMeasurementTypeDialogService.prototype.createRemoveChecklistElementButton = function (isDisabled) {
        var _this = this;
        return new PhoenixFormIconButton(this.formGroup, '', 'remove', function () {
            return _this.checklistelementsForms.controls.length <= 1 || isDisabled;
        }, function () {
            _this.deleteChecklistElement(-1);
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.deleteChecklistElement = function (i) {
        this.checklistelementsForms.removeAt(i);
    };
    PhoenixMeasurementTypeDialogService.prototype.forbiddenNameValidator = function (ownName) {
        var _this = this;
        return function (control) {
            clearTimeout(_this.debouncer);
            return Observable.create(function (observer) {
                _this.debouncer = setTimeout(function () {
                    var sc = new MeasurementTypeSearchCriteriaDto();
                    sc.name = control.value;
                    _this.measurementTypeApi.getMeasurementTypes(sc).subscribe(function (next) {
                        var isOwnName = _.find(next, { name: ownName });
                        // tslint:disable-next-line
                        var result = next.length === 0 || !_.isUndefined(isOwnName) ? null : { 'forbiddenName': { value: control.value } };
                        if (!_.isNull(result)) {
                            _this.snackbarService.openPhoenixSnackbar(4000, 'right', 'top', _this.translateService.instant('WIZARDS.MEASUREMENTTYPE.INVALIDNAME'), 'red-bg');
                        }
                        observer.next(result);
                        observer.complete();
                    });
                }, 1000);
            });
        };
    };
    PhoenixMeasurementTypeDialogService.prototype.getElements = function (isDisabled) {
        var name = this.createNameField(isDisabled);
        var description = this.createDescriptionField(isDisabled);
        /* TODO: Der Teil muss generalisiert werden und sollte wie ein Checklistelement erstellt werden. Aktuell ist es so gefordert. */
        var freetext = this.createFreetextSwitch(isDisabled);
        var freetextNotification = this.createFreetextNotificationSwitch();
        var photo = this.createPhotoSwitch(isDisabled);
        /* /TODO */
        var removeChecklistElementButton = this.createRemoveChecklistElementButton(isDisabled);
        var addChecklistElementButton = this.createAddChecklistElementButton(isDisabled);
        return [
            [name],
            [description],
            [freetext, freetextNotification],
            [photo],
            [removeChecklistElementButton, addChecklistElementButton],
        ];
    };
    PhoenixMeasurementTypeDialogService.prototype.getNotificationConfigForMeasurementType = function (measurementType) {
        return __awaiter(this, void 0, void 0, function () {
            var notificationsConfigSearchCriteria, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        notificationsConfigSearchCriteria = new NotificationConfigSearchCriteriaDto();
                        notificationsConfigSearchCriteria.measurementTypeKey = measurementType.key;
                        _b = (_a = _).first;
                        return [4 /*yield*/, this.notificationsConfigApi.getNotificationConfigs(notificationsConfigSearchCriteria).toPromise()];
                    case 1: return [2 /*return*/, _b.apply(_a, [_c.sent()])];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.parseNotificationExpression = function (expression) {
        var returnValue = [];
        console.log('part', JSON.stringify(expression.split('||')));
        _.forEach(expression.split('||'), function (part) {
            part = part.trim();
            console.log('part', JSON.stringify(part));
            if (part.startsWith('\'\'.equals(#')) {
                returnValue.push(part.substr(11, part.length - 12).trim());
            }
            else if (part.startsWith('!#')) {
                returnValue.push(part.substr(2, 34));
            }
            else if (part.startsWith('!(#')) {
                returnValue.push(part.substr(3, 34).trim());
            }
        });
        console.log('returnValue', returnValue);
        return returnValue;
    };
    PhoenixMeasurementTypeDialogService.prototype.save = function (measurementType, measurementTypeIsUsed, notificationConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var newMeasurementType, newNotificationConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newMeasurementType = this.convertFormValuesToMeasurementType(this.formGroup.value);
                        newMeasurementType.id = measurementType ? measurementType.id : undefined;
                        newMeasurementType.key = measurementType ? measurementType.key : undefined;
                        newMeasurementType.tags = measurementType ? measurementType.tags : ['checklist'];
                        if (!measurementType) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateMeasurementType(newMeasurementType, measurementTypeIsUsed)];
                    case 1:
                        newMeasurementType = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.measurementTypeApi.createMeasurementType(newMeasurementType).toPromise()];
                    case 3:
                        newMeasurementType = _a.sent();
                        _a.label = 4;
                    case 4:
                        newNotificationConfig = this.convertFormValuesToNotificationConfigExpression(this.formGroup.value);
                        newNotificationConfig.id = notificationConfig ? notificationConfig.id : undefined;
                        newNotificationConfig.measurementType = newMeasurementType.key;
                        newNotificationConfig.name = newMeasurementType.name;
                        console.log('newNotificationConfig.expression', newNotificationConfig.expression);
                        if (!measurementType) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.updateNotifications(newNotificationConfig, measurementTypeIsUsed)];
                    case 5:
                        newNotificationConfig = _a.sent();
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.notificationsConfigApi.createNotificationConfig(newNotificationConfig).toPromise()];
                    case 7:
                        newNotificationConfig = _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.CREATE.SUCCESS'));
                        _a.label = 8;
                    case 8:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadMeasurementTypePage);
                        return [2 /*return*/, { measurementType: newMeasurementType, notificationConfig: newNotificationConfig }];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.updateMeasurementType = function (measurementType, measurementTypeIsUsed) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!measurementTypeIsUsed) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.measurementTypeApi.updateMeasurementType(measurementType).toPromise()];
                    case 1:
                        measurementType = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.measurementTypeApi.updateMeasurementTypeKeepVersionChanges(measurementType).toPromise()];
                    case 3:
                        measurementType = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, measurementType];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.prototype.updateNotifications = function (notificationConfig, measurementTypeIsUsed) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!measurementTypeIsUsed) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.notificationsConfigApi.updateNotificationConfig(notificationConfig).toPromise()];
                    case 1:
                        notificationConfig = _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.EDIT.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.notificationsConfigApi.updateNotificationConfigKeepKeepVersionChanges(notificationConfig).toPromise()];
                    case 3:
                        notificationConfig = _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.EDIT.SUCCESS'));
                        _a.label = 4;
                    case 4: return [2 /*return*/, notificationConfig];
                }
            });
        });
    };
    PhoenixMeasurementTypeDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixMeasurementTypeDialogService_Factory() { return new PhoenixMeasurementTypeDialogService(i0.inject(i1.MatDialog), i0.inject(i2.MeasurementTypeApiService), i0.inject(i3.NotificationConfigApiService), i0.inject(i4.PhoenixCommunicationService), i0.inject(i5.PhoenixWizardHelperService), i0.inject(i6.PhoenixSnackbarService), i0.inject(i7.TranslateService), i0.inject(i8.PhoenixSharedService)); }, token: PhoenixMeasurementTypeDialogService, providedIn: "root" });
    return PhoenixMeasurementTypeDialogService;
}());
export { PhoenixMeasurementTypeDialogService };
