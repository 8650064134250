import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, reduce } from 'rxjs/operators';
import { AssetSearchCriteriaDto } from '../../dto.module';
import { FileMapperService } from '../../file/mapper/file-mapper.service';
import { SharedService } from '../../shared/services/shared.service';
import { AssetSearchCriteria } from '../api/asset-search-criteria';
import { AssetDto } from '../dto/asset-dto';
import { AssetMapperService } from '../mapper/asset-mapper.service';
var AssetApiService = /** @class */ (function () {
    function AssetApiService(http, sharedService, assetMapperService, fileMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.assetMapperService = assetMapperService;
        this.fileMapperService = fileMapperService;
        this.assetsUrl = 'assets';
        this.asset = 'asset';
        this.groups = 'groups';
    }
    /**
     * Gets the hierarchy from an asset up to a root asset
     * @param asset
     */
    AssetApiService.prototype.getHierarchyForAsset = function (asset) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('assets', 'asset', asset.id, 'path')).pipe(map(function (assets) { return _this.assetMapperService.assetArrayToAssetDtoArray(assets); }));
    };
    /**
     * Gets the all childrens elements from an asset "down" to the last element in a elements tree hierarchy
     * @param asset
     */
    AssetApiService.prototype.getAllChildrensForAsset = function (assetID) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('assets', 'asset', assetID, 'childrens')).pipe(map(function (assets) { return _this.assetMapperService.assetArrayToAssetDtoArray(assets); }));
    };
    /**
     * Gets assets by a given search criteria
     * @returns
     * @param assetSearchCriteriaDto
     * @param withFurtherPages
     */
    AssetApiService.prototype.getAssets = function (assetSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (assetSearchCriteriaDto === void 0) { assetSearchCriteriaDto = new AssetSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl('assets'), this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto), withFurtherPages).pipe(map(function (assets) { return _this.assetMapperService.assetArrayToAssetDtoArray(assets); }), reduce(function (all, current) { return all.concat(current); }));
    };
    AssetApiService.prototype.getAllAssetsWithMeasurmentPoints = function (assetSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (assetSearchCriteriaDto === void 0) { assetSearchCriteriaDto = new AssetSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl('rrp', 'asset', 'measurementPoints'), this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto), withFurtherPages).pipe(map(function (assets) { return _this.assetMapperService.assetArrayToAssetDtoArray(assets); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Gets the all asset branches for logged in user and given tenant
     * @param asset
     */
    AssetApiService.prototype.getAssetBranches = function (userId) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('assets', 'branches', userId.toString())).pipe(map(function (assets) { return _this.assetMapperService.assetArrayToAssetDtoArray(assets); }));
    };
    /**
     * Gets an asset by given ID with references.
     * @param id
     * @returns
     */
    AssetApiService.prototype.getAssetBranch = function (id) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('assets', 'branches', 'branch', id)).pipe(map(function (asset) { return _this.assetMapperService.assetArrayToAssetDtoArray(asset); }));
    };
    /**
     * Returns if the user has permission for the asset.
     * @param id
     * @returns
     */
    AssetApiService.prototype.hasPermissionForAsset = function (id) {
        return this.http.get(this.sharedService.buildApiUrl('assets', 'asset', id, 'hasPermission'));
    };
    /**
     * Gets all assets, by search with partialname  the parend -children hierarchy starts from node with parentId
     * @returns
     * @param assetSearchCriteriaDto
     * @param withFurtherPages
     */
    AssetApiService.prototype.filterAssetsInHierarchy = function (assetSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (assetSearchCriteriaDto === void 0) { assetSearchCriteriaDto = new AssetSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl('assets', 'filter'), this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto), withFurtherPages).pipe(map(function (assets) { return _this.assetMapperService.assetArrayToAssetDtoArray(assets); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Gets an asset by given ID with references.
     * @param id
     * @returns
     */
    AssetApiService.prototype.getAssetById = function (id) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('assets', 'asset', id)).pipe(map(function (asset) { return _this.assetMapperService.assetToAssetDto(asset); }));
    };
    /**
     * Gets an asset by given ID with references.
     * @param id
     * @returns
     */
    AssetApiService.prototype.getDynamicAssets = function (assetSearchCriteriaDto) {
        var _this = this;
        return this.sharedService.httpGet(this.sharedService.buildApiUrl('rrp', 'list'), this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto)).pipe(map(function (asset) { return _this.assetMapperService.dynamicAssetToDynamicAssetDto(asset); }));
    };
    /**
     * Gets all assets that or not under another asset
     * @param searchCriteriaDto optional
     */
    AssetApiService.prototype.getRootAssets = function (searchCriteriaDto) {
        if (searchCriteriaDto) {
            searchCriteriaDto.parentId = 'root';
        }
        else {
            searchCriteriaDto = new AssetSearchCriteria({ parentId: 'root' });
        }
        return this.getAssets(searchCriteriaDto);
    };
    /**
     * Creates an asset by given assetDto
     * @param assetDto
     * @returns
     */
    AssetApiService.prototype.createAsset = function (assetDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl('assets', 'asset'), this.assetMapperService.assetDtoToAsset(assetDto)).pipe(map(function (asset) { return _this.assetMapperService.assetToAssetDto(asset); }));
    };
    /**
     * Creates an dynamic asset by given dynamicAssetDto
     * @param assetDto
     * @returns
     */
    AssetApiService.prototype.createDynamicAsset = function (assetDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl('rrp', 'asset'), this.assetMapperService.dynamicAssetDtoToDynamicAsset(assetDto)).pipe(map(function (asset) { return _this.assetMapperService.dynamicAssetToDynamicAssetDto(asset); }));
    };
    AssetApiService.prototype.updateDynamicAssets = function (assetsDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl('rrp', 'assets'), this.assetMapperService.dynamicAssetsDtoToDynamicAssets(assetsDto)).pipe(map(function (asset) { return _this.assetMapperService.dynamicAssetToDynamicAssetDto(asset); }));
    };
    /**
     * Clones all checkpoints in parent->children hierarchy starts from assetDto
     * @param assetDto
     * @returns
     */
    AssetApiService.prototype.cloneAssetsHierarchy = function (assetDto) {
        return this.http.post(this.sharedService.buildApiUrl('assets', 'asset', 'clone'), this.assetMapperService.assetDtoToAsset(assetDto));
    };
    /**
     * Deletes an asset by given assetDto
     * @param assetDto
     * @returns
     */
    AssetApiService.prototype.deleteAsset = function (assetDto) {
        return this.http.delete(this.sharedService.buildApiUrl('assets', 'asset', assetDto.id));
    };
    /**
     * Deletes an asset by given ID
     * @param id
     * @returns
     */
    AssetApiService.prototype.deleteAssetById = function (id) {
        return this.deleteAsset(new AssetDto({ id: id }));
    };
    /**
     * Updates an assetDto by given assetDto
     * @param assetDto
     * @returns
     */
    AssetApiService.prototype.updateAsset = function (assetDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl('assets', 'asset', assetDto.id), this.assetMapperService.assetDtoToAsset(assetDto)).pipe(map(function (asset) { return _this.assetMapperService.assetToAssetDto(asset); }));
    };
    /**
     * Updates/Replaces a file of a measurement
     * @param assetDto
     * @param fileLabel
     * @param contentType
     * @param contentEncoding
     * @param file
     * @returns
     */
    AssetApiService.prototype.addFileToAsset = function (assetDto, fileLabel, contentType, contentEncoding, file) {
        var _this = this;
        return Observable.create(function (subscriber) {
            var data = new FormData();
            data.append('file', file, file.name);
            data.append('label', fileLabel);
            data.append('contentType', contentType);
            data.append('contentEncoding', contentEncoding);
            var req = new HttpRequest('POST', _this.sharedService.buildApiUrl('assets', 'asset', assetDto.id, 'files', 'attachments', file.name), data);
            _this.http.request(req)
                .subscribe(function (event) {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        subscriber.next(event.loaded / event.total);
                        break;
                    case HttpEventType.Response:
                        subscriber.next(1);
                        subscriber.complete();
                        break;
                }
            }, subscriber.error, subscriber.complete);
        });
    };
    /**
     * Deletes an asset by given assetDto
     * @param assetDto
     * @param fileName
     * @returns
     */
    AssetApiService.prototype.removeFileFromAsset = function (assetDto, fileName) {
        return this.http.delete(this.sharedService.buildApiUrl('assets', 'asset', assetDto.id, 'files', 'attachments', fileName));
    };
    /**
     * Gets a file by given path string.
     * @returns
     * @param assetDto
     * @param fileName
     */
    AssetApiService.prototype.getFileFromAsset = function (assetDto, fileName) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('assets', 'asset', assetDto.id, 'files', 'attachments', fileName)).pipe(map(function (fileItem) { return _this.fileMapperService.fileItemToFileItemDto(fileItem.fileItem); }));
    };
    /**
     * Gets a download token for a file by given asset and filename.
     * @param assetDto the asset dto
     * @param fileName name of the file the user wants to download
     * @returns
     */
    AssetApiService.prototype.getDownloadToken = function (assetDto, fileName) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('downloadToken', 'assets', assetDto.id, 'attachments', fileName)).pipe(map(function (fileAccessToken) { return _this.fileMapperService.fileAccessTokenDtoToFileAccessToken(fileAccessToken); }));
    };
    /**
     * Gets a download token for a file by given path string.
     * @param assetDto the asset dto
     * @param fileName name of the file the user wants to download
     * @param token optional, token to authenticate download
     * @returns
     */
    AssetApiService.prototype.getDownloadUrl = function (assetDto, fileName, token) {
        if (token) {
            return this.sharedService.buildApiUrl('download', 'assets', assetDto.id, 'attachments', fileName) + '?token=' + token;
        }
        else {
            return this.sharedService.buildApiUrl('download', 'assets', assetDto.id, 'attachments', fileName);
        }
    };
    /**
     * Updates an userGroups for asset with assetId
     * @param assetDto
     * @returns
     */
    AssetApiService.prototype.updateUserGroupsForAsset = function (userGroupsIds, assetId) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this.assetsUrl, this.asset, assetId, this.groups), userGroupsIds).pipe(map(function (asset) { return _this.assetMapperService.assetToAssetDto(asset); }));
    };
    return AssetApiService;
}());
export { AssetApiService };
