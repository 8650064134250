import {FormGroup} from '@angular/forms';

import {PhoenixFormElementType} from '../../enums/phoenix-form-element-type.enum';
import {PhoenixFormElement} from '../../interfaces/phoenix-form-element';

export interface DropDownEntry<T> {
  value: T;
  viewValue: string;
}

export class PhoenixFormDropdown<T> implements PhoenixFormElement {
  public formGroup: FormGroup;
  public formControlName: string;
  public type: PhoenixFormElementType = PhoenixFormElementType.DropDown;

  public disabled: boolean;
  public placeholder: string;
  public entries: DropDownEntry<T>[];
  public comparator: (e1: T, e2: T) => boolean;

  public constructor(formGroup: FormGroup,
                     formControlName: string,
                     disabled: boolean = false,
                     placeholder: string,
                     entries: DropDownEntry<T>[],
                     comparator: (e1: T, e2: T) => boolean) {
    this.formGroup = formGroup;
    this.formControlName = formControlName;
    this.disabled = disabled;
    this.placeholder = placeholder;
    this.entries = entries;
    this.comparator = comparator;
  }
}
