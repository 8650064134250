import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { MeasurementPointMapperService } from '../../asset/asset-types/measurement-point/mapper/measurement-point-mapper.service';
import { PropertyMapperService } from '../../property/mapper/property-mapper.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { Measurement } from '../api/measurement';
import { MeasurementValue } from '../api/measurement-value';
import { MeasurementDto } from '../dto/measurement-dto';
import { MeasurementValueDto } from '../dto/measurement-value-dto';
import * as i0 from "@angular/core";
import * as i1 from "../../asset/asset-types/measurement-point/mapper/measurement-point-mapper.service";
import * as i2 from "../../property/mapper/property-mapper.service";
import * as i3 from "../../user/mapper/user-mapper.service";
var LatestMeasurementMapperService = /** @class */ (function () {
    function LatestMeasurementMapperService(measurementPointMapper, propertyMapper, userMapper) {
        this.measurementPointMapper = measurementPointMapper;
        this.propertyMapper = propertyMapper;
        this.userMapper = userMapper;
    }
    /*
    MeasurementDto <=> Measurement
     */
    LatestMeasurementMapperService.prototype.measurementDtoToMeasurement = function (measurementDto) {
        var measurement = plainToClass(Measurement, measurementDto);
        measurement.asset = undefined;
        measurement.measurementValues = this.measurementValueDtoArrayToMeasurementValueArray(measurementDto.measurementValues);
        measurement.timestamp = new Date(measurementDto.timestamp);
        measurement.properties = this.propertyMapper.propertyDtoArrayToPropertyArray(measurementDto.properties);
        measurement.user = this.userMapper.userInfoDtoToUserInfo(measurementDto.user);
        return measurement;
    };
    LatestMeasurementMapperService.prototype.measurementToMeasurementDto = function (measurement) {
        var measurementDto = plainToClass(MeasurementDto, measurement);
        measurementDto.asset = undefined;
        measurementDto.measurementValues = this.measurementValueArrayToMeasurementValueDtoArray(measurement.measurementValues);
        measurementDto.timestamp = new Date(measurement.timestamp);
        measurementDto.properties = this.propertyMapper.propertyArrayToPropertyDtoArray(measurement.properties);
        measurementDto.user = this.userMapper.userInfoToUserInfoDto(measurement.user);
        return measurementDto;
    };
    LatestMeasurementMapperService.prototype.measurementArrayToMeasurementDtoArray = function (measurements) {
        var _this = this;
        return _.map(measurements, function (measurement) { return _this.measurementToMeasurementDto(measurement); });
    };
    LatestMeasurementMapperService.prototype.measurementDtoArrayToMeasurementArray = function (measurementDtos) {
        var _this = this;
        return _.map(measurementDtos, function (measurementDto) { return _this.measurementDtoToMeasurement(measurementDto); });
    };
    /*
   MeasurementValueDto <=> MeasurementValue
    */
    LatestMeasurementMapperService.prototype.measurementValueDtoToMeasurementValue = function (measurementValueDto) {
        return plainToClass(MeasurementValue, measurementValueDto);
    };
    LatestMeasurementMapperService.prototype.measurementValueToMeasurementValueDto = function (measurementValue) {
        return plainToClass(MeasurementValueDto, measurementValue);
    };
    LatestMeasurementMapperService.prototype.measurementValueArrayToMeasurementValueDtoArray = function (measurementValues) {
        var _this = this;
        return _.map(measurementValues, function (measurementValue) { return _this.measurementValueToMeasurementValueDto(measurementValue); });
    };
    LatestMeasurementMapperService.prototype.measurementValueDtoArrayToMeasurementValueArray = function (measurementValueDtos) {
        var _this = this;
        return _.map(measurementValueDtos, function (measurementValueDto) { return _this.measurementValueDtoToMeasurementValue(measurementValueDto); });
    };
    LatestMeasurementMapperService.ngInjectableDef = i0.defineInjectable({ factory: function LatestMeasurementMapperService_Factory() { return new LatestMeasurementMapperService(i0.inject(i1.MeasurementPointMapperService), i0.inject(i2.PropertyMapperService), i0.inject(i3.UserMapperService)); }, token: LatestMeasurementMapperService, providedIn: "root" });
    return LatestMeasurementMapperService;
}());
export { LatestMeasurementMapperService };
