/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-dialog-new.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@ngx-translate/core";
import * as i13 from "@angular/material/dialog";
import * as i14 from "./phoenix-dialog-new.component";
var styles_PhoenixDialogNewComponent = [i0.styles];
var RenderType_PhoenixDialogNewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixDialogNewComponent, data: {} });
export { RenderType_PhoenixDialogNewComponent as RenderType_PhoenixDialogNewComponent };
function View_PhoenixDialogNewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-divider", [["class", "mt-24 mb-24 mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(1, 49152, null, 0, i3.MatDivider, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 1).vertical; var currVal_2 = !i1.ɵnov(_v, 1).vertical; var currVal_3 = i1.ɵnov(_v, 1).inset; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PhoenixDialogNewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixDialogNewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "ml-4"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.$implicit.clickCallback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i10.MatButton, [i1.ElementRef, i6.Platform, i11.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDialogNewComponent_5)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = (_v.parent.context.$implicit.disabled() || _v.parent.context.$implicit.spinner); var currVal_3 = _v.parent.context.$implicit.color; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.spinner; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.parent.context.$implicit.label)); _ck(_v, 4, 0, currVal_5); }); }
function View_PhoenixDialogNewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 20; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixDialogNewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "ml-4"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.$implicit.clickCallback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i10.MatButton, [i1.ElementRef, i6.Platform, i11.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDialogNewComponent_7)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = (_v.parent.context.$implicit.disabled() || _v.parent.context.$implicit.spinner); var currVal_3 = _v.parent.context.$implicit.color; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.spinner; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.parent.context.$implicit.label)); _ck(_v, 4, 0, currVal_5); }); }
function View_PhoenixDialogNewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDialogNewComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDialogNewComponent_6)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.raised; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.raised; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PhoenixDialogNewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i13.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "button-container text-center mb-24"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDialogNewComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDialogNewComponent_3)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.buttons; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PhoenixDialogNewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "className", 0], [8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i13.MatDialogTitle, [[2, i13.MatDialogRef], i1.ElementRef, i13.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "h4 hint-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i13.MatDialogContent, [], null, null), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDialogNewComponent_1)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_4 = _co.buttons; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "title-wrapper ", _co.color, ""); var currVal_1 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.title)); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.subtitle)); _ck(_v, 6, 0, currVal_3); }); }
export function View_PhoenixDialogNewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-dialog-new", [], null, null, null, View_PhoenixDialogNewComponent_0, RenderType_PhoenixDialogNewComponent)), i1.ɵdid(1, 114688, null, 0, i14.PhoenixDialogNewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixDialogNewComponentNgFactory = i1.ɵccf("phoenix-dialog-new", i14.PhoenixDialogNewComponent, View_PhoenixDialogNewComponent_Host_0, { title: "title", subtitle: "subtitle", buttons: "buttons", color: "color" }, {}, ["*"]);
export { PhoenixDialogNewComponentNgFactory as PhoenixDialogNewComponentNgFactory };
