import {NgModule} from '@angular/core';
import {MatProgressBarModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {PhoenixPageModule} from '@phoenix/components/phoenix-page/phoenix-page.module';
import {PhoenixSharedComponentsModule} from '@phoenix/components/phoenix-shared-components.module';

import {ContentComponent} from './content.component';

@NgModule({
  declarations: [
    ContentComponent,
  ],
  imports: [
    RouterModule,
    FuseSharedModule,
    MatProgressBarModule,
    PhoenixPageModule,
    PhoenixSharedComponentsModule,
  ],
  exports: [
    ContentComponent,
  ],
})
export class ContentModule {
}
