import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { NotificationConfig } from '../api/notification-config';
import { NotificationConfigSearchCriteria } from '../api/notification-config-search-criteria';
import { NotificationType } from '../api/notification-type.enum';
import { NotificationConfigDto } from '../dto/notification-config-dto';
import { NotificationTypeDto } from '../dto/notification-type-dto.enum';
import * as i0 from "@angular/core";
var NotificationConfigMapperService = /** @class */ (function () {
    function NotificationConfigMapperService() {
    }
    NotificationConfigMapperService.prototype.notificationConfigToNotificationConfigDto = function (notificationConfig) {
        var notificationConfigDto = plainToClass(NotificationConfigDto, notificationConfig);
        notificationConfigDto.notificationType = this.notificationTypeToNotificationTypeDto(notificationConfig.notificationType);
        return notificationConfigDto;
    };
    NotificationConfigMapperService.prototype.notificationConfigDtoToNotificationConfig = function (notificationConfigDto) {
        var notificationConfig = plainToClass(NotificationConfig, notificationConfigDto);
        notificationConfig.notificationType = this.notificationTypeDtoToNotificationType(notificationConfigDto.notificationType);
        return notificationConfig;
    };
    NotificationConfigMapperService.prototype.notificationConfigArrayToNotificationConfigDtoArray = function (notificationConfigs) {
        var _this = this;
        return _.map(notificationConfigs, function (notificationConfig) { return _this.notificationConfigToNotificationConfigDto(notificationConfig); });
    };
    NotificationConfigMapperService.prototype.notificationTypeToNotificationTypeDto = function (notificationType) {
        return notificationType ? NotificationType[notificationType.toUpperCase()] : undefined;
    };
    NotificationConfigMapperService.prototype.notificationTypeDtoToNotificationType = function (notificationTypeDto) {
        return notificationTypeDto ? NotificationTypeDto[notificationTypeDto.toUpperCase()] : undefined;
    };
    NotificationConfigMapperService.prototype.notificationConfigSearchCriteriaDtoToNotificationConfigSearchCriteria = function (notificationConfigSearchCriteriaDto) {
        return plainToClass(NotificationConfigSearchCriteria, notificationConfigSearchCriteriaDto);
    };
    NotificationConfigMapperService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationConfigMapperService_Factory() { return new NotificationConfigMapperService(); }, token: NotificationConfigMapperService, providedIn: "root" });
    return NotificationConfigMapperService;
}());
export { NotificationConfigMapperService };
