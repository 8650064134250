import { OnInit } from '@angular/core';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';
var PhoenixTaskConfigDialogWeeklyComponent = /** @class */ (function () {
    function PhoenixTaskConfigDialogWeeklyComponent(data) {
        this.data = data;
        this.today = new Date();
        this.weekdays = [
            { label: 'Monday', value: 1 },
            { label: 'Tuesday', value: 2 },
            { label: 'Wednesday', value: 3 },
            { label: 'Thursday', value: 4 },
            { label: 'Friday', value: 5 },
            { label: 'Saturday', value: 6 },
            { label: 'Sunday', value: 7 },
        ];
    }
    PhoenixTaskConfigDialogWeeklyComponent.prototype.getStartDate = function () {
        return this.data.formGroup.get('start').value;
    };
    PhoenixTaskConfigDialogWeeklyComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(PhoenixTaskConfigDialogWeeklyComponent.prototype, "assignedUserGroupIds", {
        get: function () {
            return this.data.formGroup.get('assignedUserGroupIds').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixTaskConfigDialogWeeklyComponent.prototype, "userGroups", {
        get: function () {
            return this.data.usergroups.sort(function (a, b) { return a.name.localeCompare(b.name); });
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixTaskConfigDialogWeeklyComponent;
}());
export { PhoenixTaskConfigDialogWeeklyComponent };
