import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import * as _ from 'lodash';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-button',
  templateUrl: './phoenix-dynamic-button.component.html',
  styleUrls: ['./phoenix-dynamic-button.component.scss'],
})
export class PhoenixDynamicButtonComponent implements OnInit {
  public buttonColor: string;
  @Input()
  public form: FormGroup;
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<boolean>;
  public showSpinner: boolean;

  public buttonCallback(): void {
    if (!this.form.get('buttonClicked')) {
      this.form.addControl('buttonClicked', new FormControl());
    }
    this.showSpinner = true;
    this.form.get('buttonClicked').setValue(this.phoenixFormControl.key);
  }

  public async callbackFromOptions(): Promise<void> {
    this.phoenixFormControl.disabled = true;
    this.showSpinner = true;
    await this.phoenixFormControl.options['callback'](this.form);
    this.showSpinner = false;
    this.phoenixFormControl.disabled = false;
  }

  public ngOnInit(): void {
    this.buttonColor = _.has(this.phoenixFormControl.options, 'color') ? this.phoenixFormControl.options['color'] : 'primary';
  }

  public getIconStyle(): Object {
    let style = {
      'color': this.phoenixFormControl.options['iconColor'] ? this.phoenixFormControl.options['iconColor'] : 'white',
    }
    return style;
  }
}
