import { FormGroup } from '@angular/forms';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../enums/phoenix-dynamic-form-control-type.enum';
var PhoenixDialogFormControlComponent = /** @class */ (function () {
    function PhoenixDialogFormControlComponent() {
        this.phoenixDynamicFormControlType = PhoenixDynamicFormControlType;
        this.translationParams = {};
    }
    Object.defineProperty(PhoenixDialogFormControlComponent.prototype, "isValid", {
        get: function () {
            return this.form.controls[this.phoenixFormControl.key].valid;
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixDialogFormControlComponent;
}());
export { PhoenixDialogFormControlComponent };
