var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var NotificationConfigSearchCriteria = /** @class */ (function (_super) {
    __extends(NotificationConfigSearchCriteria, _super);
    function NotificationConfigSearchCriteria(notificationConfigSearchCriteria) {
        var _this = _super.call(this, notificationConfigSearchCriteria) || this;
        _this.assetIds = undefined;
        _this.measurementTypeKey = undefined;
        Object.assign(_this, notificationConfigSearchCriteria);
        return _this;
    }
    NotificationConfigSearchCriteria.prototype.toJSON = function () {
        return {
            assetIds: this.assetIds,
            measurementTypeKey: this.measurementTypeKey,
            page: this.page,
            size: this.size,
        };
    };
    return NotificationConfigSearchCriteria;
}(SearchCriteriaPageable));
export { NotificationConfigSearchCriteria };
