var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '../../enums/phoenix-status-color.enum';
import { ThresholdDto } from '../../gapicon/dto.module';
import { ThresholdApiService } from '../../gapicon/threshold/services/threshold-api.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import { PhoenixAutocompleteEntry } from '@phoenix/components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../gapicon/threshold/services/threshold-api.service";
import * as i3 from "../phoenix-communication-service/phoenix-communication.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./phoenix-wizard-helper.service";
import * as i6 from "../../components/phoenix-snackbar/phoenix-snackbar.service";
var PhoenixThresholdWizardService = /** @class */ (function () {
    function PhoenixThresholdWizardService(dialog, thresholdApiService, communicationService, translateService, phoenixWizardHelper, phoenixSnackbarService) {
        this.dialog = dialog;
        this.thresholdApiService = thresholdApiService;
        this.communicationService = communicationService;
        this.translateService = translateService;
        this.phoenixWizardHelper = phoenixWizardHelper;
        this.phoenixSnackbarService = phoenixSnackbarService;
        this.editThreshold = undefined;
        this.openElementWizard = undefined;
    }
    PhoenixThresholdWizardService.prototype.openCreateThresholdWizard = function (threshold, openElementWizard, afterThresholdSavedCallback) {
        var _this = this;
        this.openElementWizard = openElementWizard;
        this.openThresholdWizard(threshold, function (dialogResult) { return _this.onNewThresholdCloseCallback(dialogResult, afterThresholdSavedCallback); }, 'WIZARDS.SELECTOR.TITLE.THRESHOLD.CREATE');
    };
    PhoenixThresholdWizardService.prototype.openEditThresholdPointWizard = function (threshold) {
        var _this = this;
        this.editThreshold = threshold;
        this.openElementWizard = undefined;
        this.openThresholdWizard(threshold, function (dialogResult) { return _this.onEditThresholdCloseCallback(dialogResult); }, 'WIZARDS.SELECTOR.TITLE.THRESHOLD.EDIT');
    };
    PhoenixThresholdWizardService.prototype.openThresholdWizard = function (threshold, onClose, title) {
        this.dialog.open(PhoenixDialogComponent, {
            width: this.phoenixWizardHelper.DIALOGWIDTH,
            autoFocus: false,
            data: {
                headline: title,
                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.THRESHOLD',
                onClose: onClose,
                buttons: {
                    cancel: true,
                    previous: !!this.openElementWizard,
                    save: true,
                },
                formControls: [
                    this.createThresholdFormControl('createThreshold', 'WIZARDS.SELECTOR.TEXTBOX.NAME', 'WIZARDS.SELECTOR.TEXTBOX.DESCRIPTION', 'WIZARDS.SELECTOR.DESCRIPTION.THRESHOLDVALUETYPE', 'WIZARDS.SELECTOR.DESCRIPTION.AREAS', 'WIZARDS.SELECTOR.DESCRIPTION.ALERT', threshold),
                ],
            },
        });
    };
    PhoenixThresholdWizardService.prototype.openDeleteThresholdWizard = function (threshold) {
        var _this = this;
        this.editThreshold = threshold;
        var randomString = this.phoenixWizardHelper.getRandomString();
        var hintTextOptions = {
            classes: {
                'font-size-12': true,
                'hint-text': true,
                'primary-300-fg': true,
                'mt-4': true,
                'mb-4': true,
            },
        };
        this.dialog.open(PhoenixDialogComponent, {
            width: this.phoenixWizardHelper.DIALOGWIDTH,
            autoFocus: false,
            data: {
                headline: 'WIZARDS.THRESHOLDS.DELETE.TITLE',
                subHeadline: threshold.name,
                buttons: {
                    cancel: true,
                    delete: 'WIZARDS.THRESHOLDS.DELETE.SUBMIT',
                },
                onClose: function (dialogResult) { return _this.onDeleteCloseCallback(dialogResult); },
                translationParams: {
                    deleteCode: randomString,
                    linkedMeasurementpointCount: threshold.assetsCount.toString(),
                },
                color: 'warn',
                formControls: [[
                        new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.THRESHOLDS.DELETE.LINE1'),
                        new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.THRESHOLDS.DELETE.LINE2'),
                        new PhoenixDynamicFormControl('icon1', PhoenixDynamicFormControlType.Icon, threshold.assetsCount.toString(), false, { size: 40, classes: { 'hint-text': true } }, PhoenixIcon.FIBER_MANUAL_RECORD),
                        new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.THRESHOLDS.DELETE.CONFIRMATION'),
                        new PhoenixDynamicFormControl('delete-code', PhoenixDynamicFormControlType.Text, 'WIZARDS.THRESHOLDS.DELETE.DELETECODE', false, undefined, randomString),
                        new PhoenixDynamicFormControl('delete', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.THRESHOLDS.DELETE.DELETECODEINPUT', true, { hint: 'WIZARDS.THRESHOLDS.DELETE.DELETECODEINPUTHINT' }, undefined, undefined, [Validators.required, Validators.pattern(randomString)]),
                    ]],
            },
        });
    };
    PhoenixThresholdWizardService.prototype.openFollowingThresholdWizard = function (threshold) {
        var _this = this;
        this.dialog.open(PhoenixDialogComponent, {
            width: '800px',
            autoFocus: false,
            data: {
                headline: 'WIZARDS.SELECTOR.TITLE.FOLLOWINGTHRESHOLD',
                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.FOLLOWING',
                buttons: {
                    exit: true,
                },
                translationParams: {
                    thresholdName: threshold.name,
                },
                onClose: function (dialogResult) { return _this.onFollowingCloseCallback(dialogResult); },
                formControls: [
                    this.createElementSelectionWizardColumn('DuplicateThreshold', PhoenixIcon.REPEAT, 'WIZARDS.COMMON.DUPLICATETHRESHOLD', 'WIZARDS.SELECTOR.DESCRIPTION.DUPLICATETHRESHOLD', 'WIZARDS.SELECTOR.BUTTON.DUPLICATETHRESHOLD'),
                    this.createElementSelectionWizardColumn('NewThreshold', PhoenixIcon.PLAY_ARROW, 'WIZARDS.COMMON.NEWTHRESHOLD', 'WIZARDS.SELECTOR.DESCRIPTION.NEWTHRESHOLD', 'WIZARDS.SELECTOR.BUTTON.NEWTHRESHOLD'),
                    this.createElementSelectionWizardColumn('NavigationToThresholdDashboard', PhoenixIcon.VIEW_MODULE, 'WIZARDS.COMMON.GOTOTHRESHOLDDASHBOARD', 'WIZARDS.SELECTOR.DESCRIPTION.GOTOTHRESHOLDDASHBOARD', 'WIZARDS.SELECTOR.BUTTON.GOTOTHRESHOLDDASHBOARD'),
                ],
            },
        });
    };
    PhoenixThresholdWizardService.prototype.onFollowingCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (dialogResult.formData['buttonClicked'].value) {
                    case 'buttonDuplicateThreshold':
                        this.openCreateThresholdWizard(this.editThreshold, this.openElementWizard, function (threshold) {
                            _this.openFollowingThresholdWizard(threshold);
                        });
                        break;
                    case 'buttonNewThreshold':
                        this.openCreateThresholdWizard(undefined, this.openElementWizard, function (threshold) {
                            _this.openFollowingThresholdWizard(threshold);
                        });
                        break;
                    case 'NavigationToThresholdDashboard':
                        break;
                }
                dialogResult.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    PhoenixThresholdWizardService.prototype.onNewThresholdCloseCallback = function (dialogResult, afterSaveCallback) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var threshold, _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 5];
                        threshold = this.createThreshold(new ThresholdDto(), dialogResult);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this.thresholdApiService.createThreshold(threshold).toPromise()];
                    case 2:
                        _a.editThreshold = _b.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.THRESHOLDS.CREATE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadThresholdPage);
                        if (afterSaveCallback) {
                            afterSaveCallback(this.editThreshold);
                        }
                        else {
                            this.openElementWizard = function () { return _this.openFollowingThresholdWizard(_this.editThreshold); };
                            this.openFollowingThresholdWizard(this.editThreshold);
                        }
                        _b.label = 5;
                    case 5:
                        if (dialogResult.result === 'previous' && this.openElementWizard !== undefined) {
                            this.openElementWizard();
                        }
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixThresholdWizardService.prototype.onEditThresholdCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 5];
                        this.editThreshold = this.createThreshold(this.editThreshold, dialogResult);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.thresholdApiService.updateThreshold(this.editThreshold).toPromise()];
                    case 2:
                        _a.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.THRESHOLDS.EDIT.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadThresholdPage);
                        _a.label = 5;
                    case 5:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixThresholdWizardService.prototype.onDeleteCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'delete')) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.thresholdApiService.deleteThreshold(this.editThreshold).toPromise()];
                    case 2:
                        _a.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.THRESHOLDS.DELETE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadThresholdPage);
                        _a.label = 5;
                    case 5:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixThresholdWizardService.prototype.createThreshold = function (threshold, dialogResult) {
        threshold.name = dialogResult.formData['txtName'];
        threshold.description = dialogResult.formData['txtDescription'];
        threshold.alarmMax = dialogResult.formData['sliderUpperLimit'];
        threshold.alarmMin = dialogResult.formData['sliderLowerLimit'];
        threshold.valueMax = dialogResult.formData['sliderUpperLimit'];
        threshold.valueMin = dialogResult.formData['sliderLowerLimit'];
        threshold.alarmConfig = dialogResult.formData['alarmConfig'];
        return threshold;
    };
    PhoenixThresholdWizardService.prototype.createElementSelectionWizardColumn = function (key, icon, iconLabel, text, buttonLabel, flipIcon) {
        return [
            new PhoenixDynamicFormControl('icon' + key, PhoenixDynamicFormControlType.Icon, iconLabel, false, {
                size: 60,
                body: text,
                flip: flipIcon,
            }, icon),
            new PhoenixDynamicFormControl('button' + key, PhoenixDynamicFormControlType.Button, buttonLabel, false, {
                style: 'raised',
                color: 'basic',
            }),
        ];
    };
    PhoenixThresholdWizardService.prototype.createThresholdFormControl = function (key, lblName, lblDescription, lblValue, lblAreas, lblAlert, threshold) {
        var alarmConfigData = _.sortBy([new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.ALWAYS"), object: "ALWAYS" }),
            new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.FIRST"), object: "FIRST" }),
            new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.SECOND"), object: "SECOND" })], [function (element) { return element.label.toLowerCase(); }]);
        var alarmConfig = _.get(threshold, 'alarmConfig', 'FIRST');
        var alarmConfigFormControl = new PhoenixDynamicFormControl('alarmConfig', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SELECTOR.TEXTBOX.ALARMMAILS', false, { autocomplete: function () { return of(alarmConfigData); } }, new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM." + (alarmConfig || "FIRST")), object: alarmConfig || "FIRST" }));
        return [
            new PhoenixDynamicFormControl('txtName', PhoenixDynamicFormControlType.TextBox, lblName, true, {}, _.get(threshold, 'name', undefined)),
            new PhoenixDynamicFormControl('txtDescription', PhoenixDynamicFormControlType.TextArea, lblDescription, false, {}, _.get(threshold, 'description', undefined)),
            new PhoenixDynamicFormControl('txtValue', PhoenixDynamicFormControlType.Dropdown, lblValue, true, {
                options: [
                    { key: 'temperature', label: 'WIZARDS.SELECTOR.THRESHOLD.TEMPERATURE_IN_C' },
                ],
            }, 
            //threshold ? 'temperature' : undefined,
            'temperature'),
            new PhoenixDynamicFormControl('txtAreas', PhoenixDynamicFormControlType.Text, '', true, {}, lblAreas),
            //alarmConfigFormControl,
            new PhoenixDynamicFormControl('iconUpperLimit', PhoenixDynamicFormControlType.Icon, undefined, false, {
                size: 30,
                color: PhoenixStatusColor.NEGATIVE,
            }, PhoenixIcon.FIBER_MANUAL_RECORD),
            new PhoenixDynamicFormControl('sliderUpperLimit', PhoenixDynamicFormControlType.ThresholdLimit, '1', false, {}, parseFloat(_.get(threshold, 'alarmMax', '0'))),
            new PhoenixDynamicFormControl('iconOk', PhoenixDynamicFormControlType.Icon, undefined, false, {
                size: 30,
                color: PhoenixStatusColor.POSITIVE,
            }, PhoenixIcon.FIBER_MANUAL_RECORD),
            new PhoenixDynamicFormControl('sliderLowerLimit', PhoenixDynamicFormControlType.ThresholdLimit, '1', false, {}, parseFloat(_.get(threshold, 'alarmMin', '0'))),
            new PhoenixDynamicFormControl('iconLowerLimit', PhoenixDynamicFormControlType.Icon, undefined, false, {
                size: 30,
                color: PhoenixStatusColor.NEGATIVE,
            }, PhoenixIcon.FIBER_MANUAL_RECORD),
            new PhoenixDynamicFormControl('alarmConfig', PhoenixDynamicFormControlType.Dropdown, lblAlert, true, {
                options: [
                    { key: "FIRST", label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.FIRST") },
                    { key: "SECOND", label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.SECOND") },
                    { key: "ALWAYS", label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.ALWAYS") },
                ],
            }, alarmConfig ? alarmConfig : "FIRST"),
        ];
    };
    PhoenixThresholdWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixThresholdWizardService_Factory() { return new PhoenixThresholdWizardService(i0.inject(i1.MatDialog), i0.inject(i2.ThresholdApiService), i0.inject(i3.PhoenixCommunicationService), i0.inject(i4.TranslateService), i0.inject(i5.PhoenixWizardHelperService), i0.inject(i6.PhoenixSnackbarService)); }, token: PhoenixThresholdWizardService, providedIn: "root" });
    return PhoenixThresholdWizardService;
}());
export { PhoenixThresholdWizardService };
