import { OnInit } from '@angular/core';
import { PhoenixSensormanagementInformationDialog } from '../classes/phoenix-sensormanagement-information-dialog';
var PhoenixSensormanagementInformationDialogComponent = /** @class */ (function () {
    function PhoenixSensormanagementInformationDialogComponent(data) {
        this.data = data;
    }
    PhoenixSensormanagementInformationDialogComponent.prototype.ngOnInit = function () {
    };
    return PhoenixSensormanagementInformationDialogComponent;
}());
export { PhoenixSensormanagementInformationDialogComponent };
