var UserGroupDto = /** @class */ (function () {
    function UserGroupDto(userGroupDto) {
        this.name = undefined;
        this.id = undefined;
        this.role = undefined;
        this.type = undefined;
        this.notification = true;
        Object.assign(this, userGroupDto);
    }
    return UserGroupDto;
}());
export { UserGroupDto };
