import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
var LoadingInterceptor = /** @class */ (function () {
    function LoadingInterceptor(progressBar) {
        this.progressBar = progressBar;
        this.requests = [];
        this.reqId = 0;
    }
    LoadingInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var currentRequest = this.reqId++;
        this.addRequest(currentRequest);
        return Observable.create(function (observer) {
            var subscription = next.handle(req).subscribe(function (event) {
                if (event instanceof HttpResponse) {
                    observer.next(event);
                }
            }, function (err) {
                _this.removeRequest(currentRequest);
                observer.error(err);
            }, function () {
                _this.removeRequest(currentRequest);
                observer.complete();
            });
            // teardown logic in case of cancelled requests
            return function () {
                _this.removeRequest(currentRequest);
                subscription.unsubscribe();
            };
        });
    };
    LoadingInterceptor.prototype.addRequest = function (reqId) {
        this.requests.push(reqId);
        this.progressBar.show();
    };
    LoadingInterceptor.prototype.removeRequest = function (reqId) {
        if (this.requests.indexOf(reqId) >= 0) {
            this.requests.splice(this.requests.indexOf(reqId), 1);
        }
        if (this.requests.length === 0) {
            this.progressBar.hide();
        }
    };
    return LoadingInterceptor;
}());
export { LoadingInterceptor };
