var RegistrationRequest = /** @class */ (function () {
    function RegistrationRequest(registrationRequest) {
        this.email = undefined;
        this.roles = undefined;
        Object.assign(this, registrationRequest);
    }
    RegistrationRequest.prototype.toJSON = function () {
        return {
            email: this.email,
            roles: this.roles,
        };
    };
    return RegistrationRequest;
}());
export { RegistrationRequest };
