var AlarmCount = /** @class */ (function () {
    function AlarmCount(alarmCount) {
        this.monthYear = undefined;
        this.activeInactiveCount = undefined;
        this.closedCount = undefined;
        Object.assign(this, alarmCount);
    }
    AlarmCount.prototype.toJSON = function () {
        return {
            monthYear: this.monthYear,
            activeInactiveCount: this.activeInactiveCount,
            closedCount: this.closedCount
        };
    };
    return AlarmCount;
}());
export { AlarmCount };
