var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var UserSearchCriteria = /** @class */ (function (_super) {
    __extends(UserSearchCriteria, _super);
    function UserSearchCriteria(userSearchCriteria) {
        var _this = _super.call(this, userSearchCriteria) || this;
        _this.firstname = undefined;
        _this.lastname = undefined;
        _this.email = undefined;
        _this.company = undefined;
        _this.role = undefined;
        _this.name = undefined;
        Object.assign(_this, userSearchCriteria);
        return _this;
    }
    UserSearchCriteria.prototype.toJSON = function () {
        return {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            company: this.company,
            role: this.role,
            page: this.page,
            size: this.size,
            name: this.name
        };
    };
    return UserSearchCriteria;
}(SearchCriteriaPageable));
export { UserSearchCriteria };
