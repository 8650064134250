import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { ThresholdDto, ThresholdViolationStatisticsDto } from '../../dto.module';
import { Threshold } from '../api/threshold';
import { ThresholdSearchCriteria } from '../api/threshold-search-criteria';
import * as i0 from "@angular/core";
var ThresholdMapperService = /** @class */ (function () {
    function ThresholdMapperService() {
    }
    ThresholdMapperService.prototype.thresholdToThresholdDto = function (threshold) {
        return plainToClass(ThresholdDto, threshold);
    };
    ThresholdMapperService.prototype.thresholdDtoToThreshold = function (thresholdDto) {
        return plainToClass(Threshold, thresholdDto);
    };
    ThresholdMapperService.prototype.thresholdArrayToThresholdDtoArray = function (thresholds) {
        var _this = this;
        return _.map(thresholds, function (threshold) { return _this.thresholdToThresholdDto(threshold); });
    };
    ThresholdMapperService.prototype.thresholdDtoArrayToThresholdArray = function (thresholdsDtos) {
        var _this = this;
        return _.map(thresholdsDtos, function (thresholdDto) { return _this.thresholdDtoToThreshold(thresholdDto); });
    };
    ThresholdMapperService.prototype.thresholdSearchCriteriaDtoToThresholdSearchCriteria = function (thresholdSearchCriteriaDto) {
        return plainToClass(ThresholdSearchCriteria, thresholdSearchCriteriaDto);
    };
    ThresholdMapperService.prototype.thresholdViolationStatisticsToThresholdViolationStatisticsDto = function (thresholdViolationStatistics) {
        return plainToClass(ThresholdViolationStatisticsDto, thresholdViolationStatistics);
    };
    ThresholdMapperService.ngInjectableDef = i0.defineInjectable({ factory: function ThresholdMapperService_Factory() { return new ThresholdMapperService(); }, token: ThresholdMapperService, providedIn: "root" });
    return ThresholdMapperService;
}());
export { ThresholdMapperService };
