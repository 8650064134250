var RegistrationConfirmation = /** @class */ (function () {
    function RegistrationConfirmation(registrationConfirmation) {
        this.token = undefined;
        this.password = undefined;
        this.user = undefined;
        Object.assign(this, registrationConfirmation);
    }
    RegistrationConfirmation.prototype.toJSON = function () {
        return {
            token: this.token,
            password: this.password,
            user: this.user,
        };
    };
    return RegistrationConfirmation;
}());
export { RegistrationConfirmation };
