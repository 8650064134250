var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var SensorSearchCriteria = /** @class */ (function (_super) {
    __extends(SensorSearchCriteria, _super);
    function SensorSearchCriteria(sensorSearchCriteria) {
        var _this = _super.call(this, sensorSearchCriteria) || this;
        _this.isAssigned = undefined;
        _this.types = undefined;
        _this.sensorIds = undefined;
        _this.partialName = undefined;
        Object.assign(_this, sensorSearchCriteria);
        return _this;
    }
    SensorSearchCriteria.prototype.toJSON = function () {
        return {
            page: this.page,
            size: this.size,
            isAssigned: this.isAssigned,
            sensorIds: this.sensorIds,
            partialName: this.partialName,
            types: this.types,
        };
    };
    return SensorSearchCriteria;
}(SearchCriteriaPageable));
export { SensorSearchCriteria };
