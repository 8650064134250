import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Permission, permissionIds } from 'app/guards/permission';
import { PermissionService } from 'app/guards/permission.service';
import * as _ from 'lodash';

import { PhoenixDialogResult } from '../../components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetDto } from '../../gapicon/asset/dto/asset-dto';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';

import { PhoenixAssetWizardService } from './phoenix-asset-wizard.service';
import { PhoenixDynamicAssetWizardService } from './phoenix-dynamic-asset-wizard.service';
import { PhoenixMeasurementPointWizardService } from './phoenix-measurement-point-wizard.service';
import { PhoenixThresholdWizardService } from './phoenix-threshold-wizard.service';
import { TenantApiService } from '@phoenix/gapicon/tenant/services/tenant-api.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixCommonWizardService {

  private createThresholdPermission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.THRESHOLD.write);

  public constructor(
    private dialog: MatDialog,
    private phoenixAssetWizardService: PhoenixAssetWizardService,
    private phoenixDynamicAssetWizardService: PhoenixDynamicAssetWizardService,
    private phoenixMeasurementPointWizardService: PhoenixMeasurementPointWizardService,
    private phoenixThresholdWizardService: PhoenixThresholdWizardService,
    private permissionService: PermissionService,
    private tenantApi: TenantApiService,
  ) {
  }

  public async openNewElementWizard(parent?: AssetDto): Promise<void> {


    const createAssetFormControls: PhoenixDynamicFormControl<string | boolean>[] =
      this.createElementSelectionWizardColumn(
        'Asset',
        PhoenixIcon.STOP,
        'WIZARDS.COMMON.ASSET',
        'WIZARDS.SELECTOR.DESCRIPTION.ASSET',
        'WIZARDS.SELECTOR.BUTTON.ASSET',
      );

    const createMeasurementPointFormControls: PhoenixDynamicFormControl<string | boolean>[] =
      this.createElementSelectionWizardColumn(
        'MeasurementPoint',
        PhoenixIcon.FIBER_MANUAL_RECORD,
        'WIZARDS.COMMON.MEASUREMENTPOINT',
        'WIZARDS.SELECTOR.DESCRIPTION.MEASUREMENTPOINT',
        'WIZARDS.SELECTOR.BUTTON.MEASUREMENTPOINT',
      );
    /*  const createThresholdFormControls: PhoenixDynamicFormControl<string | boolean>[] =
        this.createElementSelectionWizardColumn(
          'Threshold',
          PhoenixIcon.PLAY_ARROW,
          'WIZARDS.COMMON.THRESHOLD',
          'WIZARDS.SELECTOR.DESCRIPTION.THRESHOLD',
          'WIZARDS.SELECTOR.BUTTON.THRESHOLD',
          true,
        );
  
  */


    const currentAssetBranch: string[] = JSON.parse(localStorage.getItem('selectedAssetBranches'));
    const tenantId: string = currentAssetBranch[0];
    const providers: string[] = await this.tenantApi.getProvidersByTenantId(tenantId).toPromise();
    let newElementWizardFormControls: PhoenixDynamicFormControl<string | boolean>[][] = [];
    newElementWizardFormControls.push(createAssetFormControls);
    newElementWizardFormControls.push(createMeasurementPointFormControls);
    if (this.permissionService.hasPermission(this.createThresholdPermission)) {
      //newElementWizardFormControls.push(createThresholdFormControls);
    }
    if (providers && providers.length > 0) {
      const createDynamicAssetFormControls: PhoenixDynamicFormControl<string | boolean>[] =
        this.createElementSelectionWizardColumn(
          'DynamicAsset',
          PhoenixIcon.CLOUD,
          'WIZARDS.COMMON.DYNAMICASSET',
          'WIZARDS.SELECTOR.DESCRIPTION.DYNAMICASSET',
          'WIZARDS.SELECTOR.BUTTON.DYNAMICASSET',
        );

      newElementWizardFormControls.push(createDynamicAssetFormControls);
    }

    this.dialog.open(PhoenixDialogComponent, {
      width: '800px',
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.SELECTOR.TITLE.MAINASSET',
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.MAIN',
        buttons: {
          cancel: true,
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onCloseCallback(dialogResult, parent),
        formControls: newElementWizardFormControls,
      },
    });
  }

  private createElementSelectionWizardColumn(
    key: string,
    icon: string,
    iconLabel: string,
    text: string,
    buttonLabel: string,
    flipIcon?: boolean,
  ): PhoenixDynamicFormControl<string | boolean>[] {
    return [
      new PhoenixDynamicFormControl<string>(
        'icon' + key,
        PhoenixDynamicFormControlType.Icon,
        iconLabel,
        false,
        {
          size: 60,
          body: text,
          flip: flipIcon,
        },
        icon,
      ),
      new PhoenixDynamicFormControl<boolean>(
        'button' + key,
        PhoenixDynamicFormControlType.Button,
        buttonLabel,
        false,
        {
          style: 'raised',
          color: 'basic',
        },
      ),
    ];
  }

  private async onCloseCallback(dialogResult: PhoenixDialogResult, parent?: AssetDto): Promise<void> {
    console.log(dialogResult.formData['buttonClicked'].value);
    switch (dialogResult.formData['buttonClicked'].value) {
      case 'buttonAsset':
        await this.phoenixAssetWizardService.openCreateAssetWizard(undefined, parent, async () => {
          await this.openNewElementWizard(parent);
        });
        break;
      case 'buttonMeasurementPoint':
        //const parentCheckpoint: AssetDto = _.includes(_.get(parent, 'tags', []), AssetTags.asset) ? undefined : parent;
        await this.phoenixMeasurementPointWizardService.openCreateMeasurementPointWizard(undefined, parent, async () => {
          await this.openNewElementWizard(parent);
        });
        break;
      case 'buttonThreshold':
        await this.phoenixThresholdWizardService.openCreateThresholdWizard(undefined, async () => {
          await this.openNewElementWizard(parent);
        });
        break;
      case 'buttonDynamicAsset':
        await this.phoenixDynamicAssetWizardService.openCreateDynamicAssetWizard(undefined, parent, async () => {
          await this.openNewElementWizard(parent);
        });
        break;
    }
    dialogResult.dialogRef.close();
  }
}

