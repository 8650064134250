<ng-container [formGroup]="element.formGroup">
  <mat-form-field [hintLabel]="element.hint | translate">
    <input matInput [type]="hidePassword ? 'password' : 'text'" [formControlName]="element.formControlName"
      [maxlength]="element.maxlength" [placeholder]="element.placeholder | translate">
    <mat-icon class="notranslate" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' :
      'visibility_off'}}
    </mat-icon>
    <mat-hint align="end" *ngIf="element.maxlength">
      {{element.formGroup.get(element.formControlName).value?.length || 0}}/{{ element.maxlength}}
    </mat-hint>
    <mat-error *ngIf="!element.formGroup.get(element.formControlName).valid">
      <span translate data-translate="DIALOG.FORM.INVALID"></span>
    </mat-error>
  </mat-form-field>
  <div *ngIf="element.passwordGenerator" class="mt-0 mat-caption">
    <a class="forgot-password" translate data-translate="FORM.PASSWORD.GENERATEPASSWORD"
      (click)="generatePassword()"></a>
  </div>
</ng-container>