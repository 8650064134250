import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixAssetBranchSelectorDialogData } from './classes/phoenix-asset-branchselector-dialog-data';
var PhoenixAsseBranchSelectorDialogComponent = /** @class */ (function () {
    function PhoenixAsseBranchSelectorDialogComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
    }
    PhoenixAsseBranchSelectorDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data.cancelButton) {
            this.buttons = [
                new PhoenixDialogButton({
                    label: 'WIZARDS.ASSETBRANCH.CANCEL',
                    click: function () { return _this.dialogRef.close(undefined); },
                }),
            ];
        }
    };
    PhoenixAsseBranchSelectorDialogComponent.prototype.selectBranch = function (branch) {
        this.dialogRef.close([branch]);
    };
    return PhoenixAsseBranchSelectorDialogComponent;
}());
export { PhoenixAsseBranchSelectorDialogComponent };
