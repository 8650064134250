/* tslint:disable */
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PermissionService } from '../../../guards/permission.service';
var ContentComponent = /** @class */ (function () {
    function ContentComponent(router, activatedRoute, fuseConfig, permissionService, communicationService) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.fuseConfig = fuseConfig;
        this.permissionService = permissionService;
        this.communicationService = communicationService;
        this.isLoggedIn = false;
        this.routeAnimationDown = false;
        this.routeAnimationFade = false;
        this.routeAnimationLeft = false;
        this.routeAnimationRight = false;
        this.routeAnimationUp = false;
        this.styleClasses = {
            current: 'backgroundImage',
            backgroundImage: 'backgroundImage',
            noBackgroundImage: 'noBackgroundImage',
        };
        combineLatest(this.communicationService.getObservable(PhoenixCommunicationSubject.UserLogin), this.router.events).subscribe(function (_a) {
            var loggedIn = _a[0], routingEvent = _a[1];
            if (loggedIn && routingEvent instanceof NavigationEnd) {
                _this.isLoggedIn = loggedIn;
            }
        });
        this.communicationService.getObservable(PhoenixCommunicationSubject.UserLogout).subscribe(function (success) {
            _this.isLoggedIn = success.value;
        });
        this.isLoggedIn = localStorage.getItem("userData") ? true : false;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; })).subscribe(function (event) {
            _this.isLoggedIn = localStorage.getItem("userData") ? true : false;
            switch (_this.fuseSettings.routerAnimation) {
                case 'fadeIn':
                    _this.routeAnimationFade = !_this.routeAnimationFade;
                    break;
                case 'slideUp':
                    _this.routeAnimationUp = !_this.routeAnimationUp;
                    break;
                case 'slideDown':
                    _this.routeAnimationDown = !_this.routeAnimationDown;
                    break;
                case 'slideRight':
                    _this.routeAnimationRight = !_this.routeAnimationRight;
                    break;
                case 'slideLeft':
                    _this.routeAnimationLeft = !_this.routeAnimationLeft;
                    break;
            }
            if (permissionService.userIsNotAuthenticated()) {
                _this.styleClasses.current = _this.styleClasses.backgroundImage;
                _this.fuseConfig.setConfig({
                    layout: {
                        navbar: { hidden: true },
                        toolbar: { hidden: true },
                        footer: { hidden: true },
                    },
                });
            }
            else {
                _this.styleClasses.current = _this.styleClasses.noBackgroundImage;
                _this.fuseConfig.setConfig({
                    layout: {
                        navbar: { hidden: false },
                        toolbar: { hidden: false },
                        footer: { hidden: true },
                    },
                });
            }
        });
        this.onConfigChanged =
            this.fuseConfig.getConfig()
                .subscribe(function (newSettings) {
                _this.fuseSettings = newSettings;
            });
    }
    ContentComponent.prototype.getStyleClasses = function () {
        return this.styleClasses.current;
    };
    ContentComponent.prototype.ngOnDestroy = function () {
        this.onConfigChanged.unsubscribe();
    };
    return ContentComponent;
}());
export { ContentComponent };
