import { OnInit } from '@angular/core';
var PhoenixDialogNewComponent = /** @class */ (function () {
    function PhoenixDialogNewComponent() {
        this.color = 'accent';
    }
    PhoenixDialogNewComponent.prototype.ngOnInit = function () {
        // this.color = 
    };
    return PhoenixDialogNewComponent;
}());
export { PhoenixDialogNewComponent };
