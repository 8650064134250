import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule, MatDialogModule, MatListModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixDialogNewModule} from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';

import {PhoenixAsseBranchSelectorDialogComponent} from './phoenix-asset-branchselector-dialog.component';
import {PhoenixAssetBranchselectorDialogService} from './phoenix-asset-branchselector-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,

        MatListModule,
        MatDialogModule,
        MatButtonModule,

        PhoenixDialogNewModule,
    ],
    declarations: [
        PhoenixAsseBranchSelectorDialogComponent,
    ],
    entryComponents: [
        PhoenixAsseBranchSelectorDialogComponent,
    ],
    providers: [
        PhoenixAssetBranchselectorDialogService,
    ],
})
export class PhoenixAssetBranchSelectorDialogModule {
}
