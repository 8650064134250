/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-clone-confirmation-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/phoenix-dialog-new/phoenix-dialog-new.component.ngfactory";
import * as i3 from "../../../components/phoenix-dialog-new/phoenix-dialog-new.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./phoenix-clone-confirmation-dialog.component";
import * as i6 from "@angular/material/dialog";
var styles_PhoenixCloneConfirmationDialogComponent = [i0.styles];
var RenderType_PhoenixCloneConfirmationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixCloneConfirmationDialogComponent, data: {} });
export { RenderType_PhoenixCloneConfirmationDialogComponent as RenderType_PhoenixCloneConfirmationDialogComponent };
export function View_PhoenixCloneConfirmationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "phoenix-dialog-new", [], null, null, null, i2.View_PhoenixDialogNewComponent_0, i2.RenderType_PhoenixDialogNewComponent)), i1.ɵdid(1, 114688, null, 0, i3.PhoenixDialogNewComponent, [], { title: [0, "title"], buttons: [1, "buttons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "mt-18"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "div", [["class", "mt-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; var currVal_1 = _co.buttons; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.data.description)); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.data.question)); _ck(_v, 8, 0, currVal_3); }); }
export function View_PhoenixCloneConfirmationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-clone-confirmation-dialog", [], null, null, null, View_PhoenixCloneConfirmationDialogComponent_0, RenderType_PhoenixCloneConfirmationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.PhoenixCloneConfirmationDialogComponent, [i6.MAT_DIALOG_DATA, i6.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixCloneConfirmationDialogComponentNgFactory = i1.ɵccf("phoenix-clone-confirmation-dialog", i5.PhoenixCloneConfirmationDialogComponent, View_PhoenixCloneConfirmationDialogComponent_Host_0, {}, {}, []);
export { PhoenixCloneConfirmationDialogComponentNgFactory as PhoenixCloneConfirmationDialogComponentNgFactory };
