import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { PhoenixMeasurementPointSelectorDialogData } from './classes/PhoenixMeasurementPointSelectorDialogData';
import { PhoenixMeasurementPointSelectorDialogComponent } from './phoenix-measurementpoint-selector-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PhoenixMeasurementPointSelectorDialogService = /** @class */ (function () {
    function PhoenixMeasurementPointSelectorDialogService(dialog) {
        this.dialog = dialog;
    }
    // TODO this shows dialog with measurementPoints  get from backend  (see showTenantSelectorDialog in tenent dialog)
    PhoenixMeasurementPointSelectorDialogService.prototype.showMeasurementPointSelectorDialog = function (measurementPoints, showCancelButton) {
        if (showCancelButton === void 0) { showCancelButton = true; }
        console.log("showMeasurementPointSelectorDialog");
        if (measurementPoints.length <= 1) {
            return of(measurementPoints);
        }
        var dialogRef = this.dialog.open(PhoenixMeasurementPointSelectorDialogComponent, {
            closeOnNavigation: false,
            autoFocus: false,
            disableClose: !showCancelButton,
            data: new PhoenixMeasurementPointSelectorDialogData({
                measurementPoints: measurementPoints,
                cancelButton: showCancelButton,
                title: 'WIZARDS.MEASUREMENTPOINT.TITLE',
                subtitle: 'WIZARDS.MEASUREMENTPOINT.SUBTITLE',
            }),
        });
        return dialogRef.afterClosed();
    };
    PhoenixMeasurementPointSelectorDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixMeasurementPointSelectorDialogService_Factory() { return new PhoenixMeasurementPointSelectorDialogService(i0.inject(i1.MatDialog)); }, token: PhoenixMeasurementPointSelectorDialogService, providedIn: "root" });
    return PhoenixMeasurementPointSelectorDialogService;
}());
export { PhoenixMeasurementPointSelectorDialogService };
