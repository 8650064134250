var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixDynamicFormControl } from '@phoenix/components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '@phoenix/components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '@phoenix/components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '@phoenix/components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import { TimeWindowConfigDayDto } from '@phoenix/gapicon/asset/dto/time-window-config-day-dto';
import { TimeWindowConfigDto } from '@phoenix/gapicon/asset/dto/time-window-config-dto';
import { TimeWindowConfigIntervallDto } from '@phoenix/gapicon/asset/dto/time-window-config-intervall-dto';
import _ from 'lodash';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixTimeWindowConfigDayWizardService } from './phoenix-time-window-config-day-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../phoenix-communication-service/phoenix-communication.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./phoenix-wizard-helper.service";
import * as i5 from "../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i6 from "./phoenix-time-window-config-day-wizard.service";
var PhoenixTimeWindowConfigWizardService = /** @class */ (function () {
    function PhoenixTimeWindowConfigWizardService(dialog, communicationService, translateService, phoenixWizardHelper, phoenixSnackbarService, timeWindowConfigDayWizardService) {
        this.dialog = dialog;
        this.communicationService = communicationService;
        this.translateService = translateService;
        this.phoenixWizardHelper = phoenixWizardHelper;
        this.phoenixSnackbarService = phoenixSnackbarService;
        this.timeWindowConfigDayWizardService = timeWindowConfigDayWizardService;
        this.dialogTimeWindowConfig = undefined;
        this.mpTimeWindowConfig = undefined;
        this.openElementWizard = undefined;
        this.rowFormControls = [];
        this.statusIconButtonFormControls = [];
        this.weekdayLabelFormControls = [];
        this.editIntervalButtonFormControls = [];
        this.intervalLabelFormControls = [];
    }
    PhoenixTimeWindowConfigWizardService.prototype.openEditTimeWindowConfigWizard = function (timeWindowConfig, openElementWizard, afterTimeWindowConfigEditedCallBack) {
        var _this = this;
        this.openElementWizard = openElementWizard;
        this.mpTimeWindowConfig = timeWindowConfig;
        this.dialogTimeWindowConfig = _.cloneDeep(timeWindowConfig);
        this.dialogTimeWindowConfig = !this.dialogTimeWindowConfig ? new TimeWindowConfigDto() : this.dialogTimeWindowConfig;
        this.dialogTimeWindowConfig.monday = !this.dialogTimeWindowConfig.monday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.monday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.monday);
        this.dialogTimeWindowConfig.tuesday = !this.dialogTimeWindowConfig.tuesday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.tuesday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.tuesday);
        this.dialogTimeWindowConfig.wednesday = !this.dialogTimeWindowConfig.wednesday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.wednesday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.wednesday);
        this.dialogTimeWindowConfig.thursday = !this.dialogTimeWindowConfig.thursday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.thursday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.thursday);
        this.dialogTimeWindowConfig.friday = !this.dialogTimeWindowConfig.friday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.friday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.friday);
        this.dialogTimeWindowConfig.saturday = !this.dialogTimeWindowConfig.saturday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.saturday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.saturday);
        this.dialogTimeWindowConfig.sunday = !this.dialogTimeWindowConfig.sunday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.sunday;
        this.createIntervalIfEmpty(this.dialogTimeWindowConfig.sunday);
        this.openTimeWindowConfigWizard(function (dialogResult) { return _this.onEditTimeWindowConfigCloseCallback(dialogResult, afterTimeWindowConfigEditedCallBack); }, 'WIZARDS.SELECTOR.TITLE.TIMEWINDOW');
    };
    PhoenixTimeWindowConfigWizardService.prototype.createIntervalIfEmpty = function (configDay) {
        if (configDay && configDay.enable && (!configDay.intervalls || configDay.intervalls.length == 0)) {
            configDay.intervalls = [];
            configDay.intervalls[0] = new TimeWindowConfigIntervallDto({ startTime: '0:00', endTime: '0:00' });
        }
    };
    PhoenixTimeWindowConfigWizardService.prototype.openTimeWindowConfigWizard = function (onClose, title) {
        this.dialog.open(PhoenixDialogComponent, {
            width: this.phoenixWizardHelper.DIALOGWIDTHBIG,
            autoFocus: false,
            data: {
                headline: title,
                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.TIMEWINDOW',
                onClose: onClose,
                buttons: {
                    cancel: true,
                    ok: true
                },
                formControls: [
                    this.createTimeWindowConfigFormControl(),
                ],
            },
        });
    };
    PhoenixTimeWindowConfigWizardService.prototype.deleteIntervalIfNotNeeded = function (configDay) {
        if (configDay && configDay.enable && configDay.intervalls && configDay.intervalls.length == 1
            && configDay.intervalls[0].startTime == '0:00' && configDay.intervalls[0].endTime == '0:00'
            || configDay && configDay.intervalls && configDay.intervalls.length == 0) {
            configDay.intervalls = null;
        }
    };
    PhoenixTimeWindowConfigWizardService.prototype.onEditTimeWindowConfigCloseCallback = function (dialogResult, afterTimeWindowConfigEditedCallBack) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (dialogResult.result === 'ok') {
                    this.mpTimeWindowConfig = this.dialogTimeWindowConfig;
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.monday);
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.tuesday);
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.wednesday);
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.thursday);
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.friday);
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.saturday);
                    this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.sunday);
                    this.mpTimeWindowConfig.dstOffset = moment().isDST() == true ? 1 : 0;
                }
                if (afterTimeWindowConfigEditedCallBack) {
                    afterTimeWindowConfigEditedCallBack(this.mpTimeWindowConfig);
                }
                dialogResult.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    PhoenixTimeWindowConfigWizardService.prototype.createTimeWindowConfigFormControl = function () {
        var formControlRows = [];
        for (var i = 0; i <= 6; i++) {
            formControlRows.push(this.createTimeWindowConfigFormControlRow(i));
        }
        return formControlRows;
    };
    PhoenixTimeWindowConfigWizardService.prototype.createTimeWindowConfigFormControlRow = function (dayIndex) {
        var configDay = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
        this.rowFormControls[dayIndex] = [];
        this.statusIconButtonFormControls[dayIndex] = this.createStatusIconButtonFormControl(dayIndex);
        this.weekdayLabelFormControls[dayIndex] = this.createWeekdayLabelFormControl(dayIndex);
        this.editIntervalButtonFormControls[dayIndex] = this.createEditIntervalButtonFormControl(dayIndex);
        this.intervalLabelFormControls[dayIndex] = this.createIntervalLabelFormControl(dayIndex);
        this.rowFormControls[dayIndex][0] = this.statusIconButtonFormControls[dayIndex];
        this.rowFormControls[dayIndex][1] = this.weekdayLabelFormControls[dayIndex];
        this.rowFormControls[dayIndex][2] = this.editIntervalButtonFormControls[dayIndex];
        this.rowFormControls[dayIndex][3] = this.intervalLabelFormControls[dayIndex];
        if (!configDay.enable) {
            this.rowFormControls[dayIndex][2].options['hidden'] = true;
        }
        return new PhoenixDynamicFormControl('timeWindowConfigArray' + dayIndex, PhoenixDynamicFormControlType.FormControlArray, undefined, false, {
            push: 2,
            topMargin: false,
            formControls: this.rowFormControls[dayIndex]
        });
    };
    PhoenixTimeWindowConfigWizardService.prototype.getWeekdayName = function (dayIndex) {
        switch (dayIndex) {
            case 0:
                return 'monday';
            case 1:
                return 'tuesday';
            case 2:
                return 'wednesday';
            case 3:
                return 'thursday';
            case 4:
                return 'friday';
            case 5:
                return 'saturday';
            case 6:
                return 'sunday';
            default:
                return 'monday';
        }
    };
    PhoenixTimeWindowConfigWizardService.prototype.createIntervalLabelFormControl = function (dayIndex) {
        var configDay = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
        var intervalText = '';
        if (configDay && configDay.intervalls && configDay.intervalls.length > 0) {
            intervalText = configDay.intervalls[0].startTime + ' - ' + configDay.intervalls[0].endTime;
            if (configDay.intervalls.length > 1) {
                intervalText += ' …';
            }
        }
        return new PhoenixDynamicFormControl('intervalLabel' + this.getWeekdayName(dayIndex), PhoenixDynamicFormControlType.Text, '', false, { fontSize: 24, classes: { 'time-window-interval': true } }, intervalText, true);
    };
    PhoenixTimeWindowConfigWizardService.prototype.createEditIntervalButtonFormControl = function (dayIndex) {
        var _this = this;
        return new PhoenixDynamicFormControl('editIntervalButton' + this.getWeekdayName(dayIndex), PhoenixDynamicFormControlType.IconButton, '', false, {
            color: 'basic',
            iconColor: PhoenixStatusColor.DEFAULT,
            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, this.showTimeWindowConfigDayDialog(form, dayIndex)];
            }); }); },
        }, PhoenixIcon.SCHEDULE);
    };
    PhoenixTimeWindowConfigWizardService.prototype.createWeekdayLabelFormControl = function (dayIndex) {
        return new PhoenixDynamicFormControl('weekdayLabel' + this.getWeekdayName(dayIndex), PhoenixDynamicFormControlType.Text, '', false, { fontSize: 24 }, this.translateService.instant('WIZARDS.TIMEWINDOW.WEEKDAYS.' + this.getWeekdayName(dayIndex).toUpperCase()), true);
    };
    PhoenixTimeWindowConfigWizardService.prototype.createStatusIconButtonFormControl = function (dayIndex) {
        var _this = this;
        var configDay = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
        return new PhoenixDynamicFormControl('statusIconButton' + this.getWeekdayName(dayIndex), PhoenixDynamicFormControlType.IconButton, '', false, {
            color: 'basic',
            iconColor: this.getStatusIconButtonIconColor(configDay),
            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, this.switchTimeWindowStatus(form, dayIndex)];
            }); }); },
        }, this.getStatusIconButtonIcon(configDay));
    };
    PhoenixTimeWindowConfigWizardService.prototype.getStatusIconButtonIcon = function (configDay) {
        if (configDay) {
            if (!configDay.enable) {
                return PhoenixIcon.LENS;
            }
            else if (configDay.intervalls && configDay.intervalls.length >= 1 &&
                !(configDay.intervalls[0].startTime == '0:00' && configDay.intervalls[0].endTime == '0:00')) {
                return PhoenixIcon.INCOMPLETE_CIRCLE;
            }
            else {
                return PhoenixIcon.INCOMPLETE_CIRCLE;
            }
        }
        else {
            return PhoenixIcon.LENS;
        }
    };
    PhoenixTimeWindowConfigWizardService.prototype.getStatusIconButtonIconColor = function (configDay) {
        if (configDay) {
            if (!configDay.enable) {
                return PhoenixStatusColor.POSITIVE;
            }
            else if (configDay.intervalls && configDay.intervalls.length >= 1 &&
                !(configDay.intervalls[0].startTime == '0:00' && configDay.intervalls[0].endTime == '0:00')) {
                return PhoenixStatusColor.POSITIVE;
            }
            else {
                return PhoenixStatusColor.NEGATIVE;
            }
        }
        else {
            return PhoenixStatusColor.POSITIVE;
        }
    };
    PhoenixTimeWindowConfigWizardService.prototype.switchTimeWindowStatus = function (form, dayIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var configDay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configDay = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
                        if (!configDay) return [3 /*break*/, 3];
                        if (!(configDay.enable == false)) return [3 /*break*/, 1];
                        configDay.enable = true;
                        configDay.intervalls = [];
                        configDay.intervalls[0] = new TimeWindowConfigIntervallDto({ startTime: '0:00', endTime: '0:00' });
                        this.intervalLabelFormControls[dayIndex].value = configDay.intervalls[0].startTime + ' - ' + configDay.intervalls[0].endTime;
                        this.statusIconButtonFormControls[dayIndex].options['iconColor'] = this.getStatusIconButtonIconColor(configDay);
                        this.statusIconButtonFormControls[dayIndex].value = this.getStatusIconButtonIcon(configDay);
                        this.rowFormControls[dayIndex][2].options['hidden'] = false;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.openDeleteDayConfigWizard(dayIndex)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PhoenixTimeWindowConfigWizardService.prototype.openDeleteDayConfigWizard = function (dayIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var hintTextOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hintTextOptions = {
                            classes: {
                                'font-size-12': true,
                                'hint-text': true,
                                'primary-300-fg': true,
                                'mt-4': true,
                                'mb-4': true,
                            },
                        };
                        return [4 /*yield*/, this.dialog.open(PhoenixDialogComponent, {
                                width: this.phoenixWizardHelper.DIALOGWIDTH,
                                autoFocus: false,
                                data: {
                                    headline: 'WIZARDS.TIMEWINDOW.DELETE.TITLE',
                                    subHeadline: this.translateService.instant('WIZARDS.TIMEWINDOW.WEEKDAYS.' + this.getWeekdayName(dayIndex).toUpperCase()),
                                    buttons: {
                                        cancel: true,
                                        delete: 'WIZARDS.TIMEWINDOW.DELETE.SUBMIT',
                                    },
                                    onClose: function (dialogResult) { return _this.onDeleteDayConfigCloseCallback(dialogResult, dayIndex); },
                                    color: 'warn',
                                    formControls: [[
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.TIMEWINDOW.DELETE.CONFIRMATION'),
                                        ]],
                                },
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixTimeWindowConfigWizardService.prototype.onDeleteDayConfigCloseCallback = function (dialogResult, dayIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var configDay;
            return __generator(this, function (_a) {
                if (dialogResult.result === 'delete') {
                    configDay = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
                    configDay.enable = false;
                    configDay.intervalls = [];
                    this.intervalLabelFormControls[dayIndex].value = '';
                    this.statusIconButtonFormControls[dayIndex].options['iconColor'] = this.getStatusIconButtonIconColor(configDay);
                    this.statusIconButtonFormControls[dayIndex].value = this.getStatusIconButtonIcon(configDay);
                    this.rowFormControls[dayIndex][2].options['hidden'] = true;
                }
                dialogResult.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    PhoenixTimeWindowConfigWizardService.prototype.showTimeWindowConfigDayDialog = function (form, dayIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var configDay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configDay = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
                        return [4 /*yield*/, this.timeWindowConfigDayWizardService.openEditTimeWindowConfigDayWizard(configDay, dayIndex, function (timeWindowConfigDay) {
                                _this.dialogTimeWindowConfig[_this.getWeekdayName(dayIndex)] = timeWindowConfigDay;
                                _this.statusIconButtonFormControls[dayIndex].options['iconColor'] = _this.getStatusIconButtonIconColor(timeWindowConfigDay);
                                _this.statusIconButtonFormControls[dayIndex].value = _this.getStatusIconButtonIcon(timeWindowConfigDay);
                                if (timeWindowConfigDay && timeWindowConfigDay.intervalls && timeWindowConfigDay.intervalls.length > 0) {
                                    var intervalLabel = timeWindowConfigDay.intervalls[0].startTime + ' - ' + timeWindowConfigDay.intervalls[0].endTime;
                                    if (timeWindowConfigDay.intervalls.length > 1) {
                                        intervalLabel += ' …';
                                    }
                                    _this.intervalLabelFormControls[dayIndex].value = intervalLabel;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixTimeWindowConfigWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixTimeWindowConfigWizardService_Factory() { return new PhoenixTimeWindowConfigWizardService(i0.inject(i1.MatDialog), i0.inject(i2.PhoenixCommunicationService), i0.inject(i3.TranslateService), i0.inject(i4.PhoenixWizardHelperService), i0.inject(i5.PhoenixSnackbarService), i0.inject(i6.PhoenixTimeWindowConfigDayWizardService)); }, token: PhoenixTimeWindowConfigWizardService, providedIn: "root" });
    return PhoenixTimeWindowConfigWizardService;
}());
export { PhoenixTimeWindowConfigWizardService };
