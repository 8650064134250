import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { AssetDto, AssetSearchCriteriaDto, FileAttachmentDto, TaskConfigDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto, UrlAttachmentDto } from '../../dto.module';
import { LatestMeasurementMapperService } from '../../measurement/mapper/latest-measurement-mapper.service';
import { PropertyMapperService } from '../../property/mapper/property-mapper.service';
import { SensorMapperService } from '../../sensor/mapper/sensor-mapper.service';
import { Asset } from '../api/asset';
import { AssetSearchCriteria } from '../api/asset-search-criteria';
import { AssetStatistics } from '../api/asset-statistics';
import { Attachments } from '../api/attachments';
import { FileAttachment } from '../api/file-attachment';
import { TaskConfigRepeat } from '../api/task-config-repeat';
import { TaskConfigRepeatType } from '../api/task-config-repeat-type.enum';
import { TaskConfig } from '../api/taskConfig';
import { TypeCount } from '../api/type-count';
import { UrlAttachment } from '../api/url-attachment';
import { MeasurementPointMapperService } from '../asset-types/measurement-point/mapper/measurement-point-mapper.service';
import { TenantMapperService } from '../asset-types/tenant/mapper/tenant-mapper.service';
import { AssetStatisticsDto } from '../dto/asset-statistics-dto';
import { AttachmentsDto } from '../dto/attachments-dto';
import { TypeCountDto } from '../dto/type-count-dto';
import { UserGroupMapperService } from "../../group/mapper/usergroup-mapper.service";
import { TimeWindowConfigIntervallDto } from '../dto/time-window-config-intervall-dto';
import { TimeWindowConfigIntervall } from '../api/time-window-config-intervall';
import { TimeWindowConfigDayDto } from '../dto/time-window-config-day-dto';
import { TimeWindowConfigDay } from '../api/time-window-config-day';
import { TimeWindowConfig } from '../api/time-window-config';
import { TimeWindowConfigDto } from '../dto/time-window-config-dto';
import { TimeWindowHelper } from './time-window-helper';
import { DynamicAssetDto } from '../dto/dynamic-asset-dto';
import { DynamicAsset } from '../api/dynamic-asset';
import * as i0 from "@angular/core";
import * as i1 from "../../measurement/mapper/latest-measurement-mapper.service";
import * as i2 from "../asset-types/measurement-point/mapper/measurement-point-mapper.service";
import * as i3 from "../asset-types/tenant/mapper/tenant-mapper.service";
import * as i4 from "../../sensor/mapper/sensor-mapper.service";
import * as i5 from "../../property/mapper/property-mapper.service";
import * as i6 from "../../group/mapper/usergroup-mapper.service";
var AssetMapperService = /** @class */ (function () {
    function AssetMapperService(measurementMapper, measurementPointMapper, tenantMapper, sensorMapper, propertyMapper, userGroupMapperService) {
        this.measurementMapper = measurementMapper;
        this.measurementPointMapper = measurementPointMapper;
        this.tenantMapper = tenantMapper;
        this.sensorMapper = sensorMapper;
        this.propertyMapper = propertyMapper;
        this.userGroupMapperService = userGroupMapperService;
    }
    AssetMapperService.prototype.assetArrayToAssetDtoArray = function (assets) {
        var _this = this;
        return assets.map(function (asset) { return _this.assetToAssetDto(asset); });
    };
    AssetMapperService.prototype.assetDtoArrayToAssetArray = function (assetDtos) {
        var _this = this;
        return _.map(assetDtos, function (assetDto) { return _this.assetDtoToAsset(assetDto); });
    };
    AssetMapperService.prototype.assetDtoToAsset = function (assetDto) {
        var asset = plainToClass(Asset, assetDto);
        if (asset) {
            asset.lastMeasurement = assetDto && assetDto.lastMeasurement ? this.measurementMapper.measurementDtoToMeasurement(assetDto.lastMeasurement) : undefined;
            asset.measurementPoint = assetDto.measurementPoint ? this.measurementPointMapper.measurementPointDtoToMeasurementPoint(assetDto.measurementPoint) : undefined;
            asset.tenant = assetDto.tenant ? this.tenantMapper.tenantDtoToTenant(assetDto.tenant) : undefined;
            asset.childStatistics = assetDto.childStatistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.childStatistics) : undefined;
            asset.sensor = assetDto.sensor ? this.sensorMapper.sensorDtoToSensor(assetDto.sensor) : undefined;
            asset.statistics = assetDto.statistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.statistics) : undefined;
            asset.properties = assetDto.properties ? this.propertyMapper.propertyDtoArrayToPropertyArray(assetDto.properties) : undefined;
            asset.attachments = assetDto.attachments ? this.attachmentsDtoToAttachments(assetDto.attachments) : undefined;
            asset.taskConfigs = assetDto.taskConfigs ? this.taskConfigDtosToTaskConfigs(assetDto.taskConfigs) : [];
            asset.assignedUserGroups = assetDto.assignedUserGroups ? this.userGroupMapperService.groupsDtoToGroups(assetDto.assignedUserGroups) : [];
            asset.timeWindowConfig = assetDto.timeWindowConfig ? this.timeWindowConfigDtoToTimeWindowConfig(assetDto.timeWindowConfig) : undefined;
            asset.costCenterCode = assetDto.costCenterCode ? assetDto.costCenterCode : undefined;
            asset.costCenterType = assetDto.costCenterType ? assetDto.costCenterType : undefined;
            asset.language = assetDto.language ? assetDto.language : undefined;
            asset.provider = assetDto.provider ? assetDto.provider : undefined;
            //asset.sensorTypeThreshold = assetDto.sensorTypeThreshold ? assetDto.sensorTypeThreshold : undefined;
        }
        return asset;
    };
    AssetMapperService.prototype.dynamicAssetsDtoToDynamicAssets = function (assetsDto) {
        var _this = this;
        return assetsDto.map(function (assetDto) { return _this.dynamicAssetDtoToDynamicAsset(assetDto); });
    };
    AssetMapperService.prototype.dynamicAssetDtoToDynamicAsset = function (assetDto) {
        var asset = plainToClass(DynamicAsset, assetDto);
        if (asset) {
            asset.lastMeasurement = assetDto && assetDto.lastMeasurement ? this.measurementMapper.measurementDtoToMeasurement(assetDto.lastMeasurement) : undefined;
            asset.measurementPoint = assetDto.measurementPoint ? this.measurementPointMapper.measurementPointDtoToMeasurementPoint(assetDto.measurementPoint) : undefined;
            asset.tenant = assetDto.tenant ? this.tenantMapper.tenantDtoToTenant(assetDto.tenant) : undefined;
            asset.childStatistics = assetDto.childStatistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.childStatistics) : undefined;
            asset.sensor = assetDto.sensor ? this.sensorMapper.sensorDtoToSensor(assetDto.sensor) : undefined;
            asset.statistics = assetDto.statistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.statistics) : undefined;
            //asset.properties = assetDto.properties ? this.propertyMapper.propertyDtoArrayToPropertyArray(assetDto.properties) : undefined;
            asset.properties = undefined;
            asset.attachments = assetDto.attachments ? this.attachmentsDtoToAttachments(assetDto.attachments) : undefined;
            //asset.taskConfigs = assetDto.taskConfigs ? this.taskConfigDtosToTaskConfigs(assetDto.taskConfigs) : [];
            asset.taskConfigs = undefined;
            //asset.assignedUserGroups = assetDto.assignedUserGroups ? this.userGroupMapperService.groupsDtoToGroups(assetDto.assignedUserGroups) : [];
            asset.timeWindowConfig = assetDto.timeWindowConfig ? this.timeWindowConfigDtoToTimeWindowConfig(assetDto.timeWindowConfig) : undefined;
            asset.costCenterCode = assetDto.costCenterCode ? assetDto.costCenterCode : undefined;
            asset.costCenterType = assetDto.costCenterType ? assetDto.costCenterType : undefined;
            asset.language = assetDto.language ? assetDto.language : undefined;
            asset.assignedUserGroups = undefined;
            asset.tags = undefined;
            asset.provider = assetDto.provider ? assetDto.provider : undefined;
        }
        return asset;
    };
    AssetMapperService.prototype.assetSearchCriteriaDtoToAssetSearchCriteria = function (assetSearchCriteriaDto) {
        return plainToClass(AssetSearchCriteria, assetSearchCriteriaDto);
    };
    AssetMapperService.prototype.assetSearchCriteriaToAssetSearchCriteriaDto = function (assetSearchCriteria) {
        return plainToClass(AssetSearchCriteriaDto, assetSearchCriteria);
    };
    AssetMapperService.prototype.assetStatisticsArrayToAssetStatisticsDtoArray = function (assetsStatistics) {
        var _this = this;
        return _.map(assetsStatistics, function (assetStatistics) { return _this.assetStatisticsToAssetStatisticsDto(assetStatistics); });
    };
    AssetMapperService.prototype.assetStatisticsDtoArrayToAssetStatisticsArray = function (assetStatisticsDtos) {
        var _this = this;
        return _.map(assetStatisticsDtos, function (assetStatisticsDto) { return _this.assetStatisticsDtoToAssetStatistics(assetStatisticsDto); });
    };
    AssetMapperService.prototype.assetStatisticsDtoToAssetStatistics = function (assetStatisticsDto) {
        return new AssetStatistics({
            activeNotificationTypesCount: _.map(assetStatisticsDto.activeNotificationTypesCount, function (typeCount) { return plainToClass(TypeCount, typeCount); }),
            assetTypesCount: _.map(assetStatisticsDto.assetTypesCount, function (typeCount) { return plainToClass(TypeCount, typeCount); }),
            violationCount: assetStatisticsDto.violationCount,
            inactiveNotificationTypesCount: _.map(assetStatisticsDto.inactiveNotificationTypesCount, function (typeCount) { return plainToClass(TypeCount, typeCount); }),
        });
    };
    AssetMapperService.prototype.assetStatisticsToAssetStatisticsDto = function (assetStatistics) {
        return new AssetStatisticsDto({
            activeNotificationTypesCount: _.map(assetStatistics.activeNotificationTypesCount, function (typeCount) { return plainToClass(TypeCountDto, typeCount); }),
            assetTypesCount: _.map(assetStatistics.assetTypesCount, function (typeCount) { return plainToClass(TypeCountDto, typeCount); }),
            violationCount: assetStatistics.violationCount,
            positiveCount: assetStatistics.positiveCount,
            inactiveNotificationTypesCount: _.map(assetStatistics.inactiveNotificationTypesCount, function (typeCount) { return plainToClass(TypeCountDto, typeCount); }),
        });
    };
    AssetMapperService.prototype.assetToAssetDto = function (asset) {
        var assetDto = plainToClass(AssetDto, asset);
        if (assetDto) {
            assetDto.lastMeasurement = asset && asset.lastMeasurement ? this.measurementMapper.measurementToMeasurementDto(asset.lastMeasurement) : undefined;
            assetDto.measurementPoint = asset.measurementPoint ? this.measurementPointMapper.measurementPointToMeasurementPointDto(asset.measurementPoint) : undefined;
            assetDto.tenant = asset.tenant ? this.tenantMapper.tenantToTenantDto(asset.tenant) : undefined;
            assetDto.childStatistics = asset.childStatistics ? this.assetStatisticsToAssetStatisticsDto(asset.childStatistics) : undefined;
            assetDto.sensor = asset.sensor ? this.sensorMapper.sensorToSensorDto(asset.sensor) : undefined;
            assetDto.statistics = asset.statistics ? this.assetStatisticsToAssetStatisticsDto(asset.statistics) : undefined;
            assetDto.properties = asset.properties ? this.propertyMapper.propertyArrayToPropertyDtoArray(asset.properties) : undefined;
            assetDto.attachments = asset.attachments ? this.attachmentsToAttachmentsDto(asset.attachments) : undefined;
            assetDto.taskConfigs = asset.taskConfigs ? this.taskConfigsToTaskConfigsDto(asset.taskConfigs) : [];
            assetDto.assignedUserGroups = asset.assignedUserGroups ? this.userGroupMapperService.groupsToGroupsDto(asset.assignedUserGroups) : [];
            assetDto.timeWindowConfig = asset.timeWindowConfig ? this.timeWindowConfigToTimeWindowConfigDto(asset.timeWindowConfig) : undefined;
            assetDto.costCenterCode = asset.costCenterCode ? asset.costCenterCode : undefined;
            assetDto.costCenterType = asset.costCenterType ? asset.costCenterType : undefined;
            assetDto.language = asset.language ? asset.language : undefined;
            assetDto.provider = asset.provider ? asset.provider : undefined;
            //assetDto.sensorTypeThreshold = asset.sensorTypeThreshold ? asset.sensorTypeThreshold : undefined;
        }
        return assetDto;
    };
    AssetMapperService.prototype.dynamicAssetToDynamicAssetDto = function (asset) {
        var assetDto = plainToClass(DynamicAssetDto, asset);
        if (assetDto) {
            assetDto.lastMeasurement = asset && asset.lastMeasurement ? this.measurementMapper.measurementToMeasurementDto(asset.lastMeasurement) : undefined;
            assetDto.measurementPoint = asset.measurementPoint ? this.measurementPointMapper.measurementPointToMeasurementPointDto(asset.measurementPoint) : undefined;
            assetDto.tenant = asset.tenant ? this.tenantMapper.tenantToTenantDto(asset.tenant) : undefined;
            assetDto.childStatistics = asset.childStatistics ? this.assetStatisticsToAssetStatisticsDto(asset.childStatistics) : undefined;
            assetDto.sensor = asset.sensor ? this.sensorMapper.sensorToSensorDto(asset.sensor) : undefined;
            assetDto.statistics = asset.statistics ? this.assetStatisticsToAssetStatisticsDto(asset.statistics) : undefined;
            //assetDto.properties = asset.properties ? this.propertyMapper.propertyArrayToPropertyDtoArray(asset.properties) : undefined;
            assetDto.attachments = asset.attachments ? this.attachmentsToAttachmentsDto(asset.attachments) : undefined;
            //assetDto.taskConfigs = asset.taskConfigs ? this.taskConfigsToTaskConfigsDto(asset.taskConfigs) : [];
            //assetDto.assignedUserGroups = asset.assignedUserGroups ? this.userGroupMapperService.groupsToGroupsDto(asset.assignedUserGroups) : [];
            assetDto.timeWindowConfig = asset.timeWindowConfig ? this.timeWindowConfigToTimeWindowConfigDto(asset.timeWindowConfig) : undefined;
            assetDto.costCenterCode = asset.costCenterCode ? asset.costCenterCode : undefined;
            assetDto.costCenterType = asset.costCenterType ? asset.costCenterType : undefined;
            assetDto.language = asset.language ? asset.language : undefined;
            assetDto.provider = asset.provider ? asset.provider : undefined;
        }
        return assetDto;
    };
    AssetMapperService.prototype.attachmentsDtoToAttachments = function (attachments) {
        return new Attachments({
            files: _.map(attachments.files, function (fileAttachment) { return plainToClass(FileAttachment, fileAttachment); }),
            urls: _.map(attachments.urls, function (urlAttachment) { return plainToClass(UrlAttachment, urlAttachment); }),
        });
    };
    AssetMapperService.prototype.attachmentsToAttachmentsDto = function (attachments) {
        return new AttachmentsDto({
            files: _.map(attachments.files, function (fileAttachment) { return plainToClass(FileAttachmentDto, fileAttachment); }),
            urls: _.map(attachments.urls, function (urlAttachment) { return plainToClass(UrlAttachmentDto, urlAttachment); }),
        });
    };
    /**
     * Convert an array of TaskConfigDtos to an array of TaskConfig objects
     * @param taskConfigDtos the TaskConfigDtos that should be converted
     */
    AssetMapperService.prototype.taskConfigDtosToTaskConfigs = function (taskConfigDtos) {
        var _this = this;
        return _.map(taskConfigDtos, function (taskConfigDto) { return new TaskConfig({
            createdDate: taskConfigDto.createdDate,
            duration: taskConfigDto.duration,
            id: taskConfigDto.id,
            repeat: taskConfigDto.repeat ? _this.taskConfigRepeatDtoToTaskConfigRepeat(taskConfigDto.repeat) : undefined,
            startDate: taskConfigDto.startDate,
            timezone: taskConfigDto.timezone,
            assignedUserGroupIds: taskConfigDto.assignedUserGroupIds ? taskConfigDto.assignedUserGroupIds : []
        }); });
    };
    AssetMapperService.prototype.taskConfigRepeatDtoToTaskConfigRepeat = function (taskConfigRepeatDto) {
        return new TaskConfigRepeat({
            value: taskConfigRepeatDto.value,
            type: TaskConfigRepeatType[taskConfigRepeatDto.type],
            config: taskConfigRepeatDto.config,
        });
    };
    AssetMapperService.prototype.taskConfigRepeatToTaskConfigRepeatDto = function (taskConfigRepeat) {
        return new TaskConfigRepeatDto({
            value: taskConfigRepeat.value,
            type: TaskConfigRepeatTypeDto[taskConfigRepeat.type],
            config: taskConfigRepeat.config,
        });
    };
    /**
     * Convert an array of TaskConfig objects to an array of TaskConfigDtos
     * @param taskConfigs the TaskConfig objects that should be converted
     */
    AssetMapperService.prototype.taskConfigsToTaskConfigsDto = function (taskConfigs) {
        var _this = this;
        return _.map(taskConfigs, function (taskConfig) { return new TaskConfigDto({
            createdDate: taskConfig.createdDate,
            duration: taskConfig.duration,
            id: taskConfig.id,
            repeat: taskConfig.repeat ? _this.taskConfigRepeatToTaskConfigRepeatDto(taskConfig.repeat) : undefined,
            startDate: taskConfig.startDate,
            timezone: taskConfig.timezone,
            assignedUserGroupIds: taskConfig.assignedUserGroupIds ? taskConfig.assignedUserGroupIds : []
        }); });
    };
    /**
     * Convert a TimeWindowConfigDto object to a TimeWindowConfig object
     * @param timeWindowConfigDto the TimeWindowConfigDto object that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigDtoToTimeWindowConfig = function (timeWindowConfigDto) {
        timeWindowConfigDto = TimeWindowHelper.convertTimeWindowConfigDtoFromLocaltoUTC(timeWindowConfigDto);
        return new TimeWindowConfig({
            monday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.monday),
            tuesday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.tuesday),
            wednesday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.wednesday),
            thursday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.thursday),
            friday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.friday),
            saturday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.saturday),
            sunday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.sunday),
            dstOffset: timeWindowConfigDto.dstOffset
        });
    };
    /**
     * Convert a TimeWindowConfig object to a TimeWindowConfigDto object
     * @param timeWindowConfig the TimeWindowConfig object that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigToTimeWindowConfigDto = function (timeWindowConfig) {
        var timeWindowConfigDto = new TimeWindowConfigDto({
            monday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.monday),
            tuesday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.tuesday),
            wednesday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.wednesday),
            thursday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.thursday),
            friday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.friday),
            saturday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.saturday),
            sunday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.sunday),
            dstOffset: timeWindowConfig.dstOffset
        });
        timeWindowConfigDto = TimeWindowHelper.convertTimeWindowConfigDtoFromUTCtoLocal(timeWindowConfigDto);
        return timeWindowConfigDto;
    };
    /**
     * Convert a TimeWindowConfigDayDto object to a TimeWindowConfigDay object
     * @param timeWindowConfigDayDto the TimeWindowConfigDayDto object that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigDayDtoToTimeWindowConfigDay = function (timeWindowConfigDayDto) {
        return new TimeWindowConfigDay({
            enable: timeWindowConfigDayDto.enable,
            intervalls: this.timeWindowConfigIntervallDtosToTimeWindowConfigIntervalls(timeWindowConfigDayDto.intervalls)
        });
    };
    /**
     * Convert a TimeWindowConfigDay object to a TimeWindowConfigDayDto object
     * @param timeWindowConfigDay the TimeWindowConfigDay object that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigDayToTimeWindowConfigDayDto = function (timeWindowConfigDay) {
        return new TimeWindowConfigDayDto({
            enable: timeWindowConfigDay.enable,
            intervalls: this.timeWindowConfigIntervallsToTimeWindowConfigIntervallDtos(timeWindowConfigDay.intervalls)
        });
    };
    /**
     * Convert a TimeWindowConfigIntervallDto object to a TimeWindowConfigIntervall object
     * @param timeWindowConfigIntervallDto the TimeWindowConfigIntervallDto object that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigIntervallDtoToTimeWindowConfigIntervall = function (timeWindowConfigIntervallDto) {
        return new TimeWindowConfigIntervall({
            startTime: timeWindowConfigIntervallDto.startTime,
            endTime: timeWindowConfigIntervallDto.endTime
        });
    };
    /**
     * Convert a TimeWindowConfigIntervall object to a TimeWindowConfigIntervallDto object
     * @param timeWindowConfigIntervall the TimeWindowConfigIntervall object that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigIntervallToTimeWindowConfigIntervallDto = function (timeWindowConfigIntervall) {
        return new TimeWindowConfigIntervallDto({
            startTime: timeWindowConfigIntervall.startTime,
            endTime: timeWindowConfigIntervall.endTime
        });
    };
    /**
     * Convert an array of TimeWindowConfigIntervallDto objects to an array of TimeWindowConfigIntervalls
     * @param timeWindowConfigIntervallDtos the TimeWindowConfigIntervallDto objects that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigIntervallDtosToTimeWindowConfigIntervalls = function (timeWindowConfigIntervallDtos) {
        return _.map(timeWindowConfigIntervallDtos, function (timeWindowConfigIntervallDto) { return new TimeWindowConfigIntervall({
            startTime: timeWindowConfigIntervallDto.startTime,
            endTime: timeWindowConfigIntervallDto.endTime
        }); });
    };
    /**
     * Convert an array of TimeWindowConfigIntervall objects to an array of TimeWindowConfigIntervallDtos
     * @param timeWindowConfigIntervalls the TimeWindowConfigIntervall objects that should be converted
     */
    AssetMapperService.prototype.timeWindowConfigIntervallsToTimeWindowConfigIntervallDtos = function (timeWindowConfigIntervalls) {
        return _.map(timeWindowConfigIntervalls, function (timeWindowConfigIntervall) { return new TimeWindowConfigIntervallDto({
            startTime: timeWindowConfigIntervall.startTime,
            endTime: timeWindowConfigIntervall.endTime
        }); });
    };
    AssetMapperService.ngInjectableDef = i0.defineInjectable({ factory: function AssetMapperService_Factory() { return new AssetMapperService(i0.inject(i1.LatestMeasurementMapperService), i0.inject(i2.MeasurementPointMapperService), i0.inject(i3.TenantMapperService), i0.inject(i4.SensorMapperService), i0.inject(i5.PropertyMapperService), i0.inject(i6.UserGroupMapperService)); }, token: AssetMapperService, providedIn: "root" });
    return AssetMapperService;
}());
export { AssetMapperService };
