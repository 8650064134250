import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-checkbox',
  templateUrl: './phoenix-dynamic-checkbox.component.html',
  styleUrls: ['./phoenix-dynamic-checkbox.component.scss'],
})
export class PhoenixDynamicCheckboxComponent {

  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<boolean>;

  @Input()
  public form: FormGroup;

  public constructor() {
  }
}
