import { FormGroup } from '@angular/forms';
import _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicTextComponent = /** @class */ (function () {
    function PhoenixDynamicTextComponent() {
        this.translationParams = {};
    }
    PhoenixDynamicTextComponent.prototype.readOnlyTextStyle = function () {
        if (_.has(this.phoenixFormControl.options, 'fontSize') && _.isNumber(this.phoenixFormControl.options['fontSize'])) {
            var style = { 'font-size.px': this.phoenixFormControl.options['fontSize'] };
            return style;
        }
        return {};
    };
    return PhoenixDynamicTextComponent;
}());
export { PhoenixDynamicTextComponent };
