import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { AuthApiService } from '@phoenix/gapicon/auth/services/auth-api.service';
import { UserApiService } from '@phoenix/gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AssetApiService } from '../../@phoenix/gapicon/asset/services/asset-api.service';
import {
  AssetDto,
  UserDto
} from '../../@phoenix/gapicon/dto.module';
import { UserGroupDto } from '../../@phoenix/gapicon/group/dto/user-group-dto';
import { Roles } from '../../@phoenix/gapicon/role/api/roles.enum';
import { PhoenixTenantSelectorDialogService } from '../../@phoenix/templates/phoenix-dialog/phoenix-tenantselector-dialog/phoenix-tenant-selector-dialog.service';
import { AppInitStorageService } from '../services/app-init-storage.service';

import { Permission, permissionRole } from './permission';
import { PhoenixAssetBranchselectorDialogService } from '../../@phoenix/templates/phoenix-dialog/phoenix-asset-branchselector-dialog/phoenix-asset-branchselector-dialog.service';
import { TenantApiService } from "../../@phoenix/gapicon/tenant/services/tenant-api.service";

@Injectable()
export class PermissionService {

  public rolesWithTenantNames: { [key: string]: string[] };
  private _availableTenants: AssetDto[];
  private _availableAssetRootBranches: AssetDto[];
  private _tenants: AssetDto[];
  private _assetBranches: AssetDto[];
  private _user: UserDto;
  private tokenExpirationTimer = null;
  // autoLogout after 10h from last user action(period of time in seconds)
  private readonly autoLogoutAfter: number = 60 * 60 * 10;
  private _defaultUrl = "/dashboard"

  public constructor(
    private authApi: AuthApiService,
    private userApi: UserApiService,
    private assetApi: AssetApiService,
    private router: Router,
    private communicationService: PhoenixCommunicationService,
    private tenantSelectorDialogService: PhoenixTenantSelectorDialogService,
    private assetBranchSelectorDialogService: PhoenixAssetBranchselectorDialogService,
    private appInitStorageService: AppInitStorageService,
    private tenantApi: TenantApiService,
    private location: Location
  ) {
    this.communicationService.getObservable(PhoenixCommunicationSubject.UserUpdated)
      .subscribe((userDto: UserDto) => this.user = userDto);
  }

  public get availableTenants(): AssetDto[] {
    return this._availableTenants;
  }

  public get availableAssetRootBranches(): AssetDto[] {
    return this._availableAssetRootBranches;
  }

  public get tenants(): AssetDto[] {
    return this._tenants;
  }

  public get assetBranches(): AssetDto[] {
    return this._assetBranches;
  }

  public set assetBranches(value: AssetDto[]) {
    // save current selected assetBranch to local storage;
    this._assetBranches = value;
    localStorage.setItem('selectedAssetBranches', JSON.stringify(value.map((asset: AssetDto) => asset.id)));
    //this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this._assetBranches);
    //this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.tenants);
  }


  public set tenants(value: AssetDto[]) {
    this._tenants = value;
    // save current selected tenant to local storage;
    localStorage.setItem('selectedTenants', JSON.stringify(value.map((tenant: AssetDto) => tenant.id)));
    this.authApi.setCurrentTenants(value.map((tenant: AssetDto) => tenant.id));
    // this.communicationService.emit(PhoenixCommunicationSubject.TenantChanged, this.tenants);
  }

  public get user(): UserDto {
    return this._user;
  }

  public set user(value: UserDto) {
    this._user = value;
    this.communicationService.emit(PhoenixCommunicationSubject.UserChanged, this.user);
  }

  public getAvailableTenantIds(): string[] {
    if (this.user) {
      return Array.from(new Set(
        this.authApi.getTenantsIdsFromGroupRole(this.user.roles.admin)
          .concat(this.authApi.getTenantsIdsFromGroupRole(this.user.roles.user)),
      ));
    }
    return [];
  }

  public getAvailableTenants(): Observable<AssetDto[]> {
    const tenantIds: string[] = this.getAvailableTenantIds();
    if (tenantIds.length > 0) {
      this.authApi.setCurrentTenants(tenantIds);
      return this.tenantApi.getAllTenantsForUserWithId(this.user.id).pipe(
        tap(() => this.authApi.setCurrentTenants(tenantIds)),
      );
    } else {
      return of([]);
    }
  }

  public async refreshBranchesForSelectedTenant(): Promise<void> {
    this._availableAssetRootBranches = await this.getAvailableAssetBranchesForSelectedTenant().toPromise();
    const newAssetBranch: AssetDto[] = await this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise();
    if (Array.isArray(newAssetBranch) && newAssetBranch.length) {
      this.assetBranches = newAssetBranch;
      this.updateGroupAssetPairs();
      this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
    }
  }

  public getTenantIds(): string[] {
    return _.map(this.tenants, 'id');
  }

  public hasPermission(permission: Permission): boolean {
    let permissionGranted: boolean;
    if ((this.tenants && this.tenants.length > 0) && (this.assetBranches && this.assetBranches.length > 0)) {
      const selectedTenant: AssetDto = this.tenants[0];
      const selectedAssetBranch: AssetDto = this.assetBranches[0];
      // get groups ids assigned to assets selected by user
      const userGroupIdsAssignedToUser: string[] = selectedAssetBranch.assignedUserGroups
        .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id))
        .map((group: UserGroupDto) => group.id);
      // get groups names assigned to assets selected by user
      const hasDefaultManagementAdminRole: boolean = selectedAssetBranch.assignedUserGroups
        .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id) && group.role == "admin" && group.name == "Default management admins")
        .length > 0;
      // check if user has admin role for particular groups assigned to selected root asset branch
      const hasUserAdminRole: boolean = this.user.roles.admin ? this.user.roles.admin
        .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;
      // check if user has user role for particular groups assigned to selected root asset branch
      const hasUserUserRole: boolean = this.user.roles.user ? this.user.roles.user
        .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;

      if (hasDefaultManagementAdminRole) {
        permissionGranted = permissionRole['DEFAULT_MANAGEMENT_ADMIN'].includes(permission.id);
      } else if (hasUserAdminRole) {
        permissionGranted = permissionRole['ADMIN'].includes(permission.id);
      } else if (hasUserUserRole) {
        permissionGranted = permissionRole['USER'].includes(permission.id);
      } else {
        _.forEach(this.user.roles, (tenants: string[], role: string) => {
          permissionGranted = _.includes(permissionRole[role.toUpperCase()], permission.id) && _.includes(tenants, selectedTenant.id);
          if (permissionGranted) {
            // End of for Each
            return false;
          }

        });
      }
    }
    if (!permissionGranted) {
      //console.log("++++ this.user:", this.user);
      permissionGranted = _.includes(permissionRole[Roles.EnvironmentAdmin.toUpperCase()], permission.id) && this.user.roles.hasOwnProperty(Roles.EnvironmentAdmin);
    }
    return permissionGranted;
  }

  public async login(email: string, password: string): Promise<void> {

    let isLoggedin: boolean = false;
    try {
      isLoggedin = await this.authApi.login(email, password).toPromise();
      this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);
    } finally {
      if (isLoggedin) {
        this.user = await this.userApi.getUserById(this.authApi.getAuth().userInfo.id).toPromise();
        this._availableTenants = await this.getAvailableTenants().toPromise();
        localStorage.setItem('availableTenants', JSON.stringify(this._availableTenants.map((tenant: AssetDto) => tenant.id)));
        this.tenants = await this.tenantSelectorDialogService.showTenantSelectorDialog(this.availableTenants).toPromise();

        this._availableAssetRootBranches = await this.getAvailableAssetBranchesForSelectedTenant().toPromise();
        this.assetBranches = await this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise();
        this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);

        // get assigned groups
        this.updateGroupAssetPairs();
        this.findUserRolesFromAvailableTenants();
        this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: isLoggedin });
        this.router.navigate(['']);
      } else {
        this.logout()
        // this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: isLoggedin });
      }
    }
  }

  public async autoLogin(): Promise<any> {
    let currentUrl = this.location.path();
    try {
      this.user = await this.userApi.getUserById(this.authApi.getAuth().userInfo.id).toPromise();
      this._availableTenants = await this.getAvailableTenants().toPromise();
      const currentTenants: string[] = JSON.parse(localStorage.getItem('selectedTenants'));
      let filteredTenants = this._availableTenants.filter((tenant: AssetDto) => currentTenants[0] === tenant.id);
      if (filteredTenants && filteredTenants.length > 0) {
        this.tenants = filteredTenants;
      }

      this._availableAssetRootBranches = await this.getAvailableAssetBranchesForSelectedTenant().toPromise();
      const currentAssetBranch: string[] = JSON.parse(localStorage.getItem('selectedAssetBranches'));
      if (currentAssetBranch && currentAssetBranch.length === 0) {
        this.assetBranches = await this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise();
      } else if (currentAssetBranch) {
        let filteredAssetBranches = this._availableAssetRootBranches.filter((branch: AssetDto) => currentAssetBranch[0] === branch.id);
        if (filteredAssetBranches && filteredAssetBranches.length > 0) {
          this.assetBranches = filteredAssetBranches;
          this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
        }
      }

      // get assigned groups
      this.updateGroupAssetPairs();
      this.findUserRolesFromAvailableTenants();

      this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: true });
      this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);

      if (currentUrl === '') {
        this.router.navigate([this._defaultUrl]);
      } else {
        this.router.navigate([currentUrl]);
      }
    } catch (e) {
      console.log('autoLogin error', e)
      this.logout();
    }


  }

  public logout(): void {
    this.authApi.logout();
    this.router.navigate(['/login']);
    // is true when logout manually
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    // this.communicationService.emit(PhoenixCommunicationSubject.UserLogout, {value: true});
  }

  public autoLogoutAfterPeriodTime(expirationDuration: number): void {
    if (this.tokenExpirationTimer) {
      clearInterval(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  public startNewAutoLoginCountDown(): void {
    if (!this.userIsNotAuthenticated()) {
      this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);
    }
  }

  public routeNeedsPermission(route: ActivatedRouteSnapshot | Route): boolean {
    return _.hasIn(route, 'data.permission');
  }

  public userIsNotAuthenticated(): boolean {
    return !Boolean(this.authApi.getAuth());
  }

  public updateGroupAssetPairs(): void {
    if (this.assetBranches && this._assetBranches.length > 0) {
      const groupAssetPairs: string[] = this.assetBranches[0].assignedUserGroups
        .filter((assignedGroup: UserGroupDto) => assignedGroup.assignedUsersIds.includes(this.user.id))
        .map((assignedGroup: UserGroupDto) => `${assignedGroup.id}:${this.assetBranches[0].id}`);
      this.authApi.setGroupAssetPairs(groupAssetPairs);
    }
  }

  private findUserRolesFromAvailableTenants(): void {
    this.rolesWithTenantNames = {};
    // _.forEach(this.user.roles, (tenants: string[], role: string) => {
    //   this.rolesWithTenantNames[role] = _.map(tenants, (tenant: string) => {
    //     return _.get(_.find(this.availableTenants, {id: tenant}), 'name');
    //   });
    //
    //   _.remove(this.rolesWithTenantNames[role], _.isUndefined);
    // });
  }

  private getAvailableAssetBranchesForSelectedTenant(): Observable<AssetDto[]> {
    if (this.user) {
      return this.assetApi.getAssetBranches(this.user.id);
    }
    return of([]);
  }


  public isAdmin(): boolean {
    const selectedTenant: AssetDto = this.tenants[0];
    const selectedAssetBranch: AssetDto = this.assetBranches[0];
    // get groups ids assigned to assets selected by user
    const userGroupIdsAssignedToUser: string[] = selectedAssetBranch.assignedUserGroups
      .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id))
      .map((group: UserGroupDto) => group.id);
    // get groups names assigned to assets selected by user
    const hasDefaultManagementAdminRole: boolean = selectedAssetBranch.assignedUserGroups
      .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id) && group.role == "admin" && group.name == "Default management admins")
      .length > 0;
    // check if user has admin role for particular groups assigned to selected root asset branch
    const hasUserAdminRole: boolean = this.user.roles.admin ? this.user.roles.admin
      .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;
    return hasDefaultManagementAdminRole; //|| hasUserAdminRole;
  }
}
