import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import { addSeconds, differenceInSeconds } from 'date-fns';
import * as _ from 'lodash';

import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { TaskConfigDto, TaskConfigRepeatConfigTypeDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto } from '../../../../gapicon/dto.module';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

import { PhoenixTaskConfigDialogAnnuallyComponent } from './phoenix-task-config-dialog-annually.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixTaskConfigDialogAnnuallyService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public async openDialog(userGroups: UserGroupDto[], taskConfig?: TaskConfigDto): Promise<MatDialogRef<PhoenixTaskConfigDialogAnnuallyComponent>> {
    const { start, end, turnus, weekdays, week, days, month, assignedUserGroupIds } = this.getValuesForFormGroup(taskConfig);
    const startDatePicker: Date = taskConfig && new Date(taskConfig.startDate) < new Date() ? new Date(taskConfig.startDate) : new Date();
    const id: string = taskConfig ? taskConfig.id : undefined;
    const formGroup: FormGroup = new FormGroup({
      start: new FormControl(start, [Validators.required]),
      end: new FormControl(end, [Validators.required]),
      turnus: new FormControl(turnus, [Validators.required]),
      weekdays: new FormControl(weekdays, []),
      week: new FormControl(week, []),
      days: new FormControl(days, []),
      month: new FormControl(month, []),
      month2: new FormControl(month, []),
      assignedUserGroupIds: new FormControl(assignedUserGroupIds, [Validators.required]),
    });

    const dialogRef: MatDialogRef<PhoenixTaskConfigDialogAnnuallyComponent> = this.dialog.open(PhoenixTaskConfigDialogAnnuallyComponent, {
      width: '950px',
      autoFocus: false,
      data: <PhoenixTaskConfigDialog>{
        title: 'TASKCONFIG.ANNUALLY.TITLE',
        subtitle: 'TASKCONFIG.ANNUALLY.SUBTITLE',
        buttons: [
          new PhoenixDialogButton({
            label: 'TASKCONFIG.CANCEL',
            click: (): void => dialogRef.close(),
          }),
          new PhoenixDialogButton({
            label: 'TASKCONFIG.ADD',
            click: (): void => dialogRef.close(this.handleDialogResult(id, formGroup.value)),
            color: 'accent',
            raised: true,
            disabled: (): boolean => {
              return formGroup.invalid || this.customValidator(formGroup);
            },
          })],
        formGroup: formGroup,
        startDatePicker: startDatePicker,
        usergroups: userGroups
      },
    });
    return dialogRef;
  }

  private customValidator(group: FormGroup): boolean {
    let disabled: boolean = true;
    if (group && group.valid) {
      const weekdays: number = group.get('weekdays').value;
      const week: number = group.get('week').value;
      const month: number = group.get('month').value;
      const month2: number = group.get('month2').value;
      const days: number[] = group.get('days').value;
      if (
        (!_.isNil(weekdays) && !_.isNil(week) && !_.isNil(month) && days.length === 0 && _.isNil(month2))
        || (days.length > 0 && !_.isNil(month2) && _.isNil(weekdays) && _.isNil(week))) {
        disabled = false;
      }
    }
    return disabled;
  }

  private getValuesForFormGroup(taskConfig: TaskConfigDto): { start, end, turnus, weekdays, week, days, month, assignedUserGroupIds } {
    const start: Date = _.get(taskConfig, ['startDate']) ? new Date(taskConfig.startDate) : undefined;
    const end: Date = _.get(taskConfig, ['startDate']) ? addSeconds(taskConfig.startDate, taskConfig.duration) : undefined;
    const turnus: number = _.get(taskConfig, ['repeat', 'value'], 1);
    const weekdays: number = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.weekdays], [])[0];
    const week: number = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.week], [])[0];
    const days: number[] = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.day], []);
    const month: number = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.month], [])[0];
    const assignedUserGroupIds: string[] = _.get(taskConfig, ['assignedUserGroupIds'], []);
    return { start, end, turnus, weekdays, week, days, month, assignedUserGroupIds };
  }

  private handleDialogResult(
    id: string, result: { start: Date, end: Date, turnus: number, week: number, weekdays: number, days: number[], month: number, month2: number, assignedUserGroupIds: string[] }): TaskConfigDto {
    const config: { [key: string]: number[] } = {};
    if (result.weekdays) {
      config[TaskConfigRepeatConfigTypeDto.weekdays] = [result.weekdays];
    }
    if (result.week) {
      config[TaskConfigRepeatConfigTypeDto.week] = [result.week];
    }
    if (result.days && result.days.length > 0) {
      config[TaskConfigRepeatConfigTypeDto.day] = result.days;
    }
    if (result.month) {
      config[TaskConfigRepeatConfigTypeDto.month] = [result.month];
    }
    if (result.month2) {
      config[TaskConfigRepeatConfigTypeDto.month] = [result.month2];
    }
    const repeat: TaskConfigRepeatDto = new TaskConfigRepeatDto({
      config: config,
      type: TaskConfigRepeatTypeDto.annually,
      value: result.turnus,
    });

    return new TaskConfigDto({
      id: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin',
      startDate: result.start,
      repeat: repeat,
      duration: differenceInSeconds(result.end, result.start),
      createdDate: new Date(),
      assignedUserGroupIds: result.assignedUserGroupIds
    });
  }
}
