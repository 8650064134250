import { PhoenixFormElementType } from '../../enums/phoenix-form-element-type.enum';
var PhoenixFormDropdown = /** @class */ (function () {
    function PhoenixFormDropdown(formGroup, formControlName, disabled, placeholder, entries, comparator) {
        if (disabled === void 0) { disabled = false; }
        this.type = PhoenixFormElementType.DropDown;
        this.formGroup = formGroup;
        this.formControlName = formControlName;
        this.disabled = disabled;
        this.placeholder = placeholder;
        this.entries = entries;
        this.comparator = comparator;
    }
    return PhoenixFormDropdown;
}());
export { PhoenixFormDropdown };
