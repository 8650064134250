/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-dynamic-form-control-array.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../phoenix-dialog-form-control/phoenix-dialog-form-control.component.ngfactory";
import * as i4 from "../phoenix-dialog-form-control/phoenix-dialog-form-control.component";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./phoenix-dynamic-form-control-array.component";
var styles_PhoenixDynamicFormControlArrayComponent = [i0.styles];
var RenderType_PhoenixDynamicFormControlArrayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixDynamicFormControlArrayComponent, data: {} });
export { RenderType_PhoenixDynamicFormControlArrayComponent as RenderType_PhoenixDynamicFormControlArrayComponent };
function View_PhoenixDynamicFormControlArrayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "push": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "phoenix-dialog-form-control", [], null, null, null, i3.View_PhoenixDialogFormControlComponent_0, i3.RenderType_PhoenixDialogFormControlComponent)), i1.ɵdid(4, 49152, null, 0, i4.PhoenixDialogFormControlComponent, [], { phoenixFormControl: [0, "phoenixFormControl"], form: [1, "form"], translationParams: [2, "translationParams"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.pushIndex && (_co.pushIndex == _v.context.index))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.form; var currVal_3 = _co.translationParams; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PhoenixDynamicFormControlArrayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mt-12 mb-4"], ["fxLayout", "row"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "noTopMargin": 0 }), i1.ɵdid(3, 737280, null, 0, i5.LayoutDirective, [i6.MediaMonitor, i1.ElementRef, i6.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(4, 1785856, null, 0, i5.LayoutGapDirective, [i6.MediaMonitor, i1.ElementRef, [6, i5.LayoutDirective], i1.NgZone, i7.Directionality, i6.StyleUtils], { gap: [0, "gap"] }, null), i1.ɵdid(5, 737280, null, 0, i5.LayoutAlignDirective, [i6.MediaMonitor, i1.ElementRef, [6, i5.LayoutDirective], i6.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicFormControlArrayComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mt-12 mb-4"; var currVal_1 = _ck(_v, 2, 0, !_co.topMargin); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "row"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "20px"; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.getLayoutAlign(); _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.phoenixFormControl.options["formControls"]; _ck(_v, 7, 0, currVal_5); }, null); }
export function View_PhoenixDynamicFormControlArrayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-dynamic-form-control-array", [], null, null, null, View_PhoenixDynamicFormControlArrayComponent_0, RenderType_PhoenixDynamicFormControlArrayComponent)), i1.ɵdid(1, 114688, null, 0, i8.PhoenixDynamicFormControlArrayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixDynamicFormControlArrayComponentNgFactory = i1.ɵccf("phoenix-dynamic-form-control-array", i8.PhoenixDynamicFormControlArrayComponent, View_PhoenixDynamicFormControlArrayComponent_Host_0, { phoenixFormControl: "phoenixFormControl", form: "form", translationParams: "translationParams" }, {}, []);
export { PhoenixDynamicFormControlArrayComponentNgFactory as PhoenixDynamicFormControlArrayComponentNgFactory };
