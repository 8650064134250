var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixCommonWizardService } from '@phoenix/services/phoenix-wizards/phoenix-common-wizard.service';
import { takeUntil } from 'rxjs/operators';
import { Permission, permissionIds } from '../../guards/permission';
import { navbarGroups } from '../../layout/components/navbar/navbar-groups/navbar-groups.module';
import { NavigationItem } from '../../navigation/classes/navigation-item';
import { NavigationService } from '../../navigation/navigation.service';
import { MainModule } from '../classes/main-module';
var permission = new Permission(permissionIds.TENANTADMINISTRATION.SETUP.read);
var SetupModule = /** @class */ (function (_super) {
    __extends(SetupModule, _super);
    function SetupModule(navigationService, communicationService, phoenixCommonWizardService) {
        var _this = _super.call(this) || this;
        _this.navigationService = navigationService;
        _this.communicationService = communicationService;
        _this.phoenixCommonWizardService = phoenixCommonWizardService;
        _this.translationKey = 'NAV.SETUP';
        _this.navItem = new NavigationItem('wizard', 0, true, _this.translationKey, function () {
            _this.phoenixCommonWizardService.openNewElementWizard();
        }, PhoenixIcon.STAR_RATE);
        _this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function () { return _this.navigationService.refreshNavigation(_this.navItem, navbarGroups.tenantAdministration.id, permission); });
        return _this;
    }
    return SetupModule;
}(MainModule));
export { SetupModule };
