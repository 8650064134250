var PropertyDto = /** @class */ (function () {
    function PropertyDto(propertyDto) {
        this.id = undefined;
        this.name = undefined;
        this.description = undefined;
        this.value = undefined;
        this.type = undefined;
        Object.assign(this, propertyDto);
    }
    return PropertyDto;
}());
export { PropertyDto };
