import { PhoenixSharedService } from '../../../services/phoenix-shared/phoenix-shared.service';
import { PhoenixFormPassword } from './classes/phoenix-form-password';
var PhoenixFormPasswordComponent = /** @class */ (function () {
    function PhoenixFormPasswordComponent(sharedService) {
        this.sharedService = sharedService;
        this.hidePassword = true;
    }
    PhoenixFormPasswordComponent.prototype.generatePassword = function () {
        this.element.formGroup.get(this.element.formControlName).setValue(this.sharedService.generatePassword());
        this.hidePassword = false;
    };
    return PhoenixFormPasswordComponent;
}());
export { PhoenixFormPasswordComponent };
