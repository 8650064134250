/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

import {PhoenixCommunicationSubject} from './phoenix-communication-subject.enum';

@Injectable({
  providedIn: 'root',
})
export class PhoenixCommunicationService {
  //TODO: We need to refactor it so we don't need Datatype 'any'
  private static subjects: Map<PhoenixCommunicationSubject, Subject<any>> = new Map<PhoenixCommunicationSubject, Subject<any>>();

  public getObservable(topic: PhoenixCommunicationSubject): Observable<any> {
    return this.getSubject(topic).asObservable();
  }

  public emit(topic: PhoenixCommunicationSubject, message?: any): void {
    this.getSubject(topic).next(message ? message : undefined);
  }

  private getSubject(topic: PhoenixCommunicationSubject): Subject<any> {
    if (PhoenixCommunicationService.subjects.has(topic)) {
      return PhoenixCommunicationService.subjects.get(topic);
    } else {
      const subject: Subject<any> = new Subject<any>();

      PhoenixCommunicationService.subjects.set(topic, subject);
      return subject;
    }
  }
}
