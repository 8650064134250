import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../shared/services/shared.service';
import { EmailMapperService } from '../mapper/email-mapper.service';
var EmailApiService = /** @class */ (function () {
    /**
     * @param http
     * @param shared
     * @param emailMapperService
     */
    function EmailApiService(http, shared, emailMapperService) {
        this.http = http;
        this.shared = shared;
        this.emailMapperService = emailMapperService;
    }
    /**
     * Sends an email.
     * @param emailDto
     * @returns
     */
    EmailApiService.prototype.sendEmail = function (emailDto) {
        return this.http.post(this.shared.buildApiUrl('emails'), this.emailMapperService.emailDtoToEmail(emailDto));
    };
    return EmailApiService;
}());
export { EmailApiService };
