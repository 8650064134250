var VariableValidator = /** @class */ (function () {
    function VariableValidator() {
    }
    /**
     * Reactive Form validator that checks if parameter with name "property" of object "obj" is truthy.
     * Optionally checks if value of two controls given by name is equal
     * @param obj
     * @param {string} property
     * @param {string} error
     * @param compareControl1
     * @param compareControl2
     * @returns {ValidatorFn}
     */
    VariableValidator.Validator = function (obj, property, error, compareControl1, compareControl2) {
        return function (control) {
            var err = {};
            if (!obj[property]) {
                err[error] = true;
            }
            if (control.parent !== undefined && (compareControl1 && compareControl2)) {
                var value1 = control.parent.get(compareControl1).value;
                var value2 = control.parent.get(compareControl2).value;
                if (value1 !== value2) {
                    err['equal'] = true;
                }
            }
            if (err === {}) {
                return undefined;
            }
            else {
                return err;
            }
        };
    };
    /**
     * Reactive Form validator that checks if user typed only white spaces. In such situation field will have invalid state
     * @param control
     */
    VariableValidator.noWhiteSpaceValidator = function (control) {
        return (control.value || '').trim().length === 0 ? { 'Whitespace': true } : null;
    };
    VariableValidator.numberRangeValidator = function (control) {
        return Number(control.value) < 1 || Number(control.value) > 1000 || !Number.isInteger(Number(control.value)) ? { 'Invalid number': true } : null;
    };
    return VariableValidator;
}());
export { VariableValidator };
