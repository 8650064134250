/**
 * Definition of a dynamic form control for PhoenixDialog
 */
var PhoenixDynamicFormControl = /** @class */ (function () {
    /**
     * Create a new instance of PhoenixDynamicFormControl
     * @param {string} key - set a unique key for your FormControl. It will be used for returning form values.
     * @param {PhoenixDynamicFormControlType} type - type of the FormControl that should be created.
     * @param {string} label - mostly used for the placeholder attribute
     * @param {boolean} required - is a value required
     * @param {Object} options - define some extra settings (e.g. { maxlength: 42 } for TextAreas)
     * @param {T} defaultValue - form control will be set to this value on initialization
     * @param disabled
     * @param validators
     * @param asyncValidators
     */
    function PhoenixDynamicFormControl(key, type, label, required, options, defaultValue, disabled, validators, asyncValidators) {
        this.key = key;
        this.type = type;
        this.label = label;
        this.required = required ? required : false;
        this.options = options ? options : {};
        this.value = defaultValue;
        this.disabled = disabled;
        this.validators = validators;
        this.asyncValidators = asyncValidators;
    }
    return PhoenixDynamicFormControl;
}());
export { PhoenixDynamicFormControl };
