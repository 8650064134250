var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { PhoenixDialogResult } from '../classes/phoenix-dialog-result';
import { PhoenixDynamicFormControlType } from '../enums/phoenix-dynamic-form-control-type.enum';
var PhoenixDialogComponent = /** @class */ (function () {
    function PhoenixDialogComponent(data, dialogRef, cdref) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.cdref = cdref;
        this.color = 'accent';
        this.defaultButtonCaptionMap = new Map();
        this.showSpinner = false;
        this.translationParams = {};
        this.defaultButtonCaptionMap.set('save', 'DIALOG.BUTTONS.SAVE');
        this.defaultButtonCaptionMap.set('cancel', 'DIALOG.BUTTONS.CANCEL');
        this.defaultButtonCaptionMap.set('ok', 'DIALOG.BUTTONS.OK');
        this.defaultButtonCaptionMap.set('previous', 'DIALOG.BUTTONS.PREVIOUS');
        this.defaultButtonCaptionMap.set('delete', 'DIALOG.BUTTONS.DELETE');
        this.defaultButtonCaptionMap.set('exit', 'DIALOG.BUTTONS.EXIT');
    }
    PhoenixDialogComponent.prototype.ngAfterContentChecked = function () {
        this.cdref.detectChanges();
    };
    PhoenixDialogComponent.prototype.closeDialog = function (result) {
        return __awaiter(this, void 0, void 0, function () {
            var dialogResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showSpinner = true;
                        if (!this.data.onClose) return [3 /*break*/, 2];
                        _.forEach(this.data.formControls, function (formControls) {
                            _.forEach(formControls, function (formControl) {
                                if (formControl.type === PhoenixDynamicFormControlType.Button) {
                                    formControl.disabled = true;
                                }
                            });
                        });
                        dialogResult = new PhoenixDialogResult();
                        dialogResult.result = result;
                        dialogResult.dialogRef = this.dialogRef;
                        dialogResult.formData = this.getFormData();
                        dialogResult.spinner = this.showSpinner;
                        return [4 /*yield*/, this.data.onClose(dialogResult)];
                    case 1:
                        _a.sent();
                        this.showSpinner = dialogResult.spinner;
                        return [3 /*break*/, 3];
                    case 2:
                        this.dialogRef.close({
                            result: result,
                            formData: this.getFormData(),
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDialogComponent.prototype.formSubmit = function () {
        if (this.form.valid) {
            this.closeDialog('submit');
        }
    };
    PhoenixDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data.translationParams) {
            this.translationParams = this.data.translationParams;
        }
        if (this.data.formControls) {
            this.form = this.convertToFormGroup(this.data.formControls);
        }
        _.forEach(this.data.buttons, function (button, key) {
            if (_.isBoolean(button) && button) {
                _this.data.buttons[key] = _this.defaultButtonCaptionMap.get(key);
            }
            else if (typeof button === 'object') {
                _this.data.buttons[key] = button;
            }
        });
        if (this.data.color) {
            this.color = this.data.color;
        }
        if (this.data.onInit) {
            this.data.onInit(this.form);
        }
    };
    PhoenixDialogComponent.prototype.convertFormControlToFormGroup = function (formControls, formGroup) {
        var _this = this;
        _.forEach(formControls, function (formControl) {
            if (formControl.required && formControl.type === PhoenixDynamicFormControlType.Switch) {
                formGroup[formControl.key] = new FormControl(formControl.value, Validators.requiredTrue);
            }
            else if (formControl.required) {
                formGroup[formControl.key] = new FormControl(formControl.value, Validators.required);
            }
            else {
                formGroup[formControl.key] = new FormControl(formControl.value);
            }
            if (_.hasIn(formControl, 'options.tabs')) {
                _.forEach(formControl.options['tabs'], function (tab) {
                    formGroup = _.merge(formGroup, _this.convertFormControlToFormGroup(tab['formControls'], formGroup));
                });
            }
            if (formControl.disabled) {
                formGroup[formControl.key].disable();
            }
        });
        formGroup['buttonClicked'] = new FormControl();
        return formGroup;
    };
    PhoenixDialogComponent.prototype.convertToFormGroup = function (formControls) {
        var _this = this;
        var formGroup = {};
        _.forEach(formControls, function (column) {
            formGroup = _this.convertFormControlToFormGroup(column, formGroup);
        });
        return new FormGroup(formGroup);
    };
    PhoenixDialogComponent.prototype.getFormData = function () {
        var _this = this;
        var data = {};
        if (this.data.formControls && this.form) {
            _.forEach(this.data.formControls, function (column) {
                data = _this.getFormDateFromFromControl(data, column);
            });
            data['buttonClicked'] = this.form.get('buttonClicked');
        }
        return data;
    };
    PhoenixDialogComponent.prototype.getFormDateFromFromControl = function (data, formControlData) {
        var _this = this;
        _.forEach(formControlData, function (formControl) {
            if (_.hasIn(formControl, 'options.tabs')) {
                _.forEach(formControl.options['tabs'], function (tab) {
                    data = _.merge(data, _this.getFormDateFromFromControl(data, tab['formControls']));
                });
            }
            data[formControl.key] = _this.form.get(formControl.key) ? _this.form.get(formControl.key).value : undefined;
        });
        return data;
    };
    return PhoenixDialogComponent;
}());
export { PhoenixDialogComponent };
