var AlarmCountDto = /** @class */ (function () {
    function AlarmCountDto(alarmCountDto) {
        this.monthYear = undefined;
        this.activeInactiveCount = undefined;
        this.closedCount = undefined;
        Object.assign(this, alarmCountDto);
    }
    return AlarmCountDto;
}());
export { AlarmCountDto };
