var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseEntity } from '../../shared/classes/base-entity';
var Threshold = /** @class */ (function (_super) {
    __extends(Threshold, _super);
    function Threshold(threshold) {
        var _this = _super.call(this, threshold) || this;
        _this.valueMin = undefined;
        _this.valueMax = undefined;
        _this.alarmMin = undefined;
        _this.alarmMax = undefined;
        _this.assetsCount = undefined;
        _this.alarmConfig = 'FIRST';
        Object.assign(_this, threshold);
        return _this;
    }
    Threshold.prototype.toJSON = function () {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            valueMin: this.valueMin,
            valueMax: this.valueMax,
            alarmMin: this.alarmMin,
            alarmMax: this.alarmMax,
            assetsCount: this.assetsCount,
            properties: this.properties,
            tags: this.tags,
            tenantId: this.tenantId,
            alarmConfig: this.alarmConfig
        };
    };
    return Threshold;
}(BaseEntity));
export { Threshold };
