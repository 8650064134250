import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Role } from '../api/role';
import { RoleSearchCriteria } from '../api/role-search-criteria';
import { RoleDto } from '../dto/role-dto';
import * as i0 from "@angular/core";
var RoleMapperService = /** @class */ (function () {
    function RoleMapperService() {
    }
    RoleMapperService.prototype.roleSearchCriteriaDtoToRoleSearchCriteria = function (roleSearchCriteriaDto) {
        return plainToClass(RoleSearchCriteria, roleSearchCriteriaDto);
    };
    RoleMapperService.prototype.roleToRoleDto = function (role) {
        return plainToClass(RoleDto, role);
    };
    RoleMapperService.prototype.roleDtoToRole = function (roleDto) {
        return plainToClass(Role, roleDto);
    };
    RoleMapperService.prototype.roleArrayToRoleDtoArray = function (roles) {
        var _this = this;
        return _.map(roles, function (role) { return _this.roleToRoleDto(role); });
    };
    RoleMapperService.prototype.roleDtoArrayToRoleArray = function (roleDtos) {
        var _this = this;
        return _.map(roleDtos, function (roleDto) { return _this.roleDtoToRole(roleDto); });
    };
    RoleMapperService.ngInjectableDef = i0.defineInjectable({ factory: function RoleMapperService_Factory() { return new RoleMapperService(); }, token: RoleMapperService, providedIn: "root" });
    return RoleMapperService;
}());
export { RoleMapperService };
