var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var RoleSearchCriteria = /** @class */ (function (_super) {
    __extends(RoleSearchCriteria, _super);
    function RoleSearchCriteria(roleSearchCriteria) {
        var _this = _super.call(this, roleSearchCriteria) || this;
        _this.name = undefined;
        Object.assign(_this, roleSearchCriteria);
        return _this;
    }
    RoleSearchCriteria.prototype.toJSON = function () {
        return {
            name: this.name,
            page: this.page,
            size: this.size,
        };
    };
    return RoleSearchCriteria;
}(SearchCriteriaPageable));
export { RoleSearchCriteria };
