import { Routes } from '@angular/router';
var routes = [
    {
        path: 'login',
        loadChildren: './login-content/login-content.module#LoginContentModule',
    },
];
var LoginModule = /** @class */ (function () {
    function LoginModule() {
    }
    return LoginModule;
}());
export { LoginModule };
