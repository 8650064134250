import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export class VariableValidator {
  /**
   * Reactive Form validator that checks if parameter with name "property" of object "obj" is truthy.
   * Optionally checks if value of two controls given by name is equal
   * @param obj
   * @param {string} property
   * @param {string} error
   * @param compareControl1
   * @param compareControl2
   * @returns {ValidatorFn}
   */
  public static Validator(obj: { [key: string]: boolean }, property: string, error: string, compareControl1?: string, compareControl2?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } => {
      const err: { [key: string]: boolean } = {};

      if (!obj[property]) {
        err[error] = true;
      }

      if (control.parent !== undefined && (compareControl1 && compareControl2)) {
        const value1: string = control.parent.get(compareControl1).value;
        const value2: string = control.parent.get(compareControl2).value;

        if (value1 !== value2) {
          err['equal'] = true;
        }
      }

      if (err === {}) {
        return undefined;
      } else {
        return err;
      }
    };
  }

  /**
   * Reactive Form validator that checks if user typed only white spaces. In such situation field will have invalid state
   * @param control
   */
  public static noWhiteSpaceValidator(control: FormControl): { [s: string]: boolean } {
    return (control.value || '').trim().length === 0 ? { 'Whitespace': true } : null;
  }

  public static numberRangeValidator(control: FormControl): { [s: string]: boolean } {
    return Number(control.value) < 1 || Number(control.value) > 1000 || !Number.isInteger(Number(control.value)) ? { 'Invalid number': true } : null;
  }

}
