import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicTabswitcherComponent = /** @class */ (function () {
    function PhoenixDynamicTabswitcherComponent() {
    }
    Object.defineProperty(PhoenixDynamicTabswitcherComponent.prototype, "selectedIndex", {
        get: function () {
            return this._selectedIndex;
        },
        set: function (value) {
            this._selectedIndex = value;
            this.updateHiddenInputValue();
        },
        enumerable: true,
        configurable: true
    });
    PhoenixDynamicTabswitcherComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.phoenixFormControl.options['tabs']) {
            this.tabs = this.phoenixFormControl.options['tabs'];
            this.selectedIndex = 0;
            _.forEach(this.tabs, function (tab, index) {
                if (tab.key === _this.phoenixFormControl.value) {
                    _this.selectedIndex = index;
                }
            });
        }
    };
    PhoenixDynamicTabswitcherComponent.prototype.updateHiddenInputValue = function () {
        this.form.get(this.phoenixFormControl.key).setValue(this.tabs[this.selectedIndex].key);
    };
    return PhoenixDynamicTabswitcherComponent;
}());
export { PhoenixDynamicTabswitcherComponent };
