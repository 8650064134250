import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import _ from 'lodash';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-form-control-array',
  templateUrl: './phoenix-dynamic-form-control-array.component.html',
  styleUrls: ['./phoenix-dynamic-form-control-array.component.scss'],
})
export class PhoenixDynamicFormControlArrayComponent implements OnInit{
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;

  @Input()
  public form: FormGroup;

  @Input()
  public translationParams: Object;

  public pushIndex: number = undefined;
  public topMargin = false;

  public ngOnInit(): void {
    if (this.phoenixFormControl
    && _.has(this.phoenixFormControl.options, 'push') && _.isNumber(this.phoenixFormControl.options['push'])
    && _.has(this.phoenixFormControl.options, 'formControls')) {
      let push = this.phoenixFormControl.options["push"];
      let items = this.phoenixFormControl.options["formControls"];
      if (push > 0 && push < items.length) {
        this.pushIndex = push;
      }
    }
    if (this.phoenixFormControl
    && _.has(this.phoenixFormControl.options, 'topMargin') && _.isBoolean(this.phoenixFormControl.options['topMargin'])) {
      this.topMargin = this.phoenixFormControl.options['topMargin'];
    }
  }

  public getLayoutAlign(): string {
    if (this.phoenixFormControl
    && _.has(this.phoenixFormControl.options, 'layoutAlign') && _.isString(this.phoenixFormControl.options['layoutAlign'])) {
      return this.phoenixFormControl.options['layoutAlign'];
    } else {
      return 'center center'
    }
  }
}
