import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { map, reduce } from 'rxjs/operators';
import { LatestMeasurementFilterDto, MeasurementSearchCriteriaDto } from '../../dto.module';
import { FileMapperService } from '../../file/mapper/file-mapper.service';
import { FileApiService } from '../../file/services/file-api.service';
import { SharedService } from '../../shared/services/shared.service';
import { MeasurementMapperService } from '../mapper/measurement-mapper.service';
import { MeasurementAggregateDto } from '../dto/measurement-aggregate-dto';
var MeasurementApiService = /** @class */ (function () {
    function MeasurementApiService(http, shared, fileApi, measurementMapper, fileMapper) {
        this.http = http;
        this.shared = shared;
        this.fileApi = fileApi;
        this.measurementMapper = measurementMapper;
        this.fileMapper = fileMapper;
        this._files = 'files';
        this._latest = 'latest';
        this._measurement = 'measurement';
        this._measurements = 'measurements';
        this._pages = 'pages';
        this._aggregateMeasurements = 'measurements/aggregate';
    }
    /**
     * Creates a measurement by given measurementDto.
     * @param measurementDto
     * @returns
     */
    MeasurementApiService.prototype.createMeasurement = function (measurementDto) {
        var _this = this;
        return this.http.post(this.shared.buildApiUrl(this._measurements, this._measurement), this.measurementMapper.measurementDtoToMeasurement(measurementDto)).pipe(map(function (data) { return _this.measurementMapper.measurementToMeasurementDto(data.measurement); }));
    };
    /**
     * Deletes a file from a measurement by given filename.
     * @param measurementDto
     * @param filename
     * @returns
     */
    MeasurementApiService.prototype.deleteFileFromMeasurement = function (measurementDto, filename) {
        return this.http.delete(this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id, this._files, filename));
    };
    /**
     * Deletes a measurement by given measurementDto.
     * @param measurementDto
     * @returns
     */
    MeasurementApiService.prototype.deleteMeasurement = function (measurementDto) {
        return this.http.delete(this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id));
    };
    /**
     * Deletes a measurement by given id.
     * @param id
     * @returns
     */
    MeasurementApiService.prototype.deleteMeasurementById = function (id) {
        return this.http.delete(this.shared.buildApiUrl(this._measurements, this._measurement, id.toString()));
    };
    /**
     * Gets a file from a measurement by given measurementDto and filename.
     * @param measurementDto
     * @param filename
     * @returns
     */
    MeasurementApiService.prototype.getFileFromMeasurement = function (measurementDto, filename) {
        var _this = this;
        return this.http.get(this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id, this._files, filename)).pipe(map(function (fileItem) { return _this.fileMapper.fileItemToFileItemDto(fileItem.fileItem); }));
    };
    /**
     * Gets the latest measurements of defined measurementpoints
     * @param latestMeasurementFilterDto
     * @param withFurtherPages
     * @returns Observable<MeasurementDto[]>
     */
    MeasurementApiService.prototype.getLatestMeasurement = function (latestMeasurementFilterDto, withFurtherPages) {
        var _this = this;
        if (latestMeasurementFilterDto === void 0) { latestMeasurementFilterDto = new LatestMeasurementFilterDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        var url = this.shared.buildApiUrl(this._measurements, this._latest);
        return this.shared.httpGetWithPagination(url, this.measurementMapper.latestMeasurementFilterDtoToLatestMeasurementFilter(latestMeasurementFilterDto), withFurtherPages).pipe(map(function (measurements) { return _this.measurementMapper.measurementArrayToMeasurementDtoArray(measurements); }));
    };
    /**
     * Gets a measurement by given measurementDto.
     * @param measurementDto
     * @returns
     */
    MeasurementApiService.prototype.getMeasurement = function (measurementDto) {
        var _this = this;
        return this.http.get(this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id.toString())).pipe(map(function (response) { return _this.measurementMapper.measurementToMeasurementDto(response.measurement); }));
    };
    /**
     * Gets a measurement by given id.
     * @param measurementId
     * @returns
     */
    MeasurementApiService.prototype.getMeasurementById = function (measurementId) {
        var _this = this;
        return this.http.get(this.shared.buildApiUrl(this._measurements, this._measurement, measurementId.toString())).pipe(map(function (response) { return _this.measurementMapper.measurementToMeasurementDto(response.measurement); }));
    };
    /**
     * Gets all measurements matching the search criteria.
     * @param measurementSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    MeasurementApiService.prototype.getMeasurements = function (measurementSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (measurementSearchCriteriaDto === void 0) { measurementSearchCriteriaDto = new MeasurementSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.shared.httpGetWithPagination(this.shared.buildApiUrl(this._measurements), this.measurementMapper.measurementSearchCriteriaDtoToMeasurementSearchCriteria(measurementSearchCriteriaDto), withFurtherPages, function (partial) {
            return _.hasIn(partial, '_embedded.measurementResourceList') ? partial._embedded.measurementResourceList.length : 0;
        }).pipe(map(function (measurements) { return _this.convertResponseToMeasurementDtoArray(measurements); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
    * Gets all measurements matching the search criteria.
    * @param measurementSearchCriteriaDto
    * @param withFurtherPages
    * @returns
    */
    MeasurementApiService.prototype.getAggregateMeasurements = function (measurementAggregateDto, withFurtherPages) {
        var _this = this;
        if (measurementAggregateDto === void 0) { measurementAggregateDto = new MeasurementAggregateDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.shared.httpGetWithPagination(this.shared.buildApiUrl(this._measurements), this.measurementMapper.measurementAggregateDtoToMeasurementSearchCriteria(measurementAggregateDto), withFurtherPages, function (partial) {
            return _.hasIn(partial, '_embedded.measurementResourceList') ? partial._embedded.measurementResourceList.length : 0;
        }).pipe(map(function (measurements) { return _this.convertResponseToMeasurementDtoArray(measurements); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Updates/Replaces a file of a measurement
     * @param measurement
     * @param fileItemDto
     * @param file
     * @returns
     */
    MeasurementApiService.prototype.updateFileOfMeasurement = function (measurement, fileItemDto, file) {
        return this.fileApi.upload(fileItemDto, file, 'PUT', this.shared.buildApiUrl(this._measurements, this._measurement, measurement.id, this._files, this.fileMapper.fileItemDtoToFileItem(fileItemDto).name));
    };
    /**
     * Uploads a file to measurement
     * @param measurement
     * @param fileItemDto
     * @param file
     * @returns
     */
    MeasurementApiService.prototype.uploadFileToMeasurement = function (measurement, fileItemDto, file) {
        return this.fileApi.upload(fileItemDto, file, 'POST', this.shared.buildApiUrl(this._measurements, this._measurement, measurement.id, this._files, fileItemDto.name));
    };
    MeasurementApiService.prototype.convertResponseToMeasurementDtoArray = function (response) {
        var _this = this;
        if (response._embedded) {
            return _.map(response._embedded.measurementResourceList, function (measurement) { return _this.measurementMapper.measurementToMeasurementDto(measurement.measurement); });
        }
        else {
            return [];
        }
    };
    /**
   * Aggregate measurements matching the search criteria.
   * @param measurementSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
    MeasurementApiService.prototype.aggregateMeasurements = function (
    //measurementSearchCriteriaDto: MeasurementSearchCriteriaDto = new MeasurementSearchCriteriaDto(),
    measurementAggregateDto, withFurtherPages) {
        var _this = this;
        //measurementSearchCriteriaDto: MeasurementSearchCriteriaDto = new MeasurementSearchCriteriaDto(),
        if (measurementAggregateDto === void 0) { measurementAggregateDto = new MeasurementAggregateDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.shared.httpGetWithPagination(this.shared.buildApiUrl(this._aggregateMeasurements), this.measurementMapper.measurementAggregateDtoToMeasurementSearchCriteria(measurementAggregateDto), withFurtherPages, function (partial) {
            return _.hasIn(partial, '_embedded.measurementResourceList') ? partial._embedded.measurementResourceList.length : 0;
        }).pipe(map(function (measurements) { return _this.convertResponseToMeasurementDtoArray(measurements); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
    * Calculate total pages.
    * @returns
    */
    MeasurementApiService.prototype.calcualetTotalPages = function () {
        return this.http.get(this.shared.buildApiUrl(this._measurements, this._pages)).pipe();
    };
    return MeasurementApiService;
}());
export { MeasurementApiService };
