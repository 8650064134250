var TenantRequest = /** @class */ (function () {
    function TenantRequest(tenantRequest) {
        this.name = undefined;
        this.email = undefined;
        this.description = undefined;
        this.roles = undefined;
        this.providers = undefined;
        Object.assign(this, tenantRequest);
    }
    TenantRequest.prototype.toJSON = function () {
        return {
            name: this.name,
            email: this.email,
            description: this.description,
            roles: this.roles,
            providers: this.providers,
        };
    };
    return TenantRequest;
}());
export { TenantRequest };
