import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'phoenix-form',
  templateUrl: './phoenix-form.component.html',
  styleUrls: ['./phoenix-form.component.scss'],
})
export class PhoenixFormComponent {
  @Input() public formGroup: FormGroup;
}
