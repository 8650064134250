var EmailRequest = /** @class */ (function () {
    function EmailRequest(emailRequest) {
        this.email = undefined;
        Object.assign(this, emailRequest);
    }
    EmailRequest.prototype.toJSON = function () {
        return {
            email: this.email,
        };
    };
    return EmailRequest;
}());
export { EmailRequest };
