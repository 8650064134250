import { OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';
import { filter, map } from 'rxjs/operators';
import { NavigationService } from '../../../app/navigation/navigation.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { ThresholdApiService } from '../../gapicon/threshold/services/threshold-api.service';
import { UserApiService } from '../../gapicon/user/services/user-api.service';
var PhoenixBreadcrumbsComponent = /** @class */ (function () {
    function PhoenixBreadcrumbsComponent(router, navigationService, assetApiService, thresholdApiService, userApiService) {
        var _this = this;
        this.router = router;
        this.navigationService = navigationService;
        this.assetApiService = assetApiService;
        this.thresholdApiService = thresholdApiService;
        this.userApiService = userApiService;
        this.breadcrumbs = [];
        this.subscription = this.router.events.pipe(filter(function (event) {
            return event instanceof NavigationEnd;
        }), map(function (event) { return _this.buildBreadCrumb(event); })).subscribe(function (result) { return _this.breadcrumbs = result; });
    }
    PhoenixBreadcrumbsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    PhoenixBreadcrumbsComponent.prototype.buildBreadCrumb = function (route) {
        var _this = this;
        var breadcrumbs = [];
        var urlParts = route.url.toString().split('/');
        _.forEach(urlParts, function (urlPart) {
            if (urlPart === '') {
                breadcrumbs.push({
                    icon: PhoenixIcon.HOME,
                    label: Observable.of(''),
                    url: '',
                    idResolver: function (id) { return Observable.of('Dashboard'); },
                });
            }
            else {
                var navigationItem = _this.navigationService.getNavigationElementByPath('/' + urlPart);
                var fallbackLabel = _this.getPathPartLabel(urlPart);
                //debugger;
                if (navigationItem) {
                    breadcrumbs.push({
                        label: Observable.of(navigationItem.translate),
                        url: _.last(breadcrumbs).url + '/' + urlPart,
                        idResolver: _this.getIdResolver(navigationItem.translate),
                    });
                }
                else if (!navigationItem && fallbackLabel) {
                    breadcrumbs.push({
                        label: Observable.of(undefined),
                        url: _.last(breadcrumbs).url + '/' + urlPart,
                        idResolver: _this.getIdResolver(fallbackLabel),
                    });
                }
                else if (!navigationItem && !fallbackLabel) {
                    breadcrumbs.push({
                        label: _.last(breadcrumbs).idResolver(_this, urlPart),
                        url: _.last(breadcrumbs).url + '/' + urlPart,
                        idResolver: _.last(breadcrumbs).idResolver,
                    });
                }
            }
        });
        return breadcrumbs;
    };
    PhoenixBreadcrumbsComponent.prototype.getIdResolver = function (type) {
        switch (type) {
            case 'NAV.CHECKPOINTS':
                return this.assetIdResolver;
            case 'NAV.NOTIFICATIONS':
                return this.notificationIdResolver;
            case 'NAV.ASSETS':
                return this.assetIdResolver;
            case 'NAV.THRESHOLDS':
                return this.thresholdIdResolver;
            case 'NAV.USER':
                return this.userIdResolver;
            default:
                return function (context, id) { return Observable.of('Unknown type ' + type + ' for id ' + id); };
        }
    };
    PhoenixBreadcrumbsComponent.prototype.getPathPartLabel = function (pathPart) {
        switch (pathPart) {
            case 'asset':
                return 'NAV.ASSETS';
            case 'checkpoint':
                return 'NAV.CHECKPOINTS';
            default:
                return undefined;
        }
    };
    PhoenixBreadcrumbsComponent.prototype.assetIdResolver = function (context, id) {
        return context.assetApiService.getAssetById(id).pipe(map(function (assetDto) { return assetDto ? assetDto.name : ""; }));
    };
    PhoenixBreadcrumbsComponent.prototype.thresholdIdResolver = function (context, id) {
        return context.thresholdApiService.getThresholdById(id).pipe(map(function (threshold) { return threshold.name; }));
    };
    PhoenixBreadcrumbsComponent.prototype.userIdResolver = function (context, id) {
        return context.userApiService.getUserById(id).pipe(map(function (userDto) { return userDto.firstName + ' ' + userDto.lastName + ' (' + userDto.email + ')'; }));
    };
    // TODO: Korrekte Anzeige mit i18n sobald Meldungen implementiert sind
    PhoenixBreadcrumbsComponent.prototype.notificationIdResolver = function (context, id) {
        return Observable.of('Meldung #' + id);
    };
    return PhoenixBreadcrumbsComponent;
}());
export { PhoenixBreadcrumbsComponent };
