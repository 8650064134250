import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule, MatDialogModule, MatListModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixDialogNewModule} from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';

import {PhoenixTenantSelectorDialogComponent} from './phoenix-tenant-selector-dialog.component';
import {PhoenixTenantSelectorDialogService} from './phoenix-tenant-selector-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,

    MatListModule,
    MatDialogModule,
    MatButtonModule,

    PhoenixDialogNewModule,
  ],
  declarations: [
    PhoenixTenantSelectorDialogComponent,
  ],
  entryComponents: [
    PhoenixTenantSelectorDialogComponent,
  ],
  providers: [
    PhoenixTenantSelectorDialogService,
  ],
})
export class PhoenixTenantselectorDialogModule {
}
