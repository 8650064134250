import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {map, reduce} from 'rxjs/operators';
import {Group} from '../../group/api/group';

import {SharedService} from '../../shared/services/shared.service';
import {User} from '../api/user';
import {UserDto} from '../dto/user-dto';
import {UserSearchCriteriaDto} from '../dto/user-search-criteria-dto';
import {UserMapperService} from '../mapper/user-mapper.service';


@Injectable()
export class UserApiService {

  private _users: string = 'users';
  private _user: string = 'user';

  /**
   * @param http
   * @param sharedService
   * @param userMapperService
   */
  public constructor(private http: HttpClient,
                     private sharedService: SharedService,
                     private userMapperService: UserMapperService,
  ) {
  }

  /**
   * Gets all users matching the search criteria.
   * @param userSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getUsers(userSearchCriteriaDto: UserSearchCriteriaDto = new UserSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<UserDto[]> {
    return this.sharedService.httpGetWithPagination<User>(
      this.sharedService.buildApiUrl(this._users),
      this.userMapperService.userSearchCriteriaDtoToUserSearchCriteria(userSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((users: User[]) => this.userMapperService.userArrayToUserDtoArray(users)),
      reduce((all: UserDto[], current: UserDto[]) => all.concat(current)),
    );
  }

  /**
   * Get users in raw format from API User[](not UserDto[])
   */
  public getAssignedUsers() : Observable<User[]> {
    return this.http.get<User[]>(this.sharedService.buildApiUrl(this._users))
  }


  /**
   * Creates a user by given userDto.
   * @returns
   * @param userDto
   */
  public createUser(userDto: UserDto): Observable<UserDto> {
    return this.http.post<User>(
      this.sharedService.buildApiUrl(this._users, this._user),
      this.userMapperService.userDtoToUser(userDto),
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }

  /**
   * Deletes a user by given userDto.
   * @returns
   * @param userDto
   */
  public deleteUser(userDto: UserDto): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(
        this._users,
        this._user,
        this.userMapperService.userDtoToUser(userDto).id.toString()),
    );
  }

  /**
   * Deletes a user by given id.
   * @param id
   * @returns
   */
  public deleteUserById(id: number): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._users, this._user, id.toString()),
    );
  }

  /**
   * Gets a user by given id.
   * @param id
   * @returns
   */
  public getUserById(id: string): Observable<UserDto> {
    return this.http.get<User>(
      this.sharedService.buildApiUrl(this._users, this._user, id),
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }

  /**
   * Gets a user by given userDto.
   * @returns
   * @param userDto
   */
  public getUser(userDto: UserDto): Observable<UserDto> {
    return this.http.get<User>(
      this.sharedService.buildApiUrl(this._users, this._user, this.userMapperService.userDtoToUser(userDto).id),
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }

  /**
   * Updates a user by given userDto.
   * @returns
   * @param userDto
   */
  public updateUser(userDto: UserDto): Observable<UserDto> {
    return this.http.put<User>(
      this.sharedService.buildApiUrl(this._users, this._user, this.userMapperService.userDtoToUser(userDto).id),
      this.userMapperService.userDtoToUser(userDto),
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }
}
