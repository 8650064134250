import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomEncoderForHttpParameter } from '@phoenix/classes/custom-encoder-for-http-parameter';
import { Observable } from 'rxjs/Observable';
import { SharedService } from '../../shared/services/shared.service';
import { ReportSearchCriteria } from '../api/report-search-criteria';
import { ReportDataDto } from '../dto/report-data-dto';


@Injectable()
export class ReportApiService {

  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
  ) {
  }

  /**
   * Gets the hierarchy from an asset up to a root asset
   * @param asset
   */
  public getReportData(reportSearchCriteria: ReportSearchCriteria): Observable<ReportDataDto> {
    const options: { params: HttpParams } = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
    options.params = reportSearchCriteria.getRequestQueryParams();
    return this.http.get<ReportDataDto>(
      this.sharedService.buildApiUrl('report', 'data'), options
    );
  }




}
