var SensorConfigValue = /** @class */ (function () {
    function SensorConfigValue(sensor) {
        this.defaultValue = undefined;
        this.key = undefined;
        this.name = undefined;
        this.type = undefined;
        this.value = undefined;
        this.unit = undefined;
        Object.assign(this, sensor);
    }
    SensorConfigValue.prototype.toJSON = function () {
        return {
            defaultValue: this.defaultValue,
            key: this.key,
            name: this.name,
            type: this.type,
            value: this.value,
            unit: this.unit,
        };
    };
    return SensorConfigValue;
}());
export { SensorConfigValue };
