import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule, MatInputModule, MatListModule, MatOptionModule, MatSelectModule, MatSlideToggleModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixDialogNewModule} from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';
import {PhoenixFormModule} from '../../../components/phoenix-form/phoenix-form.module';
import {SensorApiModule} from '../../../gapicon/sensor/sensor-api.module';

import {PhoenixSensormanagementDeleteDialogService} from './phoenix-sensormanagement-delete-dialog.service';
import {PhoenixSensormanagementDialogComponent} from './phoenix-sensormanagement-dialog.component';
import {PhoenixSensormanagementDialogService} from './phoenix-sensormanagement-dialog.service';
import {PhoenixSensormanagementInformationDialogComponent} from './phoenix-sensormanagement-information-dialog/phoenix-sensormanagement-information-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,

    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatListModule,

    PhoenixDialogNewModule,
    PhoenixFormModule,
    SensorApiModule,
  ],
  declarations: [
    PhoenixSensormanagementDialogComponent,
    PhoenixSensormanagementInformationDialogComponent,
  ],
  entryComponents: [
    PhoenixSensormanagementDialogComponent,
    PhoenixSensormanagementInformationDialogComponent,
  ],
  providers: [
    PhoenixSensormanagementDialogService,
    PhoenixSensormanagementDeleteDialogService,
  ],
})
export class PhoenixSensormanagementDialogModule {
}
