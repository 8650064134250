import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule, MatDialogModule, MatDividerModule, MatGridListModule, MatProgressSpinnerModule} from '@angular/material';
import {ModalGalleryModule} from '@ks89/angular-modal-gallery';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixGalleryComponent} from './phoenix-gallery.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    ModalGalleryModule.forRoot(),
  ],
  declarations: [
    PhoenixGalleryComponent,
  ],
  entryComponents: [
    PhoenixGalleryComponent,
  ],
})
export class PhoenixGalleryModule {
}
