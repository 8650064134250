import { Group } from '../api/group';
import { UserGroupDto } from '../dto/user-group-dto';
import { UserGroupType } from '../enums/usergroup-status.enum';
import * as i0 from "@angular/core";
var UserGroupMapperService = /** @class */ (function () {
    function UserGroupMapperService() {
    }
    UserGroupMapperService.prototype.groupsToGroupsDto = function (groups, allGroupsVisibility) {
        var _this = this;
        if (allGroupsVisibility === void 0) { allGroupsVisibility = true; }
        var groupsDto = groups.map(function (group) {
            return _this.groupToGroupDto(group);
        });
        if (!allGroupsVisibility) {
            groupsDto = groupsDto.filter(function (groupDto) { return groupDto.type !== UserGroupType.RESTRICTED; });
        }
        return groupsDto;
    };
    UserGroupMapperService.prototype.groupsDtoToGroups = function (groups) {
        var _this = this;
        return groups.map(function (group) {
            return _this.groupDtoToGroup(group);
        });
    };
    UserGroupMapperService.prototype.checkGroupType = function (type) {
        switch (type) {
            case 'NORMAL':
                return UserGroupType.NORMAL;
            case 'ALL_USERS':
                return UserGroupType.ALL_USERS;
            default:
                return UserGroupType.RESTRICTED;
        }
    };
    UserGroupMapperService.prototype.groupDtoToGroup = function (group) {
        return new Group({
            id: group.id,
            name: group.name,
            userIds: group.assignedUsersIds,
            role: group.role,
            type: group.type = this.checkGroupType(group.type),
            notification: group.notification,
        });
    };
    UserGroupMapperService.prototype.groupToGroupDto = function (group) {
        return new UserGroupDto({
            id: group.id,
            name: group.name,
            assignedUsersIds: group.userIds,
            assignedUsers: group.userIds,
            role: group.role,
            type: group.type = this.checkGroupType(group.type),
            notification: group.notification,
        });
    };
    UserGroupMapperService.ngInjectableDef = i0.defineInjectable({ factory: function UserGroupMapperService_Factory() { return new UserGroupMapperService(); }, token: UserGroupMapperService, providedIn: "root" });
    return UserGroupMapperService;
}());
export { UserGroupMapperService };
