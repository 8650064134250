import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { SharedService } from '../../shared/services/shared.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { AccountMapperService } from '../mapper/account-mapper.service';
var ACCOUNTS = 'accounts';
var REGISTRATIONS = 'registrations';
var CONFIRMATIONS = 'confirmations';
var PASSWORDRESETREQUESTS = 'passwordResetRequests';
var PASSWORDRESETS = 'passwordResets';
var INFO = 'info';
var PASSWORD = 'passwords';
/**
 * ## Account API
 * Methods to access CHECK CLOUD account api to create and manage user accounts.
 *
 * @usage
 * ```javascript
 *
 * import {AccountApiService} from 'gapicon/account-api/account-api.service';
 * import {PasswordResetRequestDto} from 'gapicon/dto/password-reset-request-dto';
 *
 * @Component({
 *   ...
 * })
 * export class YourComponent implements OnInit {
 *   constructor(private accountApi: AccountApiService) {}
 *   ngOnInit() {
 *     const passwordResetRequest = new PasswordResetRequestDto();
 *     passwordResetRequest.email = 'test@example.org';
 *
 *     this.accountApi.requestPasswordReset(passwordResetRequest)
 *       .subscribe(() => {
 *         alert('password reset successful');
 *       });
 *   }
 * }
 * ```
 */
var AccountApiService = /** @class */ (function () {
    /**
     *
     * @param http
     * @param shared
     * @param accountMapperService
     * @param userMapper
     */
    function AccountApiService(http, shared, accountMapperService, userMapper) {
        this.http = http;
        this.shared = shared;
        this.accountMapperService = accountMapperService;
        this.userMapper = userMapper;
    }
    /**
     * Start a new user registration. User will get an email to complete registration.
     * @example
     * ```javascript
     *
     * const registrationRequestDto = new RegistrationRequestDto();
     * registrationRequestDto.email = 'test@example.org';
     * registrationRequestDto.roles = ['user'];
     *
     * accountApi.createUserRegistration(registrationRequestDto)
     *  .subscribe((response: RegistrationResponseDto) => {
     *    console.log('User Registration Response:', response);
     *  });
     *  ```
     * @param registrationRequestDto
     * @returns
     */
    AccountApiService.prototype.createUserRegistration = function (registrationRequestDto) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.post(_this.shared.buildApiUrl(ACCOUNTS, REGISTRATIONS), _this.accountMapperService.registrationRequestDtoToRegistrationRequest(registrationRequestDto)).subscribe(function (registrationResponse) {
                observer.next(_this.accountMapperService.registratrionResponseToRegistratrionResponseDto(registrationResponse));
                observer.complete();
            });
        });
    };
    /**
     * Confirm email address for an open user registration and send required user data to the backend.
     * @param registrationConfirmationDto
     * @returns
     */
    AccountApiService.prototype.confirmUserRegistration = function (registrationConfirmationDto) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.post(_this.shared.buildApiUrl(ACCOUNTS, CONFIRMATIONS), _this.accountMapperService.registrationConfirmationDtoToRegistrationConfirmation(registrationConfirmationDto)).subscribe(function (registrationResponse) {
                observer.next(_this.accountMapperService.registratrionResponseToRegistratrionResponseDto(registrationResponse));
                observer.complete();
            });
        });
    };
    /**
     * Request a password reset for user self service. The user will receive an email to set his own password.
     * @param passwordResetRequest
     * @returns
     */
    AccountApiService.prototype.requestPasswordReset = function (passwordResetRequest) {
        return this.http.post(this.shared.buildApiUrl(ACCOUNTS, PASSWORDRESETREQUESTS), this.accountMapperService.passwordResetRequestDtoToPasswordResetRequest(passwordResetRequest));
    };
    /**
     * Reset password with token after user has requested an password reset.
     * @param passwordResetDto
     * @returns
     */
    AccountApiService.prototype.resetPassword = function (passwordResetDto) {
        return this.http.post(this.shared.buildApiUrl(ACCOUNTS, PASSWORDRESETS), this.accountMapperService.passwordResetDtoToPasswordReset(passwordResetDto));
    };
    /**
     * Save account info for a user. You can lock and unlock users (forever and for a specific time span)
     * @returns
     * @param userDto
     */
    AccountApiService.prototype.saveAccountInfo = function (userDto) {
        return this.http.post(this.shared.buildApiUrl(ACCOUNTS, INFO, userDto.id), this.userMapper.accountInfoDtoToAccountInfo(userDto.accountInfo));
    };
    /**
     * Password change for user self service. User can change his own password (requires both the old and new password)
     * @param passwordChangeDto
     * @returns
     */
    AccountApiService.prototype.changePassword = function (passwordChangeDto) {
        return this.http.post(this.shared.buildApiUrl(ACCOUNTS, PASSWORD), this.accountMapperService.passwordChangeDtoToPasswordChange(passwordChangeDto));
    };
    return AccountApiService;
}());
export { AccountApiService };
