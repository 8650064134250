<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">
  <div class="text-center text-semibold mt-20 text-truncate">{{'TASKCONFIG.WEEKLY.DESCRIPTION' | translate}}</div>
  <form [formGroup]="data.formGroup">
    <div fxLayout="column">
      <mat-form-field>
        <label class="mr-8" matPrefix>{{'TASKCONFIG.WEEKLY.START.PREFIX' | translate}}</label>
        <mat-placeholder>{{'TASKCONFIG.WEEKLY.START.PLACEHOLDER' | translate}}</mat-placeholder>
        <mat-datetimepicker-toggle [for]="datetimePickerStart" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerStart [startAt]="today" timeInterval="5" type="datetime">
        </mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerStart" [min]="data.startDatePicker" class="text-center"
          formControlName="start" matInput readonly>
        <label class="ml-8" matSuffix>{{'TASKCONFIG.WEEKLY.START.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <label class="mr-8" matPrefix>{{'TASKCONFIG.WEEKLY.END.PREFIX' | translate}}</label>
        <mat-placeholder>{{'TASKCONFIG.WEEKLY.END.PLACEHOLDER' | translate}}</mat-placeholder>
        <mat-datetimepicker-toggle [for]="datetimePickerEnd" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerEnd timeInterval="5" type="datetime"></mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerEnd" [min]="getStartDate()" class="text-center" formControlName="end"
          matInput readonly>
        <label class="ml-8" matSuffix>{{'TASKCONFIG.WEEKLY.END.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <label class="mr-8" matPrefix>{{'TASKCONFIG.WEEKLY.WEEKDAYS.PREFIX' | translate}}</label>
        <mat-select [placeholder]="'TASKCONFIG.WEEKLY.WEEKDAYS.PLACEHOLDER' | translate" class="text-right"
          formControlName="weekdays" multiple>
          <mat-option *ngFor="let day of weekdays" [value]="day.value">{{'TASKCONFIG.WEEKDAYS.' +
            day.label.toUpperCase() | translate}}</mat-option>
        </mat-select>
        <span class="ml-8" matSuffix>{{'TASKCONFIG.WEEKLY.WEEKDAYS.SUFFIX' | translate}}</span>
      </mat-form-field>
      <mat-form-field>
        <mat-select [placeholder]="'TASKCONFIG.ASSIGNGROUPS.ADDREMOVE' | translate" class="text-right"
          formControlName="assignedUserGroupIds" multiple>
          <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">{{userGroup.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</phoenix-dialog-new>