import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { auditTime, map, mergeMap, startWith } from 'rxjs/operators';
import { PhoenixAutocompleteEntry } from '../../classes/phoenix-autocomplete-entry';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicTextboxComponent = /** @class */ (function () {
    function PhoenixDynamicTextboxComponent() {
        this.translationParams = {};
    }
    PhoenixDynamicTextboxComponent.prototype.ngOnInit = function () {
        this.form.get(this.phoenixFormControl.key).setValidators(this.phoenixFormControl.validators);
        this.form.get(this.phoenixFormControl.key).setAsyncValidators(this.phoenixFormControl.asyncValidators);
        this.handleAutocompleteOption();
    };
    PhoenixDynamicTextboxComponent.prototype.displayFn = function (obj) {
        return obj ? obj.label : undefined;
    };
    PhoenixDynamicTextboxComponent.prototype.filter = function (val, options) {
        return options.filter(function (option) {
            return option.label.toLowerCase().includes(val.toLowerCase());
        });
    };
    PhoenixDynamicTextboxComponent.prototype.handleAutocompleteOption = function () {
        var _this = this;
        if (_.has(this.phoenixFormControl.options, 'autocomplete')) {
            var searchObservable = _.get(this.phoenixFormControl.options, 'autocomplete');
            var autocompleteData = this.form.get(this.phoenixFormControl.key).valueChanges.pipe(startWith(this.form.get(this.phoenixFormControl.key).value), auditTime(500), map(function (search) { return typeof search === 'string' ? search : undefined; }), // value of the form control could be an object
            mergeMap(searchObservable));
            this.filteredOptions = combineLatest(this.form.get(this.phoenixFormControl.key).valueChanges.pipe(startWith(new PhoenixAutocompleteEntry({ object: undefined, label: '' }))), autocompleteData).pipe(map(function (_a) {
                var formValue = _a[0], options = _a[1];
                if (typeof formValue === 'string') {
                    if (formValue === '' && !_this.phoenixFormControl.required) {
                        _this.form.get(_this.phoenixFormControl.key).setErrors(undefined);
                    }
                    else {
                        _this.form.get(_this.phoenixFormControl.key).setErrors({ error: 'parent element is not an object' });
                    }
                    return _this.filter(formValue, options);
                }
                else {
                    _this.form.get(_this.phoenixFormControl.key).setErrors(undefined);
                    return options;
                }
            }));
        }
    };
    return PhoenixDynamicTextboxComponent;
}());
export { PhoenixDynamicTextboxComponent };
