import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { PropertyMapperService } from '../../property/mapper/property-mapper.service';
import { AccountInfo } from '../api/account-info';
import { User } from '../api/user';
import { UserConfig } from '../api/user-config';
import { UserInfo } from '../api/user-info';
import { UserSearchCriteria } from '../api/user-search-criteria';
import { AccountInfoDto } from '../dto/account-info-dto';
import { UserConfigDto } from '../dto/user-config-dto';
import { UserDto } from '../dto/user-dto';
import { UserInfoDto } from '../dto/user-info-dto';
import * as i0 from "@angular/core";
import * as i1 from "../../property/mapper/property-mapper.service";
var UserMapperService = /** @class */ (function () {
    function UserMapperService(propertyMapper) {
        this.propertyMapper = propertyMapper;
    }
    UserMapperService.prototype.accountInfoDtoToAccountInfo = function (accountInfoDto) {
        return plainToClass(AccountInfo, accountInfoDto);
    };
    UserMapperService.prototype.accountInfoToAccountInfoDto = function (accountInfo) {
        var accountInfoDto = plainToClass(AccountInfoDto, accountInfo);
        if (!_.isNil(accountInfo.blockedUntil)) {
            accountInfoDto.blockedUntil = new Date(accountInfo.blockedUntil);
        }
        return accountInfoDto;
    };
    UserMapperService.prototype.userArrayToUserDtoArray = function (users) {
        var _this = this;
        return _.map(users, function (user) { return _this.userToUserDto(user); });
    };
    UserMapperService.prototype.userConfigArrayToUserConfigDtoArray = function (userConfigs) {
        var _this = this;
        return _.map(userConfigs, function (userConfig) { return _this.userConfigToUserConfigDto(userConfig); });
    };
    UserMapperService.prototype.userConfigDtoArrayToUserConfigArray = function (userConfigDtos) {
        var _this = this;
        return _.map(userConfigDtos, function (userConfigDto) { return _this.userConfigDtoToUserConfig(userConfigDto); });
    };
    UserMapperService.prototype.userConfigDtoToUserConfig = function (userConfigDto) {
        return plainToClass(UserConfig, userConfigDto);
    };
    UserMapperService.prototype.userConfigToUserConfigDto = function (userConfig) {
        return plainToClass(UserConfigDto, userConfig);
    };
    UserMapperService.prototype.userDtoToUser = function (userDto) {
        var user = plainToClass(User, userDto);
        user.accountInfo = userDto.accountInfo ? this.accountInfoDtoToAccountInfo(userDto.accountInfo) : undefined;
        user.properties = this.propertyMapper.propertyDtoArrayToPropertyArray(userDto.properties);
        user.configurations = this.userConfigDtoArrayToUserConfigArray(userDto.configurations);
        return user;
    };
    UserMapperService.prototype.userInfoDtoToUserInfo = function (userInfoDto) {
        return plainToClass(UserInfo, userInfoDto);
    };
    UserMapperService.prototype.userInfoToUserInfoDto = function (userInfo) {
        return plainToClass(UserInfoDto, userInfo);
    };
    UserMapperService.prototype.userSearchCriteriaDtoToUserSearchCriteria = function (userSearchCriteriaDto) {
        return plainToClass(UserSearchCriteria, userSearchCriteriaDto);
    };
    UserMapperService.prototype.userToUserDto = function (user) {
        var userDto = plainToClass(UserDto, user);
        userDto.accountInfo = user.accountInfo ? this.accountInfoToAccountInfoDto(user.accountInfo) : undefined;
        userDto.properties = this.propertyMapper.propertyArrayToPropertyDtoArray(user.properties);
        userDto.configurations = this.userConfigArrayToUserConfigDtoArray(user.configurations);
        return userDto;
    };
    UserMapperService.ngInjectableDef = i0.defineInjectable({ factory: function UserMapperService_Factory() { return new UserMapperService(i0.inject(i1.PropertyMapperService)); }, token: UserMapperService, providedIn: "root" });
    return UserMapperService;
}());
export { UserMapperService };
