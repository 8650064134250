var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { addSeconds, differenceInSeconds } from 'date-fns';
import * as _ from 'lodash';
import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { TaskConfigDto, TaskConfigRepeatConfigTypeDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto } from '../../../../gapicon/dto.module';
import { PhoenixTaskConfigDialogWeeklyComponent } from './phoenix-task-config-dialog-weekly.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PhoenixTaskConfigDialogWeeklyService = /** @class */ (function () {
    function PhoenixTaskConfigDialogWeeklyService(dialog) {
        this.dialog = dialog;
    }
    PhoenixTaskConfigDialogWeeklyService.prototype.openDialog = function (userGroups, taskConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a, start, end, turnus, weekdays, assignedUserGroupIds, startDatePicker, id, formGroup, dialogRef;
            return __generator(this, function (_b) {
                _a = this.getValuesForFormGroup(taskConfig), start = _a.start, end = _a.end, turnus = _a.turnus, weekdays = _a.weekdays, assignedUserGroupIds = _a.assignedUserGroupIds;
                startDatePicker = taskConfig && new Date(taskConfig.startDate) < new Date() ? new Date(taskConfig.startDate) : new Date();
                id = taskConfig ? taskConfig.id : undefined;
                formGroup = new FormGroup({
                    start: new FormControl(start, [Validators.required]),
                    end: new FormControl(end, [Validators.required]),
                    turnus: new FormControl(turnus, [Validators.required]),
                    weekdays: new FormControl(weekdays, [Validators.required]),
                    assignedUserGroupIds: new FormControl(assignedUserGroupIds, [Validators.required]),
                });
                dialogRef = this.dialog.open(PhoenixTaskConfigDialogWeeklyComponent, {
                    width: '950px',
                    autoFocus: false,
                    data: {
                        title: 'TASKCONFIG.WEEKLY.TITLE',
                        subtitle: 'TASKCONFIG.WEEKLY.SUBTITLE',
                        buttons: [
                            new PhoenixDialogButton({
                                label: 'TASKCONFIG.CANCEL',
                                click: function () { return dialogRef.close(); },
                            }),
                            new PhoenixDialogButton({
                                label: 'TASKCONFIG.ADD',
                                click: function () { return dialogRef.close(_this.handleDialogResult(id, formGroup.value)); },
                                color: 'accent',
                                raised: true,
                                disabled: function () { return formGroup.invalid; },
                            })
                        ],
                        formGroup: formGroup,
                        startDatePicker: startDatePicker,
                        usergroups: userGroups
                    },
                });
                return [2 /*return*/, dialogRef];
            });
        });
    };
    PhoenixTaskConfigDialogWeeklyService.prototype.getValuesForFormGroup = function (taskConfig) {
        var start = _.get(taskConfig, ['startDate']) ? new Date(taskConfig.startDate) : undefined;
        var end = _.get(taskConfig, ['startDate']) ? addSeconds(taskConfig.startDate, taskConfig.duration) : undefined;
        var turnus = _.get(taskConfig, ['repeat', 'value'], 1);
        var weekdays = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.weekdays], []);
        var assignedUserGroupIds = _.get(taskConfig, ['assignedUserGroupIds'], []);
        return { start: start, end: end, turnus: turnus, weekdays: weekdays, assignedUserGroupIds: assignedUserGroupIds };
    };
    PhoenixTaskConfigDialogWeeklyService.prototype.handleDialogResult = function (id, result) {
        var config = {};
        config[TaskConfigRepeatConfigTypeDto.weekdays] = result.weekdays;
        var repeat = new TaskConfigRepeatDto({
            config: config,
            type: TaskConfigRepeatTypeDto.weekly,
            value: result.turnus,
        });
        return new TaskConfigDto({
            id: id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin',
            startDate: result.start,
            repeat: repeat,
            duration: differenceInSeconds(result.end, result.start),
            createdDate: new Date(),
            assignedUserGroupIds: result.assignedUserGroupIds
        });
    };
    PhoenixTaskConfigDialogWeeklyService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixTaskConfigDialogWeeklyService_Factory() { return new PhoenixTaskConfigDialogWeeklyService(i0.inject(i1.MatDialog)); }, token: PhoenixTaskConfigDialogWeeklyService, providedIn: "root" });
    return PhoenixTaskConfigDialogWeeklyService;
}());
export { PhoenixTaskConfigDialogWeeklyService };
