import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observer} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

import {AssetDto, FileItemDto, FileStorageInformationDto} from '../../dto.module';
import {SharedService} from '../../shared/services/shared.service';
import {FileAccessToken} from '../api/file-access-token';
import {FileItem} from '../api/file-item';
import {FileStorageInformation} from '../api/file-storage-information';
import {FileAccessTokenDto} from '../dto/file-access-token-dto';
import {FileMapperService} from '../mapper/file-mapper.service';

@Injectable()
export class FileApiService {

  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private fileMapperService: FileMapperService,
  ) {
  }

  /**
   * Deletes a file by given fileDto.
   * @returns
   * @param fileItemDto
   */
  public deleteFile(fileItemDto: FileItemDto): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl('files', this.fileMapperService.fileItemDtoToFileItem(fileItemDto).name),
    );
  }

  /**
   * Gets a download token for a file by given path string.
   * @param path
   * @returns
   */
  public getDownloadTokenByPath(path: string): Observable<FileAccessTokenDto> {
    return this.http.get<FileAccessToken>(
      this.sharedService.buildApiUrl('downloadToken', path),
    ).pipe(
      map((fileAccessToken: FileAccessTokenDto) => this.fileMapperService.fileAccessTokenDtoToFileAccessToken(fileAccessToken)),
    );
  }

  /**
   * Gets a download token for a file by given path string.
   * @param path
   * @param token optional, token to authenticate download
   * @returns
   */
  public getDownloadUrlByPath(path: string, token?: string): string {
    if (token) {
      return this.sharedService.buildApiUrl('download', path) + '?token=' + token;
    } else {
      return this.sharedService.buildApiUrl('download', path);
    }
  }

  /**
   * Gets a file by given path string.
   * @param path
   * @returns
   */
  public getFileByPath(path: string): Observable<FileItemDto> {
    return this.http.get<FileItem>(
      this.sharedService.buildApiUrl('files', path),
    ).pipe(
      map((fileItem: FileItem) => this.fileMapperService.fileItemToFileItemDto(fileItem)),
    );
  }

  /**
   * Gets the file storage information.
   * @param assetDto
   */
  public getFileStorageInformation(assetDto: AssetDto): Observable<FileStorageInformationDto> {
    return this.http.get<FileStorageInformation>(
      this.sharedService.buildApiUrl('files', 'assets', assetDto.id, 'attachments', 'storageinformation'),
    ).pipe(
      map((fileStorageInformation: FileStorageInformation) => this.fileMapperService.fileStorageInformationToFileStorageInformationDto(fileStorageInformation)),
    );
  }

  /**
   * Overwrites a file by given fileItem and file.
   * @param fileItemDto
   * @param file
   * @returns
   */
  public updateFile(fileItemDto: FileItemDto, file: File): Observable<number> {
    return this.upload(
      fileItemDto,
      file,
      'PUT',
      this.sharedService.buildApiUrl('files', this.fileMapperService.fileItemDtoToFileItem(fileItemDto).name),
    );
  }

  /**
   * Generic method to upload a file to BLOB-Storage.
   * @param fileItemDto
   * @param file
   * @param method
   * @param path
   * @returns
   */
  public upload(fileItemDto: FileItemDto, file: File, method: string, path: string): Observable<number> {
    return Observable.create((observer: Observer<number>) => {
      const fileItem: FileItem = this.fileMapperService.fileItemDtoToFileItem(fileItemDto);
      const data: FormData = new FormData();
      data.append('file', file, file.name);
      data.append('contentType', fileItem.contentType);
      data.append('contentEncoding', fileItem.contentEncoding);

      const req: HttpRequest<FormData> = new HttpRequest<FormData>(
        method,
        path,
        data,
        {
          reportProgress: true,
        });

      this.http.request(req)
      // tslint:disable-next-line
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              observer.next(event.loaded / event.total);
              break;
            case HttpEventType.Response:
              observer.next(1);
              observer.complete();
              break;
          }
        }, observer.error, observer.complete);
    });
  }

  /**
   * Uploads a file by given fileItem and file.
   * @param fileItemDto
   * @param file
   * @returns
   */
  public uploadFile(fileItemDto: FileItemDto, file: File): Observable<number> {
    return this.upload(
      fileItemDto,
      file,
      'POST',
      this.sharedService.buildApiUrl('files', this.fileMapperService.fileItemDtoToFileItem(fileItemDto).name),
    );
  }
}
