import { PhoenixFormElementType } from '../../enums/phoenix-form-element-type.enum';
var PhoenixFormText = /** @class */ (function () {
    // -value from *component.ts
    function PhoenixFormText(formGroup, formControlName, placeholder, value, hint, minlength, maxlength, required, disabled, readonly) {
        if (placeholder === void 0) { placeholder = ''; }
        if (value === void 0) { value = ''; }
        if (hint === void 0) { hint = ''; }
        if (required === void 0) { required = false; }
        if (disabled === void 0) { disabled = false; }
        if (readonly === void 0) { readonly = false; }
        this.type = PhoenixFormElementType.Text;
        this.formGroup = formGroup;
        this.formControlName = formControlName;
        this.placeholder = placeholder;
        this.value = value;
        this.hint = hint;
        this.minlength = minlength;
        this.maxlength = maxlength;
        this.required = required;
        this.disabled = disabled;
        this.readonly = readonly;
    }
    return PhoenixFormText;
}());
export { PhoenixFormText };
