/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar-menu-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i5 from "@angular/material/menu";
import * as i6 from "@angular/common";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./toolbar-menu-item.component";
var styles_ToolbarMenuItemComponent = [i0.styles];
var RenderType_ToolbarMenuItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarMenuItemComponent, data: {} });
export { RenderType_ToolbarMenuItemComponent as RenderType_ToolbarMenuItemComponent };
function View_ToolbarMenuItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.entry.picture; _ck(_v, 2, 0, currVal_1); }); }
function View_ToolbarMenuItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "flag mr-16"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entry.picture; _ck(_v, 0, 0, currVal_0); }); }
export function View_ToolbarMenuItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""], ["role", "menuitem"]], [[2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.entryClicked() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_MatMenuItem_0, i4.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, null, 0, i5.MatMenuItem, [i1.ElementRef, i6.DOCUMENT, i7.FocusMonitor, [2, i5.ɵf22]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "div", [["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i8.LayoutDirective, [i9.MediaMonitor, i1.ElementRef, i9.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(4, 737280, null, 0, i8.LayoutAlignDirective, [i9.MediaMonitor, i1.ElementRef, [6, i8.LayoutDirective], i9.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarMenuItemComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarMenuItemComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_5 = "row"; _ck(_v, 3, 0, currVal_5); var currVal_6 = "start center"; _ck(_v, 4, 0, currVal_6); var currVal_7 = (_co.entry.type === "icon"); _ck(_v, 6, 0, currVal_7); var currVal_8 = (_co.entry.type === "img"); _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1)._highlighted; var currVal_1 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_2 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_3 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_9 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.entry.title)); _ck(_v, 10, 0, currVal_9); }); }
export function View_ToolbarMenuItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-toolbar-menu-item", [], null, null, null, View_ToolbarMenuItemComponent_0, RenderType_ToolbarMenuItemComponent)), i1.ɵdid(1, 49152, null, 0, i11.ToolbarMenuItemComponent, [], null, null)], null, null); }
var ToolbarMenuItemComponentNgFactory = i1.ɵccf("phoenix-toolbar-menu-item", i11.ToolbarMenuItemComponent, View_ToolbarMenuItemComponent_Host_0, { entry: "entry" }, { clicked: "clicked" }, []);
export { ToolbarMenuItemComponentNgFactory as ToolbarMenuItemComponentNgFactory };
