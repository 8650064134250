import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';
import { ThresholdMapperService } from '../../threshold/mapper/threshold-mapper.service';
import { StatisticsMapperService } from '../mapper/statistics-mapper.service';
var StatisticsApiService = /** @class */ (function () {
    /**
     * @param http
     * @param shared
     * @param statisticsMapperService
     * @param thresholdMapperService
     */
    function StatisticsApiService(http, shared, statisticsMapperService, thresholdMapperService) {
        this.http = http;
        this.shared = shared;
        this.statisticsMapperService = statisticsMapperService;
        this.thresholdMapperService = thresholdMapperService;
    }
    /**
     * Gets thresholdViolationStatistics matching the search criteria.
     * @param searchCriteria
     * @returns
     */
    StatisticsApiService.prototype.getThresholdViolations = function (searchCriteria) {
        var _this = this;
        var options = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
        if (searchCriteria) {
            options.params = this.statisticsMapperService.statisticsSearchCriteriaDtoToStatisticsSearchCriteria(searchCriteria).getRequestQueryParams();
        }
        return this.http.get(this.shared.buildApiUrl('statistics', 'thresholdViolations'), options).pipe(map(function (thresholdViolationStatistics) {
            return _this.thresholdMapperService.thresholdViolationStatisticsToThresholdViolationStatisticsDto(thresholdViolationStatistics);
        }));
    };
    return StatisticsApiService;
}());
export { StatisticsApiService };
