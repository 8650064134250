import {NgModule} from '@angular/core';
import {MatButtonModule, MatIconModule} from '@angular/material';

import {FuseNavigationModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {TranslateModule} from '@ngx-translate/core';
import {NavbarVerticalStyle2Component} from 'app/layout/components/navbar/vertical/style-2/style-2.component';

@NgModule({
  declarations: [
    NavbarVerticalStyle2Component,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,

    FuseSharedModule,
    FuseNavigationModule,
    TranslateModule.forChild(),
  ],
  exports: [
    NavbarVerticalStyle2Component,
  ],
})
export class NavbarVerticalStyle2Module {
}
