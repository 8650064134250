import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Observer, throwError } from 'rxjs';
import { EmptyObservable } from 'rxjs-compat/observable/EmptyObservable';
import { catchError, expand } from 'rxjs/operators';

import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { MeasurementTypeDto } from '../../dto.module';
import { MeasurementValueTypeDto } from '../../measurement-type/dto/measurement-value-type-dto';
import { ValueTypeDto } from '../../measurement-type/dto/value-type-dto.enum';
import { SearchCriteriaPageable } from '../classes/search-criteria-pageable';

const APIURL: string = '/api/';
const AUTHURL: string = '/auth/oauth/';

@Injectable()
export class SharedService {
  /**
   *
   */
  public constructor(
    private http: HttpClient,
  ) {
  }

  /**
   * String builder for api URL
   * @param parts
   * @returns
   */
  public buildApiUrl(...parts: string[]): string {
    return APIURL + parts.join('/');
  }

  /**
   * String builder for authentication URL
   * @param parts
   * @returns
   */
  public buildAuthUrl(...parts: string[]): string {
    return AUTHURL + parts.join('/');
  }

  public getBooleanMeasurementValueTypeDtoKeys(measurementType: MeasurementTypeDto): string[] {
    return _.map(this.getBooleanMeasurementValueTypeDtos(measurementType), 'key');
  }

  public getBooleanMeasurementValueTypeDtos(measurementType: MeasurementTypeDto): MeasurementValueTypeDto[] {
    return _.filter(_.get(measurementType, 'valueTypes', undefined), (measurementValueTypeDto: MeasurementValueTypeDto) => {
      return measurementValueTypeDto.type === ValueTypeDto.BOOLEAN || measurementValueTypeDto.type === ValueTypeDto.NUMBER
        || measurementValueTypeDto.type === ValueTypeDto.DECIMAL;
    });
  }

  public httpGet<T>(
    url: string,
    searchCriteria: SearchCriteriaPageable,
  ): Observable<T[]> {
    const options: { params: HttpParams } = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };

    options.params = searchCriteria.getRequestQueryParams();

    return new Observable<T[]>((observer: Observer<T[]>): void => {
      this.http.get<T[]>(url, options)
        .pipe(
          expand((response: T[]): Observable<T[]> => {

            observer.complete();
            return new EmptyObservable();

          }))
        .subscribe((data: T[]) => {
          observer.next(data);
        }, error => {
          observer.error(error);
        });
    });
  }

  public httpGetWithPagination<T>(
    url: string,
    searchCriteria: SearchCriteriaPageable,
    withFurtherPages: boolean,
    // tslint:disable-next-line
    lengthFunction: (arr: any) => number = (arr: T[]): number => arr.length,
  ): Observable<T[]> {
    const options: { params: HttpParams } = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };

    searchCriteria.page = searchCriteria.page ? searchCriteria.page : 0;
    options.params = searchCriteria.getRequestQueryParams();

    return new Observable<T[]>((observer: Observer<T[]>): void => {
      this.http.get<T[]>(url, options)
        .pipe(
          expand((response: T[]): Observable<T[]> => {
            if (withFurtherPages && lengthFunction(response) === searchCriteria.size) {
              searchCriteria.page += 1;
              options.params = searchCriteria ? searchCriteria.getRequestQueryParams() : undefined;
              return this.http.get<T[]>(url, options);
            } else {
              observer.complete();
              return new EmptyObservable();
            }
          }))
        .subscribe((data: T[]) => {
          observer.next(data);
        }, error => {
          observer.error(error);
        });
    });
  }
}
