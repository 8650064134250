var PasswordReset = /** @class */ (function () {
    function PasswordReset(passwordReset) {
        this.token = undefined;
        this.password = undefined;
        Object.assign(this, passwordReset);
    }
    PasswordReset.prototype.toJSON = function () {
        return {
            token: this.token,
            password: this.password,
        };
    };
    return PasswordReset;
}());
export { PasswordReset };
