var SensorTypeDto = /** @class */ (function () {
    function SensorTypeDto(sensorType) {
        this.id = undefined;
        this.configurations = undefined;
        this.registrationParams = undefined;
        this.measurementTypes = undefined;
        this.name = undefined;
        this.key = undefined;
        Object.assign(this, sensorType);
    }
    return SensorTypeDto;
}());
export { SensorTypeDto };
