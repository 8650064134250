var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import * as _ from 'lodash';
import moment from 'moment';
import { TaskConfigRepeatConfigTypeDto, TaskConfigRepeatTypeDto } from '../../../gapicon/dto.module';
import { PhoenixTaskConfigDialog } from './classes/phoenix-task-config-dialog';
import { PhoenixTaskConfigDialogAnnuallyService } from './phoenix-task-config-dialog-annually/phoenix-task-config-dialog-annually.service';
import { PhoenixTaskConfigDialogDailyService } from './phoenix-task-config-dialog-daily/phoenix-task-config-dialog-daily.service';
import { PhoenixTaskConfigDialogMonthlyService } from './phoenix-task-config-dialog-monthly/phoenix-task-config-dialog-monthly.service';
import { PhoenixTaskConfigDialogOnceService } from './phoenix-task-config-dialog-once/phoenix-task-config-dialog-once.service';
import { PhoenixTaskConfigDialogWeeklyService } from './phoenix-task-config-dialog-weekly/phoenix-task-config-dialog-weekly.service';
var PhoenixTaskConfigDialogComponent = /** @class */ (function () {
    function PhoenixTaskConfigDialogComponent(data, dialog, onceService, dailyService, weeklyService, monthlyService, annuallyService, translate) {
        this.data = data;
        this.dialog = dialog;
        this.onceService = onceService;
        this.dailyService = dailyService;
        this.weeklyService = weeklyService;
        this.monthlyService = monthlyService;
        this.annuallyService = annuallyService;
        this.translate = translate;
        this.repeatType = TaskConfigRepeatTypeDto;
    }
    PhoenixTaskConfigDialogComponent.prototype.deleteConfiguration = function (taskConfig) {
        _.pull(this.data.taskConfigs, taskConfig);
    };
    PhoenixTaskConfigDialogComponent.prototype.editConfiguration = function (taskConfig) {
        this.openDialog(taskConfig.repeat ? taskConfig.repeat.type : undefined, taskConfig);
    };
    PhoenixTaskConfigDialogComponent.prototype.getPanelContent = function (taskConfigDto) {
        var panelContentStrings = [];
        panelContentStrings.push(this.translate.instant('TASKCONFIG.PANEL.1').concat(' ', this.getRepititionString(taskConfigDto.repeat), ' ', this.translate.instant('TASKCONFIG.PANEL.2'), ' ', format(taskConfigDto.startDate, 'YYYY-MM-DD HH:mm'), ' ', this.translate.instant('TASKCONFIG.PANEL.3'), ' ', this.getDurationStringPrecise(moment.duration(taskConfigDto.duration, 'seconds')), ' ', this.translate.instant('TASKCONFIG.PANEL.4'), ' '), this.translate.instant('TASKCONFIG.PANEL.6').concat(' ', this.userGroupsToString(taskConfigDto.assignedUserGroupIds)));
        _.forEach(this.getExtendedRepititionStrings(taskConfigDto.repeat), function (s) { return panelContentStrings.push(s); });
        return panelContentStrings;
    };
    PhoenixTaskConfigDialogComponent.prototype.userGroupsToString = function (assignedUserGroupIds) {
        return assignedUserGroupIds ? this.data.usergroups.filter(function (ug) { return assignedUserGroupIds.includes(ug.id); }).map(function (ug) { return ug.name; }).join(',') : '';
    };
    PhoenixTaskConfigDialogComponent.prototype.getPanelDescription = function (taskConfigDto) {
        return this.translate.instant('TASKCONFIG.PANEL.2')
            .concat(' ', format(taskConfigDto.startDate, 'YYYY-MM-DD HH:mm'), ' - ', this.getDurationString(moment.duration(taskConfigDto.duration, 'seconds')));
    };
    PhoenixTaskConfigDialogComponent.prototype.getPanelTitle = function (taskConfigDto) {
        return this.translate.instant('TASKCONFIG.DURATION.REPEAT')
            .concat(' ', this.getRepititionString(taskConfigDto.repeat));
    };
    PhoenixTaskConfigDialogComponent.prototype.openDialog = function (repeatType, taskConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var service;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (repeatType) {
                            switch (repeatType) {
                                case TaskConfigRepeatTypeDto.once:
                                    service = this.onceService;
                                    break;
                                case TaskConfigRepeatTypeDto.daily:
                                    service = this.dailyService;
                                    break;
                                case TaskConfigRepeatTypeDto.weekly:
                                    service = this.weeklyService;
                                    break;
                                case TaskConfigRepeatTypeDto.monthly:
                                    service = this.monthlyService;
                                    break;
                                case TaskConfigRepeatTypeDto.annually:
                                    service = this.annuallyService;
                                    break;
                            }
                        }
                        else {
                            service = this.onceService;
                        }
                        return [4 /*yield*/, service.openDialog(this.data.usergroups, taskConfig)];
                    case 1:
                        (_a.sent()).afterClosed().subscribe(function (result) {
                            if (result) {
                                if (taskConfig) {
                                    _.pull(_this.data.taskConfigs, taskConfig);
                                }
                                _this.data.taskConfigs.push(result);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixTaskConfigDialogComponent.prototype.extendRepeatStringWithMonthRepitionString = function (repeat) {
        var _this = this;
        var s = '';
        var month = repeat.config[TaskConfigRepeatConfigTypeDto.month];
        if (month && month.length > 0) {
            s += this.translate.instant('TASKCONFIG.MONTHS.LABEL').concat(': ');
            _.forEach(month, function (no) {
                s += _this.translate.instant('TASKCONFIG.MONTHS.' + no.toString()) + ', ';
            });
        }
        return s;
    };
    PhoenixTaskConfigDialogComponent.prototype.extendRepeatStringWithMonthdayRepitionString = function (repeat) {
        var s = '';
        var days = repeat.config[TaskConfigRepeatConfigTypeDto.day];
        if (days && days.length > 0) {
            s += this.translate.instant('TASKCONFIG.RADIOBUTTONS.MONTHDAYS').concat(': ');
            _.forEach(days, function (no) {
                s += no < 10 ? '0' + no.toString() : no.toString();
                s += ', ';
            });
        }
        return s;
    };
    PhoenixTaskConfigDialogComponent.prototype.extendRepeatStringWithWeekRepitionString = function (repeat) {
        var _this = this;
        var s = '';
        var week = repeat.config[TaskConfigRepeatConfigTypeDto.week];
        if (week && week.length > 0) {
            s += this.translate.instant('TASKCONFIG.WEEK.LABEL').concat(': ');
            _.forEach(week, function (no) {
                s += _this.translate.instant('TASKCONFIG.WEEK.' + no.toString()) + ', ';
            });
        }
        return s;
    };
    PhoenixTaskConfigDialogComponent.prototype.extendRepeatStringWithWeekdayRepitionString = function (repeat) {
        var _this = this;
        var s = '';
        var weekdays = repeat.config[TaskConfigRepeatConfigTypeDto.weekdays];
        if (weekdays && weekdays.length > 0) {
            s += this.translate.instant('TASKCONFIG.WEEKDAYS.LABEL').concat(': ');
            _.forEach(weekdays, function (no) {
                s += _this.translate.instant('TASKCONFIG.WEEKDAYS.' + no.toString()) + ', ';
            });
        }
        return s;
    };
    PhoenixTaskConfigDialogComponent.prototype.getDurationString = function (duration) {
        return this.translate.instant('TASKCONFIG.DURATION.LABEL')
            .concat(this.getDurationStringPrecise(duration));
    };
    PhoenixTaskConfigDialogComponent.prototype.getDurationStringPrecise = function (duration) {
        return ''.concat(duration.years() > 0 ? duration.years().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.YEARS'), ' ') : '', duration.months() > 0 ? duration.months().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.MONTHS'), ' ') : '', duration.days() > 0 ? duration.days().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.DAYS'), ' ') : '', duration.hours() > 0 ? duration.hours().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.HOURS'), ' ') : '', duration.minutes() > 0 ? duration.minutes().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.MINUTES'), ' ') : '');
    };
    PhoenixTaskConfigDialogComponent.prototype.getExtendedRepititionStrings = function (repeat) {
        var strings = [];
        if (repeat && repeat.type) {
            switch (repeat.type) {
                case TaskConfigRepeatTypeDto.weekly:
                    strings.push(this.extendRepeatStringWithWeekdayRepitionString(repeat));
                    break;
                case TaskConfigRepeatTypeDto.monthly:
                    if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.weekdays, []).length > 0) {
                        strings.push(this.extendRepeatStringWithWeekRepitionString(repeat));
                        strings.push(this.extendRepeatStringWithWeekdayRepitionString(repeat));
                    }
                    if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.day, []).length > 0) {
                        strings.push(this.extendRepeatStringWithMonthdayRepitionString(repeat));
                    }
                    break;
                case TaskConfigRepeatTypeDto.annually:
                    strings.push(this.extendRepeatStringWithMonthRepitionString(repeat));
                    if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.weekdays, []).length > 0) {
                        strings.push(this.extendRepeatStringWithWeekRepitionString(repeat));
                        strings.push(this.extendRepeatStringWithWeekdayRepitionString(repeat));
                    }
                    if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.day, []).length > 0) {
                        strings.push(this.extendRepeatStringWithMonthdayRepitionString(repeat));
                    }
                    break;
            }
        }
        return strings;
    };
    PhoenixTaskConfigDialogComponent.prototype.getRepititionString = function (repeat) {
        var repitionString;
        if (repeat && repeat.type) {
            switch (repeat.type) {
                case TaskConfigRepeatTypeDto.once:
                    repitionString = this.translate.instant('TASKCONFIG.MENU.ONCE');
                    break;
                case TaskConfigRepeatTypeDto.daily:
                    repitionString = repeat.value.toString().concat('-', this.translate.instant('TASKCONFIG.MENU.DAILY'));
                    break;
                case TaskConfigRepeatTypeDto.weekly:
                    repitionString = this.translate.instant('TASKCONFIG.MENU.WEEKLY');
                    break;
                case TaskConfigRepeatTypeDto.monthly:
                    repitionString = this.translate.instant('TASKCONFIG.MENU.MONTHLY');
                    break;
                case TaskConfigRepeatTypeDto.annually:
                    repitionString = this.translate.instant('TASKCONFIG.MENU.ANNUALLY');
                    break;
            }
        }
        else {
            repitionString = this.translate.instant('TASKCONFIG.MENU.ONCE');
        }
        return repitionString;
    };
    return PhoenixTaskConfigDialogComponent;
}());
export { PhoenixTaskConfigDialogComponent };
