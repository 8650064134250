var SensorRegistration = /** @class */ (function () {
    function SensorRegistration(sensorRegistration) {
        this.registrationParams = undefined;
        this.sensorAsset = undefined;
        Object.assign(this, sensorRegistration);
    }
    SensorRegistration.prototype.toJSON = function () {
        return {
            registrationParams: this.registrationParams,
            sensorAsset: this.sensorAsset,
        };
    };
    return SensorRegistration;
}());
export { SensorRegistration };
