import { plainToClass } from 'class-transformer';
import { AssetDto } from '../../dto.module';
import { UserGroupUpdateDto } from '../../group/dto/user-group-update-dto';
import { UserGroupMapperService } from '../../group/mapper/usergroup-mapper.service';
import { TenantRequest } from '../api/tenant-request';
import { TenantSearchCriteria } from '../api/tenant-search-criteria';
import { TenantUpdateRequest } from '../api/tenant-update-request';
import { TenantUpdateRequestDto } from '../dto/tenant-update-request-dto';
import { TenantJob } from '../api/tenant-job';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../../group/mapper/usergroup-mapper.service";
var TenantRequestMapperService = /** @class */ (function () {
    function TenantRequestMapperService(userGroupMapperService) {
        this.userGroupMapperService = userGroupMapperService;
    }
    TenantRequestMapperService.prototype.tenantRequestDtoToTenantRequest = function (tenantRequestDto) {
        return plainToClass(TenantRequest, tenantRequestDto);
    };
    TenantRequestMapperService.prototype.tenantUpdateRequestDtoToTenantUpdateRequest = function (tenantRequestDto) {
        return plainToClass(TenantUpdateRequest, tenantRequestDto);
    };
    TenantRequestMapperService.prototype.tenantSearchCriteriaDtoToTenantSearchCriteria = function (tenantSearchCriteriaDto) {
        return plainToClass(TenantSearchCriteria, tenantSearchCriteriaDto);
    };
    TenantRequestMapperService.prototype.tenantToTenantUpdateRequest = function (tenant) {
        var tenantUpdateRequest = new TenantUpdateRequest();
        tenantUpdateRequest.name = tenant.name;
        tenantUpdateRequest.roles = tenant.tenant.roles;
        tenantUpdateRequest.description = tenant.description;
        tenantUpdateRequest.providers = tenant.providers;
        if (tenant.properties.find(function (property) { return property.name === 'sensorTypeThreshold'; })) {
            tenantUpdateRequest.sensorTypeThreshold = tenant.properties.find(function (property) { return property.name === 'sensorTypeThreshold'; }).value;
        }
        if (tenant.properties.find(function (property) { return property.name === 'mobileSensorCheckInterval'; })) {
            tenantUpdateRequest.mobileSensorCheckInterval = tenant.properties.find(function (property) { return property.name === 'mobileSensorCheckInterval'; }).value;
        }
        if (tenant.properties.find(function (property) { return property.name === 'stationarySensorCheckInterval'; })) {
            tenantUpdateRequest.stationarySensorCheckInterval = tenant.properties.find(function (property) { return property.name === 'stationarySensorCheckInterval'; }).value;
        }
        //tenantUpdateRequest.sensorTypeThreshold = tenant.sensorTypeThreshold;
        return tenantUpdateRequest;
    };
    TenantRequestMapperService.prototype.assetDtoToTenantUpdateRequestDto = function (tenant) {
        var tenantUpdateRequestDto = new TenantUpdateRequestDto();
        tenantUpdateRequestDto.name = tenant.name;
        tenantUpdateRequestDto.roles = tenant.tenant.roles;
        tenantUpdateRequestDto.description = tenant.description;
        tenantUpdateRequestDto.providers = tenant.providers;
        tenantUpdateRequestDto.sensorTypeThreshold = tenant.properties.find(function (property) { return property.name === 'sensorTypeThreshold'; }) ? tenant.properties.find(function (property) { return property.name === 'sensorTypeThreshold'; }).value : undefined;
        tenantUpdateRequestDto.mobileSensorCheckInterval = tenant.properties.find(function (property) { return property.name === 'mobileSensorCheckInterval'; }) ? tenant.properties.find(function (property) { return property.name === 'mobileSensorCheckInterval'; }).value : undefined;
        tenantUpdateRequestDto.stationarySensorCheckInterval = tenant.properties.find(function (property) { return property.name === 'stationarySensorCheckInterval'; }) ? tenant.properties.find(function (property) { return property.name === 'stationarySensorCheckInterval'; }).value : undefined;
        return tenantUpdateRequestDto;
    };
    TenantRequestMapperService.prototype.assetDtoToUserGroupUpdateDto = function (tenant) {
        var userGroupUpdateDto = new UserGroupUpdateDto();
        userGroupUpdateDto.assignedUserGroups = this.userGroupMapperService.groupsDtoToGroups(tenant.assignedUserGroups);
        return userGroupUpdateDto;
    };
    TenantRequestMapperService.prototype.tenantJobDtoToTenantJob = function (tenantJobDto) {
        /*
        const tenantJob: TenantJob = new TenantJob();
        tenantJob.tenantName = tenantJobDto.tenantName;
        tenantJob.jobType = tenantJobDto.jobType;
        tenantJob.interval = tenantJobDto.interval;
        tenantJob.nextExecuteTime = tenantJobDto.nextExecuteTime;
        return tenantJob;
        */
        return plainToClass(TenantJob, tenantJobDto);
    };
    TenantRequestMapperService.prototype.tenantJobDtosToTenantJobs = function (tenantJobDtos) {
        var _this = this;
        return _.map(tenantJobDtos, function (tenantJobDto) { return _this.tenantJobDtoToTenantJob(tenantJobDto); });
    };
    TenantRequestMapperService.ngInjectableDef = i0.defineInjectable({ factory: function TenantRequestMapperService_Factory() { return new TenantRequestMapperService(i0.inject(i1.UserGroupMapperService)); }, token: TenantRequestMapperService, providedIn: "root" });
    return TenantRequestMapperService;
}());
export { TenantRequestMapperService };
