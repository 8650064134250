import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

import {CustomEncoderForHttpParameter} from '../../../classes/custom-encoder-for-http-parameter';
import {SharedService} from '../../shared/services/shared.service';
import {ThresholdViolationStatistics} from '../../threshold/api/threshold-violation-statistics';
import {ThresholdViolationStatisticsDto} from '../../threshold/dto/threshold-violation-statistics-dto';
import {ThresholdMapperService} from '../../threshold/mapper/threshold-mapper.service';
import {StatisticsSearchCriteriaDto} from '../dto/statistics-search-criteria-dto';
import {StatisticsMapperService} from '../mapper/statistics-mapper.service';

@Injectable()
export class StatisticsApiService {
  /**
   * @param http
   * @param shared
   * @param statisticsMapperService
   * @param thresholdMapperService
   */
  public constructor(
    private http: HttpClient,
    private shared: SharedService,
    private statisticsMapperService: StatisticsMapperService,
    private thresholdMapperService: ThresholdMapperService,
  ) {
  }

  /**
   * Gets thresholdViolationStatistics matching the search criteria.
   * @param searchCriteria
   * @returns
   */
  public getThresholdViolations(searchCriteria?: StatisticsSearchCriteriaDto): Observable<ThresholdViolationStatisticsDto> {
    const options: { params: HttpParams } = {params: new HttpParams({encoder: new CustomEncoderForHttpParameter()})};
    if (searchCriteria) {
      options.params = this.statisticsMapperService.statisticsSearchCriteriaDtoToStatisticsSearchCriteria(searchCriteria).getRequestQueryParams();
    }

    return this.http.get<ThresholdViolationStatistics>(
      this.shared.buildApiUrl('statistics', 'thresholdViolations'),
      options,
    ).pipe(
      map((thresholdViolationStatistics: ThresholdViolationStatistics) =>
        this.thresholdMapperService.thresholdViolationStatisticsToThresholdViolationStatisticsDto(thresholdViolationStatistics)),
    );
  }
}
