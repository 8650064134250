import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { MatCheckboxModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ModalGalleryModule } from '@ks89/angular-modal-gallery';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import 'mousetrap';
import 'reflect-metadata';

import { PhoenixSnackbarModule } from '../@phoenix/components/phoenix-snackbar/phoenix-snackbar.module';
import { AuthApiModule } from '../@phoenix/gapicon/auth/auth-api.module';
import { TenantInterceptorModule } from '../@phoenix/gapicon/tenant-interceptor/tenant-interceptor.module';
import { TenantApiModule } from '../@phoenix/gapicon/tenant/tenant-api.module';
import { UserApiModule } from '../@phoenix/gapicon/user/user-api.module';
import { PhoenixSharedModule } from '../@phoenix/phoenix-shared.module';
import { PhoenixAssetBranchSelectorDialogModule } from '../@phoenix/templates/phoenix-dialog/phoenix-asset-branchselector-dialog/phoenix-asset-branchselector-dialog.module';
import { PhoenixPhoenixCloneConfirmationDialogModule } from '../@phoenix/templates/phoenix-dialog/phoenix-clone-confirmation-dialog/phoenix-clone-confirmation-dialog.module';
import { PhoenixTenantselectorDialogModule } from '../@phoenix/templates/phoenix-dialog/phoenix-tenantselector-dialog/phoenix-tenantselector-dialog.module';
import { PhoenixMeasurementPointSelectorDialogModule } from '../@phoenix/templates/phoenix-dialog/phoenix-threshold-to-measurementpoints-dialog/phoenix-measurementpointelector-dialog.module';

import { AppComponent } from './app.component';
import { fuseConfig } from './fuse-config';
import { CanActivateGuard } from './guards/can-activate.guard';
import { CanLoadGuard } from './guards/can-load.guard';
import { PermissionService } from './guards/permission.service';
import { ErrorInterceptor } from './interceptors/ErrorInterceptor';
import { LoadingInterceptor } from './interceptors/LoadingInterceptor';
import { LayoutModule } from './layout/layout.module';
import { CheckpointsModule } from './main/checkpoints/checkpoints.module';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { DevicemanagementModule } from './main/devicemanagement/devicemanagement.module';
import { GroupmanagementTenantModule } from './main/groupmanagement/groupmanagement-tenant/groupmanagement-tenant.module';
import { HelpModule } from './main/help/help.module';
import { LoginModule } from './main/login/login.module';
import { MeasurementTypeModule } from './main/measurement-type/measurement-type.module';
import { NotificationsModule } from './main/notifications/notifications.module';
import { SensormanagementModule } from './main/sensormanagement/sensormanagement.module';
import { SetupModule } from './main/setup/setup.module';
import { TasksModule } from './main/tasks/tasks.module';
import { TenantmanagementModule } from './main/tenantmanagement/tenantmanagement.module';
import { UsermanagementEnvironmentModule } from './main/usermanagement/usermanagement-environment/usermanagement-environment.module';
import { UsermanagementTenantModule } from './main/usermanagement/usermanagement-tenant/usermanagement-tenant.module';
import { NavigationService } from './navigation/navigation.service';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as highstock from 'highcharts/modules/stock.src';
import { ThresholdmanagementModule } from './main/thresholdmanagement/thresholdmanagement.module';
import { TenantJobManagementModule } from './main/tenant-job-management/tenant-job-management.module';

const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: 'dashboard',

  }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [highstock]
    },

    CanLoadGuard,
    CanActivateGuard,
    NavigationService,
    PermissionService,
  ],
  imports: [
    TenantInterceptorModule,
    AuthApiModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,

    // Phoenix Modules
    LoginModule,
    DashboardModule,
    CheckpointsModule,
    NotificationsModule,
    ThresholdmanagementModule,
    UsermanagementTenantModule,
    GroupmanagementTenantModule,
    HelpModule,
    SetupModule,
    MeasurementTypeModule,
    DevicemanagementModule,
    SensormanagementModule,
    TenantmanagementModule,
    TenantJobManagementModule,
    UserApiModule,
    TenantApiModule,
    PhoenixSnackbarModule,
    PhoenixSharedModule,
    PhoenixTenantselectorDialogModule,
    PhoenixPhoenixCloneConfirmationDialogModule,
    PhoenixMeasurementPointSelectorDialogModule,
    UsermanagementEnvironmentModule,
    TasksModule,
    PhoenixAssetBranchSelectorDialogModule,
    // 3rd party Modules
    ModalGalleryModule.forRoot(),

    ChartModule
  ],
  bootstrap: [
    AppComponent,
  ],
  exports: [],
})
export class AppModule {

}
