import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatBadgeModule, MatButtonModule, MatIconModule, MatTooltipModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixButtonComponent} from './phoenix-button.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
  ],
  declarations: [
    PhoenixButtonComponent,
  ],
  exports: [
    PhoenixButtonComponent,
  ],
})
export class PhoenixButtonModule {
}
