/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-measurement-type-information-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/common";
import * as i5 from "../../../../components/phoenix-dialog-new/phoenix-dialog-new.component.ngfactory";
import * as i6 from "../../../../components/phoenix-dialog-new/phoenix-dialog-new.component";
import * as i7 from "./phoenix-measurement-type-information-dialog.component";
import * as i8 from "@angular/material/dialog";
var styles_PhoenixMeasurementTypeInformationDialogComponent = [i0.styles];
var RenderType_PhoenixMeasurementTypeInformationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixMeasurementTypeInformationDialogComponent, data: {} });
export { RenderType_PhoenixMeasurementTypeInformationDialogComponent as RenderType_PhoenixMeasurementTypeInformationDialogComponent };
function View_PhoenixMeasurementTypeInformationDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "cursor": 0, "text-decoration": 1 }), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, "pointer", "underline"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_3); }); }
export function View_PhoenixMeasurementTypeInformationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "phoenix-dialog-new", [], null, null, null, i5.View_PhoenixDialogNewComponent_0, i5.RenderType_PhoenixDialogNewComponent)), i1.ɵdid(1, 114688, null, 0, i6.PhoenixDialogNewComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], buttons: [2, "buttons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "mat-list", [["class", "mat-list"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(3, 49152, null, 0, i3.MatList, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixMeasurementTypeInformationDialogComponent_1)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; var currVal_1 = _co.data.subtitle; var currVal_2 = _co.data.buttons; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.data.links; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_PhoenixMeasurementTypeInformationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-measurement-type-information-dialog", [], null, null, null, View_PhoenixMeasurementTypeInformationDialogComponent_0, RenderType_PhoenixMeasurementTypeInformationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.PhoenixMeasurementTypeInformationDialogComponent, [i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixMeasurementTypeInformationDialogComponentNgFactory = i1.ɵccf("phoenix-measurement-type-information-dialog", i7.PhoenixMeasurementTypeInformationDialogComponent, View_PhoenixMeasurementTypeInformationDialogComponent_Host_0, {}, {}, []);
export { PhoenixMeasurementTypeInformationDialogComponentNgFactory as PhoenixMeasurementTypeInformationDialogComponentNgFactory };
