var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteria } from './search-criteria';
var SearchCriteriaPageable = /** @class */ (function (_super) {
    __extends(SearchCriteriaPageable, _super);
    function SearchCriteriaPageable(searchCriteriaPageable) {
        var _this = _super.call(this, searchCriteriaPageable) || this;
        _this.size = 5000;
        _this.page = 0;
        Object.assign(_this, searchCriteriaPageable);
        return _this;
    }
    return SearchCriteriaPageable;
}(SearchCriteria));
export { SearchCriteriaPageable };
