import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

import {Property} from '../api/property';
import {PropertyDto} from '../dto/property-dto';

@Injectable({
  providedIn: 'root',
})
export class PropertyMapperService {

  public constructor() {
  }

  public propertyToPropertyDto(property: Property): PropertyDto {
    return plainToClass(PropertyDto, property);
  }

  public propertyDtoToProperty(propertyDto: PropertyDto): Property {
    return plainToClass(Property, propertyDto);
  }

  public propertyArrayToPropertyDtoArray(properties: Property[]): PropertyDto[] {
    return _.map(properties, (property: Property) => this.propertyToPropertyDto(property));
  }

  public propertyDtoArrayToPropertyArray(propertyDtos: PropertyDto[]): Property[] {
    return _.map(propertyDtos, (propertyDto: PropertyDto) => this.propertyDtoToProperty(propertyDto));
  }
}
