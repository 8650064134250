import {format, isValid} from 'date-fns';

import {JsonExportable} from '../../shared/classes/json-exportable';

export class AccountInfo implements JsonExportable {
  public blocked: boolean = false;
  public blockedUntil: Date = undefined;

  public constructor(accountInfo?: Partial<AccountInfo>) {
    Object.assign(this, accountInfo);
  }

  public toJSON(): Object {
    return {
      blocked: this.blocked,
      blockedUntil: (this.blockedUntil && isValid(this.blockedUntil)) ? format(this.blockedUntil) : undefined,
    };
  }
}
