import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable, Observer } from 'rxjs';
import { EmptyObservable } from 'rxjs-compat/observable/EmptyObservable';
import { expand } from 'rxjs/operators';
import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { ValueTypeDto } from '../../measurement-type/dto/value-type-dto.enum';
var APIURL = '/api/';
var AUTHURL = '/auth/oauth/';
var SharedService = /** @class */ (function () {
    /**
     *
     */
    function SharedService(http) {
        this.http = http;
    }
    /**
     * String builder for api URL
     * @param parts
     * @returns
     */
    SharedService.prototype.buildApiUrl = function () {
        var parts = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parts[_i] = arguments[_i];
        }
        return APIURL + parts.join('/');
    };
    /**
     * String builder for authentication URL
     * @param parts
     * @returns
     */
    SharedService.prototype.buildAuthUrl = function () {
        var parts = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parts[_i] = arguments[_i];
        }
        return AUTHURL + parts.join('/');
    };
    SharedService.prototype.getBooleanMeasurementValueTypeDtoKeys = function (measurementType) {
        return _.map(this.getBooleanMeasurementValueTypeDtos(measurementType), 'key');
    };
    SharedService.prototype.getBooleanMeasurementValueTypeDtos = function (measurementType) {
        return _.filter(_.get(measurementType, 'valueTypes', undefined), function (measurementValueTypeDto) {
            return measurementValueTypeDto.type === ValueTypeDto.BOOLEAN || measurementValueTypeDto.type === ValueTypeDto.NUMBER
                || measurementValueTypeDto.type === ValueTypeDto.DECIMAL;
        });
    };
    SharedService.prototype.httpGet = function (url, searchCriteria) {
        var _this = this;
        var options = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
        options.params = searchCriteria.getRequestQueryParams();
        return new Observable(function (observer) {
            _this.http.get(url, options)
                .pipe(expand(function (response) {
                observer.complete();
                return new EmptyObservable();
            }))
                .subscribe(function (data) {
                observer.next(data);
            }, function (error) {
                observer.error(error);
            });
        });
    };
    SharedService.prototype.httpGetWithPagination = function (url, searchCriteria, withFurtherPages, 
    // tslint:disable-next-line
    lengthFunction) {
        var _this = this;
        // tslint:disable-next-line
        if (lengthFunction === void 0) { lengthFunction = function (arr) { return arr.length; }; }
        var options = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
        searchCriteria.page = searchCriteria.page ? searchCriteria.page : 0;
        options.params = searchCriteria.getRequestQueryParams();
        return new Observable(function (observer) {
            _this.http.get(url, options)
                .pipe(expand(function (response) {
                if (withFurtherPages && lengthFunction(response) === searchCriteria.size) {
                    searchCriteria.page += 1;
                    options.params = searchCriteria ? searchCriteria.getRequestQueryParams() : undefined;
                    return _this.http.get(url, options);
                }
                else {
                    observer.complete();
                    return new EmptyObservable();
                }
            }))
                .subscribe(function (data) {
                observer.next(data);
            }, function (error) {
                observer.error(error);
            });
        });
    };
    return SharedService;
}());
export { SharedService };
