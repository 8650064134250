import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicDropdownComponent = /** @class */ (function () {
    function PhoenixDynamicDropdownComponent() {
    }
    PhoenixDynamicDropdownComponent.prototype.ngOnInit = function () {
        if (this.phoenixFormControl.disabled) {
            this.form.get(this.phoenixFormControl.key).disable();
        }
    };
    PhoenixDynamicDropdownComponent.prototype.getElementLabel = function (key) {
        var element = _.find(this.phoenixFormControl.options['options'], function (option) {
            return option.key === key;
        });
        return element ? element.label : '';
    };
    PhoenixDynamicDropdownComponent.prototype.selectAllForTheGivenDropdownObject = function (form, formControlKey, dropdownValues) {
        form.get(formControlKey).setValue(_.map(dropdownValues, 'key'));
    };
    return PhoenixDynamicDropdownComponent;
}());
export { PhoenixDynamicDropdownComponent };
