import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { PhoenixDialogResult } from '../../../components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../../components/phoenix-snackbar/phoenix-snackbar.service';
import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';
import { AssetApiService } from '../../../gapicon/asset/services/asset-api.service';
import { PhoenixCommunicationSubject } from '../../../services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixWizardHelperService } from '../../../services/phoenix-wizards/phoenix-wizard-helper.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixSensormanagementDeleteDialogService {
  public constructor(private dialog: MatDialog,
    private assetApiService: AssetApiService,
    private communicationService: PhoenixCommunicationService,
    private wizardHelper: PhoenixWizardHelperService,
    private snackbarService: PhoenixSnackbarService,
    private translateService: TranslateService,
  ) {
  }

  public openDeleteSensorDialog(assetDto: AssetDto): void {
    const randomString: string = this.wizardHelper.getRandomString();

    this.dialog.open(PhoenixDialogComponent, {
      width: this.wizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.SENSOR.DELETE.TITLE',
        subHeadline: assetDto.sensor.sensorId,
        buttons: {
          cancel: true,
          delete: 'WIZARDS.SENSOR.DELETE.SUBMIT',
        },
        translationParams: {
          deleteCode: randomString,
        },
        color: 'warn',
        formControls: [
          _.concat(
            this.createTextFormControls(),
            new PhoenixDynamicFormControl<string>(
              'delete-code',
              PhoenixDynamicFormControlType.Text,
              'WIZARDS.SENSOR.DELETE.DELETECODE',
              undefined,
              undefined,
              randomString,
            ),
            new PhoenixDynamicFormControl<string>(
              'delete',
              PhoenixDynamicFormControlType.TextBox,
              'WIZARDS.SENSOR.DELETE.DELETECODEINPUT',
              true,
              { hint: 'WIZARDS.SENSOR.DELETE.DELETECODEINPUTHINT' },
              undefined,
              undefined,
              [Validators.required, Validators.pattern(randomString)],
            ),
          ),
        ],
        onClose: (r: PhoenixDialogResult): Promise<void> => this.onDeleteCloseCallback(r, assetDto),
      },
    },
    );
  }

  private async onDeleteCloseCallback(dialogResult: PhoenixDialogResult, assetDto: AssetDto): Promise<void> {
    if (dialogResult.result === 'delete') {
      try {
        await this.assetApiService.deleteAsset(assetDto).toPromise();
        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.SENSOR.DELETE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }
      this.communicationService.emit(PhoenixCommunicationSubject.ReloadSensorPage);
    }
    dialogResult.dialogRef.close();
  }

  private createTextFormControls(): PhoenixDynamicFormControl<string>[] {
    const hintTextOptions: { classes: { [key: string]: boolean } } = {
      classes: {
        'font-size-12': true,
        'hint-text': true,
        'primary-300-fg': true,
        'mt-4': true,
        'mb-4': true,
      },
    };

    const formControls: PhoenixDynamicFormControl<string>[] = [];

    const textToDisplay: string[] = [
      'WIZARDS.SENSOR.DELETE.LINE1',
      'WIZARDS.SENSOR.DELETE.LINE2',
      'WIZARDS.SENSOR.DELETE.CONFIRMATION',
    ];

    _.forEach(textToDisplay, (text: string) => {
      formControls.push(
        new PhoenixDynamicFormControl<string>(
          'text',
          PhoenixDynamicFormControlType.Text,
          undefined,
          false,
          hintTextOptions,
          text,
        ),
      );
    });
    return formControls;
  }
}
