import { HttpClient } from '@angular/common/http';
import { map, reduce } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { UserSearchCriteriaDto } from '../dto/user-search-criteria-dto';
import { UserMapperService } from '../mapper/user-mapper.service';
var UserApiService = /** @class */ (function () {
    /**
     * @param http
     * @param sharedService
     * @param userMapperService
     */
    function UserApiService(http, sharedService, userMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.userMapperService = userMapperService;
        this._users = 'users';
        this._user = 'user';
    }
    /**
     * Gets all users matching the search criteria.
     * @param userSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    UserApiService.prototype.getUsers = function (userSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (userSearchCriteriaDto === void 0) { userSearchCriteriaDto = new UserSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._users), this.userMapperService.userSearchCriteriaDtoToUserSearchCriteria(userSearchCriteriaDto), withFurtherPages).pipe(map(function (users) { return _this.userMapperService.userArrayToUserDtoArray(users); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Get users in raw format from API User[](not UserDto[])
     */
    UserApiService.prototype.getAssignedUsers = function () {
        return this.http.get(this.sharedService.buildApiUrl(this._users));
    };
    /**
     * Creates a user by given userDto.
     * @returns
     * @param userDto
     */
    UserApiService.prototype.createUser = function (userDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._users, this._user), this.userMapperService.userDtoToUser(userDto)).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    /**
     * Deletes a user by given userDto.
     * @returns
     * @param userDto
     */
    UserApiService.prototype.deleteUser = function (userDto) {
        return this.http.delete(this.sharedService.buildApiUrl(this._users, this._user, this.userMapperService.userDtoToUser(userDto).id.toString()));
    };
    /**
     * Deletes a user by given id.
     * @param id
     * @returns
     */
    UserApiService.prototype.deleteUserById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._users, this._user, id.toString()));
    };
    /**
     * Gets a user by given id.
     * @param id
     * @returns
     */
    UserApiService.prototype.getUserById = function (id) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._users, this._user, id)).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    /**
     * Gets a user by given userDto.
     * @returns
     * @param userDto
     */
    UserApiService.prototype.getUser = function (userDto) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._users, this._user, this.userMapperService.userDtoToUser(userDto).id)).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    /**
     * Updates a user by given userDto.
     * @returns
     * @param userDto
     */
    UserApiService.prototype.updateUser = function (userDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._users, this._user, this.userMapperService.userDtoToUser(userDto).id), this.userMapperService.userDtoToUser(userDto)).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    return UserApiService;
}());
export { UserApiService };
