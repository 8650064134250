import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';

import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

@Component({
  selector: 'phoenix-task-config-dialog-once',
  templateUrl: './phoenix-task-config-dialog-once.component.html',
  styleUrls: ['./phoenix-task-config-dialog-once.component.scss'],
})
export class PhoenixTaskConfigDialogOnceComponent implements OnInit {
  public today: Date = new Date();
  public group: FormGroup = new FormGroup({
    start: new FormControl(this.today),
    end: new FormControl(),
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixTaskConfigDialog,
  ) {
  }

  public getStartDate(): Date {
    return this.group.get('start').value;
  }

  public ngOnInit(): void {
  }

  get assignedUserGroupIds(): Date {
    return this.data.formGroup.get('assignedUserGroupIds').value;
  }

  get userGroups(): UserGroupDto[] {
    return this.data.usergroups.sort((a, b) => a.name.localeCompare(b.name));
  }

}
