var SensorType = /** @class */ (function () {
    function SensorType(sensorType) {
        this.id = undefined;
        this.configurations = undefined;
        this.registrationParams = undefined;
        this.measurementTypes = undefined;
        this.name = undefined;
        this.key = undefined;
        Object.assign(this, sensorType);
    }
    SensorType.prototype.toJSON = function () {
        return {
            id: this.id,
            configurations: this.configurations,
            registrationParams: this.registrationParams,
            measurementTypes: this.measurementTypes,
            name: this.name,
            key: this.key,
        };
    };
    return SensorType;
}());
export { SensorType };
