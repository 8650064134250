/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-measurementpoint-selector-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../components/phoenix-dialog-new/phoenix-dialog-new.component.ngfactory";
import * as i6 from "../../../components/phoenix-dialog-new/phoenix-dialog-new.component";
import * as i7 from "./phoenix-measurementpoint-selector-dialog.component";
import * as i8 from "@angular/material/dialog";
var styles_PhoenixMeasurementPointSelectorDialogComponent = [i0.styles];
var RenderType_PhoenixMeasurementPointSelectorDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixMeasurementPointSelectorDialogComponent, data: {} });
export { RenderType_PhoenixMeasurementPointSelectorDialogComponent as RenderType_PhoenixMeasurementPointSelectorDialogComponent };
function View_PhoenixMeasurementPointSelectorDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mt-16 mb-16"], ["fxLayout", "column"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectMeasurementPoint(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "cursor": 0, "text-decoration": 1 }), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _ck(_v, 6, 0, "pointer", "underline"); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_3); }); }
export function View_PhoenixMeasurementPointSelectorDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "phoenix-dialog-new", [], null, null, null, i5.View_PhoenixDialogNewComponent_0, i5.RenderType_PhoenixDialogNewComponent)), i1.ɵdid(1, 114688, null, 0, i6.PhoenixDialogNewComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], buttons: [2, "buttons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "mt-24"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixMeasurementPointSelectorDialogComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; var currVal_1 = _co.data.subtitle; var currVal_2 = _co.buttons; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.data.measurementPoints; _ck(_v, 4, 0, currVal_3); }, null); }
export function View_PhoenixMeasurementPointSelectorDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-measurementpoint-selector-dialog", [], null, null, null, View_PhoenixMeasurementPointSelectorDialogComponent_0, RenderType_PhoenixMeasurementPointSelectorDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.PhoenixMeasurementPointSelectorDialogComponent, [i8.MAT_DIALOG_DATA, i8.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixMeasurementPointSelectorDialogComponentNgFactory = i1.ɵccf("phoenix-measurementpoint-selector-dialog", i7.PhoenixMeasurementPointSelectorDialogComponent, View_PhoenixMeasurementPointSelectorDialogComponent_Host_0, {}, {}, []);
export { PhoenixMeasurementPointSelectorDialogComponentNgFactory as PhoenixMeasurementPointSelectorDialogComponentNgFactory };
