import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';

import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { TokenRequestResponse } from '../api/token-request-response';
import { AuthInfoDto } from '../dto/auth-info-dto';

@Injectable()
export class AuthApiService {
  private static auth: AuthInfoDto = undefined;
  private static clientSecret: string = 'etFNu55RoCniu4UefUd3';
  private static clientId: string = 'mcf_fe';
  private static scope: string = 'mcf';

  /**
   * Constructor
   * @param http
   * @param sharedService
   * @param userMapperService
   */
  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userMapperService: UserMapperService,
  ) {
  }

  /**
   * logging in a user by tenant, email and passwords.
   * @param email
   * @param password
   * @returns Resolves with true if login was successful
   */
  public login(email: string, password: string): Observable<boolean> {
    return Observable.create((subscriber: Subscriber<boolean>) => {
      const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      const data: HttpParams = new HttpParams({ encoder: new CustomEncoderForHttpParameter() })
        .set('grant_type', 'password')
        .set('username', email)
        .set('password', password)
        .set('client_id', AuthApiService.clientId)
        .set('client_secret', AuthApiService.clientSecret)
        .set('scope', AuthApiService.scope);

      this.http.post<TokenRequestResponse>(
        this.sharedService.buildAuthUrl('token'),
        data,
        { headers: headers },
      ).subscribe((response: TokenRequestResponse) => {
        AuthApiService.auth = new AuthInfoDto(this.userMapperService);
        AuthApiService.auth.applyTokenRequestResponse(response);
        // save user Data to local storage
        localStorage.setItem('userData', JSON.stringify(response));
        subscriber.next(true);
        subscriber.complete();
        // tslint:disable-next-line
      }, (error) => {
        subscriber.next(false);
        subscriber.error(error);
      });
    });
  }

  /**
   * Automatic logging in a user by tenant, email and passwords based on user data from
   * local storage when user refresh the browser page
   * @returns Resolves with true if user was logged in before refershing page or not
   */
  public autoLogin(): boolean {
    // getting user Data from local storage
    const userData: TokenRequestResponse = JSON.parse(localStorage.getItem('userData'));
    const currentTenants: string[] = JSON.parse(localStorage.getItem('selectedTenants'));
    const currentAssetBranch: string[] = JSON.parse(localStorage.getItem('selectedAssetBranches'));
    // getting tenant data from local storage
    if (!userData || !currentTenants || currentTenants.length == 0 || !currentAssetBranch || currentAssetBranch.length == 0) {
      return false;
    }
    // apply user data for request
    userData.autoLogin = true;
    AuthApiService.auth = new AuthInfoDto(this.userMapperService);
    AuthApiService.auth.applyTokenRequestResponse(userData);
    return true;
  }

  /**
   * Requesting new access_token with stored refresh_token. User has to be logged in first.
   * @returns Returns observable which resolves with true if refreshing token was successful
   */
  public refresh(): Observable<boolean> {
    return Observable.create((subscriber: Subscriber<boolean>) => {
      if (!AuthApiService.auth) {
        subscriber.error('User is not logged in');
        subscriber.complete();
      } else {
        const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const data: HttpParams = new HttpParams({ encoder: new CustomEncoderForHttpParameter() })
          .set('grant_type', 'refresh_token')
          .set('client_id', AuthApiService.clientId)
          .set('client_secret', AuthApiService.clientSecret)
          .set('scope', AuthApiService.scope)
          .set('refresh_token', AuthApiService.auth.refresh_token);

        this.http.post<TokenRequestResponse>(
          this.sharedService.buildAuthUrl('token'),
          data,
          { headers: headers },
        ).subscribe((response: TokenRequestResponse) => {
          AuthApiService.auth.applyTokenRequestResponse(response);
          localStorage.setItem('userData', JSON.stringify(response));
          subscriber.next(true);
          subscriber.complete();
          // tslint:disable-next-line
        }, error => {
          this.logout();
          subscriber.next(false);
          subscriber.error(error);
        });
      }
    });
  }

  /**
   * Returns current auth info
   * @returns
   */
  public getAuth(): AuthInfoDto {
    return AuthApiService.auth;
  }

  /**
   * Logout current user
   */
  public logout(): void {
    // delete data from local storage when user logout
    localStorage.removeItem('userData');
    localStorage.removeItem('selectedTenants');
    localStorage.removeItem('selectedAssetBranches');
    localStorage.removeItem('userSelection');
    localStorage.removeItem('selectedLanguage');
    localStorage.removeItem('availableTenants');
    AuthApiService.auth = null;

  }

  /**
   * Sets tenant for all requests
   * @param tenants
   */
  public setCurrentTenants(tenants: string[]): void {
    // tenants added in header
    AuthApiService.auth = new AuthInfoDto(this.userMapperService, AuthApiService.auth);
    AuthApiService.auth.currentTenants = tenants;
  }

  public setGroupAssetPairs(groupApiPairs: string[]): void {
    AuthApiService.auth.groupAssetPairs = groupApiPairs;
  }

  public getTenantsIdsFromGroupRole(roleArray: string[]): string[] {
    if (roleArray) {
      return roleArray.map((item: string) => {
        const items: string[] = item.split('.');
        return items.length === 2 ? items[1] : item;
      });
    } else {
      return [];
    }
  }
}


