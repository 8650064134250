import { OnInit } from '@angular/core';
import { PhoenixMeasurementTypeInformationDialog } from '../classes/phoenix-measurement-type-information-dialog';
var PhoenixMeasurementTypeInformationDialogComponent = /** @class */ (function () {
    function PhoenixMeasurementTypeInformationDialogComponent(data) {
        this.data = data;
    }
    PhoenixMeasurementTypeInformationDialogComponent.prototype.ngOnInit = function () {
    };
    return PhoenixMeasurementTypeInformationDialogComponent;
}());
export { PhoenixMeasurementTypeInformationDialogComponent };
