import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {PhoenixDialogButton} from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import {AssetDto} from '../../../gapicon/asset/dto/asset-dto';

import {
  PhoenixMeasurementPointSelectorDialogData,
} from './classes/PhoenixMeasurementPointSelectorDialogData';

@Component({
  selector: 'phoenix-measurementpoint-selector-dialog',
  templateUrl: './phoenix-measurementpoint-selector-dialog.component.html',
  styleUrls: ['./phoenix-measurementpoint-selector-dialog.component.scss'],
})
export class PhoenixMeasurementPointSelectorDialogComponent implements OnInit {

  public buttons: PhoenixDialogButton[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixMeasurementPointSelectorDialogData,
    private dialogRef: MatDialogRef<PhoenixMeasurementPointSelectorDialogComponent>,
  ) {
  }

  public ngOnInit(): void {

    if (this.data.cancelButton) {
      this.buttons = [
        new PhoenixDialogButton({
          label: 'WIZARDS.MEASUREMENTPOINT.CLOSE',
          click: (): void => this.dialogRef.close(undefined),
        }),
      ];
    }
  }

  public selectMeasurementPoint(measurementPoint: AssetDto): void {
    this.dialogRef.close([measurementPoint]);
  }

}
