import { HttpClient, HttpParams } from '@angular/common/http';
import { EmptyObservable } from 'rxjs-compat/observable/EmptyObservable';
import { Observable } from 'rxjs/Observable';
import { expand } from 'rxjs/operators';
import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';
import { ThresholdSearchCriteriaDto } from '../dto/threshold-search-criteria-dto';
import { ThresholdMapperService } from '../mapper/threshold-mapper.service';
var ThresholdApiService = /** @class */ (function () {
    /**
     * @param http
     * @param sharedService
     * @param thresholdMapperService
     */
    function ThresholdApiService(http, sharedService, thresholdMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.thresholdMapperService = thresholdMapperService;
        this._threshold = 'threshold';
        this._thresholds = 'thresholds';
    }
    /**
     * Gets all thresholds matching the search criteria.
     * @param thresholdSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    ThresholdApiService.prototype.getThresholds = function (thresholdSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (thresholdSearchCriteriaDto === void 0) { thresholdSearchCriteriaDto = new ThresholdSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return new Observable(function (observer) {
            var filter = _this.thresholdMapperService.thresholdSearchCriteriaDtoToThresholdSearchCriteria(thresholdSearchCriteriaDto);
            var url = _this.sharedService.buildApiUrl(_this._thresholds);
            var options = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
            options.params = filter.getRequestQueryParams();
            _this.http.get(url, options)
                .pipe(expand(function (threshold) {
                if (withFurtherPages && threshold.length === filter.size) {
                    filter.page += 1;
                    options.params = filter.getRequestQueryParams();
                    return _this.http.get(url, options);
                }
                else {
                    observer.complete();
                    return new EmptyObservable();
                }
            }))
                .subscribe(function (thresholds) {
                observer.next(_this.thresholdMapperService.thresholdArrayToThresholdDtoArray(thresholds));
            });
        });
    };
    /**
     * Gets a threshold by given id.
     * @param id
     * @returns
     */
    ThresholdApiService.prototype.getThresholdById = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.get(_this.sharedService.buildApiUrl(_this._thresholds, _this._threshold, id.toString()))
                .subscribe(function (threshold) {
                observer.next(_this.thresholdMapperService.thresholdToThresholdDto(threshold));
                observer.complete();
            });
        });
    };
    /**
     * Gets a threshold by given thresholdDto.
     * @param thresholdDto
     * @returns
     */
    ThresholdApiService.prototype.getThreshold = function (thresholdDto) {
        var _this = this;
        var getThreshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
        return new Observable(function (observer) {
            _this.http.get(_this.sharedService.buildApiUrl(_this._thresholds, _this._threshold, getThreshold.id.toString()))
                .subscribe(function (threshold) {
                observer.next(_this.thresholdMapperService.thresholdToThresholdDto(threshold));
                observer.complete();
            });
        });
    };
    /**
     * Creates a threshold by given thresholdDto.
     * @returns
     * @param thresholdDto
     */
    ThresholdApiService.prototype.createThreshold = function (thresholdDto) {
        var _this = this;
        var createThreshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
        return new Observable(function (observer) {
            _this.http.post(_this.sharedService.buildApiUrl(_this._thresholds, _this._threshold), createThreshold)
                .subscribe(function (threshold) {
                observer.next(_this.thresholdMapperService.thresholdToThresholdDto(threshold));
                observer.complete();
            });
        });
    };
    /**
     * Deletes a threshold by given thresholdDto.
     * @returns
     * @param thresholdDto
     */
    ThresholdApiService.prototype.deleteThreshold = function (thresholdDto) {
        var deleteThreshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
        return this.http.delete(this.sharedService.buildApiUrl(this._thresholds, this._threshold, deleteThreshold.id.toString()));
    };
    /**
     * Deletes a threshold by given id.
     * @param id
     * @returns
     */
    ThresholdApiService.prototype.deleteThresholdById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._thresholds, this._threshold, id.toString()));
    };
    /**
     * Updates a threshold by given thresholdDto.
     * @returns
     * @param thresholdDto
     */
    ThresholdApiService.prototype.updateThreshold = function (thresholdDto) {
        var _this = this;
        return new Observable(function (observer) {
            var updateThreshold = _this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
            _this.http.put(_this.sharedService.buildApiUrl(_this._thresholds, _this._threshold, updateThreshold.id.toString()), updateThreshold)
                .subscribe(function (threshold) {
                observer.next(_this.thresholdMapperService.thresholdToThresholdDto(threshold));
                observer.complete();
            });
        });
    };
    return ThresholdApiService;
}());
export { ThresholdApiService };
