var NotificationConfigDto = /** @class */ (function () {
    function NotificationConfigDto(notificationConfigDto) {
        this.id = undefined;
        this.name = undefined;
        this.notificationType = undefined;
        this.measurementType = undefined;
        this.expression = '';
        Object.assign(this, notificationConfigDto);
    }
    return NotificationConfigDto;
}());
export { NotificationConfigDto };
