import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, reduce } from 'rxjs/operators';

import { SharedService } from '../../shared/services/shared.service';
import { Alarm } from '../api/alarm';
import { AlarmDto } from '../dto/alarm-dto';
import { AlarmSearchCriteriaDto } from '../dto/alarm-search-criteria-dto';
import { AlarmMapperService } from '../mapper/alarm-mapper.service';
import { AlarmCount } from '../api/alarmCount';
import { AlarmCountDto } from '../dto/alarm-count-dto';

@Injectable()
export class AlarmApiService {

  private _alarm: string = 'alarm';
  private _alarms: string = 'alarms';
  private _aggregation: string = 'aggregation';

  /**
   *
   * @param http
   * @param sharedService
   * @param alarmMapperService
   */
  public constructor(private http: HttpClient,
    private sharedService: SharedService,
    private alarmMapperService: AlarmMapperService,
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Gets all alarms matching the search criteria
   * @param alarmSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getAlarms(alarmSearchCriteriaDto: AlarmSearchCriteriaDto = new AlarmSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<AlarmDto[]> {
    return this.sharedService.httpGetWithPagination<Alarm>(
      this.sharedService.buildApiUrl(this._alarms),
      this.alarmMapperService.alarmSearchCriteriaDtoToAlarmSearchCriteria(alarmSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((alarms: Alarm[]) => this.alarmMapperService.alarmArrayToAlarmDtoArray(alarms)),
      reduce((all: AlarmDto[], current: AlarmDto[]) => all.concat(current)),
    );
  }

  /**
   * Gets an alarm by given ID.
   * @param alarmId
   * @returns
   */
  public getAlarmById(alarmId: string): Observable<AlarmDto> {
    return this.http.get<Alarm>(
      this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmId),
    ).pipe(
      map((alarm: Alarm) => this.alarmMapperService.alarmToAlarmDto(alarm)),
    );
  }

  /**
   * Gets an alarm by given alarmDto.
   * @param alarmDto
   * @returns
   */
  public getAlarm(alarmDto: AlarmDto): Observable<AlarmDto> {
    return this.http.get<Alarm>(
      this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmDto.id),
    ).pipe(
      map((alarm: Alarm) => this.alarmMapperService.alarmToAlarmDto(alarm)),
    );
  }

  /**
   * Creates an alarm by given alarmDto.
   * @param alarmDto
   * @returns
   */
  public createAlarm(alarmDto: AlarmDto): Observable<void> {
    return this.http.post<void>(
      this.sharedService.buildApiUrl(this._alarms, this._alarm),
      this.alarmMapperService.alarmDtoToAlarm(alarmDto),
    );
  }

  /**
   * Updates an alarm by given alarmDto.
   * @param alarmDto
   * @returns
   */
  public updateAlarm(alarmDto: AlarmDto): Observable<AlarmDto> {
    return this.http.put<Alarm>(
      this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmDto.id),
      this.alarmMapperService.alarmDtoToAlarm(alarmDto),
    ).pipe(
      map((alarm: Alarm) => this.alarmMapperService.alarmToAlarmDto(alarm)),
    );
  }


  /**
   * Updates alarms by given alarmDto[].
   * @param alarmDtos
   * @returns
  */
  public updateAlarms(alarmDtos: AlarmDto[]): Observable<AlarmDto[]> {
    return this.http.put<Alarm[]>(
      this.sharedService.buildApiUrl(this._alarms),
      this.alarmMapperService.alarmDtoArrayToAlarmArray(alarmDtos),
    ).pipe(
      map((alarms: Alarm[]) => this.alarmMapperService.alarmArrayToAlarmDtoArray(alarms)),
    );
  }


  /**
   * Deletes an alarm by given alarmDto
   * @param alarmDto
   * @returns
   */
  public deleteAlarm(alarmDto: AlarmDto): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmDto.id),
    );
  }

  /**
   * Deletes an alarm by given alarm ID.
   * @param id
   * @returns
   */
  public deleteAlarmById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._alarms, this._alarm, id),
    );
  }

  /**
   * Gets Alarms by a given search criteria
   * @returns
   */
  public aggregateAlarmsForLast12Months(): Observable<AlarmCountDto[]> {
    return this.httpClient.get<AlarmCount>(
      this.sharedService.buildApiUrl(this._alarms, this._aggregation),
    ).pipe(
      map((alarmCounts: AlarmCount[]) => this.alarmMapperService.alarmCountArrayToAlarmCountDtoArray(alarmCounts)),
      reduce((all: AlarmCountDto[], current: AlarmCountDto[]) => all.concat(current)),
    );
  }
}
