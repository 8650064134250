/**
 * Class for Toolbarmenu.
 */
var ToolbarMenu = /** @class */ (function () {
    function ToolbarMenu(id, title, iconClass, iconName, imageClass, imageSrc, spanClass) {
        this.id = id;
        this.title = title;
        this.iconClass = iconClass;
        this.iconName = iconName;
        this.imageClass = imageClass;
        this.imageSrc = imageSrc;
        this.spanClass = spanClass;
    }
    Object.defineProperty(ToolbarMenu.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarMenu.prototype, "title", {
        get: function () {
            return this._title;
        },
        set: function (value) {
            this._title = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarMenu.prototype, "iconClass", {
        get: function () {
            return this._iconClass;
        },
        set: function (value) {
            this._iconClass = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarMenu.prototype, "iconName", {
        get: function () {
            return this._iconName;
        },
        set: function (value) {
            this._iconName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarMenu.prototype, "imageClass", {
        get: function () {
            return this._imageClass;
        },
        set: function (value) {
            this._imageClass = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarMenu.prototype, "imageSrc", {
        get: function () {
            return this._imageSrc;
        },
        set: function (value) {
            this._imageSrc = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarMenu.prototype, "spanClass", {
        get: function () {
            return this._spanClass;
        },
        set: function (value) {
            this._spanClass = value;
        },
        enumerable: true,
        configurable: true
    });
    return ToolbarMenu;
}());
export { ToolbarMenu };
