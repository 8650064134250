import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import _ from 'lodash';
import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { ExtendedInformationDto } from '../../../../gapicon/dto.module';
import { PhoenixMeasurementTypeExtendedInformationsDialogComponent } from './phoenix-measurement-type-extended-informations-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PhoenixMeasurementTypeExtendedInformationsDialogService = /** @class */ (function () {
    function PhoenixMeasurementTypeExtendedInformationsDialogService(dialog) {
        this.dialog = dialog;
    }
    PhoenixMeasurementTypeExtendedInformationsDialogService.prototype.openDialog = function (extendedInformationDtos, checklistElementKey) {
        var _this = this;
        var formArray = new FormArray(_.map(extendedInformationDtos, function (dto) {
            return new FormGroup({
                key: new FormControl(dto.key, Validators.required),
                name: new FormControl(dto.name, Validators.required),
                type: new FormControl(dto.type, Validators.required),
                value: new FormControl(dto.value, Validators.required),
            });
        }));
        var formGroup = new FormGroup({
            extendedInformations: formArray,
        });
        var dialogRef = this.dialog.open(PhoenixMeasurementTypeExtendedInformationsDialogComponent, {
            width: '550px',
            autoFocus: false,
            data: {
                title: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.' + (extendedInformationDtos.length > 0 ? 'EDIT.' : '') + 'HEADLINE',
                subtitle: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.SUBHEADLINE',
                buttons: [
                    new PhoenixDialogButton({
                        label: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.CANCEL',
                        click: function () { return dialogRef.close(); },
                    }),
                    new PhoenixDialogButton({
                        label: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.SAVE',
                        click: function () { return dialogRef.close(_this.save(formArray)); },
                        disabled: function () { return formGroup.invalid; },
                        color: 'accent',
                        raised: true,
                    })
                ],
                formGroup: formGroup,
                extendedInformationForms: formArray,
                checklistElementKey: checklistElementKey,
            },
        });
        return dialogRef;
    };
    PhoenixMeasurementTypeExtendedInformationsDialogService.prototype.save = function (formArray) {
        return _.map(formArray.controls, function (control) { return new ExtendedInformationDto(control.value); });
    };
    PhoenixMeasurementTypeExtendedInformationsDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixMeasurementTypeExtendedInformationsDialogService_Factory() { return new PhoenixMeasurementTypeExtendedInformationsDialogService(i0.inject(i1.MatDialog)); }, token: PhoenixMeasurementTypeExtendedInformationsDialogService, providedIn: "root" });
    return PhoenixMeasurementTypeExtendedInformationsDialogService;
}());
export { PhoenixMeasurementTypeExtendedInformationsDialogService };
