/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-breadcrumbs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i6 from "@angular/material/progress-spinner";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/common";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "./phoenix-breadcrumbs.component";
import * as i14 from "../../../app/navigation/navigation.service";
import * as i15 from "../../gapicon/asset/services/asset-api.service";
import * as i16 from "../../gapicon/threshold/services/threshold-api.service";
import * as i17 from "../../gapicon/user/services/user-api.service";
var styles_PhoenixBreadcrumbsComponent = [i0.styles];
var RenderType_PhoenixBreadcrumbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixBreadcrumbsComponent, data: {} });
export { RenderType_PhoenixBreadcrumbsComponent as RenderType_PhoenixBreadcrumbsComponent };
function View_PhoenixBreadcrumbsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-icon", [["class", "s-16 notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), i1.ɵdid(2, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, 0, ["", " "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = _v.parent.parent.context.$implicit.url; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.parent.context.$implicit.icon; _ck(_v, 3, 0, currVal_2); }); }
function View_PhoenixBreadcrumbsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "fuse-white-fg mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i5.View_MatSpinner_0, i5.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i6.MatSpinner, [i1.ElementRef, i7.Platform, [2, i8.DOCUMENT], [2, i9.ANIMATION_MODULE_TYPE], i6.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 15; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhoenixBreadcrumbsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "s-16 notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_right"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_PhoenixBreadcrumbsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixBreadcrumbsComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PhoenixBreadcrumbsComponent_4)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixBreadcrumbsComponent_5)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.parent.context.$implicit.label)) === null); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.url; _ck(_v, 7, 0, currVal_2); var currVal_4 = !_v.parent.context.last; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_v.parent.context.$implicit.label)))); _ck(_v, 8, 0, currVal_3); }); }
function View_PhoenixBreadcrumbsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PhoenixBreadcrumbsComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.label)) || _v.context.$implicit.icon); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PhoenixBreadcrumbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "font-size-16 fuse-white-fg"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i11.LayoutDirective, [i12.MediaMonitor, i1.ElementRef, i12.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i11.LayoutAlignDirective, [i12.MediaMonitor, i1.ElementRef, [6, i11.LayoutDirective], i12.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixBreadcrumbsComponent_1)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.breadcrumbs; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_PhoenixBreadcrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-breadcrumbs", [], null, null, null, View_PhoenixBreadcrumbsComponent_0, RenderType_PhoenixBreadcrumbsComponent)), i1.ɵdid(1, 180224, null, 0, i13.PhoenixBreadcrumbsComponent, [i4.Router, i14.NavigationService, i15.AssetApiService, i16.ThresholdApiService, i17.UserApiService], null, null)], null, null); }
var PhoenixBreadcrumbsComponentNgFactory = i1.ɵccf("phoenix-breadcrumbs", i13.PhoenixBreadcrumbsComponent, View_PhoenixBreadcrumbsComponent_Host_0, {}, {}, []);
export { PhoenixBreadcrumbsComponentNgFactory as PhoenixBreadcrumbsComponentNgFactory };
