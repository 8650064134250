import { Platform } from '@angular/cdk/platform';
import { OnDestroy, OnInit } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import german from '../../i18n/de.json';
import english from '../../i18n/en.json';
import french from '../../i18n/fr.json';
import italian from '../../i18n/it.json';
import { PhoenixUserConfigType } from '../@phoenix/enums/phoenix-user-config-type.enum';
import { AuthApiService } from '../@phoenix/gapicon/auth/services/auth-api.service';
import { PhoenixCommunicationSubject } from '../@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PermissionService } from './guards/permission.service';
import { navigation } from './navigation/navigation';
var AppComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     * @param _permissionService
     * @param _communicationService
     */
    function AppComponent(document, _fuseConfigService, _fuseNavigationService, _fuseSidebarService, _fuseSplashScreenService, _fuseTranslationLoaderService, _translateService, _platform, _permissionService, _communicationService, _authApiService) {
        var _this = this;
        this.document = document;
        this._fuseConfigService = _fuseConfigService;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseSplashScreenService = _fuseSplashScreenService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._platform = _platform;
        this._permissionService = _permissionService;
        this._communicationService = _communicationService;
        this._authApiService = _authApiService;
        this._fuseConfigService.setConfig({
            colorTheme: 'theme-gc',
        });
        // Get default navigation
        this.navigation = navigation;
        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');
        // Add languages
        this._translateService.addLangs([
            'en',
            'de',
            'it',
            'fr',
        ]);
        // Set the default language
        this._translateService.setDefaultLang('en');
        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(english, german, italian, french);
        // Use a language
        this._translateService.use(localStorage.getItem('selectedLanguage') || this._translateService.getBrowserLang());
        this._communicationService.getObservable(PhoenixCommunicationSubject.UserLogin).subscribe(function () {
            var language = _this.getUserLanguage();
            if (language) {
                _this._translateService.use(language);
                localStorage.setItem('selectedLanguage', language);
            }
        });
        moment.locale('de');
        // Workaround because of error in moment type definition
        moment.defaultFormat = 'L LT';
        /**
         * ------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ------------------------------------------------------------------
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/
        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
                this._translateService.setDefaultLang('en');
                this._translateService.setDefaultLang('tr');
             });
         */
        /**
         * ------------------------------------------------------------------
         * ngxTranslate Fix End
         * ------------------------------------------------------------------
         */
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On destroy
     */
    AppComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    /**
     * On init
     */
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // persist changes
        var autoLogged = this._authApiService.autoLogin();
        if (autoLogged) {
            this._permissionService.autoLogin();
        }
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (config) {
            _this.fuseConfig = config;
            // Boxed
            if (_this.fuseConfig.layout.width === 'boxed') {
                _this.document.body.classList.add('boxed');
            }
            else {
                _this.document.body.classList.remove('boxed');
            }
            // Color theme - Use normal for loop for IE11 compatibility
            for (var i = 0; i < _this.document.body.classList.length; i++) {
                var className = _this.document.body.classList[i];
                if (className.startsWith('theme-')) {
                    _this.document.body.classList.remove(className);
                }
            }
            _this.document.body.classList.add(_this.fuseConfig.colorTheme);
            // persisting user after refreshing the page
        });
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    AppComponent.prototype.toggleSidebarOpen = function (key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    };
    AppComponent.prototype.refreshLoginState = function (target) {
        this._permissionService.startNewAutoLoginCountDown();
    };
    AppComponent.prototype.getUserLanguage = function () {
        var language;
        if (this._permissionService.user) {
            _.forEach(this._permissionService.user.configurations, function (conf) {
                if (conf.type === PhoenixUserConfigType.Locale) {
                    language = conf.value;
                }
            });
        }
        return language;
    };
    return AppComponent;
}());
export { AppComponent };
