import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

import {ThresholdDto, ThresholdSearchCriteriaDto, ThresholdViolationStatisticsDto} from '../../dto.module';
import {Threshold} from '../api/threshold';
import {ThresholdSearchCriteria} from '../api/threshold-search-criteria';
import {ThresholdViolationStatistics} from '../api/threshold-violation-statistics';

@Injectable({
  providedIn: 'root',
})
export class ThresholdMapperService {

  public constructor() {
  }

  public thresholdToThresholdDto(threshold: Threshold): ThresholdDto {
    return plainToClass(ThresholdDto, threshold);
  }

  public thresholdDtoToThreshold(thresholdDto: ThresholdDto): Threshold {
    return plainToClass(Threshold, thresholdDto);
  }

  public thresholdArrayToThresholdDtoArray(thresholds: Threshold[]): ThresholdDto[] {
    return _.map(thresholds, (threshold: Threshold) => this.thresholdToThresholdDto(threshold));
  }

  public thresholdDtoArrayToThresholdArray(thresholdsDtos: ThresholdDto[]): Threshold[] {
    return _.map(thresholdsDtos, (thresholdDto: ThresholdDto) => this.thresholdDtoToThreshold(thresholdDto));
  }

  public thresholdSearchCriteriaDtoToThresholdSearchCriteria(thresholdSearchCriteriaDto: ThresholdSearchCriteriaDto): ThresholdSearchCriteria {
    return plainToClass(ThresholdSearchCriteria, thresholdSearchCriteriaDto);
  }

  public thresholdViolationStatisticsToThresholdViolationStatisticsDto(thresholdViolationStatistics: ThresholdViolationStatistics): ThresholdViolationStatisticsDto {
    return plainToClass(ThresholdViolationStatisticsDto, thresholdViolationStatistics);
  }
}
