import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';

import { PhoenixFormElementType } from '../../../components/phoenix-form/enums/phoenix-form-element-type.enum';
import { ExtendedInformationDto, ValueTypeDto } from '../../../gapicon/dto.module';

import { PhoenixMeasurementTypeDialog } from './classes/phoenix-measurement-type-dialog';
import { PhoenixMeasurementTypeExtendedInformationsDialogService } from './phoenix-measurement-type-extended-informations-dialog/phoenix-measurement-type-extended-informations-dialog.service';


@Component({
  selector: 'phoenix-measurement-type',
  templateUrl: './phoenix-measurement-type-dialog.component.html',
  styleUrls: ['./phoenix-measurement-type-dialog.component.scss'],
})
export class PhoenixMeasurementTypeDialogComponent implements OnInit {

  public elementType: typeof PhoenixFormElementType = PhoenixFormElementType;

  public measurementType: string[] = _.map(ValueTypeDto, (element: string) => {
    return element;
  }).filter(element => {
    return (element != ValueTypeDto.STRING && element != ValueTypeDto.ATTACHMENT)
  });;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixMeasurementTypeDialog,
    private extendedInformationsDialogService: PhoenixMeasurementTypeExtendedInformationsDialogService,
  ) {
  }

  public ngOnInit(): void {
  }

  public openExtendedInformationDialog(formGroup: FormGroup): void {
    this.extendedInformationsDialogService.openDialog(formGroup.value.extendedInformations, formGroup.value.key)
      .afterClosed()
      .subscribe((result: ExtendedInformationDto[]) => {
        if (result) {
          formGroup.patchValue({ extendedInformations: result });
        }
      });
  }
}
