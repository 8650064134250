import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { Alarm } from '../api/alarm';
import { AlarmHistory } from '../api/alarm-history';
import { AlarmSearchCriteria } from '../api/alarm-search-criteria';
import { AlarmStatus } from '../api/alarm-status.enum';
import { AlarmDto } from '../dto/alarm-dto';
import { AlarmHistoryDto } from '../dto/alarm-history-dto';
import { AlarmStatusDto } from '../dto/alarm-status-dto.enum';
import { AlarmCount } from '../api/alarmCount';
import { AlarmCountDto } from '../dto/alarm-count-dto';
import * as i0 from "@angular/core";
import * as i1 from "../../user/mapper/user-mapper.service";
var AlarmMapperService = /** @class */ (function () {
    function AlarmMapperService(userMapperService) {
        this.userMapperService = userMapperService;
    }
    AlarmMapperService.prototype.alarmArrayToAlarmDtoArray = function (alarms) {
        var _this = this;
        return _.map(alarms, function (alarm) { return _this.alarmToAlarmDto(alarm); });
    };
    AlarmMapperService.prototype.alarmDtoArrayToAlarmArray = function (alarmDtos) {
        var _this = this;
        return _.map(alarmDtos, function (alarmDto) { return _this.alarmDtoToAlarm(alarmDto); });
    };
    AlarmMapperService.prototype.alarmDtoToAlarm = function (alarmDto) {
        var alarm = plainToClass(Alarm, alarmDto);
        alarm.status = this.alarmStatusDtoToAlarmStatus(alarmDto.status);
        alarm.history = this.alarmHistoryDtoArrayToAlarmHistoryArray(alarmDto.history);
        alarm.updateUser = this.userMapperService.userInfoDtoToUserInfo(alarmDto.updateUser);
        return alarm;
    };
    AlarmMapperService.prototype.alarmHistoryArrayToAlarmHistoryDtoArray = function (alarmHistoryArray) {
        var _this = this;
        return _.map(alarmHistoryArray, function (alarmHistory) { return _this.alarmHistoryToAlarmHistoryDto(alarmHistory); });
    };
    AlarmMapperService.prototype.alarmHistoryDtoArrayToAlarmHistoryArray = function (alarmHistoryDtoArray) {
        var _this = this;
        return _.map(alarmHistoryDtoArray, function (alarmHistoryDto) { return _this.alarmHistoryDtoToAlarmHistory(alarmHistoryDto); });
    };
    AlarmMapperService.prototype.alarmHistoryDtoToAlarmHistory = function (alarmHistoryDto) {
        return plainToClass(AlarmHistory, alarmHistoryDto);
    };
    AlarmMapperService.prototype.alarmHistoryToAlarmHistoryDto = function (alarmHistory) {
        var alarmHistoryDto = plainToClass(AlarmHistoryDto, alarmHistory);
        alarmHistoryDto.timestamp = new Date(alarmHistory.timestamp);
        return alarmHistoryDto;
    };
    AlarmMapperService.prototype.alarmSearchCriteriaDtoToAlarmSearchCriteria = function (alarmSearchCriteriaDto) {
        return plainToClass(AlarmSearchCriteria, alarmSearchCriteriaDto);
    };
    AlarmMapperService.prototype.alarmStatusDtoToAlarmStatus = function (alarmStatusDto) {
        return alarmStatusDto ? AlarmStatus[alarmStatusDto.toUpperCase()] : undefined;
    };
    AlarmMapperService.prototype.alarmStatusToAlarmStatusDto = function (alarmStatus) {
        return alarmStatus ? AlarmStatusDto[alarmStatus.toUpperCase()] : undefined;
    };
    AlarmMapperService.prototype.alarmToAlarmDto = function (alarm) {
        var alarmDto = plainToClass(AlarmDto, alarm);
        alarmDto.timestamp = new Date(alarmDto.timestamp);
        alarmDto.updateTimestamp = new Date(alarmDto.updateTimestamp);
        alarmDto.status = this.alarmStatusToAlarmStatusDto(alarm.status);
        alarmDto.history = this.alarmHistoryArrayToAlarmHistoryDtoArray(alarm.history);
        alarmDto.updateUser = this.userMapperService.userInfoToUserInfoDto(alarm.updateUser);
        return alarmDto;
    };
    AlarmMapperService.prototype.alarmCountToAlarmCountDto = function (alarmCount) {
        var alarmCountDto = plainToClass(AlarmCountDto, alarmCount);
        alarmCountDto.monthYear = alarmCount.monthYear;
        alarmCountDto.activeInactiveCount = alarmCount.activeInactiveCount;
        alarmCountDto.closedCount = alarmCount.closedCount;
        return alarmCountDto;
    };
    AlarmMapperService.prototype.alarmCountDtoToAlarm = function (alarmCountDto) {
        var alarmCount = plainToClass(AlarmCount, alarmCountDto);
        alarmCount.monthYear = alarmCountDto.monthYear;
        alarmCount.activeInactiveCount = alarmCountDto.activeInactiveCount;
        alarmCount.closedCount = alarmCountDto.closedCount;
        return alarmCount;
    };
    AlarmMapperService.prototype.alarmCountArrayToAlarmCountDtoArray = function (alarmCounts) {
        var _this = this;
        return _.map(alarmCounts, function (alarmCount) { return _this.alarmCountToAlarmCountDto(alarmCount); });
    };
    AlarmMapperService.ngInjectableDef = i0.defineInjectable({ factory: function AlarmMapperService_Factory() { return new AlarmMapperService(i0.inject(i1.UserMapperService)); }, token: AlarmMapperService, providedIn: "root" });
    return AlarmMapperService;
}());
export { AlarmMapperService };
