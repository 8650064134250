<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">
  <div class="text-center text-semibold mt-20 text-truncate">{{'TASKCONFIG.DAILY.DESCRIPTION' | translate}}</div>
  <form [formGroup]="data.formGroup">
    <div fxLayout="column">
      <mat-form-field>
        <label class="mr-8" matPrefix>{{'TASKCONFIG.DAILY.START.PREFIX' | translate}}</label>
        <mat-placeholder>{{'TASKCONFIG.DAILY.START.PLACEHOLDER' | translate}}</mat-placeholder>
        <mat-datetimepicker-toggle [for]="datetimePickerStart" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerStart [startAt]="today" timeInterval="5" type="datetime">
        </mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerStart" [min]="data.startDatePicker" class="text-center"
          formControlName="start" matInput readonly>
        <label class="ml-8" matSuffix>{{'TASKCONFIG.DAILY.START.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <label class="mr-8" matPrefix>{{'TASKCONFIG.DAILY.END.PREFIX' | translate}}</label>
        <mat-placeholder>{{'TASKCONFIG.DAILY.END.PLACEHOLDER' | translate}}</mat-placeholder>
        <mat-datetimepicker-toggle [for]="datetimePickerEnd" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerEnd timeInterval="5" type="datetime"></mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerEnd" [min]="startDate" class="text-center" formControlName="end"
          matInput readonly>
        <label class="ml-8" matSuffix>{{'TASKCONFIG.DAILY.END.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <mat-select [placeholder]="'TASKCONFIG.ASSIGNGROUPS.ADDREMOVE' | translate" class="text-right"
          formControlName="assignedUserGroupIds" multiple>
          <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">{{userGroup.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</phoenix-dialog-new>