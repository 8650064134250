var PasswordChange = /** @class */ (function () {
    function PasswordChange(passwordChange) {
        this.newPassword = undefined;
        this.oldPassword = undefined;
        Object.assign(this, passwordChange);
    }
    PasswordChange.prototype.toJSON = function () {
        return {
            newPassword: this.newPassword,
            oldPassword: this.oldPassword,
        };
    };
    return PasswordChange;
}());
export { PasswordChange };
