import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {combineLatest} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {auditTime, map, mergeMap, startWith} from 'rxjs/operators';

import {PhoenixAutocompleteEntry} from '../../classes/phoenix-autocomplete-entry';
import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-textbox',
  templateUrl: './phoenix-dynamic-textbox.component.html',
  styleUrls: ['./phoenix-dynamic-textbox.component.scss'],
})
export class PhoenixDynamicTextboxComponent implements OnInit {
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;
  @Input()
  public translationParams: Object = {};
  @Input()
  public form: FormGroup;
  public filteredOptions: Observable<PhoenixAutocompleteEntry<Object>[]>;

  public ngOnInit(): void {
    this.form.get(this.phoenixFormControl.key).setValidators(this.phoenixFormControl.validators);
    this.form.get(this.phoenixFormControl.key).setAsyncValidators(this.phoenixFormControl.asyncValidators);
    this.handleAutocompleteOption();
  }

  public displayFn(obj?: PhoenixAutocompleteEntry<Object>): string | undefined {
    return obj ? obj.label : undefined;
  }

  private filter(val: string, options: PhoenixAutocompleteEntry<Object>[]): PhoenixAutocompleteEntry<Object>[] {
    return options.filter((option: PhoenixAutocompleteEntry<Object>) => {
      return option.label.toLowerCase().includes(val.toLowerCase());
    });
  }

  private handleAutocompleteOption(): void {
    if (_.has(this.phoenixFormControl.options, 'autocomplete')) {
      const searchObservable: (search: string) => Observable<PhoenixAutocompleteEntry<Object>[]> = _.get(this.phoenixFormControl.options, 'autocomplete');
      const autocompleteData: Observable<PhoenixAutocompleteEntry<Object>[]> = this.form.get(this.phoenixFormControl.key).valueChanges.pipe(
        startWith(this.form.get(this.phoenixFormControl.key).value),
        auditTime(500),
        map((search: string | PhoenixAutocompleteEntry<Object>) => typeof search === 'string' ? search : undefined), // value of the form control could be an object
        mergeMap(searchObservable),
      );

      this.filteredOptions = combineLatest(
        this.form.get(this.phoenixFormControl.key).valueChanges.pipe(
          startWith(new PhoenixAutocompleteEntry<Object>({object: undefined, label: ''})),
        ),
        autocompleteData,
      ).pipe(
        map(([formValue, options]: [string | PhoenixAutocompleteEntry<Object>, PhoenixAutocompleteEntry<Object>[]]) => {
          if (typeof formValue === 'string') {
            if (formValue === '' && !this.phoenixFormControl.required) {
              this.form.get(this.phoenixFormControl.key).setErrors(undefined);
            } else {
              this.form.get(this.phoenixFormControl.key).setErrors({error: 'parent element is not an object'});
            }
            return this.filter(formValue, options);
          } else { // Item bereits ausgewählt
            this.form.get(this.phoenixFormControl.key).setErrors(undefined);
            return options;
          }
        }));
    }
  }
}
