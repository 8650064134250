var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { AccountApiService } from '../../gapicon/account/services/account-api.service';
import { PasswordChangeDto } from '../../gapicon/dto.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../gapicon/account/services/account-api.service";
import * as i3 from "../phoenix-snackbar/phoenix-snackbar.service";
import * as i4 from "@ngx-translate/core";
var PhoenixAccountInfoDialogService = /** @class */ (function () {
    function PhoenixAccountInfoDialogService(dialog, accountApi, snackbarService, translateService) {
        this.dialog = dialog;
        this.accountApi = accountApi;
        this.snackbarService = snackbarService;
        this.translateService = translateService;
        this.unsubscribe = new Subject();
    }
    PhoenixAccountInfoDialogService.prototype.openChangePasswordDialog = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var dialogRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.open(PhoenixDialogComponent, {
                            width: '400px',
                            data: {
                                headline: 'DIALOGS.PASSWORDCHANGE.TITLE',
                                subHeadline: 'DIALOGS.PASSWORDCHANGE.SUBTITLE',
                                buttons: {
                                    save: true,
                                    cancel: true,
                                },
                                onClose: function (dialogResult) { return _this.passwordChangeDialogCallback(dialogResult); },
                                formControls: [[
                                        this.createFormControlForPasswordDialog('passwordold', 'DIALOGS.PASSWORDCHANGE.OLD'),
                                        this.createFormControlForPasswordDialog('passwordnew', 'DIALOGS.PASSWORDCHANGE.NEW', { passwordRules: true }),
                                        this.createFormControlForPasswordDialog('passwordnewconfirm', 'DIALOGS.PASSWORDCHANGE.NEWCONFIRM', { passwordRules: true }),
                                    ]],
                            },
                        })];
                    case 1:
                        dialogRef = _a.sent();
                        return [4 /*yield*/, dialogRef.afterOpen().toPromise()];
                    case 2:
                        _a.sent();
                        merge(dialogRef.componentInstance.form.get('passwordnew').valueChanges, dialogRef.componentInstance.form.get('passwordnewconfirm').valueChanges)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe(function () { return _this.setErrorsForPasswordConfirmControl(dialogRef); });
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixAccountInfoDialogService.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    /**
     * sends result of dialog via gapicon to backend
     * @param dialogResult
     */
    PhoenixAccountInfoDialogService.prototype.passwordChangeDialogCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var passwordChange, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 4];
                        passwordChange = new PasswordChangeDto();
                        passwordChange.newPassword = dialogResult.formData['passwordnew'];
                        passwordChange.oldPassword = dialogResult.formData['passwordold'];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.accountApi.changePassword(passwordChange).toPromise()];
                    case 2:
                        _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('DIALOGS.PASSWORDCHANGE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Checks if both password and password confirmation provided in the dialog are equal and sets an form control error if not
     * @param dialogRef
     */
    PhoenixAccountInfoDialogService.prototype.setErrorsForPasswordConfirmControl = function (dialogRef) {
        if (dialogRef.componentInstance.form.get('passwordnew').value !== dialogRef.componentInstance.form.get('passwordnewconfirm').value) {
            dialogRef.componentInstance.form.get('passwordnewconfirm').setErrors({ equal: true });
        }
        else {
            dialogRef.componentInstance.form.get('passwordnewconfirm').setErrors(undefined);
        }
    };
    PhoenixAccountInfoDialogService.prototype.createFormControlForPasswordDialog = function (key, label, options) {
        if (options === void 0) { options = {}; }
        return new PhoenixDynamicFormControl(key, PhoenixDynamicFormControlType.Password, label, true, options);
    };
    PhoenixAccountInfoDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixAccountInfoDialogService_Factory() { return new PhoenixAccountInfoDialogService(i0.inject(i1.MatDialog), i0.inject(i2.AccountApiService), i0.inject(i3.PhoenixSnackbarService), i0.inject(i4.TranslateService)); }, token: PhoenixAccountInfoDialogService, providedIn: "root" });
    return PhoenixAccountInfoDialogService;
}());
export { PhoenixAccountInfoDialogService };
