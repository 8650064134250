var UserDto = /** @class */ (function () {
    function UserDto(userDto) {
        this.accountInfo = undefined;
        this.company = undefined;
        this.configurations = [];
        this.email = undefined;
        this.firstName = undefined;
        this.id = undefined;
        this.lastName = undefined;
        this.properties = undefined;
        this.roles = undefined;
        Object.assign(this, userDto);
    }
    return UserDto;
}());
export { UserDto };
