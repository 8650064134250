/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i4 from "../../../@fuse/services/config.service";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/router";
import * as i7 from "./phoenix-page.component";
var styles_PhoenixPageComponent = [i0.styles];
var RenderType_PhoenixPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixPageComponent, data: {} });
export { RenderType_PhoenixPageComponent as RenderType_PhoenixPageComponent };
function View_PhoenixPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "top-bg accent"]], null, null, null, null, null))], null, null); }
export function View_PhoenixPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "w-100-p fullwidth"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "page-layout carded": 0 }), i1.ɵdid(3, 4341760, null, 0, i3.FusePerfectScrollbarDirective, [i1.ElementRef, i4.FuseConfigService, i5.Platform, i6.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixPageComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "center"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "h-100-p": 0 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "w-100-p fullwidth"; var currVal_1 = _ck(_v, 2, 0, _co.isLoggedIn); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.isLoggedIn; _ck(_v, 5, 0, currVal_3); var currVal_4 = "center"; var currVal_5 = _ck(_v, 8, 0, !_co.isLoggedIn); _ck(_v, 7, 0, currVal_4, currVal_5); }, null); }
export function View_PhoenixPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-page", [], null, null, null, View_PhoenixPageComponent_0, RenderType_PhoenixPageComponent)), i1.ɵdid(1, 49152, null, 0, i7.PhoenixPageComponent, [], null, null)], null, null); }
var PhoenixPageComponentNgFactory = i1.ɵccf("phoenix-page", i7.PhoenixPageComponent, View_PhoenixPageComponent_Host_0, { isLoggedIn: "isLoggedIn" }, {}, ["*"]);
export { PhoenixPageComponentNgFactory as PhoenixPageComponentNgFactory };
