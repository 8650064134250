<div mat-dialog-title class="title-wrapper {{ color }}">
  <div class="main-title h1">{{ data.headline | translate }}</div>
  <div class="sub-title h4 hint-text">{{ data.subHeadline | translate }}</div>
</div>
<div class="content">
  <p class="font-size-13 line-height-18" *ngIf="data.description">{{ data.description | translate }}</p>
  <div class="mt-20" fxLayoutAlign="center center">
    <ks-modal-gallery [id]="1" [modalImages]="images"
                      [currentImageConfig]="{loadingConfig:{enable: true, type: 2}}"></ks-modal-gallery>
  </div>
  <mat-divider class="mt-12"></mat-divider>
  <div class="text-center m-20">
    <button mat-raised-button class="ml-4" color="accent" (click)="closeDialog()">{{
      'GALLERY.BUTTONS.OK' | translate }}
    </button>
  </div>
</div>
