import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { takeUntil } from 'rxjs/operators';

import { PhoenixCommunicationSubject } from '../../../@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { CanLoadGuard } from '../../guards/can-load.guard';
import { NavigationItem } from '../../navigation/classes/navigation-item';
import { NavigationService } from '../../navigation/navigation.service';
import { AvailableTenantsResolverService } from '../../resolvers/available-tenants-resolver.service';
import { MainModule } from '../classes/main-module';
import { Permission, permissionIds } from 'app/guards/permission';
import { CanActivateGuard } from 'app/guards/can-activate.guard';
import { MatIconModule } from '@angular/material';


//const permission: Permission = new Permission(permissionIds.DASHBOARD.read);

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard-content/dashboard-content.module#DashboardContentModule',
    canActivate: [CanActivateGuard],
    //canLoad: [CanLoadGuard],
    // resolve: [AvailableTenantsResolverService],
    //data: { permission: permission },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
  ],
  declarations: [],
})
export class DashboardModule extends MainModule {
  private translationKey: string = 'NAV.DASHBOARD';
  private navItem: NavigationItem = new NavigationItem(
    'dashboard',
    -1,
    false,
    this.translationKey,
    '/dashboard',
    PhoenixIcon.DASHBOARD,
  );

  public constructor(
    private navigationService: NavigationService,
    private communicationService: PhoenixCommunicationService,
  ) {
    super();
    this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.navigationService.refreshNavigation(this.navItem));
  }
}
