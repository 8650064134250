import { OnInit } from '@angular/core';
import _ from 'lodash';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';
var PhoenixTaskConfigDialogAnnuallyComponent = /** @class */ (function () {
    function PhoenixTaskConfigDialogAnnuallyComponent(data) {
        this.data = data;
        this.months = [
            { label: 'January', value: 1, maxDays: 31 },
            { label: 'February', value: 2, maxDays: 29 },
            { label: 'March', value: 3, maxDays: 31 },
            { label: 'April', value: 4, maxDays: 30 },
            { label: 'May', value: 5, maxDays: 31 },
            { label: 'June', value: 6, maxDays: 30 },
            { label: 'July', value: 7, maxDays: 31 },
            { label: 'August', value: 8, maxDays: 31 },
            { label: 'September', value: 9, maxDays: 30 },
            { label: 'October', value: 10, maxDays: 31 },
            { label: 'November', value: 11, maxDays: 30 },
            { label: 'December', value: 12, maxDays: 31 },
        ];
        this.today = new Date();
        this.week = [
            { label: 'first', value: 1 },
            { label: 'second', value: 2 },
            { label: 'third', value: 3 },
            { label: 'fourth', value: 4 },
            { label: 'last', value: 999 },
        ];
        this.weekdays = [
            { label: 'Monday', value: 1 },
            { label: 'Tuesday', value: 2 },
            { label: 'Wednesday', value: 3 },
            { label: 'Thursday', value: 4 },
            { label: 'Friday', value: 5 },
            { label: 'Saturday', value: 6 },
            { label: 'Sunday', value: 7 },
        ];
        this.maxDays = 31;
    }
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.getDays = function () {
        return this.days;
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.getStartDate = function () {
        return this.data.formGroup.get('start').value;
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.ngOnInit = function () {
        this.initDays();
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.resetDaysAndMonth2 = function () {
        this.resetDays();
        this.resetMonth2();
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.resetWeekdays = function () {
        if (!_.isNil(this.data.formGroup.get('month').value)) {
            this.setMaxDays(this.data.formGroup.get('month').value);
        }
        else {
            this.data.formGroup.get('month').setValue(undefined);
        }
        this.data.formGroup.get('weekdays').setValue(undefined);
        this.data.formGroup.get('week').setValue(undefined);
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.setMaxDays = function (month) {
        var _this = this;
        this.maxDays = _.find(this.months, ['value', month]).maxDays;
        this.data.formGroup.get('days').setValue(_.filter(this.data.formGroup.get('days').value, function (day) { return day <= _this.maxDays; }));
        this.initDays();
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.initDays = function () {
        this.days = [];
        for (var i = 1; i <= this.maxDays; i++) {
            this.days.push({ label: i < 10 ? '0'.concat(i.toString()) : ''.concat(i.toString()), value: i });
        }
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.resetDays = function () {
        this.data.formGroup.get('days').setValue([]);
    };
    PhoenixTaskConfigDialogAnnuallyComponent.prototype.resetMonth2 = function () {
        this.data.formGroup.get('month2').setValue(undefined);
    };
    Object.defineProperty(PhoenixTaskConfigDialogAnnuallyComponent.prototype, "assignedUserGroupIds", {
        get: function () {
            return this.data.formGroup.get('assignedUserGroupIds').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixTaskConfigDialogAnnuallyComponent.prototype, "userGroups", {
        get: function () {
            return this.data.usergroups.sort(function (a, b) { return a.name.localeCompare(b.name); });
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixTaskConfigDialogAnnuallyComponent;
}());
export { PhoenixTaskConfigDialogAnnuallyComponent };
