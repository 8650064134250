import {format, isValid} from 'date-fns';

import {JsonExportable} from '../../shared/classes/json-exportable';
import {UserInfo} from '../../user/api/user-info';

import {AlarmStatus} from './alarm-status.enum';

export class AlarmHistory implements JsonExportable {
  public status: AlarmStatus = undefined;
  public timestamp: Date = undefined;
  public comment: String = undefined;
  public user: UserInfo = undefined;

  public constructor(alarmHistory?: Partial<AlarmHistory>) {
    Object.assign(this, alarmHistory);
  }

  public toJSON(): Object {
    return {
      status: this.status,
      timestamp: (this.timestamp && isValid(this.timestamp)) ? format(this.timestamp) : undefined,
      comment: this.comment,
      user: this.user,
    };
  }
}
