var MeasurementValueType = /** @class */ (function () {
    function MeasurementValueType(measurementValueType) {
        this.id = undefined;
        this.key = undefined;
        this.name = undefined;
        this.type = undefined;
        this.defaultValue = undefined;
        this.unit = undefined;
        this.children = undefined;
        Object.assign(this, measurementValueType);
    }
    MeasurementValueType.prototype.toJSON = function () {
        return {
            id: this.id,
            key: this.key,
            name: this.name,
            type: this.type,
            defaultValue: this.defaultValue,
            unit: this.unit,
            children: this.children,
        };
    };
    return MeasurementValueType;
}());
export { MeasurementValueType };
