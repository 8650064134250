import { plainToClass } from 'class-transformer';
import { StatisticsSearchCriteria } from '../api/statistics-search-criteria';
import * as i0 from "@angular/core";
var StatisticsMapperService = /** @class */ (function () {
    function StatisticsMapperService() {
    }
    StatisticsMapperService.prototype.statisticsSearchCriteriaDtoToStatisticsSearchCriteria = function (statisticsSearchCriteriaDto) {
        return plainToClass(StatisticsSearchCriteria, statisticsSearchCriteriaDto);
    };
    StatisticsMapperService.ngInjectableDef = i0.defineInjectable({ factory: function StatisticsMapperService_Factory() { return new StatisticsMapperService(); }, token: StatisticsMapperService, providedIn: "root" });
    return StatisticsMapperService;
}());
export { StatisticsMapperService };
