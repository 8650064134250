var EmailDto = /** @class */ (function () {
    function EmailDto(emailDto) {
        this.receiver = undefined;
        this.subject = undefined;
        this.text = undefined;
        Object.assign(this, emailDto);
    }
    return EmailDto;
}());
export { EmailDto };
