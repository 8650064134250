var PasswordResetRequest = /** @class */ (function () {
    function PasswordResetRequest(passwordResetRequest) {
        this.email = undefined;
        Object.assign(this, passwordResetRequest);
    }
    PasswordResetRequest.prototype.toJSON = function () {
        return {
            email: this.email,
        };
    };
    return PasswordResetRequest;
}());
export { PasswordResetRequest };
