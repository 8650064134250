var RegistrationRequestDto = /** @class */ (function () {
    function RegistrationRequestDto(registrationRequestDto) {
        this.email = undefined;
        /**
         * Key -> Role,
         * Value -> Tenants
         */
        this.roles = undefined;
        Object.assign(this, registrationRequestDto);
    }
    return RegistrationRequestDto;
}());
export { RegistrationRequestDto };
