var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixAccountInfoDialogService } from '@phoenix/components/phoenix-dialogs/phoenix-account-info-dialog.service';
import { UserConfigDto } from '@phoenix/gapicon/user/dto/user-config-dto';
import { UserApiService } from '@phoenix/gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixUserWizardService } from '@phoenix/services/phoenix-wizards/phoenix-user-wizard.service';
import * as _ from 'lodash';
import { PhoenixUserConfigType } from '../../../../@phoenix/enums/phoenix-user-config-type.enum';
import { PhoenixAssetBranchselectorDialogService } from '../../../../@phoenix/templates/phoenix-dialog/phoenix-asset-branchselector-dialog/phoenix-asset-branchselector-dialog.service';
import { PhoenixTenantSelectorDialogService } from '../../../../@phoenix/templates/phoenix-dialog/phoenix-tenantselector-dialog/phoenix-tenant-selector-dialog.service';
import { PermissionService } from '../../../guards/permission.service';
import { ToolbarIconMenuItem } from './toolbar-menu-item/toolbar-icon-menu-item';
import { ToolbarImageMenuItem } from './toolbar-menu-item/toolbar-image-menu-item';
import { ToolbarMenu } from './toolbar-menu/toolbar-menu';
/**
 * FuseToolbarComponent
 */
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(router, translate, permissionService, sidebarService, phoenixUserWizardService, communicationService, accountInfoDialogs, userService, tenantSelectorDialogService, assetBranchSelectorDialogService) {
        var _this = this;
        this.router = router;
        this.translate = translate;
        this.permissionService = permissionService;
        this.sidebarService = sidebarService;
        this.phoenixUserWizardService = phoenixUserWizardService;
        this.communicationService = communicationService;
        this.accountInfoDialogs = accountInfoDialogs;
        this.userService = userService;
        this.tenantSelectorDialogService = tenantSelectorDialogService;
        this.assetBranchSelectorDialogService = assetBranchSelectorDialogService;
        this.canChangeTenant = false;
        this.canChangeAssetBranch = false;
        this.flagPath = 'assets/images/flags/';
        this.imagePath = 'assets/images/';
        this.loadUserMenu();
        this.userChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.UserChanged)
            .subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
            var tenants, _a, selectedTenants, tenant;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadUserMenu();
                        if (!(localStorage.getItem("availableTenants") != null)) return [3 /*break*/, 1];
                        _a = JSON.parse(localStorage.getItem("availableTenants"));
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.permissionService.getAvailableTenants().toPromise()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        tenants = _a;
                        if (tenants.length > 0) {
                            selectedTenants = localStorage.getItem("selectedTenants") ? JSON.parse(localStorage.getItem("selectedTenants")) : [];
                            if (selectedTenants.length > 0 && tenants.length > 0) {
                                tenant = tenants.find(function (t) { return t.id === selectedTenants[0]; });
                                if (tenant) {
                                    this.currentTenant = tenant.name;
                                }
                                this.canChangeTenant = true;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        this.tenantChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.TenantChanged)
            .subscribe(function (tenants) {
            if (tenants.length > 0) {
                _this.currentTenant = tenants[0].name;
                _this.canChangeTenant = true;
            }
        });
        this.assetBranchChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
            .subscribe(function (assetBranches) {
            if (assetBranches.length > 0) {
                _this.currentAssetBranch = assetBranches[0].name;
                _this.canChangeAssetBranch = _.get(_this.permissionService, 'availableAssetRootBranches', []).length > 1;
            }
        });
        this.userGroupChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.UserGroupChanged)
            .subscribe(function () {
            _this.canChangeAssetBranch = _.get(_this.permissionService, 'availableAssetRootBranches', []).length > 1;
        });
        var profileSettingsEntry = new ToolbarIconMenuItem('prSe', 'TOOLBAR.PROFILESETTINGS', function () {
            _this.openProfileSettings();
        }, 'account_circle', this);
        var changePasswordEntry = new ToolbarIconMenuItem('prCp', 'TOOLBAR.CHANGEPASSWORD', function () {
            _this.openChangePassword();
        }, 'vpn_key', this);
        var logoutEntry = new ToolbarIconMenuItem('logout', 'Logout', this.logout, 'exit_to_app', this);
        this.userMenuEntries = [profileSettingsEntry, changePasswordEntry, logoutEntry];
        var german = new ToolbarImageMenuItem('de', 'LANGUAGES.GERMAN', this.setLanguage, this.flagPath + "de.png", this);
        var english = new ToolbarImageMenuItem('en', 'LANGUAGES.ENGLISH', this.setLanguage, this.flagPath + "en.png", this);
        var french = new ToolbarImageMenuItem('fr', 'LANGUAGES.FRENCH', this.setLanguage, this.flagPath + "fr.png", this);
        var italian = new ToolbarImageMenuItem('it', 'LANGUAGES.ITALIAN', this.setLanguage, this.flagPath + "it.png", this);
        this.languageMenuEntries = [
            german,
            english,
            french,
            italian,
        ];
        // TODO: Das hier sollte noch schöner werden.
        this.languageMenu = new ToolbarMenu('languageMenu', this.translate.currentLang, 's-16', 'arrow_drop_down', 'flag mr-8', "" + this.flagPath + this.translate.currentLang + ".png", 'iso text-uppercase');
        this.currentTenant = _.get(this.permissionService, 'tenants', []).length > 0 ? this.permissionService.tenants[0].name : undefined;
        this.currentAssetBranch = _.get(this.permissionService, 'assetBranches', []).length > 1 ? this.permissionService.assetBranches[0].name : undefined;
        this.canChangeTenant = _.get(this.permissionService, 'availableTenants', []).length > 1;
        this.canChangeAssetBranch = _.get(this.permissionService, 'availableAssetRootBranches', []).length > 1;
    }
    ToolbarComponent.prototype.changeTenant = function () {
        return __awaiter(this, void 0, void 0, function () {
            var newTenant;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tenantSelectorDialogService.showTenantSelectorDialog(this.permissionService.availableTenants, true).toPromise()];
                    case 1:
                        newTenant = _a.sent();
                        if (!(Array.isArray(newTenant) && newTenant.length)) return [3 /*break*/, 3];
                        this.permissionService.tenants = newTenant;
                        return [4 /*yield*/, this.permissionService.refreshBranchesForSelectedTenant()];
                    case 2:
                        _a.sent();
                        this.communicationService.emit(PhoenixCommunicationSubject.TenantChanged, this.permissionService.tenants);
                        this.router.navigate(['/']);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ToolbarComponent.prototype.changeAssetBranch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var newAssetBranch;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this.permissionService.availableAssetRootBranches, true).toPromise()];
                    case 1:
                        newAssetBranch = _a.sent();
                        if (Array.isArray(newAssetBranch) && newAssetBranch.length) {
                            this.permissionService.assetBranches = newAssetBranch;
                            this.permissionService.updateGroupAssetPairs();
                            this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.permissionService.assetBranches);
                            /*
                            this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                            this.communicationService.emit(PhoenixCommunicationSubject.UpdateDateTimePickersSelectedPreset);
                            */
                            this.router.navigate(['/']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * gets currently logged in user from gapicon, else returns 'unknown'
     * @returns {string}
     */
    ToolbarComponent.prototype.getCurrentUser = function () {
        var userDto = this.permissionService.user;
        return userDto ? userDto.firstName + " " + userDto.lastName : 'unknown';
    };
    /**
     * deletes token and navigates to Login-Page
     */
    ToolbarComponent.prototype.logout = function () {
        this.context.permissionService.logout();
    };
    ToolbarComponent.prototype.ngOnDestroy = function () {
        this.userChangedSubscription.unsubscribe();
        this.tenantChangedSubscription.unsubscribe();
        this.userGroupChangedSubscription.unsubscribe();
    };
    /**
     * Shows change password dialog for current user
     */
    ToolbarComponent.prototype.openChangePassword = function () {
        this.accountInfoDialogs.openChangePasswordDialog();
    };
    /**
     * will open Profile Settings with option to change PW
     */
    ToolbarComponent.prototype.openProfileSettings = function () {
        this.phoenixUserWizardService.openEditUserWizard(this.permissionService.user);
    };
    /**
     * sets language to be used for translation
     * @param lang
     */
    ToolbarComponent.prototype.setLanguage = function (lang) {
        // Set the selected language for toolbar
        this.context.languageMenu.title = lang.id;
        localStorage.setItem('selectedLanguage', lang.id);
        this.context.languageMenu.imageSrc = lang.picture;
        // Use the selected language for translations
        this.context.translate.use(lang.id);
        // save the language settings:
        this.context.setUserLanguage(this.context.permissionService.user, lang.id);
        this.context.userService.updateUser(this.context.permissionService.user).toPromise();
    };
    ToolbarComponent.prototype.toggleSidebarOpened = function (key) {
        this.sidebarService.getSidebar(key).toggleOpen();
    };
    ToolbarComponent.prototype.loadUserMenu = function () {
        this.userMenu = new ToolbarMenu('userMenu', this.getCurrentUser(), 's-16', 'arrow_drop_down', 'avatar', this.imagePath + "avatars/profile.jpg", 'username mr-12');
    };
    ToolbarComponent.prototype.loadLanguageMenu = function (currentUser) {
        var locale = currentUser.configurations.find(function (value) { return value.type === 'LOCALE'; });
        this.languageMenu = new ToolbarMenu('languageMenu', locale.value, 's-16', 'arrow_drop_down', 'flag mr-8', "" + this.flagPath + locale.value + ".png", 'iso text-uppercase');
    };
    ToolbarComponent.prototype.setUserLanguage = function (userDto, language) {
        var config = userDto.configurations.find(function (conf) {
            if (conf.type === PhoenixUserConfigType.Locale) {
                conf.value = language;
            }
            return conf.type === PhoenixUserConfigType.Locale;
        });
        if (!config) {
            var conf = new UserConfigDto();
            conf.type = PhoenixUserConfigType.Locale;
            conf.value = language;
            userDto.configurations.push(conf);
        }
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
