import { PhoenixFormElementType } from '../../enums/phoenix-form-element-type.enum';
var PhoenixFormSwitch = /** @class */ (function () {
    function PhoenixFormSwitch(formGroup, formControlName, label, hint, disabled, checked) {
        if (label === void 0) { label = ''; }
        if (hint === void 0) { hint = ''; }
        if (disabled === void 0) { disabled = false; }
        if (checked === void 0) { checked = false; }
        this.type = PhoenixFormElementType.Switch;
        this.formGroup = formGroup;
        this.formControlName = formControlName;
        this.label = label;
        this.hint = hint;
        this.disabled = disabled;
        this.checked = checked;
    }
    return PhoenixFormSwitch;
}());
export { PhoenixFormSwitch };
