var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';
var AlarmSearchCriteriaDto = /** @class */ (function (_super) {
    __extends(AlarmSearchCriteriaDto, _super);
    function AlarmSearchCriteriaDto(alarmSearchCriteriaDto) {
        var _this = _super.call(this, alarmSearchCriteriaDto) || this;
        _this.alarmId = undefined;
        _this.assetIds = undefined;
        _this.dateFrom = undefined;
        _this.dateTo = undefined;
        _this.statuses = undefined;
        _this.userIds = undefined;
        Object.assign(_this, alarmSearchCriteriaDto);
        return _this;
    }
    return AlarmSearchCriteriaDto;
}(SearchCriteriaPageableDto));
export { AlarmSearchCriteriaDto };
