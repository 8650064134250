import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatOptionModule, MatSelectModule, MatSlideToggleModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixFormIconButtonComponent} from './phoenix-form-button/phoenix-form-icon-button.component';
import {PhoenixFormDropdownComponent} from './phoenix-form-dropdown/phoenix-form-dropdown.component';
import {PhoenixFormPasswordComponent} from './phoenix-form-password/phoenix-form-password.component';
import {PhoenixFormSwitchComponent} from './phoenix-form-switch/phoenix-form-switch.component';
import {PhoenixFormTextComponent} from './phoenix-form-text/phoenix-form-text.component';
import {PhoenixFormTextareaComponent} from './phoenix-form-textarea/phoenix-form-textarea.component';
import {PhoenixFormComponent} from './phoenix-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
  ],
  declarations: [
    PhoenixFormComponent,
    PhoenixFormTextComponent,
    PhoenixFormTextareaComponent,
    PhoenixFormSwitchComponent,
    PhoenixFormIconButtonComponent,
    PhoenixFormPasswordComponent,
    PhoenixFormDropdownComponent,
  ],
  exports: [
    PhoenixFormComponent,
    PhoenixFormTextComponent,
    PhoenixFormSwitchComponent,
    PhoenixFormTextareaComponent,
    PhoenixFormIconButtonComponent,
    PhoenixFormPasswordComponent,
    PhoenixFormDropdownComponent,
  ],
})
export class PhoenixFormModule {
}
