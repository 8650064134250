import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { PermissionService } from './permission.service';


@Injectable()
export class CanLoadGuard implements CanLoad {
  public constructor(private permissionService: PermissionService, private router: Router) {
  }

  public canLoad(activatedRoute: Route): Observable<boolean> | Promise<boolean> | boolean {
    //console.log('canLoad', this.permissionService.userIsNotAuthenticated())
    if (this.permissionService.userIsNotAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    }
    if (this.permissionService.routeNeedsPermission(activatedRoute)) {
      const hasPermission = this.permissionService.hasPermission(activatedRoute.data.permission);
      return hasPermission;
    }
    // User is authenticated and needs no permission.
    return true;
  }
}
