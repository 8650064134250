var PhoenixSnackbarComponent = /** @class */ (function () {
    function PhoenixSnackbarComponent() {
    }
    Object.defineProperty(PhoenixSnackbarComponent.prototype, "text", {
        get: function () {
            return this._text;
        },
        set: function (value) {
            this._text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixSnackbarComponent.prototype, "buttons", {
        get: function () {
            return this._buttons;
        },
        set: function (value) {
            this._buttons = value;
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixSnackbarComponent;
}());
export { PhoenixSnackbarComponent };
