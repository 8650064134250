/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-dynamic-icon-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/common";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/forms";
import * as i11 from "./phoenix-dynamic-icon-button.component";
var styles_PhoenixDynamicIconButtonComponent = [i0.styles];
var RenderType_PhoenixDynamicIconButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixDynamicIconButtonComponent, data: {} });
export { RenderType_PhoenixDynamicIconButtonComponent as RenderType_PhoenixDynamicIconButtonComponent };
function View_PhoenixDynamicIconButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["mat-mini-fab", ""], ["type", "submit"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonCallback("submit") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "mat-icon", [["class", "notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "color": 0 }), i1.ɵdid(6, 638976, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(7, 0, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = "primary"; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _ck(_v, 5, 0, _co.defaultIconColor); _ck(_v, 4, 0, currVal_5); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 6).inline; _ck(_v, 3, 0, currVal_4); var currVal_6 = _co.phoenixFormControl.value; _ck(_v, 7, 0, currVal_6); }); }
function View_PhoenixDynamicIconButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["mat-mini-fab", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callbackFromOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "mat-icon", [["class", "notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(5, 638976, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null]], { inline: [0, "inline"] }, null), (_l()(), i1.ɵted(6, 0, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.phoenixFormControl.disabled || _co.phoenixFormControl.options["disabled"]); var currVal_3 = (_co.phoenixFormControl.options["color"] ? _co.phoenixFormControl.options["color"] : "primary"); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.getIconStyle(); _ck(_v, 4, 0, currVal_5); var currVal_6 = true; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 5).inline; _ck(_v, 3, 0, currVal_4); var currVal_7 = _co.phoenixFormControl.value; _ck(_v, 6, 0, currVal_7); }); }
export function View_PhoenixDynamicIconButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "mat-caption mt-12 mb-4 text-center"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "hidden": 0 }), i1.ɵdid(3, 540672, null, 0, i10.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i10.ControlContainer, null, [i10.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i10.NgControlStatusGroup, [[4, i10.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicIconButtonComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixDynamicIconButtonComponent_2)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "mat-caption mt-12 mb-4 text-center"; var currVal_8 = _ck(_v, 2, 0, _co.phoenixFormControl.options["hidden"]); _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = _co.form; _ck(_v, 3, 0, currVal_9); var currVal_10 = !_co.phoenixFormControl.options["callback"]; _ck(_v, 7, 0, currVal_10); var currVal_11 = _co.phoenixFormControl.options["callback"]; _ck(_v, 9, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PhoenixDynamicIconButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-dynamic-icon-button", [], null, null, null, View_PhoenixDynamicIconButtonComponent_0, RenderType_PhoenixDynamicIconButtonComponent)), i1.ɵdid(1, 114688, null, 0, i11.PhoenixDynamicIconButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoenixDynamicIconButtonComponentNgFactory = i1.ɵccf("phoenix-dynamic-icon-button", i11.PhoenixDynamicIconButtonComponent, View_PhoenixDynamicIconButtonComponent_Host_0, { form: "form", phoenixFormControl: "phoenixFormControl" }, {}, []);
export { PhoenixDynamicIconButtonComponentNgFactory as PhoenixDynamicIconButtonComponentNgFactory };
