import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { PhoenixSnackbarService } from '../../@phoenix/components/phoenix-snackbar/phoenix-snackbar.service';
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(snackBarService) {
        this.snackBarService = snackBarService;
    }
    ErrorInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap(function () {
        }, function (event) {
            if (event instanceof HttpErrorResponse) {
                if (req.method == 'DELETE' && req.url.startsWith('/api/users/user/')) {
                    var pathSegments = req.url.split('/');
                    if (pathSegments.length > 5 && pathSegments[5] != 'tenant') {
                        _this.snackBarService.openPhoenixHttpErrorSnackbar(event);
                    }
                }
                else {
                    _this.snackBarService.openPhoenixHttpErrorSnackbar(event);
                }
            }
        }));
    };
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
