import { plainToClass } from 'class-transformer';
import { Email } from '../api/email';
import { EmailRequest } from '../api/email-request';
import { EmailDto } from '../dto/email-dto';
import { EmailRequestDto } from '../dto/email-request-dto';
import * as i0 from "@angular/core";
var EmailMapperService = /** @class */ (function () {
    function EmailMapperService() {
    }
    EmailMapperService.prototype.emailToEmailDto = function (email) {
        return plainToClass(EmailDto, email);
    };
    EmailMapperService.prototype.emailDtoToEmail = function (emailDto) {
        return plainToClass(Email, emailDto);
    };
    EmailMapperService.prototype.emailRequestToEmailRequestDto = function (emailRequest) {
        return plainToClass(EmailRequestDto, emailRequest);
    };
    EmailMapperService.prototype.emailRequestDtoToEmailRequest = function (emailRequestDto) {
        return plainToClass(EmailRequest, emailRequestDto);
    };
    EmailMapperService.ngInjectableDef = i0.defineInjectable({ factory: function EmailMapperService_Factory() { return new EmailMapperService(); }, token: EmailMapperService, providedIn: "root" });
    return EmailMapperService;
}());
export { EmailMapperService };
