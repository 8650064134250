import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixSharedServicesModule} from '../../../services/phoenix-shared/phoenix-shared-services.module';
import {PhoenixSharedComponentsModule} from '../../phoenix-shared-components.module';

import {DisableControlDirective} from './directives/disable-control.directive';
import {PhoenixDialogFormControlComponent} from './phoenix-dialog-form-control/phoenix-dialog-form-control.component';
import {PhoenixDynamicButtonComponent} from './phoenix-dynamic-button/phoenix-dynamic-button.component';
import {PhoenixDynamicCheckboxComponent} from './phoenix-dynamic-checkbox/phoenix-dynamic-checkpoint.component';
import {PhoenixDynamicChipsComponent} from './phoenix-dynamic-chips/phoenix-dynamic-chips.component';
import {PhoenixDynamicDropdownComponent} from './phoenix-dynamic-dropdown/phoenix-dynamic-dropdown.component';
import {PhoenixDynamicFormControlArrayComponent} from './phoenix-dynamic-form-control-array/phoenix-dynamic-form-control-array.component';
import { PhoenixDynamicIconButtonComponent } from './phoenix-dynamic-icon-button/phoenix-dynamic-icon-button.component';
import {PhoenixDynamicIconComponent} from './phoenix-dynamic-icon/phoenix-dynamic-icon.component';
import {PhoenixDynamicPasswordComponent} from './phoenix-dynamic-password/phoenix-dynamic-password.component';
import {PhoenixDynamicSwitchComponent} from './phoenix-dynamic-switch/phoenix-dynamic-switch.component';
import {PhoenixDynamicTabswitcherComponent} from './phoenix-dynamic-tabswitcher/phoenix-dynamic-tabswitcher.component';
import {PhoenixDynamicTextComponent} from './phoenix-dynamic-text/phoenix-dynamic-text.component';
import {PhoenixDynamicTextareaComponent} from './phoenix-dynamic-textarea/phoenix-dynamic-textarea.component';
import {PhoenixDynamicTextboxComponent} from './phoenix-dynamic-textbox/phoenix-dynamic-textbox.component';
import {PhoenixDynamicThresholdLimitComponent} from './phoenix-dynamic-threshold-limit/phoenix-dynamic-threshold-limit.component';
import { PhoenixDynamicTimeWindowIntervalComponent } from './phoenix-dynamic-time-window-interval/phoenix-dynamic-time-window-interval.component';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatInputModule,
      MatCheckboxModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTabsModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    PhoenixSharedComponentsModule,
    PhoenixSharedServicesModule,
  ],
  declarations: [
    PhoenixDynamicTextareaComponent,
    PhoenixDynamicTextboxComponent,
    PhoenixDynamicTextComponent,
    PhoenixDynamicSwitchComponent,
    PhoenixDynamicPasswordComponent,
    PhoenixDynamicChipsComponent,
    PhoenixDynamicIconComponent,
    PhoenixDynamicIconButtonComponent,
    PhoenixDynamicDropdownComponent,
    PhoenixDynamicTabswitcherComponent,
    PhoenixDynamicButtonComponent,
    PhoenixDynamicThresholdLimitComponent,
    PhoenixDialogFormControlComponent,
    PhoenixDynamicFormControlArrayComponent,
    DisableControlDirective,
    PhoenixDynamicCheckboxComponent,
    PhoenixDynamicTimeWindowIntervalComponent,
  ],
  exports: [
    PhoenixDialogFormControlComponent,
  ],
})
export class PhoenixDynamicFormControlsModule {
}
