import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { ExtendedInformation } from '../api/extended-information';
import { ExtendedInformationType } from '../api/extended-information-type.enum';
import { MeasurementType } from '../api/measurement-type';
import { MeasurementTypeSearchCriteria } from '../api/measurement-type-search-criteria';
import { MeasurementValueType } from '../api/measurement-value-type';
import { ValueType } from '../api/value-type.enum';
import { ExtendedInformationDto } from '../dto/extended-information-dto';
import { ExtendedInformationTypeDto } from '../dto/extended-information-type-dto.enum';
import { MeasurementTypeDto } from '../dto/measurement-type-dto';
import { MeasurementValueTypeDto } from '../dto/measurement-value-type-dto';
import { ValueTypeDto } from '../dto/value-type-dto.enum';
import * as i0 from "@angular/core";
var MeasurementTypeMapperService = /** @class */ (function () {
    function MeasurementTypeMapperService() {
    }
    MeasurementTypeMapperService.prototype.measurementTypeArrayToMeasurementTypeDtoArray = function (measurementTypes) {
        var _this = this;
        return _.map(measurementTypes, function (measurementType) { return _this.measurementTypeToMeasurementTypeDto(measurementType); });
    };
    MeasurementTypeMapperService.prototype.measurementTypeDtoToMeasurementType = function (measurementTypeDto) {
        var measurementType = plainToClass(MeasurementType, measurementTypeDto);
        measurementType.valueTypes = this.measurementValueTypeDtoArrayToMeasurementValueTypeArray(measurementTypeDto.valueTypes);
        measurementType.extendedInformations = this.extendedInformationDtoArrayToExtendedInformationArray(measurementTypeDto.extendedInformations);
        return measurementType;
    };
    MeasurementTypeMapperService.prototype.measurementTypeSearchCriteriaDtoToMeasurementTypeSearchCriteria = function (measurementTypeSearchCriteriaDto) {
        return plainToClass(MeasurementTypeSearchCriteria, measurementTypeSearchCriteriaDto);
    };
    MeasurementTypeMapperService.prototype.measurementTypeToMeasurementTypeDto = function (measurementType) {
        var measurementTypeDto = plainToClass(MeasurementTypeDto, measurementType);
        measurementTypeDto.valueTypes = this.measurementValueTypeArrayToMeasurementValueTypeDtoArray(measurementType.valueTypes);
        measurementTypeDto.extendedInformations = this.extendedInformationArrayToExtendedInformationDtoArray(measurementType.extendedInformations);
        return measurementTypeDto;
    };
    MeasurementTypeMapperService.prototype.extendedInformationArrayToExtendedInformationDtoArray = function (informations) {
        var _this = this;
        return _.map(informations, function (information) { return _this.extendedInformationToExtendedInformationDto(information); });
    };
    MeasurementTypeMapperService.prototype.extendedInformationDtoArrayToExtendedInformationArray = function (informationDtos) {
        var _this = this;
        return _.map(informationDtos, function (informationDto) { return _this.extendedInformationDtoToExtendedInformation(informationDto); });
    };
    MeasurementTypeMapperService.prototype.extendedInformationDtoToExtendedInformation = function (informationDto) {
        var extendedInformation = plainToClass(ExtendedInformation, informationDto);
        extendedInformation.type = this.extendedInformationTypeDtoToExtendedInformationType(informationDto.type);
        return extendedInformation;
    };
    MeasurementTypeMapperService.prototype.extendedInformationToExtendedInformationDto = function (information) {
        var extendedInformationDto = plainToClass(ExtendedInformationDto, information);
        extendedInformationDto.type = this.extendedInformationTypeToExtendedInformationTypeDto(information.type);
        return extendedInformationDto;
    };
    MeasurementTypeMapperService.prototype.extendedInformationTypeDtoToExtendedInformationType = function (typeDto) {
        return typeDto ? ExtendedInformationType[typeDto.toUpperCase()] : undefined;
    };
    MeasurementTypeMapperService.prototype.extendedInformationTypeToExtendedInformationTypeDto = function (type) {
        return type ? ExtendedInformationTypeDto[type.toUpperCase()] : undefined;
    };
    MeasurementTypeMapperService.prototype.measurementValueTypeArrayToMeasurementValueTypeDtoArray = function (measurementValueTypes) {
        var _this = this;
        return _.map(measurementValueTypes, function (measurementValueType) { return _this.measurementValueTypeToMeasurementValueTypeDto(measurementValueType); });
    };
    MeasurementTypeMapperService.prototype.measurementValueTypeDtoArrayToMeasurementValueTypeArray = function (measurementValueTypeDtos) {
        var _this = this;
        return _.map(measurementValueTypeDtos, function (measurementValueTypeDto) { return _this.measurementValueTypeDtoToMeasurementValueType(measurementValueTypeDto); });
    };
    MeasurementTypeMapperService.prototype.measurementValueTypeDtoToMeasurementValueType = function (measurementValueTypeDto) {
        var measurementValueType = plainToClass(MeasurementValueType, measurementValueTypeDto);
        measurementValueType.children = this.measurementValueTypeDtoArrayToMeasurementValueTypeArray(measurementValueTypeDto.children);
        measurementValueType.type = this.valueTypeDtoToValueType(measurementValueTypeDto.type);
        return measurementValueType;
    };
    MeasurementTypeMapperService.prototype.measurementValueTypeToMeasurementValueTypeDto = function (measurementValueType) {
        var measurementValueTypeDto = plainToClass(MeasurementValueTypeDto, measurementValueType);
        measurementValueTypeDto.children = this.measurementValueTypeArrayToMeasurementValueTypeDtoArray(measurementValueType.children);
        measurementValueTypeDto.type = this.valueTypeToValueTypeDto(measurementValueType.type);
        return measurementValueTypeDto;
    };
    MeasurementTypeMapperService.prototype.valueTypeDtoToValueType = function (valueTypeDto) {
        return valueTypeDto ? ValueType[valueTypeDto.toUpperCase()] : undefined;
    };
    MeasurementTypeMapperService.prototype.valueTypeToValueTypeDto = function (valueType) {
        return valueType ? ValueTypeDto[valueType.toUpperCase()] : undefined;
    };
    MeasurementTypeMapperService.ngInjectableDef = i0.defineInjectable({ factory: function MeasurementTypeMapperService_Factory() { return new MeasurementTypeMapperService(); }, token: MeasurementTypeMapperService, providedIn: "root" });
    return MeasurementTypeMapperService;
}());
export { MeasurementTypeMapperService };
