import { Subject } from 'rxjs';
var MainModule = /** @class */ (function () {
    function MainModule() {
        this.ngUnsubscribe = new Subject();
    }
    MainModule.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    return MainModule;
}());
export { MainModule };
