// TODO: Diese Klasse, das Interface und ToolbarImageMenuItem unterscheiden sich nur im vordefinierten _type. Sind dann 2 Klassen und 1 Interface notwendig???
/**
 * Implementation of ToolbarMenuItem Interface
 */
var ToolbarIconMenuItem = /** @class */ (function () {
    function ToolbarIconMenuItem(id, title, action, icon, context) {
        this._type = 'icon';
        this.id = id;
        this.title = title;
        this.action = action;
        this.picture = icon;
        this.context = context;
    }
    Object.defineProperty(ToolbarIconMenuItem.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarIconMenuItem.prototype, "title", {
        get: function () {
            return this._title;
        },
        set: function (value) {
            this._title = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarIconMenuItem.prototype, "action", {
        get: function () {
            return this._action;
        },
        set: function (value) {
            this._action = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarIconMenuItem.prototype, "picture", {
        get: function () {
            return this._picture;
        },
        set: function (value) {
            this._picture = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarIconMenuItem.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarIconMenuItem.prototype, "context", {
        get: function () {
            return this._context;
        },
        set: function (value) {
            this._context = value;
        },
        enumerable: true,
        configurable: true
    });
    return ToolbarIconMenuItem;
}());
export { ToolbarIconMenuItem };
