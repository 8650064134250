import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatGridListModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixDynamicFormControlsModule} from './form-controls/phoenix-dynamic-form-controls.module';
import {PhoenixDialogComponent} from './phoenix-dialog/phoenix-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    FlexLayoutModule,
    MatDialogModule,
    MatGridListModule,
    MatDividerModule,
    MatCheckboxModule,
    PhoenixDynamicFormControlsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PhoenixDialogComponent,
  ],
  entryComponents: [
    PhoenixDialogComponent,
  ],
})
export class PhoenixDialogModule {
}
