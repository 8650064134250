import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule, MatDialogModule, MatListModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixDialogNewModule} from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';

import {PhoenixCloneConfirmationDialogComponent} from './phoenix-clone-confirmation-dialog.component';
import {PhoenixCloneConfirmationDialogService} from './phoenix-clone-confirmation-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,

    MatListModule,
    MatDialogModule,
    MatButtonModule,

    PhoenixDialogNewModule,
  ],
  declarations: [
    PhoenixCloneConfirmationDialogComponent,
  ],
  entryComponents: [
    PhoenixCloneConfirmationDialogComponent,
  ],
  providers: [
    PhoenixCloneConfirmationDialogService,
  ],
})
export class PhoenixPhoenixCloneConfirmationDialogModule {
}
