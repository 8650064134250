var MeasurementTypeDto = /** @class */ (function () {
    function MeasurementTypeDto(measurementTypeDto) {
        this.description = undefined;
        this.extendedInformations = undefined;
        this.id = undefined;
        this.key = undefined;
        this.name = undefined;
        this.tags = undefined;
        this.valueTypes = undefined;
        this.version = undefined;
        this.beginDate = undefined;
        this.endDate = undefined;
        Object.assign(this, measurementTypeDto);
    }
    return MeasurementTypeDto;
}());
export { MeasurementTypeDto };
