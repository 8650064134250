var Sensor = /** @class */ (function () {
    function Sensor(sensor) {
        this.assetId = undefined;
        this.currentConfiguration = undefined;
        this.wantedConfiguration = undefined;
        this.sensorId = undefined;
        this.type = undefined;
        Object.assign(this, sensor);
    }
    Sensor.prototype.toJSON = function () {
        return {
            assetId: this.assetId,
            currentConfiguration: this.currentConfiguration,
            wantedConfiguration: this.wantedConfiguration,
            sensorId: this.sensorId,
            type: this.type,
        };
    };
    return Sensor;
}());
export { Sensor };
