/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-snackbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../phoenix-button/phoenix-button.component.ngfactory";
import * as i5 from "../phoenix-button/phoenix-button.component";
import * as i6 from "@angular/common";
import * as i7 from "./phoenix-snackbar.component";
var styles_PhoenixSnackbarComponent = [i0.styles];
var RenderType_PhoenixSnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixSnackbarComponent, data: {} });
export { RenderType_PhoenixSnackbarComponent as RenderType_PhoenixSnackbarComponent };
function View_PhoenixSnackbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "phoenix-button", [], null, null, null, i4.View_PhoenixButtonComponent_0, i4.RenderType_PhoenixButtonComponent)), i1.ɵdid(4, 49152, null, 0, i5.PhoenixButtonComponent, [], { button: [0, "button"] }, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_PhoenixSnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixSnackbarComponent_1)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.buttons; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.text; _ck(_v, 4, 0, currVal_2); }); }
export function View_PhoenixSnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-snackbar", [], null, null, null, View_PhoenixSnackbarComponent_0, RenderType_PhoenixSnackbarComponent)), i1.ɵdid(1, 49152, null, 0, i7.PhoenixSnackbarComponent, [], null, null)], null, null); }
var PhoenixSnackbarComponentNgFactory = i1.ɵccf("phoenix-snackbar", i7.PhoenixSnackbarComponent, View_PhoenixSnackbarComponent_Host_0, {}, {}, []);
export { PhoenixSnackbarComponentNgFactory as PhoenixSnackbarComponentNgFactory };
