import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {PhoenixDialogButton} from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';

import {PhoenixCloneConfirmationDialogData} from './classes/PhoenixCloneConfirmationDialogData';
@Component({
  selector: 'phoenix-clone-confirmation-dialog',
  templateUrl: './phoenix-clone-confirmation-dialog.component.html',
  styleUrls: ['./phoenix-clone-confirmation-dialog.component.scss'],
})
export class PhoenixCloneConfirmationDialogComponent implements OnInit {

  public buttons: PhoenixDialogButton[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixCloneConfirmationDialogData,
    private dialogRef: MatDialogRef<PhoenixCloneConfirmationDialogComponent>,
  ) {
  }

  public ngOnInit(): void {

      this.buttons = [

        new PhoenixDialogButton({
          label: 'WIZARDS.CLONE.CANCEL',
          click: (): void => this.dialogRef.close(false),
        }),

        new PhoenixDialogButton({
          label: 'WIZARDS.CLONE.YESBUTTON',
          click: (): void => this.dialogRef.close(true),
        }),
      ];
  }
}
