var BaseDto = /** @class */ (function () {
    function BaseDto(baseDto) {
        this.id = undefined;
        this.name = undefined;
        this.description = undefined;
        this.tags = [];
        this.tenantId = undefined;
        this.properties = [];
        Object.assign(this, baseDto);
    }
    return BaseDto;
}());
export { BaseDto };
