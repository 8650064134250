import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Sensor } from '../api/sensor';
import { SensorConfig } from '../api/sensor-config';
import { SensorConfigValue } from '../api/sensor-config-value';
import { SensorSearchCriteria } from '../api/sensor-search-criteria';
import { SensorConfigDto } from '../dto/sensor-config-dto';
import { SensorConfigValueDto } from '../dto/sensor-config-value-dto';
import { SensorDto } from '../dto/sensor-dto';
import { SensorSearchCriteriaDto } from '../dto/sensor-search-criteria-dto';
import * as i0 from "@angular/core";
var SensorMapperService = /** @class */ (function () {
    function SensorMapperService() {
    }
    /*
    Sensor <=> SensorDto
     */
    SensorMapperService.prototype.sensorToSensorDto = function (sensor) {
        var sensorDto = plainToClass(SensorDto, sensor);
        sensorDto.currentConfiguration = sensorDto.currentConfiguration ? this.sensorConfigToSensorConfigDto(sensor.currentConfiguration) : undefined;
        sensorDto.wantedConfiguration = sensorDto.wantedConfiguration ? this.sensorConfigToSensorConfigDto(sensor.wantedConfiguration) : undefined;
        return sensorDto;
    };
    SensorMapperService.prototype.sensorDtoToSensor = function (sensorDto) {
        var sensor = plainToClass(Sensor, sensorDto);
        sensor.currentConfiguration = sensor.currentConfiguration ? this.sensorConfigDtoToSensorConfig(sensorDto.currentConfiguration) : undefined;
        sensor.wantedConfiguration = sensor.wantedConfiguration ? this.sensorConfigDtoToSensorConfig(sensorDto.wantedConfiguration) : undefined;
        return sensor;
    };
    SensorMapperService.prototype.sensorArrayToSensorDtoArray = function (sensors) {
        var _this = this;
        return _.map(sensors, function (sensor) { return _this.sensorToSensorDto(sensor); });
    };
    SensorMapperService.prototype.sensorDtoArrayToSensorArray = function (sensorDtos) {
        var _this = this;
        return _.map(sensorDtos, function (sensorDto) { return _this.sensorDtoToSensor(sensorDto); });
    };
    /*
   SensorSearchCriteria <=> SensorSearchCriteriaDto
    */
    SensorMapperService.prototype.sensorSearchCriteriaDtoToSensorSearchCriteria = function (sensorSearchCriteriaDto) {
        return plainToClass(SensorSearchCriteria, sensorSearchCriteriaDto);
    };
    SensorMapperService.prototype.sensorSearchCriteriaToSensorSearchCriteriaDto = function (sensorSearchCriteria) {
        return plainToClass(SensorSearchCriteriaDto, sensorSearchCriteria);
    };
    /*
    SensorConfig <=> SensorConfigDto
     */
    SensorMapperService.prototype.sensorConfigDtoToSensorConfig = function (sensorConfigDto) {
        var sensorConfig = plainToClass(SensorConfig, sensorConfigDto);
        sensorConfig.configurations = this.sensorConfigValueDtoArrayToSensorConfigValueArray(sensorConfigDto.configurations);
        return sensorConfig;
    };
    SensorMapperService.prototype.sensorConfigToSensorConfigDto = function (sensorConfig) {
        var sensorConfigDto = plainToClass(SensorConfigDto, sensorConfig);
        sensorConfigDto.configurations = this.sensorConfigValueArrayToSensorConfigValueDtoArray(sensorConfig.configurations);
        return sensorConfigDto;
    };
    /*
    SensorConfigValue <=> SensorConfigValueDto
    */
    SensorMapperService.prototype.sensorConfigValueDtoToSensorConfigValue = function (sensorConfigValueDto) {
        return plainToClass(SensorConfigValue, sensorConfigValueDto);
    };
    SensorMapperService.prototype.sensorConfigValueToSensorConfigValueDto = function (sensorConfigValue) {
        return plainToClass(SensorConfigValueDto, sensorConfigValue);
    };
    SensorMapperService.prototype.sensorConfigValueDtoArrayToSensorConfigValueArray = function (sensorConfigValueDtos) {
        var _this = this;
        return _.map(sensorConfigValueDtos, function (sensorConfigValueDto) { return _this.sensorConfigValueDtoToSensorConfigValue(sensorConfigValueDto); });
    };
    SensorMapperService.prototype.sensorConfigValueArrayToSensorConfigValueDtoArray = function (sensorConfigValues) {
        var _this = this;
        return _.map(sensorConfigValues, function (sensorConfigValue) { return _this.sensorConfigValueToSensorConfigValueDto(sensorConfigValue); });
    };
    SensorMapperService.ngInjectableDef = i0.defineInjectable({ factory: function SensorMapperService_Factory() { return new SensorMapperService(); }, token: SensorMapperService, providedIn: "root" });
    return SensorMapperService;
}());
export { SensorMapperService };
