var FileItemDto = /** @class */ (function () {
    function FileItemDto(fileItemDto) {
        this.childItems = undefined;
        this.contentEncoding = undefined;
        this.contentLength = undefined;
        this.contentMd5 = undefined;
        this.contentType = undefined;
        this.data = undefined;
        this.lastModified = undefined;
        this.name = undefined;
        this.owner = undefined;
        this.parent = undefined;
        this.type = undefined;
        Object.assign(this, fileItemDto);
    }
    return FileItemDto;
}());
export { FileItemDto };
