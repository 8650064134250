import { Routes } from '@angular/router';
import 'hammerjs';
import 'mousetrap';
import 'reflect-metadata';
import * as highstock from 'highcharts/modules/stock.src';
var appRoutes = [
    {
        path: '**',
        redirectTo: 'dashboard',
    }
];
var ɵ0 = function () { return [highstock]; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
