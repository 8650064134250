import { Router } from '@angular/router';
import { differenceInSeconds, isBefore } from 'date-fns';
import { EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthApiService } from './auth-api.service';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(authApi, router) {
        this.authApi = authApi;
        this.router = router;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var auth = this.authApi.getAuth();
        if (req.url.startsWith('/auth')) {
            return next.handle(req);
        }
        if (auth) {
            // Token is already expired or validity is smaller than 60 seconds.
            if (isBefore(auth.expires, new Date()) || differenceInSeconds(auth.expires, new Date()) < AuthInterceptor.minTokenValidity) {
                // token needs to be refreshed
                return this.authApi.refresh().pipe(mergeMap(function () {
                    req = _this.setHeaderToRequest(req, _this.authApi.getAuth());
                    return next.handle(req);
                }), catchError(function (error, caugth) {
                    _this.router.navigate(['/login']);
                    return EMPTY;
                }));
            }
            else {
                req = this.setHeaderToRequest(req, auth);
                return next.handle(req);
            }
        }
        else {
            return next.handle(req);
        }
    };
    AuthInterceptor.prototype.setHeaderToRequest = function (req, auth) {
        req = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + auth.access_token,
            },
        });
        return req;
    };
    AuthInterceptor.minTokenValidity = 60;
    return AuthInterceptor;
}());
export { AuthInterceptor };
