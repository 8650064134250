import { Subject } from 'rxjs/Subject';
import * as i0 from "@angular/core";
var PhoenixCommunicationService = /** @class */ (function () {
    function PhoenixCommunicationService() {
    }
    PhoenixCommunicationService.prototype.getObservable = function (topic) {
        return this.getSubject(topic).asObservable();
    };
    PhoenixCommunicationService.prototype.emit = function (topic, message) {
        this.getSubject(topic).next(message ? message : undefined);
    };
    PhoenixCommunicationService.prototype.getSubject = function (topic) {
        if (PhoenixCommunicationService.subjects.has(topic)) {
            return PhoenixCommunicationService.subjects.get(topic);
        }
        else {
            var subject = new Subject();
            PhoenixCommunicationService.subjects.set(topic, subject);
            return subject;
        }
    };
    //TODO: We need to refactor it so we don't need Datatype 'any'
    PhoenixCommunicationService.subjects = new Map();
    PhoenixCommunicationService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixCommunicationService_Factory() { return new PhoenixCommunicationService(); }, token: PhoenixCommunicationService, providedIn: "root" });
    return PhoenixCommunicationService;
}());
export { PhoenixCommunicationService };
