import { Router } from '@angular/router';
import { PermissionService } from './permission.service';
var CanLoadGuard = /** @class */ (function () {
    function CanLoadGuard(permissionService, router) {
        this.permissionService = permissionService;
        this.router = router;
    }
    CanLoadGuard.prototype.canLoad = function (activatedRoute) {
        //console.log('canLoad', this.permissionService.userIsNotAuthenticated())
        if (this.permissionService.userIsNotAuthenticated()) {
            this.router.navigate(['/login']);
            return false;
        }
        if (this.permissionService.routeNeedsPermission(activatedRoute)) {
            var hasPermission = this.permissionService.hasPermission(activatedRoute.data.permission);
            return hasPermission;
        }
        // User is authenticated and needs no permission.
        return true;
    };
    return CanLoadGuard;
}());
export { CanLoadGuard };
