import { format, isValid } from 'date-fns';
var Measurement = /** @class */ (function () {
    function Measurement(measurement) {
        this.id = undefined;
        this.sensorId = undefined;
        this.asset = undefined;
        this.measurementValues = undefined;
        this.timestamp = undefined;
        this.type = undefined;
        this.properties = undefined;
        this.user = undefined;
        this.device = undefined;
        this.attachments = undefined;
        this.acknowledgment = undefined;
        this.approver = undefined;
        Object.assign(this, measurement);
    }
    Measurement.prototype.toJSON = function () {
        return {
            id: this.id,
            sensorId: this.sensorId,
            asset: this.asset,
            measurementValues: this.measurementValues,
            timestamp: (this.timestamp && isValid(this.timestamp)) ? format(this.timestamp) : undefined,
            type: this.type,
            properties: this.properties,
            user: this.user,
            device: this.device,
            attachments: this.attachments,
            acknowledgment: this.acknowledgment,
            approver: this.approver
        };
    };
    return Measurement;
}());
export { Measurement };
