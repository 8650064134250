import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { PhoenixButton } from '../phoenix-button/phoenix-button';
import { PhoenixDynamicFormControl } from '../phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../phoenix-dialog/phoenix-dialog/phoenix-dialog.component';

import { PhoenixSnackbarComponent } from './phoenix-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixSnackbarService {


  public constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {
  }

  public openPhoenixSnackbar(duration: number, horizontalPosition: MatSnackBarHorizontalPosition, verticalPosition: MatSnackBarVerticalPosition, text: string,
    panelClass?: string, buttons?: PhoenixButton[]): MatSnackBarRef<PhoenixSnackbarComponent> {
    const configObject: MatSnackBarConfig = {};
    configObject.duration = duration;
    configObject.horizontalPosition = horizontalPosition;
    configObject.verticalPosition = verticalPosition;
    configObject.panelClass = panelClass;

    const snackbarRef: MatSnackBarRef<PhoenixSnackbarComponent> = this.snackBar.openFromComponent(PhoenixSnackbarComponent, configObject);
    snackbarRef.instance.buttons = buttons;
    snackbarRef.instance.text = text;
    return snackbarRef;
  }

  public openPhoenixDefaultErrorSnackbar(text: string): MatSnackBarRef<PhoenixSnackbarComponent> {
    return this.openPhoenixDefaultSnackbar(text, 'red-bg');
  }

  public openPhoenixDefaultSnackbar(text: string, panelClass?: string): MatSnackBarRef<PhoenixSnackbarComponent> {
    const configObject: MatSnackBarConfig = new MatSnackBarConfig();
    configObject.duration = 4000;
    configObject.horizontalPosition = 'right';
    configObject.verticalPosition = 'top';
    configObject.panelClass = panelClass ? panelClass : 'green-bg';

    const snackbarRef: MatSnackBarRef<PhoenixSnackbarComponent> = this.snackBar.openFromComponent(PhoenixSnackbarComponent, configObject);
    snackbarRef.instance.text = text;
    return snackbarRef;
  }

  public openPhoenixHttpErrorSnackbar(error: HttpErrorResponse): MatSnackBarRef<PhoenixSnackbarComponent> {
    const okButton: PhoenixButton = new PhoenixButton('SNACKBAR.ERROR.BUTTONLABEL.OK', '', (): void => {
      snackbarRef.dismiss();
    });
    const showDetailsButton: PhoenixButton = new PhoenixButton('SNACKBAR.ERROR.BUTTONLABEL.SHOWDETAILS', '', (): void => {
      this.openPhoenixDialog('450px', 'SNACKBAR.ERROR.DIALOG.HEADLINE', 'SNACKBAR.ERROR.DIALOG.SUBHEADLINE', 'warn', this.createErrorTextFormControls(error));
    });
    const snackbarRef: MatSnackBarRef<PhoenixSnackbarComponent> = this.snackBar.openFromComponent(PhoenixSnackbarComponent, {
      duration: 10000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'red-bg',
    });
    if (error) {
      snackbarRef.instance.text = this.translateService.instant('HTTP.ERROR.CODE.' + error.error.errorCode);
    }
    snackbarRef.instance.buttons = [okButton, showDetailsButton];
    return snackbarRef;
  }

  private openPhoenixDialog(width: string, headline: string, subHeadline: string, color: string, formControlls: PhoenixDynamicFormControl<string>[]): void {
    this.dialog.open(PhoenixDialogComponent, {
      width: width,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: headline,
        subHeadline: subHeadline,
        buttons: {
          ok: true,
        },
        color: color,
        formControls: [
          formControlls,
        ],
      },
    });
  }

  private createErrorTextFormControls(error: HttpErrorResponse): PhoenixDynamicFormControl<string>[] {
    const hintTextOptions: { classes: { [key: string]: boolean } } = {
      classes: {
        'font-size-14': true,
        'primary-300-fg': true,
      },
    };
    const formControls: PhoenixDynamicFormControl<string>[] = [];
    const textToDisplay: string[] = [
      this.translateService.instant('HTTP.ERROR.DIALOG.STATUS') + ': ' + error.status,
      this.translateService.instant('HTTP.ERROR.DIALOG.ERRORCODE') + ': ' + error.error.errorCode,
      this.translateService.instant('HTTP.ERROR.DIALOG.ERRORMSG') + ': ' + this.translateService.instant('HTTP.ERROR.CODE.' + error.error.errorCode),
      this.translateService.instant('HTTP.ERROR.DIALOG.CORRELATIONID') + ': ' + error.error.correlationId,
    ];
    _.forEach(textToDisplay, (text: string) => {
      formControls.push(
        new PhoenixDynamicFormControl<string>(
          'text',
          PhoenixDynamicFormControlType.Text,
          undefined,
          false,
          hintTextOptions,
          text,
        ),
      );
    });
    if (_.get(error, 'error.errors', []).length > 0) {
      formControls.push(
        new PhoenixDynamicFormControl<string>(
          'text',
          PhoenixDynamicFormControlType.Text,
          undefined,
          false,
          hintTextOptions,
          this.translateService.instant('HTTP.ERROR.DIALOG.RAWERRORTEXTS') + ': ',
        ),
      );
      _.forEach(error.error.errors, (text: string) => {
        formControls.push(
          new PhoenixDynamicFormControl<string>(
            'text',
            PhoenixDynamicFormControlType.Text,
            undefined,
            false,
            hintTextOptions,
            text,
          ),
        );
      });
    }

    return formControls;
  }

}



