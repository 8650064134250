var NotificationConfig = /** @class */ (function () {
    function NotificationConfig(notificationConfig) {
        this.id = undefined;
        this.name = undefined;
        this.notificationType = undefined;
        this.measurementType = undefined;
        this.expression = '';
        Object.assign(this, notificationConfig);
    }
    NotificationConfig.prototype.toJSON = function () {
        return {
            id: this.id,
            name: this.name,
            notificationType: this.notificationType,
            measurementType: this.measurementType,
            expression: this.expression,
        };
    };
    return NotificationConfig;
}());
export { NotificationConfig };
