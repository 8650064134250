var MeasurementValue = /** @class */ (function () {
    function MeasurementValue(measurementValue) {
        this.key = undefined;
        this.unit = undefined;
        this.value = undefined;
        Object.assign(this, measurementValue);
    }
    MeasurementValue.prototype.toJSON = function () {
        return {
            key: this.key,
            unit: this.unit,
            value: this.value,
        };
    };
    return MeasurementValue;
}());
export { MeasurementValue };
