import 'rxjs/add/operator/timeout';
import { InjectionToken } from '@angular/core';
export var DEFAULT_TIMEOUT = new InjectionToken('defaultTimeout');
export var defaultTimeout = 50000;
var TimeoutInterceptor = /** @class */ (function () {
    function TimeoutInterceptor(defaultTimeout) {
        this.defaultTimeout = defaultTimeout;
    }
    TimeoutInterceptor.prototype.intercept = function (req, next) {
        var timeout = Number(req.headers.get('timeout')) || this.defaultTimeout;
        return next.handle(req).timeout(timeout);
    };
    return TimeoutInterceptor;
}());
export { TimeoutInterceptor };
