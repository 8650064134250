var UserInfo = /** @class */ (function () {
    function UserInfo(userInfo) {
        this.id = undefined;
        this.email = undefined;
        this.tenant = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        Object.assign(this, userInfo);
    }
    UserInfo.prototype.toJSON = function () {
        return {
            id: this.id,
            email: this.email,
            tenant: this.tenant,
            firstName: this.firstName,
            lastName: this.lastName,
        };
    };
    return UserInfo;
}());
export { UserInfo };
