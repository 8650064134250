var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isValid } from 'date-fns';
import { SearchCriteria } from '../../shared/classes/search-criteria';
var StatisticsSearchCriteria = /** @class */ (function (_super) {
    __extends(StatisticsSearchCriteria, _super);
    function StatisticsSearchCriteria(statisticsSearchCriteria) {
        var _this = _super.call(this, statisticsSearchCriteria) || this;
        _this.measurementPointIds = undefined;
        _this.assetIds = undefined;
        _this.thresholdIds = undefined;
        _this.dateFrom = undefined;
        _this.dateTo = undefined;
        Object.assign(_this, statisticsSearchCriteria);
        return _this;
    }
    StatisticsSearchCriteria.prototype.toJSON = function () {
        return {
            measurementPointIds: this.measurementPointIds,
            assetIds: this.assetIds,
            thresholdIds: this.thresholdIds,
            dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
            dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
        };
    };
    return StatisticsSearchCriteria;
}(SearchCriteria));
export { StatisticsSearchCriteria };
