import { OnInit } from '@angular/core';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';
var PhoenixTaskConfigDialogMonthlyComponent = /** @class */ (function () {
    function PhoenixTaskConfigDialogMonthlyComponent(data) {
        this.data = data;
        this.today = new Date();
        this.week = [
            { label: 'first', value: 1 },
            { label: 'second', value: 2 },
            { label: 'third', value: 3 },
            { label: 'fourth', value: 4 },
            { label: 'last', value: 999 },
        ];
        this.weekdays = [
            { label: 'Monday', value: 1 },
            { label: 'Tuesday', value: 2 },
            { label: 'Wednesday', value: 3 },
            { label: 'Thursday', value: 4 },
            { label: 'Friday', value: 5 },
            { label: 'Saturday', value: 6 },
            { label: 'Sunday', value: 7 },
        ];
    }
    PhoenixTaskConfigDialogMonthlyComponent.prototype.getStartDate = function () {
        return this.data.formGroup.get('start').value;
    };
    PhoenixTaskConfigDialogMonthlyComponent.prototype.ngOnInit = function () {
        this.days = [];
        for (var i = 1; i <= 31; i++) {
            this.days.push({ label: i < 10 ? '0'.concat(i.toString()) : ''.concat(i.toString()), value: i });
        }
    };
    PhoenixTaskConfigDialogMonthlyComponent.prototype.resetDays = function () {
        this.data.formGroup.get('days').setValue([]);
    };
    PhoenixTaskConfigDialogMonthlyComponent.prototype.resetWeekdays = function () {
        this.data.formGroup.get('weekdays').setValue(undefined);
        this.data.formGroup.get('week').setValue(undefined);
    };
    Object.defineProperty(PhoenixTaskConfigDialogMonthlyComponent.prototype, "assignedUserGroupIds", {
        get: function () {
            return this.data.formGroup.get('assignedUserGroupIds').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixTaskConfigDialogMonthlyComponent.prototype, "userGroups", {
        get: function () {
            return this.data.usergroups.sort(function (a, b) { return a.name.localeCompare(b.name); });
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixTaskConfigDialogMonthlyComponent;
}());
export { PhoenixTaskConfigDialogMonthlyComponent };
