import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicIconComponent = /** @class */ (function () {
    function PhoenixDynamicIconComponent() {
    }
    PhoenixDynamicIconComponent.prototype.ngOnInit = function () {
        if (_.has(this.phoenixFormControl.options, 'size') && _.isNumber(this.phoenixFormControl.options['size'])) {
            this.iconSize = this.phoenixFormControl.options['size'];
        }
        else {
            this.iconSize = 20;
        }
    };
    return PhoenixDynamicIconComponent;
}());
export { PhoenixDynamicIconComponent };
