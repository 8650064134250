var MeasurementPoint = /** @class */ (function () {
    //public alarmConfig: string = 'FIRST';
    function MeasurementPoint(measurementPoint) {
        this.sensorId = undefined;
        this.type = undefined;
        this.threshold = undefined;
        Object.assign(this, measurementPoint);
    }
    MeasurementPoint.prototype.toJSON = function () {
        return {
            sensorId: this.sensorId,
            type: this.type,
            threshold: this.threshold,
        };
    };
    return MeasurementPoint;
}());
export { MeasurementPoint };
