var MeasurementValueDto = /** @class */ (function () {
    function MeasurementValueDto(measurementValueDto) {
        this.key = undefined;
        this.unit = undefined;
        this.value = undefined;
        Object.assign(this, measurementValueDto);
    }
    return MeasurementValueDto;
}());
export { MeasurementValueDto };
