import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material';

import {ExtendedInformationTypeDto} from '../../../../gapicon/dto.module';

import {PhoenixMeasurementTypeExtendedInformationsDialog} from './phoenix-measurement-type-extended-informations-dialog';

@Component({
  selector: 'phoenix-measurement-type-extended-informations-dialog',
  templateUrl: './phoenix-measurement-type-extended-informations-dialog.component.html',
  styleUrls: ['./phoenix-measurement-type-extended-informations-dialog.component.scss'],
})
export class PhoenixMeasurementTypeExtendedInformationsDialogComponent implements OnInit {

  public types: { label: string, value: ExtendedInformationTypeDto }[] = [
    {
      label: ExtendedInformationTypeDto.TEXT,
      value: ExtendedInformationTypeDto.TEXT,
    },
    {
      label: ExtendedInformationTypeDto.URL,
      value: ExtendedInformationTypeDto.URL,
    },
  ];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixMeasurementTypeExtendedInformationsDialog,
  ) {
  }

  public addElement(): void {
    this.data.extendedInformationForms.push(
      new FormGroup({
        key: new FormControl(this.data.checklistElementKey, Validators.required),
        name: new FormControl(undefined, Validators.required),
        type: new FormControl(ExtendedInformationTypeDto.TEXT, Validators.required),
        value: new FormControl(undefined, Validators.required),
      }));
  }

  public ngOnInit(): void {
  }

  public removeElement(i: number): void {
    this.data.extendedInformationForms.removeAt(i);
  }

}
