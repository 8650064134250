var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isValid } from 'date-fns';
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var AlarmSearchCriteria = /** @class */ (function (_super) {
    __extends(AlarmSearchCriteria, _super);
    function AlarmSearchCriteria(alarmSearchCriteria) {
        var _this = _super.call(this, alarmSearchCriteria) || this;
        _this.alarmId = undefined;
        _this.dateFrom = undefined;
        _this.assetIds = undefined;
        _this.dateTo = undefined;
        _this.statuses = undefined;
        _this.userIds = undefined;
        Object.assign(_this, alarmSearchCriteria);
        return _this;
    }
    AlarmSearchCriteria.prototype.toJSON = function () {
        return {
            alarmId: this.alarmId,
            dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
            dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
            statuses: this.statuses,
            userIds: this.userIds,
            assetIds: this.assetIds,
            page: this.page,
            size: this.size,
        };
    };
    return AlarmSearchCriteria;
}(SearchCriteriaPageable));
export { AlarmSearchCriteria };
