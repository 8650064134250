var MeasurementDto = /** @class */ (function () {
    function MeasurementDto(measurementDto) {
        this.id = undefined;
        this.sensorId = undefined;
        this.asset = undefined;
        this.measurementValues = undefined;
        this.timestamp = undefined;
        this.type = undefined;
        this.properties = undefined;
        this.user = undefined;
        this.device = undefined;
        this.attachments = undefined;
        this.acknowledgment = undefined;
        this.approver = undefined;
        Object.assign(this, measurementDto);
    }
    return MeasurementDto;
}());
export { MeasurementDto };
