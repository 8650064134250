import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import { GroupApiService } from '@phoenix/gapicon/group/services/group-api.service';
import { addSeconds, differenceInSeconds } from 'date-fns';
import * as _ from 'lodash';

import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { TaskConfigDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto } from '../../../../gapicon/dto.module';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

import { PhoenixTaskConfigDialogDailyComponent } from './phoenix-task-config-dialog-daily.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixTaskConfigDialogDailyService {

  public constructor(
    private dialog: MatDialog
  ) {
  }

  public async openDialog(userGroups: UserGroupDto[], taskConfig?: TaskConfigDto): Promise<MatDialogRef<PhoenixTaskConfigDialogDailyComponent>> {
    const { start, end, assignedUserGroupIds } = this.getValuesForFormGroup(taskConfig);
    const startDatePicker: Date = taskConfig && new Date(taskConfig.startDate) < new Date() ? new Date(taskConfig.startDate) : new Date();
    const id: string = taskConfig ? taskConfig.id : undefined;
    const formGroup: FormGroup = new FormGroup({
      start: new FormControl(start, [Validators.required]),
      end: new FormControl(end, [Validators.required]),
      assignedUserGroupIds: new FormControl(assignedUserGroupIds, [Validators.required]),
    });

    const dialogRef: MatDialogRef<PhoenixTaskConfigDialogDailyComponent> = this.dialog.open(PhoenixTaskConfigDialogDailyComponent, {
      width: '950px',
      autoFocus: false,
      data: <PhoenixTaskConfigDialog>{
        title: 'TASKCONFIG.DAILY.TITLE',
        subtitle: 'TASKCONFIG.DAILY.SUBTITLE',
        buttons: [
          new PhoenixDialogButton({
            label: 'TASKCONFIG.CANCEL',
            click: (): void => dialogRef.close(),
          }),
          new PhoenixDialogButton({
            label: 'TASKCONFIG.ADD',
            click: (): void => dialogRef.close(this.handleDialogResult(id, formGroup.value)),
            color: 'accent',
            raised: true,
            disabled: (): boolean => formGroup.invalid,
          })],
        formGroup: formGroup,
        startDatePicker: startDatePicker,
        usergroups: userGroups
      },
    });
    return dialogRef;
  }

  private getValuesForFormGroup(taskConfig: TaskConfigDto): { start, end, assignedUserGroupIds } {
    const start: Date = _.get(taskConfig, ['startDate']) ? new Date(taskConfig.startDate) : undefined;
    const end: Date = _.get(taskConfig, ['startDate']) ? addSeconds(taskConfig.startDate, taskConfig.duration) : undefined;
    const assignedUserGroupIds: string[] = _.get(taskConfig, ['assignedUserGroupIds'], []);
    return { start, end, assignedUserGroupIds };
  }

  private handleDialogResult(id: string, result: { start: Date, end: Date, assignedUserGroupIds: string[] }): TaskConfigDto {
    const config: { [key: string]: number[] } = {};
    const repeat: TaskConfigRepeatDto = new TaskConfigRepeatDto({
      config: config,
      type: TaskConfigRepeatTypeDto.daily,
      value: 1,
    });

    return new TaskConfigDto({
      id: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin',
      startDate: result.start,
      repeat: repeat,
      duration: differenceInSeconds(result.end, result.start),
      createdDate: new Date(),
      assignedUserGroupIds: result.assignedUserGroupIds
    });
  }
}
