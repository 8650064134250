import { plainToClass } from 'class-transformer';
import { AssetMapperService } from '../../asset/mapper/asset-mapper.service';
import { SensorRegistration } from '../api/sensor-registration';
import { SensorRegistrationDto } from '../dto/sensor-registration-dto';
import { SensorMapperService } from './sensor-mapper.service';
import * as i0 from "@angular/core";
import * as i1 from "./sensor-mapper.service";
import * as i2 from "../../asset/mapper/asset-mapper.service";
var SensorRegistrationMapperService = /** @class */ (function () {
    function SensorRegistrationMapperService(sensorMapper, assetMapper) {
        this.sensorMapper = sensorMapper;
        this.assetMapper = assetMapper;
    }
    /*
   SensorRegistration <=> SensorRegistrationDto
    */
    SensorRegistrationMapperService.prototype.sensorRegistrationDtoToSensorRegistration = function (sensorRegistrationDto) {
        var sensorRegistration = plainToClass(SensorRegistration, sensorRegistrationDto);
        sensorRegistration.registrationParams = this.sensorMapper.sensorConfigValueDtoArrayToSensorConfigValueArray(sensorRegistrationDto.registrationParams);
        sensorRegistration.sensorAsset = this.assetMapper.assetDtoToAsset(sensorRegistrationDto.sensorAsset);
        return sensorRegistration;
    };
    SensorRegistrationMapperService.prototype.sensorRegistrationToSensorRegistrationDto = function (sensorRegistration) {
        var sensorRegistrationDto = plainToClass(SensorRegistrationDto, sensorRegistration);
        sensorRegistrationDto.registrationParams = this.sensorMapper.sensorConfigValueArrayToSensorConfigValueDtoArray(sensorRegistration.registrationParams);
        sensorRegistrationDto.sensorAsset = this.assetMapper.assetToAssetDto(sensorRegistration.sensorAsset);
        return sensorRegistrationDto;
    };
    SensorRegistrationMapperService.ngInjectableDef = i0.defineInjectable({ factory: function SensorRegistrationMapperService_Factory() { return new SensorRegistrationMapperService(i0.inject(i1.SensorMapperService), i0.inject(i2.AssetMapperService)); }, token: SensorRegistrationMapperService, providedIn: "root" });
    return SensorRegistrationMapperService;
}());
export { SensorRegistrationMapperService };
