import {NgModule} from '@angular/core';
import {MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components/index';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixWizardsModule} from '@phoenix/services/phoenix-wizards/phoenix-wizards.module';

import {ToolbarMenuItemComponent} from './toolbar-menu-item/toolbar-menu-item.component';
import {ToolbarMenuComponent} from './toolbar-menu/toolbar-menu.component';
import {ToolbarComponent} from './toolbar.component';

/**
 * Toolbar Module
 */
@NgModule({
  declarations: [
    ToolbarComponent,
    ToolbarMenuItemComponent,
    ToolbarMenuComponent,
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatToolbarModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    PhoenixWizardsModule,
    TranslateModule.forRoot(),
  ],
  exports: [
    ToolbarComponent,
  ],
})
export class ToolbarModule {
}
