var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MatDialog } from '@angular/material';
import { Permission, permissionIds } from 'app/guards/permission';
import { PermissionService } from 'app/guards/permission.service';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { PhoenixAssetWizardService } from './phoenix-asset-wizard.service';
import { PhoenixDynamicAssetWizardService } from './phoenix-dynamic-asset-wizard.service';
import { PhoenixMeasurementPointWizardService } from './phoenix-measurement-point-wizard.service';
import { PhoenixThresholdWizardService } from './phoenix-threshold-wizard.service';
import { TenantApiService } from '@phoenix/gapicon/tenant/services/tenant-api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./phoenix-asset-wizard.service";
import * as i3 from "./phoenix-dynamic-asset-wizard.service";
import * as i4 from "./phoenix-measurement-point-wizard.service";
import * as i5 from "./phoenix-threshold-wizard.service";
import * as i6 from "../../../app/guards/permission.service";
import * as i7 from "../../gapicon/tenant/services/tenant-api.service";
var PhoenixCommonWizardService = /** @class */ (function () {
    function PhoenixCommonWizardService(dialog, phoenixAssetWizardService, phoenixDynamicAssetWizardService, phoenixMeasurementPointWizardService, phoenixThresholdWizardService, permissionService, tenantApi) {
        this.dialog = dialog;
        this.phoenixAssetWizardService = phoenixAssetWizardService;
        this.phoenixDynamicAssetWizardService = phoenixDynamicAssetWizardService;
        this.phoenixMeasurementPointWizardService = phoenixMeasurementPointWizardService;
        this.phoenixThresholdWizardService = phoenixThresholdWizardService;
        this.permissionService = permissionService;
        this.tenantApi = tenantApi;
        this.createThresholdPermission = new Permission(permissionIds.TENANTADMINISTRATION.THRESHOLD.write);
    }
    PhoenixCommonWizardService.prototype.openNewElementWizard = function (parent) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var createAssetFormControls, createMeasurementPointFormControls, currentAssetBranch, tenantId, providers, newElementWizardFormControls, createDynamicAssetFormControls;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createAssetFormControls = this.createElementSelectionWizardColumn('Asset', PhoenixIcon.STOP, 'WIZARDS.COMMON.ASSET', 'WIZARDS.SELECTOR.DESCRIPTION.ASSET', 'WIZARDS.SELECTOR.BUTTON.ASSET');
                        createMeasurementPointFormControls = this.createElementSelectionWizardColumn('MeasurementPoint', PhoenixIcon.FIBER_MANUAL_RECORD, 'WIZARDS.COMMON.MEASUREMENTPOINT', 'WIZARDS.SELECTOR.DESCRIPTION.MEASUREMENTPOINT', 'WIZARDS.SELECTOR.BUTTON.MEASUREMENTPOINT');
                        currentAssetBranch = JSON.parse(localStorage.getItem('selectedAssetBranches'));
                        tenantId = currentAssetBranch[0];
                        return [4 /*yield*/, this.tenantApi.getProvidersByTenantId(tenantId).toPromise()];
                    case 1:
                        providers = _a.sent();
                        newElementWizardFormControls = [];
                        newElementWizardFormControls.push(createAssetFormControls);
                        newElementWizardFormControls.push(createMeasurementPointFormControls);
                        if (this.permissionService.hasPermission(this.createThresholdPermission)) {
                            //newElementWizardFormControls.push(createThresholdFormControls);
                        }
                        if (providers && providers.length > 0) {
                            createDynamicAssetFormControls = this.createElementSelectionWizardColumn('DynamicAsset', PhoenixIcon.CLOUD, 'WIZARDS.COMMON.DYNAMICASSET', 'WIZARDS.SELECTOR.DESCRIPTION.DYNAMICASSET', 'WIZARDS.SELECTOR.BUTTON.DYNAMICASSET');
                            newElementWizardFormControls.push(createDynamicAssetFormControls);
                        }
                        this.dialog.open(PhoenixDialogComponent, {
                            width: '800px',
                            autoFocus: false,
                            data: {
                                headline: 'WIZARDS.SELECTOR.TITLE.MAINASSET',
                                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.MAIN',
                                buttons: {
                                    cancel: true,
                                },
                                onClose: function (dialogResult) { return _this.onCloseCallback(dialogResult, parent); },
                                formControls: newElementWizardFormControls,
                            },
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixCommonWizardService.prototype.createElementSelectionWizardColumn = function (key, icon, iconLabel, text, buttonLabel, flipIcon) {
        return [
            new PhoenixDynamicFormControl('icon' + key, PhoenixDynamicFormControlType.Icon, iconLabel, false, {
                size: 60,
                body: text,
                flip: flipIcon,
            }, icon),
            new PhoenixDynamicFormControl('button' + key, PhoenixDynamicFormControlType.Button, buttonLabel, false, {
                style: 'raised',
                color: 'basic',
            }),
        ];
    };
    PhoenixCommonWizardService.prototype.onCloseCallback = function (dialogResult, parent) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(dialogResult.formData['buttonClicked'].value);
                        _a = dialogResult.formData['buttonClicked'].value;
                        switch (_a) {
                            case 'buttonAsset': return [3 /*break*/, 1];
                            case 'buttonMeasurementPoint': return [3 /*break*/, 3];
                            case 'buttonThreshold': return [3 /*break*/, 5];
                            case 'buttonDynamicAsset': return [3 /*break*/, 7];
                        }
                        return [3 /*break*/, 9];
                    case 1: return [4 /*yield*/, this.phoenixAssetWizardService.openCreateAssetWizard(undefined, parent, function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.openNewElementWizard(parent)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 3: 
                    //const parentCheckpoint: AssetDto = _.includes(_.get(parent, 'tags', []), AssetTags.asset) ? undefined : parent;
                    return [4 /*yield*/, this.phoenixMeasurementPointWizardService.openCreateMeasurementPointWizard(undefined, parent, function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.openNewElementWizard(parent)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 4:
                        //const parentCheckpoint: AssetDto = _.includes(_.get(parent, 'tags', []), AssetTags.asset) ? undefined : parent;
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 5: return [4 /*yield*/, this.phoenixThresholdWizardService.openCreateThresholdWizard(undefined, function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.openNewElementWizard(parent)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, this.phoenixDynamicAssetWizardService.openCreateDynamicAssetWizard(undefined, parent, function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.openNewElementWizard(parent)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 8:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 9:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixCommonWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixCommonWizardService_Factory() { return new PhoenixCommonWizardService(i0.inject(i1.MatDialog), i0.inject(i2.PhoenixAssetWizardService), i0.inject(i3.PhoenixDynamicAssetWizardService), i0.inject(i4.PhoenixMeasurementPointWizardService), i0.inject(i5.PhoenixThresholdWizardService), i0.inject(i6.PermissionService), i0.inject(i7.TenantApiService)); }, token: PhoenixCommonWizardService, providedIn: "root" });
    return PhoenixCommonWizardService;
}());
export { PhoenixCommonWizardService };
