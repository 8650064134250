import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { MeasurementPointMapperService } from '../../asset/asset-types/measurement-point/mapper/measurement-point-mapper.service';
import { AssetMapperService } from '../../asset/mapper/asset-mapper.service';
import { PropertyMapperService } from '../../property/mapper/property-mapper.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { LatestMeasurementFilter } from '../api/latest-measurement-filter';
import { Measurement } from '../api/measurement';
import { MeasurementSearchCriteria } from '../api/measurement-search-criteria';
import { MeasurementValue } from '../api/measurement-value';
import { LatestMeasurementFilterDto } from '../dto/latest-measurement-filter-dto';
import { MeasurementDto } from '../dto/measurement-dto';
import { MeasurementSearchCriteriaDto } from '../dto/measurement-search-criteria-dto';
import { MeasurementValueDto } from '../dto/measurement-value-dto';
import * as i0 from "@angular/core";
import * as i1 from "../../asset/asset-types/measurement-point/mapper/measurement-point-mapper.service";
import * as i2 from "../../property/mapper/property-mapper.service";
import * as i3 from "../../user/mapper/user-mapper.service";
import * as i4 from "../../asset/mapper/asset-mapper.service";
var MeasurementMapperService = /** @class */ (function () {
    function MeasurementMapperService(measurementPointMapper, propertyMapper, userMapper, assetMapper) {
        this.measurementPointMapper = measurementPointMapper;
        this.propertyMapper = propertyMapper;
        this.userMapper = userMapper;
        this.assetMapper = assetMapper;
    }
    /*
    MeasurementDto <=> Measurement
     */
    MeasurementMapperService.prototype.measurementDtoToMeasurement = function (measurementDto) {
        var measurement = plainToClass(Measurement, measurementDto);
        measurement.asset = this.assetMapper.assetDtoToAsset(measurementDto.asset);
        measurement.measurementValues = this.measurementValueDtoArrayToMeasurementValueArray(measurementDto.measurementValues);
        measurement.timestamp = new Date(measurementDto.timestamp);
        measurement.properties = this.propertyMapper.propertyDtoArrayToPropertyArray(measurementDto.properties);
        measurement.user = this.userMapper.userInfoDtoToUserInfo(measurementDto.user);
        return measurement;
    };
    MeasurementMapperService.prototype.measurementToMeasurementDto = function (measurement) {
        var measurementDto = plainToClass(MeasurementDto, measurement);
        measurementDto.asset = this.assetMapper.assetToAssetDto(measurement.asset);
        measurementDto.measurementValues = this.measurementValueArrayToMeasurementValueDtoArray(measurement.measurementValues);
        measurementDto.timestamp = new Date(measurement.timestamp);
        measurementDto.properties = this.propertyMapper.propertyArrayToPropertyDtoArray(measurement.properties);
        measurementDto.user = this.userMapper.userInfoToUserInfoDto(measurement.user);
        return measurementDto;
    };
    MeasurementMapperService.prototype.measurementArrayToMeasurementDtoArray = function (measurements) {
        var _this = this;
        return _.map(measurements, function (measurement) { return _this.measurementToMeasurementDto(measurement); });
    };
    MeasurementMapperService.prototype.measurementDtoArrayToMeasurementArray = function (measurementDtos) {
        var _this = this;
        return _.map(measurementDtos, function (measurementDto) { return _this.measurementDtoToMeasurement(measurementDto); });
    };
    /*
    MeasurementValueDto <=> MeasurementValue
     */
    MeasurementMapperService.prototype.measurementValueDtoToMeasurementValue = function (measurementValueDto) {
        return plainToClass(MeasurementValue, measurementValueDto);
    };
    MeasurementMapperService.prototype.measurementValueToMeasurementValueDto = function (measurementValue) {
        return plainToClass(MeasurementValueDto, measurementValue);
    };
    MeasurementMapperService.prototype.measurementValueArrayToMeasurementValueDtoArray = function (measurementValues) {
        var _this = this;
        return _.map(measurementValues, function (measurementValue) { return _this.measurementValueToMeasurementValueDto(measurementValue); });
    };
    MeasurementMapperService.prototype.measurementValueDtoArrayToMeasurementValueArray = function (measurementValueDtos) {
        var _this = this;
        return _.map(measurementValueDtos, function (measurementValueDto) { return _this.measurementValueDtoToMeasurementValue(measurementValueDto); });
    };
    /*
    MeasurementSearchCriteriaDto <=> MeasurementSearchCriteria
     */
    MeasurementMapperService.prototype.measurementSearchCriteriaDtoToMeasurementSearchCriteria = function (measurementSearchCriteriaDto) {
        return plainToClass(MeasurementSearchCriteria, measurementSearchCriteriaDto);
    };
    MeasurementMapperService.prototype.measurementAggregateDtoToMeasurementSearchCriteria = function (measurementAggregateDto) {
        return plainToClass(MeasurementSearchCriteria, measurementAggregateDto);
    };
    MeasurementMapperService.prototype.measurementSearchCriteriaToMeasurementSearchCriteriaDto = function (measurementSearchCriteria) {
        return plainToClass(MeasurementSearchCriteriaDto, measurementSearchCriteria);
    };
    /*
    LatestMeasurementFilterDto <=> LatestMeasurementFilter
     */
    MeasurementMapperService.prototype.latestMeasurementFilterDtoToLatestMeasurementFilter = function (latestMeasurementFilterDto) {
        return plainToClass(LatestMeasurementFilter, latestMeasurementFilterDto);
    };
    MeasurementMapperService.prototype.latestMeasurementFilterToLatestMeasurementFilterDto = function (latestMeasurementFilter) {
        return plainToClass(LatestMeasurementFilterDto, latestMeasurementFilter);
    };
    MeasurementMapperService.ngInjectableDef = i0.defineInjectable({ factory: function MeasurementMapperService_Factory() { return new MeasurementMapperService(i0.inject(i1.MeasurementPointMapperService), i0.inject(i2.PropertyMapperService), i0.inject(i3.UserMapperService), i0.inject(i4.AssetMapperService)); }, token: MeasurementMapperService, providedIn: "root" });
    return MeasurementMapperService;
}());
export { MeasurementMapperService };
