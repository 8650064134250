import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';

import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

@Component({
  selector: 'phoenix-task-config-dialog-monthly',
  templateUrl: './phoenix-task-config-dialog-monthly.component.html',
  styleUrls: ['./phoenix-task-config-dialog-monthly.component.scss'],
})
export class PhoenixTaskConfigDialogMonthlyComponent implements OnInit {
  public dayTypeSelector: number;
  public days: { label: string, value: number }[];
  public today: Date = new Date();
  public week: { label: string, value: number }[] = [
    { label: 'first', value: 1 },
    { label: 'second', value: 2 },
    { label: 'third', value: 3 },
    { label: 'fourth', value: 4 },
    { label: 'last', value: 999 },
  ];
  public weekdays: { label: string, value: number }[] = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 7 },
  ];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixTaskConfigDialog,
  ) {
  }

  public getStartDate(): Date {
    return this.data.formGroup.get('start').value;
  }

  public ngOnInit(): void {
    this.days = [];
    for (let i: number = 1; i <= 31; i++) {
      this.days.push({ label: i < 10 ? '0'.concat(i.toString()) : ''.concat(i.toString()), value: i });
    }
  }

  public resetDays(): void {
    this.data.formGroup.get('days').setValue([]);
  }

  public resetWeekdays(): void {
    this.data.formGroup.get('weekdays').setValue(undefined);
    this.data.formGroup.get('week').setValue(undefined);
  }

  get assignedUserGroupIds(): Date {
    return this.data.formGroup.get('assignedUserGroupIds').value;
  }

  get userGroups(): UserGroupDto[] {
    return this.data.usergroups.sort((a, b) => a.name.localeCompare(b.name));
  }
}
