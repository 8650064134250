var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { timer } from 'rxjs/observable/timer';
import { PhoenixAutocompleteEntry } from '../../components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetDto, AssetSearchCriteriaDto } from '../../gapicon/dto.module';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixMeasurementPointWizardService } from './phoenix-measurement-point-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import { GroupApiService } from "../../gapicon/group/services/group-api.service";
import { CostCenterTypes } from '@phoenix/gapicon/asset/dto/cost-center-types';
import { DynamicAssetDto } from '@phoenix/gapicon/asset/dto/dynamic-asset-dto';
import { languages } from '@phoenix/gapicon/asset/dto/languages';
import { TenantApiService } from '@phoenix/gapicon/tenant/services/tenant-api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../gapicon/asset/services/asset-api.service";
import * as i3 from "../../gapicon/group/services/group-api.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../phoenix-communication-service/phoenix-communication.service";
import * as i6 from "./phoenix-measurement-point-wizard.service";
import * as i7 from "./phoenix-wizard-helper.service";
import * as i8 from "../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i9 from "../../gapicon/tenant/services/tenant-api.service";
var PhoenixDynamicAssetWizardService = /** @class */ (function () {
    function PhoenixDynamicAssetWizardService(dialog, assetApiService, userGroupsApiService, translateService, communicationService, phoenixMeasurementPointWizardService, phoenixWizardHelper, snackbarService, tenantApi) {
        this.dialog = dialog;
        this.assetApiService = assetApiService;
        this.userGroupsApiService = userGroupsApiService;
        this.translateService = translateService;
        this.communicationService = communicationService;
        this.phoenixMeasurementPointWizardService = phoenixMeasurementPointWizardService;
        this.phoenixWizardHelper = phoenixWizardHelper;
        this.snackbarService = snackbarService;
        this.tenantApi = tenantApi;
        this.openElementWizard = undefined;
    }
    PhoenixDynamicAssetWizardService.prototype.openCreateDynamicAssetWizard = function (asset, parent, openElementWizard) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var userGroups, _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        this.editedAsset = asset;
                        return [4 /*yield*/, this.userGroupsApiService.getGroups(false).toPromise()];
                    case 1:
                        userGroups = _f.sent();
                        this.openElementWizard = openElementWizard;
                        _b = (_a = this.dialog).open;
                        _c = [PhoenixDialogComponent];
                        _d = {
                            width: this.phoenixWizardHelper.DIALOGWIDTH,
                            autoFocus: false
                        };
                        _e = {
                            headline: 'WIZARDS.ASSETS.CREATE.TITLE',
                            subHeadline: 'WIZARDS.ASSETS.CREATE.SUBTITLE',
                            buttons: {
                                cancel: true,
                                previous: !!this.openElementWizard,
                                save: true,
                            }
                        };
                        return [4 /*yield*/, this.getFormControls(asset, parent, userGroups)];
                    case 2:
                        _b.apply(_a, _c.concat([(_d.data = (_e.formControls = _f.sent(),
                                _e.onInit = function (form) { return _this.dialogOnInitCallback(form); },
                                _e.onClose = function (dialogResult) { return _this.onNewAssetCloseCallback(dialogResult, userGroups, parent); },
                                _e),
                                _d)]));
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.openDeleteDynamicAssetWizard = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var randomString;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        randomString = this.phoenixWizardHelper.getRandomString();
                        this.deleteAsset = asset;
                        return [4 /*yield*/, this.dialog.open(PhoenixDialogComponent, {
                                width: this.phoenixWizardHelper.DIALOGWIDTH,
                                autoFocus: false,
                                data: {
                                    headline: 'WIZARDS.ASSETS.DELETE.TITLE',
                                    subHeadline: asset.name,
                                    buttons: {
                                        cancel: true,
                                        save: 'WIZARDS.ASSETS.DELETE.SUBMIT',
                                    },
                                    translationParams: {
                                        deleteCode: randomString,
                                    },
                                    color: 'warn',
                                    formControls: [
                                        _.concat(this.createTextFormControls(), this.createAssetChildCountIcons(asset.childStatistics), new PhoenixDynamicFormControl('delete-code', PhoenixDynamicFormControlType.Text, 'WIZARDS.ASSETS.DELETE.DELETECODE', undefined, undefined, randomString), new PhoenixDynamicFormControl('delete', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.ASSETS.DELETE.DELETECODEINPUT', true, { hint: 'WIZARDS.ASSETS.DELETE.DELETECODEINPUTHINT' }, undefined, undefined, [Validators.required, Validators.pattern(randomString)])),
                                    ],
                                    onClose: function (r) { return _this.deleteAssetCallback(_this.deleteAsset, r); },
                                },
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.openEditAssetWizard = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var parentAsset, userGroups, _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        this.editedAsset = asset;
                        this.openElementWizard = undefined;
                        return [4 /*yield*/, this.userGroupsApiService.getGroups(false).toPromise()];
                    case 1:
                        userGroups = _f.sent();
                        if (!asset.parentId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.assetApiService.getAssetById(asset.parentId).toPromise()];
                    case 2:
                        parentAsset = _f.sent();
                        _f.label = 3;
                    case 3:
                        _b = (_a = this.dialog).open;
                        _c = [PhoenixDialogComponent];
                        _d = {
                            width: this.phoenixWizardHelper.DIALOGWIDTH,
                            autoFocus: false
                        };
                        _e = {
                            headline: 'WIZARDS.ASSETS.EDIT.TITLE',
                            subHeadline: 'WIZARDS.ASSETS.EDIT.SUBTITLE',
                            buttons: {
                                cancel: true,
                                previous: !!this.openElementWizard,
                                save: true,
                            }
                        };
                        return [4 /*yield*/, this.getFormControls(asset, parentAsset, userGroups)];
                    case 4:
                        _b.apply(_a, _c.concat([(_d.data = (_e.formControls = _f.sent(),
                                _e.onInit = function (form) { return _this.dialogOnInitCallback(form); },
                                _e.onClose = function (dialogResult) { return _this.onEditAssetCloseCallback(dialogResult, userGroups); },
                                _e),
                                _d)])).afterClosed().toPromise();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.createAssetChildCountIcons = function (childrenCount) {
        var options = { size: 40, classes: { 'hint-text': true } };
        var optionsWithFlippedIcon = { size: 40, classes: { 'hint-text': true }, flipped: true };
        var alarmCount = _.sumBy(childrenCount.activeNotificationTypesCount, 'count') + _.sumBy(childrenCount.inactiveNotificationTypesCount, 'count');
        return new PhoenixDynamicFormControl('array1', PhoenixDynamicFormControlType.FormControlArray, undefined, false, {
            formControls: [
                new PhoenixDynamicFormControl('icon1', PhoenixDynamicFormControlType.Icon, _.sumBy(childrenCount.assetTypesCount, function (stat) { return stat.type !== AssetTags.measurementPoint ? stat.count : 0; }).toString(), false, options, PhoenixIcon.STOP),
                new PhoenixDynamicFormControl('icon2', PhoenixDynamicFormControlType.Icon, _.sumBy(childrenCount.assetTypesCount, function (stat) { return stat.type === AssetTags.measurementPoint ? stat.count : 0; }).toString(), false, options, PhoenixIcon.FIBER_MANUAL_RECORD),
                new PhoenixDynamicFormControl('icon3', PhoenixDynamicFormControlType.Icon, childrenCount.violationCount.toString(), false, optionsWithFlippedIcon, PhoenixIcon.PLAY_ARROW),
                new PhoenixDynamicFormControl('icon4', PhoenixDynamicFormControlType.Icon, alarmCount.toString(), false, options, PhoenixIcon.NOTIFICATIONS),
            ],
        });
    };
    PhoenixDynamicAssetWizardService.prototype.createElementSelectionWizardColumn = function (key, icon, iconLabel, text, buttonLabel, flipIcon, hint, disabled) {
        return [
            new PhoenixDynamicFormControl('icon' + key, PhoenixDynamicFormControlType.Icon, iconLabel, false, {
                size: 60,
                body: text,
                flip: flipIcon,
            }, icon),
            new PhoenixDynamicFormControl('button' + key, PhoenixDynamicFormControlType.Button, buttonLabel, false, {
                style: 'raised',
                color: 'basic',
                disabled: disabled,
                hint: hint,
            }, undefined, disabled),
        ];
    };
    PhoenixDynamicAssetWizardService.prototype.createTextFormControls = function () {
        var hintTextOptions = {
            classes: {
                'font-size-12': true,
                'hint-text': true,
                'primary-300-fg': true,
                'mt-4': true,
                'mb-4': true,
            },
        };
        var formControls = [];
        var textToDisplay = [
            'WIZARDS.ASSETS.DELETE.LINE1',
            'WIZARDS.ASSETS.DELETE.LINE2',
            'WIZARDS.ASSETS.DELETE.LINE3',
            'WIZARDS.ASSETS.DELETE.LINE4',
            'WIZARDS.ASSETS.DELETE.LINE5',
            'WIZARDS.ASSETS.DELETE.LINE6',
            'WIZARDS.ASSETS.DELETE.LINE7',
            'WIZARDS.ASSETS.DELETE.LINE8',
            'WIZARDS.ASSETS.DELETE.CONFIRMATION',
        ];
        _.forEach(textToDisplay, function (text) {
            formControls.push(new PhoenixDynamicFormControl('text', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, text));
        });
        return formControls;
    };
    PhoenixDynamicAssetWizardService.prototype.deleteAssetCallback = function (asset, dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var observables, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 6];
                        observables = [];
                        return [4 /*yield*/, this.getDeleteAssetObservablesRecursive(asset, observables)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, concat.apply(void 0, observables).toPromise()];
                    case 3:
                        _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.ASSETS.DELETE.SUCCESS'));
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        _a.label = 6;
                    case 6:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.dialogOnInitCallback = function (form) {
        form.get('assetQrSwitch').valueChanges.subscribe(function () {
            var tags = form.get('assetTags').value;
            if (form.get('assetQrSwitch').value && !form.get('assetQrSwitch').disabled) {
                form.get('assetQr').enable();
                tags.pop();
                tags.push(AssetTags.checkpoint);
            }
            else {
                form.get('assetQr').disable();
                tags.pop();
                tags.push(AssetTags.asset);
            }
        });
    };
    PhoenixDynamicAssetWizardService.prototype.getDeleteAssetObservablesRecursive = function (asset, observables) {
        return __awaiter(this, void 0, void 0, function () {
            var childAssets, _i, childAssets_1, childAsset;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.assetApiService.getAssets(new AssetSearchCriteriaDto({ parentId: asset.id })).toPromise()];
                    case 1:
                        childAssets = _a.sent();
                        _i = 0, childAssets_1 = childAssets;
                        _a.label = 2;
                    case 2:
                        if (!(_i < childAssets_1.length)) return [3 /*break*/, 5];
                        childAsset = childAssets_1[_i];
                        return [4 /*yield*/, this.getDeleteAssetObservablesRecursive(childAsset, observables)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        observables.push(timer(500), this.assetApiService.deleteAsset(asset));
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.getFormControls = function (asset, parent, usergroups) {
        if (asset === void 0) { asset = new AssetDto({ tags: [AssetTags.asset] }); }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var controls, currentAssetBranch, tenantId, providers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        controls = [];
                        controls.push(new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.FORMFIELDS.ASSETNAME', true, {}, _.get(asset, 'name', undefined), undefined, [Validators.required]));
                        controls.push(new PhoenixDynamicFormControl('assetDescription', PhoenixDynamicFormControlType.TextArea, 'WIZARDS.FORMFIELDS.ASSETDESCRIPTION', false, {}, _.get(asset, 'description', undefined)));
                        controls.push(new PhoenixDynamicFormControl('assetQrSwitch', PhoenixDynamicFormControlType.Switch, 'WIZARDS.FORMFIELDS.ASSETQR', false, {
                            hint: 'WIZARDS.FORMFIELDS.ASSETQRHINT',
                        }, asset.tags.includes(AssetTags.checkpoint), asset.id !== undefined));
                        controls.push(new PhoenixDynamicFormControl('assetQr', PhoenixDynamicFormControlType.TextBox, '', false, { hint: 'WIZARDS.FORMFIELDS.ASSETQRHINT' }, _.get(asset, 'code'), !asset.tags.includes(AssetTags.checkpoint), [Validators.pattern(new RegExp('^[a-zA-Z0-9]{0,64}$'))], [this.validateQrCode(_.get(asset, 'code'))]));
                        currentAssetBranch = JSON.parse(localStorage.getItem('selectedAssetBranches'));
                        tenantId = currentAssetBranch[0];
                        return [4 /*yield*/, this.tenantApi.getProvidersByTenantId(tenantId).toPromise()];
                    case 1:
                        providers = _a.sent();
                        controls.push(new PhoenixDynamicFormControl('provider', PhoenixDynamicFormControlType.Dropdown, 'WIZARDS.FORMFIELDS.PROVIDER', true, {
                            'options': providers.map(function (provider) { return { key: provider, label: 'WIZARDS.DYNAMIC.PROVIDERS.' + provider.toUpperCase() }; })
                        }, _.get(asset, 'provider', providers[0]), undefined, [Validators.required]));
                        controls.push(new PhoenixDynamicFormControl('costCenterCode', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.FORMFIELDS.COSTCENTERCODE', true, { hint: 'WIZARDS.FORMFIELDS.COSTCENTERCODEHINT' }, _.get(asset, 'costCenterCode', undefined), undefined, [Validators.pattern(new RegExp('[0-9]+')), Validators.required]));
                        controls.push(new PhoenixDynamicFormControl('costCenterType', PhoenixDynamicFormControlType.Dropdown, 'WIZARDS.FORMFIELDS.COSTCENTERTYPE', true, {
                            'options': [
                                { key: CostCenterTypes.purchasedOrders, label: 'WIZARDS.DYNAMIC.PURCHASEDORDERS' },
                                { key: CostCenterTypes.productionCpu, label: 'WIZARDS.DYNAMIC.PRODUCTIONCPU' },
                                { key: CostCenterTypes.productionMenuPlan, label: 'WIZARDS.DYNAMIC.PRODUCTIONMENUPLAN' },
                                { key: CostCenterTypes.servingFood, label: 'WIZARDS.DYNAMIC.SERVINGFOOD' },
                                { key: CostCenterTypes.transportProducedFoodCpu, label: 'WIZARDS.DYNAMIC.TRANSPORTPRODUCEDFOODCPU' }
                            ],
                        }, _.get(asset, 'costCenterType', CostCenterTypes.purchasedOrders), undefined, [Validators.required]));
                        controls.push(new PhoenixDynamicFormControl('language', PhoenixDynamicFormControlType.Dropdown, 'WIZARDS.FORMFIELDS.LANGUAGE', true, {
                            'options': [
                                { key: languages.german, label: 'WIZARDS.DYNAMIC.LANGUAGE.GERMAN' },
                                { key: languages.english, label: 'WIZARDS.DYNAMIC.LANGUAGE.ENGLISH' },
                            ],
                        }, _.get(asset, 'costCenterType', languages.german), undefined, [Validators.required]));
                        controls.push(new PhoenixDynamicFormControl('assetTags', PhoenixDynamicFormControlType.Chips, 'WIZARDS.FORMFIELDS.TAGS', false, {}, _.get(asset, 'tags', [AssetTags.asset])));
                        controls.push(new PhoenixDynamicFormControl('assetParent', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.FORMFIELDS.PARENT', false, {
                            hint: 'WIZARDS.FORMFIELDS.PARENTHINT',
                            autocomplete: function (search) { return _this.phoenixWizardHelper.assetAutocompleteSearchFunction(search, _this.editedAsset); },
                        }, parent ? new PhoenixAutocompleteEntry({ object: parent, label: parent.name }) : undefined));
                        controls.push(new PhoenixDynamicFormControl('userGroups', PhoenixDynamicFormControlType.Dropdown, 'WIZARDS.ASSETS.ASSIGNGROUPS.ADDREMOVE', false, {
                            multiple: true, options: this.getAssignedUserGroupsForSelect(usergroups),
                        }, this.getAssignedUserGroupsForDisplay(asset.assignedUserGroups), undefined));
                        return [2 /*return*/, [controls]];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.getAssignedUserGroupsForSelect = function (userGroups) {
        return userGroups
            .sort(function (a, b) { return a.name.localeCompare(b.name); })
            .map(function (userGroup) {
            return {
                key: userGroup.id,
                label: "" + userGroup.name,
            };
        });
    };
    PhoenixDynamicAssetWizardService.prototype.getAssignedUserGroupsForDisplay = function (userGroups) {
        return userGroups
            .map(function (userGroup) {
            return userGroup.id;
        });
    };
    PhoenixDynamicAssetWizardService.prototype.onEditAssetCloseCallback = function (dialogResult, userGroups) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedGroupsIds_1, newGroups, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 5];
                        this.editedAsset.name = dialogResult.formData['assetName'];
                        this.editedAsset.parentId = _.get(dialogResult.formData, 'assetParent.object.id', undefined);
                        this.editedAsset.description = dialogResult.formData['assetDescription'];
                        // Bugfix Steuerzeichen
                        if (!_.isNil(this.editedAsset.description) && this.editedAsset.description.match('\u0000')) {
                            this.editedAsset.description = undefined;
                        }
                        this.editedAsset.code = dialogResult.formData['assetQrSwitch'] ? (dialogResult.formData['assetQr'] || '') : undefined;
                        this.editedAsset.tags = dialogResult.formData['assetTags'];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        selectedGroupsIds_1 = Array.from(dialogResult.formData['userGroups']);
                        newGroups = userGroups.filter(function (group) {
                            return selectedGroupsIds_1.includes(group.id); // Filter out the appropriate one
                        });
                        this.editedAsset.assignedUserGroups = newGroups;
                        return [4 /*yield*/, this.assetApiService.updateAsset(this.editedAsset).toPromise()];
                    case 2:
                        _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.ASSETS.EDIT.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        _a.label = 5;
                    case 5:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.onFollowingCloseCallback = function (dialogResult, parent) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (dialogResult.formData['buttonClicked'].value) {
                    case 'buttonDuplicateAsset':
                        this.editedAsset.code = null;
                        this.openCreateDynamicAssetWizard(this.editedAsset, parent, this.openElementWizard);
                        break;
                    case 'buttonNewAsset':
                        this.openCreateDynamicAssetWizard(undefined, undefined, this.openElementWizard);
                        break;
                    case 'buttonSubMeasurementPoint':
                        this.phoenixMeasurementPointWizardService.openCreateMeasurementPointWizard(undefined, this.editedAsset, this.openElementWizard);
                        break;
                    case 'buttonSubAsset':
                        this.openCreateDynamicAssetWizard(undefined, this.editedAsset, this.openElementWizard);
                        break;
                }
                dialogResult.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.onNewAssetCloseCallback = function (dialogResult, userGroups, parent) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var asset_1, qrCode, selectedGroupsIds_2, newGroups, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 5];
                        asset_1 = new DynamicAssetDto({
                            name: dialogResult.formData['assetName'],
                            parentId: _.get(dialogResult.formData, 'assetParent.object.id', undefined),
                            description: dialogResult.formData['assetDescription'],
                            costCenterCode: dialogResult.formData['costCenterCode'],
                            costCenterType: dialogResult.formData['costCenterType'],
                            language: dialogResult.formData['language'],
                            provider: dialogResult.formData['provider'],
                        });
                        if (dialogResult.formData['assetQrSwitch']) {
                            qrCode = dialogResult.formData['assetQr'];
                            asset_1.code = !_.isNil(qrCode) && qrCode.length > 0 ? qrCode : '';
                            asset_1.tags.push(AssetTags.checkpoint);
                        }
                        else {
                            asset_1.tags.push(AssetTags.asset);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        selectedGroupsIds_2 = Array.from(dialogResult.formData['userGroups']);
                        newGroups = userGroups.filter(function (group) {
                            return selectedGroupsIds_2.includes(group.id); // Filter out the appropriate one
                        });
                        asset_1.assignedUserGroups = newGroups;
                        return [4 /*yield*/, this.assetApiService.createDynamicAsset(asset_1).toPromise()];
                    case 2:
                        _a.sent();
                        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.ASSETS.CREATE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        this.editedAsset = asset_1;
                        this.openElementWizard = function () { return _this.openFollowingElementWizard(asset_1, parent); };
                        this.openFollowingElementWizard(asset_1, parent);
                        _a.label = 5;
                    case 5:
                        if (dialogResult.result === 'previous' && this.openElementWizard !== undefined) {
                            this.openElementWizard();
                        }
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixDynamicAssetWizardService.prototype.openFollowingElementWizard = function (asset, parent) {
        var _this = this;
        var subMeasurementPointColumn;
        var subAssetColumn;
        if (!asset.tags.includes(AssetTags.checkpoint)) {
            subMeasurementPointColumn = this.createElementSelectionWizardColumn('SubMeasurementPoint', 'arrow_forward fiber_manual_record', 'WIZARDS.COMMON.SUBMEASUREMENTPOINT', 'WIZARDS.SELECTOR.DESCRIPTION.SUBMEASUREMENTPOINT', 'WIZARDS.SELECTOR.BUTTON.SUBMEASUREMENTPOINT', false, 'WIZARDS.COMMON.NOMEASUREMENTPOINT', true);
        }
        else {
            subMeasurementPointColumn = this.createElementSelectionWizardColumn('SubMeasurementPoint', 'arrow_forward fiber_manual_record', 'WIZARDS.COMMON.SUBMEASUREMENTPOINT', 'WIZARDS.SELECTOR.DESCRIPTION.SUBMEASUREMENTPOINT', 'WIZARDS.SELECTOR.BUTTON.SUBMEASUREMENTPOINT');
        }
        if (!asset.tags.includes(AssetTags.asset)) {
            subAssetColumn = this.createElementSelectionWizardColumn('SubAsset', 'arrow_forward stop', 'WIZARDS.COMMON.SUBASSET', 'WIZARDS.SELECTOR.DESCRIPTION.SUBASSET', 'WIZARDS.SELECTOR.BUTTON.SUBASSET', true, 'WIZARDS.COMMON.NOASSET', true);
        }
        else {
            subAssetColumn = this.createElementSelectionWizardColumn('SubAsset', 'arrow_forward stop', 'WIZARDS.COMMON.SUBASSET', 'WIZARDS.SELECTOR.DESCRIPTION.SUBASSET', 'WIZARDS.SELECTOR.BUTTON.SUBASSET', true);
        }
        this.dialog.open(PhoenixDialogComponent, {
            width: '1150px',
            autoFocus: false,
            data: {
                headline: 'WIZARDS.SELECTOR.TITLE.FOLLOWINGASSET',
                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.FOLLOWING',
                buttons: {
                    exit: true,
                },
                translationParams: {
                    assetName: asset.name,
                },
                onClose: function (dialogResult) { return _this.onFollowingCloseCallback(dialogResult, parent); },
                formControls: [
                    this.createElementSelectionWizardColumn('DuplicateAsset', PhoenixIcon.REPEAT, 'WIZARDS.COMMON.DUPLICATEASSET', 'WIZARDS.SELECTOR.DESCRIPTION.DUPLICATEASSET', 'WIZARDS.SELECTOR.BUTTON.DUPLICATEASSET'),
                    this.createElementSelectionWizardColumn('NewAsset', PhoenixIcon.STOP, 'WIZARDS.COMMON.NEWASSET', 'WIZARDS.SELECTOR.DESCRIPTION.NEWASSET', 'WIZARDS.SELECTOR.BUTTON.NEWASSET'),
                    subMeasurementPointColumn,
                    subAssetColumn,
                ],
            },
        });
    };
    PhoenixDynamicAssetWizardService.prototype.validateQrCode = function (ownCode) {
        var _this = this;
        return function (control) {
            clearTimeout(_this.debouncer);
            return Observable.create(function (observer) {
                _this.debouncer = setTimeout(function () {
                    if (!_.isNil(control.value) && _.get(control, 'value.length', 0) > 0) {
                        var sc = new AssetSearchCriteriaDto({ code: control.value });
                        _this.assetApiService.getAssets(sc).subscribe(function (next) {
                            var isOwnSensorCode = _.find(next, { code: ownCode });
                            // tslint:disable-next-line
                            var result = next.length === 0 || !_.isUndefined(isOwnSensorCode) ? null : { 'forbiddenName': { value: control.value } };
                            if (!_.isNull(result)) {
                                _this.snackbarService.openPhoenixSnackbar(4000, 'right', 'top', _this.translateService.instant('WIZARDS.FORMFIELDS.ASSETQRALREADYTAKEN'), 'red-bg');
                            }
                            observer.next(result);
                            observer.complete();
                        });
                    }
                    else {
                        observer.next(undefined);
                        observer.complete();
                    }
                }, 1000);
            });
        };
    };
    PhoenixDynamicAssetWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixDynamicAssetWizardService_Factory() { return new PhoenixDynamicAssetWizardService(i0.inject(i1.MatDialog), i0.inject(i2.AssetApiService), i0.inject(i3.GroupApiService), i0.inject(i4.TranslateService), i0.inject(i5.PhoenixCommunicationService), i0.inject(i6.PhoenixMeasurementPointWizardService), i0.inject(i7.PhoenixWizardHelperService), i0.inject(i8.PhoenixSnackbarService), i0.inject(i9.TenantApiService)); }, token: PhoenixDynamicAssetWizardService, providedIn: "root" });
    return PhoenixDynamicAssetWizardService;
}());
export { PhoenixDynamicAssetWizardService };
