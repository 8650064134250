var TenantUpdateRequest = /** @class */ (function () {
    function TenantUpdateRequest(tenantRequest) {
        this.name = undefined;
        this.description = undefined;
        this.roles = undefined;
        this.providers = undefined;
        this.sensorTypeThreshold = undefined;
        this.mobileSensorCheckInterval = undefined;
        this.stationarySensorCheckInterval = undefined;
        Object.assign(this, tenantRequest);
    }
    TenantUpdateRequest.prototype.toJSON = function () {
        return {
            name: this.name,
            roles: this.roles,
            description: this.description,
            providers: this.providers,
            sensorTypeThreshold: this.sensorTypeThreshold,
            mobileSensorCheckInterval: this.mobileSensorCheckInterval,
            stationarySensorCheckInterval: this.stationarySensorCheckInterval,
        };
    };
    return TenantUpdateRequest;
}());
export { TenantUpdateRequest };
