import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Tenant } from '../api/tenant';
import { TenantDto } from '../dto/tenant-dto';
import * as i0 from "@angular/core";
var TenantMapperService = /** @class */ (function () {
    function TenantMapperService() {
    }
    TenantMapperService.prototype.tenantDtoToTenant = function (tenantDto) {
        return plainToClass(Tenant, tenantDto);
    };
    TenantMapperService.prototype.tenantToTenantDto = function (tenant) {
        return plainToClass(TenantDto, tenant);
    };
    TenantMapperService.prototype.tenantArrayToTenantDtoArray = function (tenants) {
        var _this = this;
        return _.map(tenants, function (tenant) { return _this.tenantToTenantDto(tenant); });
    };
    TenantMapperService.prototype.tenantDtoArrayToTenantArray = function (tenantDtos) {
        var _this = this;
        return _.map(tenantDtos, function (tenantDto) { return _this.tenantDtoToTenant(tenantDto); });
    };
    TenantMapperService.ngInjectableDef = i0.defineInjectable({ factory: function TenantMapperService_Factory() { return new TenantMapperService(); }, token: TenantMapperService, providedIn: "root" });
    return TenantMapperService;
}());
export { TenantMapperService };
