import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { PhoenixTenantSelectorDialogData } from './classes/PhoenixTenantSelectorDialogData';
import { PhoenixTenantSelectorDialogComponent } from './phoenix-tenant-selector-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PhoenixTenantSelectorDialogService = /** @class */ (function () {
    function PhoenixTenantSelectorDialogService(dialog) {
        this.dialog = dialog;
    }
    PhoenixTenantSelectorDialogService.prototype.showTenantSelectorDialog = function (tenants, showCancelButton) {
        if (showCancelButton === void 0) { showCancelButton = false; }
        if (tenants.length <= 1) {
            return of(tenants);
        }
        var dialogRef = this.dialog.open(PhoenixTenantSelectorDialogComponent, {
            closeOnNavigation: false,
            autoFocus: false,
            disableClose: !showCancelButton,
            data: new PhoenixTenantSelectorDialogData({
                tenants: tenants,
                cancelButton: showCancelButton,
                title: 'WIZARDS.TENANT.TITLE',
                subtitle: 'WIZARDS.TENANT.SUBTITLE',
            }),
        });
        return dialogRef.afterClosed();
    };
    PhoenixTenantSelectorDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixTenantSelectorDialogService_Factory() { return new PhoenixTenantSelectorDialogService(i0.inject(i1.MatDialog)); }, token: PhoenixTenantSelectorDialogService, providedIn: "root" });
    return PhoenixTenantSelectorDialogService;
}());
export { PhoenixTenantSelectorDialogService };
