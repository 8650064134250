var SensorConfig = /** @class */ (function () {
    function SensorConfig(sensor) {
        this.configurations = undefined;
        Object.assign(this, sensor);
    }
    SensorConfig.prototype.toJSON = function () {
        return {
            configurations: this.configurations,
        };
    };
    return SensorConfig;
}());
export { SensorConfig };
