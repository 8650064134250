import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInSeconds, isBefore } from 'date-fns';
import { EMPTY } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthInfoDto } from '../../dto.module';

import { AuthApiService } from './auth-api.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private static minTokenValidity: number = 60;

  public constructor(private authApi: AuthApiService, private router: Router) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth: AuthInfoDto = this.authApi.getAuth();
    if (req.url.startsWith('/auth')) {
      return next.handle(req);
    }
    if (auth) {
      // Token is already expired or validity is smaller than 60 seconds.
      if (isBefore(auth.expires, new Date()) || differenceInSeconds(auth.expires, new Date()) < AuthInterceptor.minTokenValidity) {
        // token needs to be refreshed
        return this.authApi.refresh().pipe(
          mergeMap(() => {
            req = this.setHeaderToRequest(req, this.authApi.getAuth());
            return next.handle(req);
          }), catchError((error, caugth) => {
            this.router.navigate(['/login']);
            return EMPTY;
          })
        );
      } else {
        req = this.setHeaderToRequest(req, auth);
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }

  private setHeaderToRequest(req: HttpRequest<any>, auth: AuthInfoDto): HttpRequest<any> {
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + auth.access_token,
      },
    });
    return req;
  }
}
