var UserInfoDto = /** @class */ (function () {
    function UserInfoDto(userInfoDto) {
        this.id = undefined;
        this.email = undefined;
        this.tenant = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        Object.assign(this, userInfoDto);
    }
    return UserInfoDto;
}());
export { UserInfoDto };
