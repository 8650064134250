var SensorDto = /** @class */ (function () {
    function SensorDto(sensor) {
        this.assetId = undefined;
        this.currentConfiguration = undefined;
        this.wantedConfiguration = undefined;
        this.sensorId = undefined;
        this.type = undefined;
        Object.assign(this, sensor);
    }
    return SensorDto;
}());
export { SensorDto };
