var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';
var SensorSearchCriteriaDto = /** @class */ (function (_super) {
    __extends(SensorSearchCriteriaDto, _super);
    function SensorSearchCriteriaDto(sensorSearchCriteriaDto) {
        var _this = _super.call(this, sensorSearchCriteriaDto) || this;
        _this.isAssigned = undefined;
        _this.types = undefined;
        _this.sensorIds = undefined;
        _this.partialName = undefined;
        Object.assign(_this, sensorSearchCriteriaDto);
        return _this;
    }
    return SensorSearchCriteriaDto;
}(SearchCriteriaPageableDto));
export { SensorSearchCriteriaDto };
