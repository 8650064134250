import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

import {PhoenixMeasurementTypeInformationDialog} from '../classes/phoenix-measurement-type-information-dialog';

@Component({
  selector: 'phoenix-measurement-type-information-dialog',
  templateUrl: './phoenix-measurement-type-information-dialog.component.html',
  styleUrls: ['./phoenix-measurement-type-information-dialog.component.scss'],
})
export class PhoenixMeasurementTypeInformationDialogComponent implements OnInit {

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixMeasurementTypeInformationDialog,
  ) {
  }

  public ngOnInit(): void {
  }
}
