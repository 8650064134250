import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { FileAccessToken } from '../api/file-access-token';
import { FileItem } from '../api/file-item';
import { FileItemType } from '../api/file-item-type.enum';
import { FileAccessTokenDto } from '../dto/file-access-token-dto';
import { FileItemDto } from '../dto/file-item-dto';
import { FileItemTypeDto } from '../dto/file-item-type-dto.enum';
import { FileStorageInformationDto } from '../dto/file-storage-information-dto';
import * as i0 from "@angular/core";
var FileMapperService = /** @class */ (function () {
    function FileMapperService() {
    }
    FileMapperService.prototype.fileAccessTokenDtoToFileAccessToken = function (fileAccessTokenDto) {
        return new FileAccessToken(fileAccessTokenDto);
    };
    FileMapperService.prototype.fileAccessTokenToFileAccessTokenDto = function (fileAccessToken) {
        return new FileAccessTokenDto(fileAccessToken);
    };
    FileMapperService.prototype.fileItemArrayToFileItemDtoArray = function (fileItems) {
        var _this = this;
        return _.map(fileItems, function (fileItem) { return _this.fileItemToFileItemDto(fileItem); });
    };
    FileMapperService.prototype.fileItemDtoToFileItem = function (fileItemDto) {
        var fileItem = plainToClass(FileItem, fileItemDto);
        fileItem.type = FileItemType[fileItemDto.type];
        return fileItem;
    };
    FileMapperService.prototype.fileItemToFileItemDto = function (fileItem) {
        var fileItemDto = plainToClass(FileItemDto, fileItem);
        fileItemDto.lastModified = new Date(fileItem.lastModified);
        fileItemDto.type = FileItemTypeDto[fileItem.type];
        return fileItemDto;
    };
    FileMapperService.prototype.fileStorageInformationToFileStorageInformationDto = function (fileStorageInformation) {
        return plainToClass(FileStorageInformationDto, fileStorageInformation);
    };
    FileMapperService.ngInjectableDef = i0.defineInjectable({ factory: function FileMapperService_Factory() { return new FileMapperService(); }, token: FileMapperService, providedIn: "root" });
    return FileMapperService;
}());
export { FileMapperService };
