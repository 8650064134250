import { AuthApiService } from '../../auth/services/auth-api.service';
var TenantInterceptor = /** @class */ (function () {
    function TenantInterceptor(authApi) {
        this.authApi = authApi;
    }
    TenantInterceptor.prototype.intercept = function (req, next) {
        var auth = this.authApi.getAuth();
        if (req.url.startsWith('/auth')) {
            return next.handle(req);
        }
        if (auth && auth.currentTenants && auth.currentTenants.length > 0) {
            req = this.setHeaderToRequest(req, auth);
            return next.handle(req);
        }
        else {
            return next.handle(req);
        }
    };
    TenantInterceptor.prototype.setHeaderToRequest = function (req, auth) {
        var header = {
            'gc-tenants': auth.currentTenants.join(','),
        };
        if (auth.groupAssetPairs.length > 0) {
            header['gc-groups-assets'] = auth.groupAssetPairs.join(',');
        }
        req = req.clone({
            setHeaders: header,
        });
        return req;
    };
    return TenantInterceptor;
}());
export { TenantInterceptor };
