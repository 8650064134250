import moment from 'moment';
import { TimeWindowConfigDayDto } from "../dto/time-window-config-day-dto";
import { TimeWindowConfigDto } from "../dto/time-window-config-dto";
import { TimeWindowConfigIntervallDto } from '../dto/time-window-config-intervall-dto';
var TimeWindowHelper = /** @class */ (function () {
    function TimeWindowHelper() {
    }
    TimeWindowHelper.convertTimeWindowConfigDtoFromUTCtoLocal = function (timeWindowConfigDto) {
        var intervals = [];
        intervals = this.createLocalIntervalsFromUTCConfig(timeWindowConfigDto);
        intervals = this.correctWeekOverlapping(intervals, false);
        intervals = this.sortIntervals(intervals);
        intervals = this.joinContinuousIntervalsInDay(intervals);
        var convertedConfig = this.createConfigFromIntervals(intervals);
        convertedConfig.dstOffset = timeWindowConfigDto.dstOffset;
        return convertedConfig;
    };
    TimeWindowHelper.convertTimeWindowConfigDtoFromLocaltoUTC = function (timeWindowConfigDto) {
        var intervals = [];
        intervals = this.createUTCIntervalsFromLocalConfig(timeWindowConfigDto);
        intervals = this.correctWeekOverlapping(intervals, true);
        intervals = this.sortIntervals(intervals);
        intervals = this.joinContinuousIntervalsInDay(intervals);
        var convertedConfig = this.createConfigFromIntervals(intervals);
        convertedConfig.dstOffset = timeWindowConfigDto.dstOffset;
        return convertedConfig;
    };
    TimeWindowHelper.createLocalIntervalsFromUTCConfig = function (timeWindowConfigDto) {
        var intervals = [];
        if (timeWindowConfigDto) {
            if (timeWindowConfigDto.monday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.monday, 1);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.tuesday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.tuesday, 2);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.wednesday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.wednesday, 3);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.thursday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.thursday, 4);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.friday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.friday, 5);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.saturday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.saturday, 6);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.sunday) {
                var dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.sunday, 7);
                intervals = intervals.concat(dayIntervals);
            }
        }
        return intervals;
    };
    TimeWindowHelper.convertUTCConfigDayToLocalIntervals = function (timeWindowConfigDayDto, weekday) {
        var now = moment.utc();
        var timezone = moment().utcOffset();
        var intervals = [];
        if (!timeWindowConfigDayDto.enable) {
            var interval = new Interval();
            interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().startOf('day').local();
            interval.endDate = moment(interval.startDate).add(1, 'day');
            intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
        }
        else if (this.isFullDayBlock(timeWindowConfigDayDto)) {
            var interval = new Interval();
            interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().startOf('day').local();
            interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().startOf('day').local();
            intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
        }
        else {
            for (var i = 0; i < timeWindowConfigDayDto.intervalls.length; i++) {
                var interval = new Interval();
                var startTime = timeWindowConfigDayDto.intervalls[i].startTime.split(':');
                var endTime = timeWindowConfigDayDto.intervalls[i].endTime.split(':');
                interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().hours(+startTime[0]).minutes(+startTime[1]).seconds(0).local();
                interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().hours(+endTime[0]).minutes(+endTime[1]).seconds(0).local();
                if (timeWindowConfigDayDto.intervalls[i].endTime == '0:00') {
                    interval.endDate.add(1, 'day');
                }
                intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
            }
        }
        return intervals;
    };
    TimeWindowHelper.createUTCIntervalsFromLocalConfig = function (timeWindowConfigDto) {
        var intervals = [];
        if (timeWindowConfigDto) {
            if (timeWindowConfigDto.monday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.monday, 1);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.tuesday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.tuesday, 2);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.wednesday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.wednesday, 3);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.thursday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.thursday, 4);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.friday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.friday, 5);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.saturday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.saturday, 6);
                intervals = intervals.concat(dayIntervals);
            }
            if (timeWindowConfigDto.sunday) {
                var dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.sunday, 7);
                intervals = intervals.concat(dayIntervals);
            }
        }
        return intervals;
    };
    TimeWindowHelper.convertLocalConfigDayToUTCIntervals = function (timeWindowConfigDayDto, weekday) {
        var now = moment();
        var timezone = now.utcOffset();
        var intervals = [];
        if (!timeWindowConfigDayDto.enable) {
            var interval = new Interval();
            interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).startOf('day').utc();
            interval.endDate = moment(interval.startDate).add(1, 'day');
            intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
        }
        else if (this.isFullDayBlock(timeWindowConfigDayDto)) {
            var interval = new Interval();
            interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).startOf('day').utc();
            interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).startOf('day').utc();
            intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
        }
        else {
            for (var i = 0; i < timeWindowConfigDayDto.intervalls.length; i++) {
                var interval = new Interval();
                var startTime = timeWindowConfigDayDto.intervalls[i].startTime.split(':');
                var endTime = timeWindowConfigDayDto.intervalls[i].endTime.split(':');
                interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).hours(+startTime[0]).minutes(+startTime[1]).seconds(0).utc();
                interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).hours(+endTime[0]).minutes(+endTime[1]).seconds(0).utc();
                if (timeWindowConfigDayDto.intervalls[i].endTime == '0:00') {
                    interval.endDate.add(1, 'day');
                }
                intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
            }
        }
        return intervals;
    };
    TimeWindowHelper.splitIntervalIfOverlapping = function (interval) {
        var intervals = [];
        if (interval && interval.startDate.isoWeekday() != interval.endDate.isoWeekday()
            && !(interval.endDate.hours() == 0 && interval.endDate.minutes() == 0 && interval.endDate.seconds() == 0)) {
            intervals[0] = new Interval();
            intervals[0].startDate = moment(interval.startDate);
            intervals[0].endDate = moment(interval.startDate).add(1, 'day').startOf('day');
            intervals[1] = new Interval();
            intervals[1].startDate = moment(interval.startDate).add(1, 'day').startOf('day');
            intervals[1].endDate = moment(interval.endDate);
        }
        else {
            intervals.push(interval);
        }
        return intervals;
    };
    TimeWindowHelper.isFullDayBlock = function (timeWindowConfigDayDto) {
        if (!timeWindowConfigDayDto.intervalls || timeWindowConfigDayDto.intervalls &&
            (timeWindowConfigDayDto.intervalls.length == 0 || timeWindowConfigDayDto.intervalls.length == 1
                && timeWindowConfigDayDto.intervalls[0].startTime == '0:00' && timeWindowConfigDayDto.intervalls[0].endTime == '0:00')) {
            return true;
        }
        else {
            return false;
        }
    };
    TimeWindowHelper.correctWeekOverlapping = function (intervals, utc) {
        if (intervals && intervals.length > 0) {
            var monday = utc ? moment.utc().isoWeekday(1).startOf('day') : moment().isoWeekday(1).startOf('day');
            var sunday = utc ? moment.utc().isoWeekday(7).startOf('day') : moment().isoWeekday(7).startOf('day');
            for (var i = 0; i < intervals.length; i++) {
                if (intervals[i].startDate.isBefore(monday, 'day')) {
                    intervals[i].startDate.add(7, 'day');
                    intervals[i].endDate.add(7, 'day');
                }
                else if (intervals[i].startDate.isAfter(sunday, 'day')) {
                    intervals[i].startDate.subtract(7, 'day');
                    intervals[i].endDate.subtract(7, 'day');
                }
            }
        }
        return intervals;
    };
    TimeWindowHelper.sortIntervals = function (intervals) {
        return intervals.sort(function (a, b) {
            if (a.startDate.isBefore(b.startDate)) {
                return -1;
            }
            else if (a.startDate.isSame(b.startDate)) {
                return 0;
            }
            else {
                return 1;
            }
        });
    };
    TimeWindowHelper.joinContinuousIntervalsInDay = function (intervals) {
        var joinedIntervals = [];
        if (intervals && intervals.length > 1) {
            var j = 0;
            joinedIntervals[j] = intervals[j];
            for (var i = 1; i < intervals.length; i++) {
                if (joinedIntervals[j].startDate.isSame(intervals[i].startDate, 'day')
                    && joinedIntervals[j].endDate.isSame(intervals[i].startDate, 'minute')) {
                    joinedIntervals[j].endDate = intervals[i].endDate;
                }
                else {
                    j++;
                    joinedIntervals[j] = intervals[i];
                }
            }
        }
        return joinedIntervals;
    };
    TimeWindowHelper.createConfigFromIntervals = function (intervals) {
        var timeWindowConfigDto = new TimeWindowConfigDto();
        var dayIntervals = [];
        if (intervals && intervals.length > 0) {
            for (var i = 0; i < intervals.length; i++) {
                if (!dayIntervals[intervals[i].startDate.isoWeekday() - 1]) {
                    dayIntervals[intervals[i].startDate.isoWeekday() - 1] = [];
                }
                dayIntervals[intervals[i].startDate.isoWeekday() - 1].push(intervals[i]);
            }
        }
        timeWindowConfigDto.monday = this.createConfigDayDtoFromDayIntervals(dayIntervals[0]);
        timeWindowConfigDto.tuesday = this.createConfigDayDtoFromDayIntervals(dayIntervals[1]);
        timeWindowConfigDto.wednesday = this.createConfigDayDtoFromDayIntervals(dayIntervals[2]);
        timeWindowConfigDto.thursday = this.createConfigDayDtoFromDayIntervals(dayIntervals[3]);
        timeWindowConfigDto.friday = this.createConfigDayDtoFromDayIntervals(dayIntervals[4]);
        timeWindowConfigDto.saturday = this.createConfigDayDtoFromDayIntervals(dayIntervals[5]);
        timeWindowConfigDto.sunday = this.createConfigDayDtoFromDayIntervals(dayIntervals[6]);
        return timeWindowConfigDto;
    };
    TimeWindowHelper.createConfigDayDtoFromDayIntervals = function (intervals) {
        var timeWindowConfigDay = new TimeWindowConfigDayDto();
        if (intervals && intervals.length > 0) {
            var filteredIntervals = [];
            for (var i = 0; i < intervals.length; i++) {
                if (intervals[i] && !intervals[i].startDate.isSame(intervals[i].endDate, 'minute')) {
                    filteredIntervals.push(intervals[i]);
                }
            }
            if (filteredIntervals.length == 0) {
                timeWindowConfigDay.enable = true;
                timeWindowConfigDay.intervalls = null;
            }
            else if (filteredIntervals.length == 1 && this.isFullDayInterval(filteredIntervals[0])) {
                timeWindowConfigDay.enable = false;
                timeWindowConfigDay.intervalls = null;
            }
            else if (filteredIntervals.length == 1 && filteredIntervals[0].startDate.isSame(filteredIntervals[0].endDate, 'minute')) {
                timeWindowConfigDay.enable = true;
                timeWindowConfigDay.intervalls = null;
            }
            else {
                timeWindowConfigDay.enable = true;
                timeWindowConfigDay.intervalls = [];
                for (var i = 0; i < filteredIntervals.length; i++) {
                    timeWindowConfigDay.intervalls.push(this.createConfigIntervalFromInterval(filteredIntervals[i]));
                }
            }
        }
        else {
            timeWindowConfigDay.enable = true;
            timeWindowConfigDay.intervalls = null;
        }
        return timeWindowConfigDay;
    };
    TimeWindowHelper.isFullDayInterval = function (interval) {
        if (interval && interval.startDate.hours() == 0 && interval.startDate.minutes() == 0
            && interval.startDate.seconds() == 0 && moment(interval.startDate).add(1, 'day').isSame(interval.endDate, 'minute')) {
            return true;
        }
        else {
            return false;
        }
    };
    TimeWindowHelper.createConfigIntervalFromInterval = function (interval) {
        var timeWindowConfigIntervalDto = new TimeWindowConfigIntervallDto();
        if (interval && interval.startDate && interval.endDate) {
            timeWindowConfigIntervalDto.startTime = interval.startDate.format('H:mm');
            timeWindowConfigIntervalDto.endTime = interval.endDate.format('H:mm');
        }
        return timeWindowConfigIntervalDto;
    };
    return TimeWindowHelper;
}());
export { TimeWindowHelper };
var Interval = /** @class */ (function () {
    function Interval() {
    }
    return Interval;
}());
export { Interval };
