import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: './login-content/login-content.module#LoginContentModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
  ],
})
export class LoginModule {
}
