import { format, isValid } from 'date-fns';
var FileItem = /** @class */ (function () {
    function FileItem(fileItem) {
        this.childItems = undefined;
        this.contentEncoding = undefined;
        this.contentLength = undefined;
        this.contentMd5 = undefined;
        this.contentType = undefined;
        this.data = undefined;
        this.lastModified = undefined;
        this.name = undefined;
        this.owner = undefined;
        this.parent = undefined;
        this.type = undefined;
        Object.assign(this, fileItem);
    }
    FileItem.prototype.toJSON = function () {
        return {
            owner: this.owner,
            type: this.type,
            parent: this.parent,
            childItems: this.childItems,
            lastModified: (this.lastModified && isValid(this.lastModified)) ? format(this.lastModified) : undefined,
            name: this.name,
            data: this.data,
            contentType: this.contentType,
            contentEncoding: this.contentEncoding,
            contentLength: this.contentLength,
            contentMd5: this.contentMd5,
        };
    };
    return FileItem;
}());
export { FileItem };
