import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatOptionModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixDialogNewModule } from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';
import { PhoenixFormModule } from '../../../components/phoenix-form/phoenix-form.module';
import { PhoenixNavigationServiceModule } from '../../../services/phoenix-navigation-service/phoenix-navigation-service.module';

import { PhoenixMeasurementTypeDialogComponent } from './phoenix-measurement-type-dialog.component';
import { PhoenixMeasurementTypeDialogService } from './phoenix-measurement-type-dialog.service';
import { PhoenixMeasurementTypeExtendedInformationsDialogComponent } from './phoenix-measurement-type-extended-informations-dialog/phoenix-measurement-type-extended-informations-dialog.component';
import { PhoenixMeasurementTypeInformationDialogComponent } from './phoenix-measurement-type-information-dialog/phoenix-measurement-type-information-dialog.component';
import { PhoenixMeasurementTypeInformationDialogService } from './phoenix-measurement-type-information-dialog/phoenix-measurement-type-information-dialog.service';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,

    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatListModule,
    MatIconModule,
    MatBadgeModule,

    PhoenixDialogNewModule,
    PhoenixFormModule,
    PhoenixNavigationServiceModule,
  ],
  declarations: [
    PhoenixMeasurementTypeDialogComponent,
    PhoenixMeasurementTypeInformationDialogComponent,
    PhoenixMeasurementTypeExtendedInformationsDialogComponent,
  ],
  entryComponents: [
    PhoenixMeasurementTypeDialogComponent,
    PhoenixMeasurementTypeInformationDialogComponent,
    PhoenixMeasurementTypeExtendedInformationsDialogComponent,
  ],
  providers: [
    PhoenixMeasurementTypeDialogService,
    PhoenixMeasurementTypeInformationDialogService,
  ],
})
export class PhoenixMeasurementTypeDialogModule {
}
