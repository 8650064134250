var CustomEncoderForHttpParameter = /** @class */ (function () {
    function CustomEncoderForHttpParameter() {
    }
    CustomEncoderForHttpParameter.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    CustomEncoderForHttpParameter.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    CustomEncoderForHttpParameter.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    CustomEncoderForHttpParameter.prototype.decodeValue = function (value) {
        return decodeURIComponent(value);
    };
    return CustomEncoderForHttpParameter;
}());
export { CustomEncoderForHttpParameter };
