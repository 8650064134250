var FuseNavVerticalGroupComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FuseNavVerticalGroupComponent() {
        this.classes = 'nav-group nav-item';
    }
    return FuseNavVerticalGroupComponent;
}());
export { FuseNavVerticalGroupComponent };
