import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material';

import {FuseSharedModule} from '../../../@fuse/shared.module';

import {PhoenixPageContentHeaderComponent} from './phoenix-page-content-header/phoenix-page-content-header.component';
import {PhoenixPageContentMainComponent} from './phoenix-page-content-main/phoenix-page-content-main.component';
import {PhoenixPageContentToolbarComponent} from './phoenix-page-content-toolbar/phoenix-page-content-toolbar.component';
import {PhoenixPageContentComponent} from './phoenix-page-content/phoenix-page-content.component';
import {PhoenixPageHeaderComponent} from './phoenix-page-header/phoenix-page-header.component';
import {PhoenixPageComponent} from './phoenix-page.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatToolbarModule,
    FuseSharedModule,
  ],
  declarations: [
    PhoenixPageComponent,
    PhoenixPageHeaderComponent,
    PhoenixPageContentToolbarComponent,
    PhoenixPageContentComponent,
    PhoenixPageContentMainComponent,
    PhoenixPageContentHeaderComponent,
  ],
  exports: [
    PhoenixPageComponent,
    PhoenixPageHeaderComponent,
    PhoenixPageContentToolbarComponent,
    PhoenixPageContentComponent,
    PhoenixPageContentMainComponent,
    PhoenixPageContentHeaderComponent,
  ],
})
export class PhoenixPageModule {
}
