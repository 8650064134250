import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Image, ModalImage} from '@ks89/angular-modal-gallery';
import * as _ from 'lodash';

import {FileItemDto} from '../../gapicon/file/dto/file-item-dto';

import {PhoenixGalleryData} from './classes/phoenix-gallery-data';

@Component({
  selector: 'phoenix-gallery',
  templateUrl: './phoenix-gallery.component.html',
  styleUrls: ['./phoenix-gallery.component.scss'],
})
export class PhoenixGalleryComponent implements OnInit {

  public images: Image[];
  public color: string = 'accent';

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixGalleryData,
    private dialogRef: MatDialogRef<PhoenixGalleryComponent>,
  ) {
  }

  public ngOnInit(): void {
    const modalImages: Image[] = [];
    _.forEach(this.data.files, (file: FileItemDto, idx: number) => {
      const imageData: string = `data:image/${file.contentType};base64,${file.data}`;
      const image: ModalImage = {img: imageData};
      modalImages.push(new Image(idx, image));
    });
    this.images = modalImages;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
