import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-icon',
  templateUrl: './phoenix-dynamic-icon.component.html',
  styleUrls: ['./phoenix-dynamic-icon.component.scss'],
})
export class PhoenixDynamicIconComponent implements OnInit {
  public iconSize: number;

  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;

  @Input()
  public form: FormGroup;

  public ngOnInit(): void {
    if (_.has(this.phoenixFormControl.options, 'size') && _.isNumber(this.phoenixFormControl.options['size'])) {
      this.iconSize = this.phoenixFormControl.options['size'];
    } else {
      this.iconSize = 20;
    }
  }


}
