import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {PhoenixSharedService} from '../../../../services/phoenix-shared/phoenix-shared.service';
import {VariableValidator} from '../../../../Validators/variable-validator';
import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-password',
  templateUrl: './phoenix-dynamic-password.component.html',
  styleUrls: ['./phoenix-dynamic-password.component.scss'],
})
export class PhoenixDynamicPasswordComponent implements OnInit {
  public passwordFocus: boolean = false;
  public hidePassword: boolean = true;

  public passwordValidity: { [key: string]: boolean } = {
    isPasswordValid: false,
  };

  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;

  @Input()
  public form: FormGroup;

  public constructor(
    public phoenixShared: PhoenixSharedService,
  ) {
  }

  public ngOnInit(): void {
    const validators: ValidatorFn[] = [];

    if (this.phoenixFormControl.required) {
      validators.push(Validators.required);
    }

    if (_.get(this.phoenixFormControl.options, 'passwordRules', false)) {
      validators.push(VariableValidator.Validator(this.passwordValidity, 'isPasswordValid', 'rules', 'password'));
    }
    this.form.get(this.phoenixFormControl.key).setValidators(validators);
  }
}
