/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-dynamic-chips.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/chips";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/material/core";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../../node_modules/@angular/material/chips/typings/index.ngfactory";
import * as i7 from "@angular/material/form-field";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/common";
import * as i11 from "./phoenix-dynamic-chips.component";
var styles_PhoenixDynamicChipsComponent = [i0.styles];
var RenderType_PhoenixDynamicChipsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixDynamicChipsComponent, data: {} });
export { RenderType_PhoenixDynamicChipsComponent as RenderType_PhoenixDynamicChipsComponent };
function View_PhoenixDynamicChipsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-chip", [["class", "h6 mat-chip"], ["color", "primary"], ["role", "option"]], [[1, "tabindex", 0], [2, "mat-chip-selected", null], [2, "mat-chip-with-avatar", null], [2, "mat-chip-with-trailing-icon", null], [2, "mat-chip-disabled", null], [1, "disabled", 0], [1, "aria-disabled", 0], [1, "aria-selected", 0]], [[null, "click"], [null, "keydown"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).focus() !== false);
        ad = (pd_2 && ad);
    } if (("blur" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._blur() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, [[1, 4]], 3, i2.MatChip, [i1.ElementRef, i1.NgZone, i3.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS]], { color: [0, "color"] }, null), i1.ɵqud(335544320, 2, { avatar: 0 }), i1.ɵqud(335544320, 3, { trailingIcon: 0 }), i1.ɵqud(335544320, 4, { removeIcon: 0 }), (_l()(), i1.ɵted(5, null, ["", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_8 = "primary"; _ck(_v, 1, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled ? null : (0 - 1)); var currVal_1 = i1.ɵnov(_v, 1).selected; var currVal_2 = i1.ɵnov(_v, 1).avatar; var currVal_3 = (i1.ɵnov(_v, 1).trailingIcon || i1.ɵnov(_v, 1).removeIcon); var currVal_4 = i1.ɵnov(_v, 1).disabled; var currVal_5 = (i1.ɵnov(_v, 1).disabled || null); var currVal_6 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_7 = i1.ɵnov(_v, 1).ariaSelected; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit)); _ck(_v, 5, 0, currVal_9); }); }
export function View_PhoenixDynamicChipsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["clasS", "mt-12 mb-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mat-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "mat-chip-list", [["class", "mat-chip-list"]], [[1, "tabindex", 0], [1, "aria-describedby", 0], [1, "aria-required", 0], [1, "aria-disabled", 0], [1, "aria-invalid", 0], [1, "aria-multiselectable", 0], [1, "role", 0], [2, "mat-chip-list-disabled", null], [2, "mat-chip-list-invalid", null], [2, "mat-chip-list-required", null], [1, "aria-orientation", 0], [8, "id", 0]], [[null, "focus"], [null, "blur"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).focus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._blur() !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._keydown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_MatChipList_0, i6.RenderType_MatChipList)), i1.ɵprd(6144, null, i7.MatFormFieldControl, null, [i2.MatChipList]), i1.ɵdid(7, 1556480, null, 1, i2.MatChipList, [i1.ElementRef, i1.ChangeDetectorRef, [2, i8.Directionality], [2, i9.NgForm], [2, i9.FormGroupDirective], i4.ErrorStateMatcher, [8, null]], null, null), i1.ɵqud(603979776, 1, { chips: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PhoenixDynamicChipsComponent_1)), i1.ɵdid(10, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_13 = _co.phoenixFormControl.value; _ck(_v, 10, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.phoenixFormControl.label)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7)._tabIndex; var currVal_2 = (i1.ɵnov(_v, 7)._ariaDescribedby || null); var currVal_3 = i1.ɵnov(_v, 7).required.toString(); var currVal_4 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_5 = i1.ɵnov(_v, 7).errorState; var currVal_6 = i1.ɵnov(_v, 7).multiple; var currVal_7 = i1.ɵnov(_v, 7).role; var currVal_8 = i1.ɵnov(_v, 7).disabled; var currVal_9 = i1.ɵnov(_v, 7).errorState; var currVal_10 = i1.ɵnov(_v, 7).required; var currVal_11 = i1.ɵnov(_v, 7).ariaOrientation; var currVal_12 = i1.ɵnov(_v, 7)._uid; _ck(_v, 5, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }); }
export function View_PhoenixDynamicChipsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-dynamic-chips", [], null, null, null, View_PhoenixDynamicChipsComponent_0, RenderType_PhoenixDynamicChipsComponent)), i1.ɵdid(1, 49152, null, 0, i11.PhoenixDynamicChipsComponent, [], null, null)], null, null); }
var PhoenixDynamicChipsComponentNgFactory = i1.ɵccf("phoenix-dynamic-chips", i11.PhoenixDynamicChipsComponent, View_PhoenixDynamicChipsComponent_Host_0, { phoenixFormControl: "phoenixFormControl", form: "form" }, {}, []);
export { PhoenixDynamicChipsComponentNgFactory as PhoenixDynamicChipsComponentNgFactory };
