import { HttpClient, HttpParams } from '@angular/common/http';
import { EmptyObservable } from 'rxjs-compat/observable/EmptyObservable';
import { Observable } from 'rxjs/Observable';
import { expand } from 'rxjs/operators';
import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { AssetMapperService } from '../../asset/mapper/asset-mapper.service';
import { SharedService } from '../../shared/services/shared.service';
import { TenantSearchCriteriaDto } from '../dto/tenant-search-criteria-dto';
import { TenantRequestMapperService } from '../mapper/tenant-request-mapper.service';
var TenantApiService = /** @class */ (function () {
    /**
     * @param http
     * @param sharedService
     * @param tenantRequestMapperService
     * @param assetMapperService
     */
    function TenantApiService(http, sharedService, tenantRequestMapperService, assetMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.tenantRequestMapperService = tenantRequestMapperService;
        this.assetMapperService = assetMapperService;
        this._tenant = 'tenant';
        this._tenants = 'tenants';
        this._assignedGroups = 'assigned-groups';
        this._rrp = 'rrp';
        this._providers = 'providers';
        this._jobs = 'jobs';
    }
    /**
     * Gets all tenants matching the search criteria.
     * @param tenantSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    TenantApiService.prototype.getTenants = function (tenantSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (tenantSearchCriteriaDto === void 0) { tenantSearchCriteriaDto = new TenantSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return new Observable(function (observer) {
            var filter = _this.tenantRequestMapperService.tenantSearchCriteriaDtoToTenantSearchCriteria(tenantSearchCriteriaDto);
            var url = _this.sharedService.buildApiUrl(_this._tenants);
            var options = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
            options.params = filter.getRequestQueryParams();
            _this.http.get(url, options)
                .pipe(expand(function (asset) {
                if (withFurtherPages && asset.length === filter.size) {
                    filter.page += 1;
                    options.params = filter.getRequestQueryParams();
                    return _this.http.get(url, options);
                }
                else {
                    observer.complete();
                    return new EmptyObservable();
                }
            }))
                .subscribe(function (assets) {
                observer.next(_this.assetMapperService.assetArrayToAssetDtoArray(assets));
            });
        });
    };
    /**
     * Gets a tenant by given id.
     * @param id
     * @returns
     */
    TenantApiService.prototype.getTenantById = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.get(_this.sharedService.buildApiUrl(_this._tenants, _this._tenant, id.toString()))
                .subscribe(function (tenant) {
                observer.next(_this.assetMapperService.assetToAssetDto(tenant));
                observer.complete();
            });
        });
    };
    /**
     * Gets a tenant by given id.
     * @param id
     * @returns
     */
    TenantApiService.prototype.getProvidersByTenantId = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.get(_this.sharedService.buildApiUrl(_this._rrp, _this._providers, id.toString()))
                .subscribe(function (providers) {
                observer.next(providers);
                observer.complete();
            });
        });
    };
    /**
     * Gets a tenant by given id.
     * @param id
     * @returns
     */
    TenantApiService.prototype.getAllTenantsForUserWithId = function (userId) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.get(_this.sharedService.buildApiUrl(_this._tenants, userId.toString()))
                .subscribe(function (tenant) {
                observer.next(_this.assetMapperService.assetArrayToAssetDtoArray(tenant));
                observer.complete();
            });
        });
    };
    /**
     * Gets a tenant by given tenantDto.
     * @returns
     * @param tenantDto
     */
    TenantApiService.prototype.getTenant = function (tenantDto) {
        var _this = this;
        var getTenant = this.assetMapperService.assetDtoToAsset(tenantDto);
        return new Observable(function (observer) {
            _this.http.get(_this.sharedService.buildApiUrl(_this._tenants, _this._tenant, getTenant.id.toString()))
                .subscribe(function (tenant) {
                observer.next(_this.assetMapperService.assetToAssetDto(tenant));
                observer.complete();
            });
        });
    };
    /**
     * Creates a tenant by given tenantRequestDto.
     * @returns
     * @param tenantRequestDto
     */
    TenantApiService.prototype.createTenant = function (tenantRequestDto) {
        var _this = this;
        var createTenant = this.tenantRequestMapperService.tenantRequestDtoToTenantRequest(tenantRequestDto);
        return new Observable(function (observer) {
            _this.http.post(_this.sharedService.buildApiUrl(_this._tenants, _this._tenant), createTenant)
                .subscribe(function (tenant) {
                observer.next(_this.assetMapperService.assetToAssetDto(tenant));
                observer.complete();
            });
        });
    };
    /**
     * Deletes a tenant by given tenantDto.
     * @returns
     * @param tenantDto
     */
    TenantApiService.prototype.deleteTenant = function (tenantDto) {
        var deleteTenant = this.assetMapperService.assetDtoToAsset(tenantDto);
        return this.http.delete(this.sharedService.buildApiUrl(this._tenants, this._tenant, deleteTenant.id.toString()));
    };
    /**
     * Deletes a tenant by given id.
     * @param id
     * @returns
     */
    TenantApiService.prototype.deleteTenantById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._tenants, this._tenant, id.toString()));
    };
    /**
     * Updates a tenant by given tenantDto.
     * @returns
     * @param tenantDto
     * @param tenantUpdateRequestDto
     */
    TenantApiService.prototype.updateTenant = function (tenantDto, tenantUpdateRequestDto) {
        var _this = this;
        var updateTenant = this.assetMapperService.assetDtoToAsset(tenantDto);
        var updateTenantRequest;
        if (tenantUpdateRequestDto) {
            updateTenantRequest = this.tenantRequestMapperService.tenantUpdateRequestDtoToTenantUpdateRequest(tenantUpdateRequestDto);
        }
        else {
            updateTenantRequest = this.tenantRequestMapperService.tenantToTenantUpdateRequest(updateTenant);
        }
        return new Observable(function (observer) {
            _this.http.put(_this.sharedService.buildApiUrl(_this._tenants, _this._tenant, updateTenant.id.toString()), updateTenantRequest)
                .subscribe(function (tenant) {
                observer.next(_this.assetMapperService.assetToAssetDto(tenant));
                observer.complete();
            });
        });
    };
    TenantApiService.prototype.updateAssignedGroupsInTenant = function (tenantDto) {
        var _this = this;
        var tenantId = tenantDto.id;
        var userGroupUpdateDto = this.tenantRequestMapperService.assetDtoToUserGroupUpdateDto(tenantDto);
        return new Observable(function (observer) {
            _this.http.put(_this.sharedService.buildApiUrl(_this._tenants, _this._tenant, _this._assignedGroups, tenantId), userGroupUpdateDto)
                .subscribe(function (tenant) {
                observer.next(_this.assetMapperService.assetToAssetDto(tenant));
                observer.complete();
            });
        });
    };
    TenantApiService.prototype.getTenantJobs = function () {
        var _this = this;
        return new Observable(function (observer) {
            var url = _this.sharedService.buildApiUrl(_this._tenants, _this._jobs);
            _this.http.get(url)
                .pipe()
                .subscribe(function (tenantJobs) {
                observer.next(_this.tenantRequestMapperService.tenantJobDtosToTenantJobs(tenantJobs));
                observer.complete();
            });
        });
    };
    TenantApiService.prototype.restartTenantJobs = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.put(_this.sharedService.buildApiUrl(_this._tenants, _this._jobs), null)
                .pipe()
                .subscribe(function (assets) {
                observer.next(_this.assetMapperService.assetArrayToAssetDtoArray(assets));
                observer.complete();
            });
        });
    };
    /**
     * Deletes a tenant job by given tenantJobDto.
     * @returns
     * @param tenantJobDto
     */
    TenantApiService.prototype.deleteTenantJob = function (tenantJob) {
        var deleteTenantJob = this.tenantRequestMapperService.tenantJobDtoToTenantJob(tenantJob);
        return this.http.delete(this.sharedService.buildApiUrl(this._tenants, this._tenant, deleteTenantJob.tenantId, this._jobs, deleteTenantJob.jobType));
    };
    /**
     * Deletes a tenant job by given tenantJobDto.
     * @returns
     * @param tenantJobDto
     */
    TenantApiService.prototype.resumeTenantJob = function (tenantJob) {
        var _this = this;
        var resumedTenantJob = this.tenantRequestMapperService.tenantJobDtoToTenantJob(tenantJob);
        return new Observable(function (observer) {
            _this.http.put(_this.sharedService.buildApiUrl(_this._tenants, _this._tenant, resumedTenantJob.tenantId, _this._jobs, resumedTenantJob.jobType), null)
                .pipe()
                .subscribe(function (job) {
                observer.next(_this.tenantRequestMapperService.tenantJobDtoToTenantJob(job));
                observer.complete();
            });
        });
    };
    return TenantApiService;
}());
export { TenantApiService };
