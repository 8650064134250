import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

import {PropertyMapperService} from '../../property/mapper/property-mapper.service';
import {AccountInfo} from '../api/account-info';
import {User} from '../api/user';
import {UserConfig} from '../api/user-config';
import {UserInfo} from '../api/user-info';
import {UserSearchCriteria} from '../api/user-search-criteria';
import {AccountInfoDto} from '../dto/account-info-dto';
import {UserConfigDto} from '../dto/user-config-dto';
import {UserDto} from '../dto/user-dto';
import {UserInfoDto} from '../dto/user-info-dto';
import {UserSearchCriteriaDto} from '../dto/user-search-criteria-dto';

@Injectable({
  providedIn: 'root',
})
export class UserMapperService {

  public constructor(
    private propertyMapper: PropertyMapperService,
  ) {
  }

  public accountInfoDtoToAccountInfo(accountInfoDto: AccountInfoDto): AccountInfo {
    return plainToClass(AccountInfo, accountInfoDto);
  }

  public accountInfoToAccountInfoDto(accountInfo: AccountInfo): AccountInfoDto {
    const accountInfoDto: AccountInfoDto = plainToClass(AccountInfoDto, accountInfo);
    if (!_.isNil(accountInfo.blockedUntil)) {
      accountInfoDto.blockedUntil = new Date(accountInfo.blockedUntil);
    }
    return accountInfoDto;
  }

  public userArrayToUserDtoArray(users: User[]): UserDto[] {
    return _.map(users, (user: User) => this.userToUserDto(user));
  }

  public userConfigArrayToUserConfigDtoArray(userConfigs: UserConfig[]): UserConfigDto[] {
    return _.map(userConfigs, (userConfig: UserConfig) => this.userConfigToUserConfigDto(userConfig));
  }

  public userConfigDtoArrayToUserConfigArray(userConfigDtos: UserConfigDto[]): UserConfig[] {
    return _.map(userConfigDtos, (userConfigDto: UserConfigDto) => this.userConfigDtoToUserConfig(userConfigDto));
  }

  public userConfigDtoToUserConfig(userConfigDto: UserConfigDto): UserConfig {
    return plainToClass(UserConfig, userConfigDto);
  }

  public userConfigToUserConfigDto(userConfig: UserConfig): UserConfigDto {
    return plainToClass(UserConfigDto, userConfig);
  }

  public userDtoToUser(userDto: UserDto): User {
    const user: User = plainToClass(User, userDto);
    user.accountInfo = userDto.accountInfo ? this.accountInfoDtoToAccountInfo(userDto.accountInfo) : undefined;
    user.properties = this.propertyMapper.propertyDtoArrayToPropertyArray(userDto.properties);
    user.configurations = this.userConfigDtoArrayToUserConfigArray(userDto.configurations);
    return user;
  }

  public userInfoDtoToUserInfo(userInfoDto: UserInfoDto): UserInfo {
    return plainToClass(UserInfo, userInfoDto);
  }

  public userInfoToUserInfoDto(userInfo: UserInfo): UserInfoDto {
    return plainToClass(UserInfoDto, userInfo);
  }

  public userSearchCriteriaDtoToUserSearchCriteria(userSearchCriteriaDto: UserSearchCriteriaDto): UserSearchCriteria {
    return plainToClass(UserSearchCriteria, userSearchCriteriaDto);
  }

  public userToUserDto(user: User): UserDto {

    const userDto: UserDto = plainToClass(UserDto, user);
    userDto.accountInfo = user.accountInfo ? this.accountInfoToAccountInfoDto(user.accountInfo) : undefined;
    userDto.properties = this.propertyMapper.propertyArrayToPropertyDtoArray(user.properties);
    userDto.configurations = this.userConfigArrayToUserConfigDtoArray(user.configurations);
    return userDto;
  }
}
