var FuseNavVerticalItemComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FuseNavVerticalItemComponent() {
        this.classes = 'nav-item';
    }
    return FuseNavVerticalItemComponent;
}());
export { FuseNavVerticalItemComponent };
