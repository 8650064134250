import { Injectable, OnDestroy } from '@angular/core';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

import { Permission } from '../guards/permission';
import { PermissionService } from '../guards/permission.service';
import { navbarGroups } from '../layout/components/navbar/navbar-groups/navbar-groups.module';

import { NavigationBaseCollector } from './classes/navigation-base-collector';
import { NavigationGroup } from './classes/navigation-group';
import { NavigationItem } from './classes/navigation-item';
import { NavigationElement } from './interfaces/navigation-element';
import { navigation } from './navigation';

/**
 * Cointains methods to administrate navigation entries
 */
@Injectable()
export class NavigationService implements OnDestroy {
  private _navigationElements: { [key: string]: NavigationGroup } = navbarGroups;
  private ngUnsubscribe: Subject<Observable<boolean>> = new Subject();

  public constructor(
    private communicationService: PhoenixCommunicationService,
    private permissionService: PermissionService,
  ) {
    this.communicationService.getObservable(PhoenixCommunicationSubject.UserLogout)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((success: { value: true }) => {
        if (success.value) {
          this.resetNavigationElements();
        }
      });
  }

  public get navigationElements(): {} {
    return this._navigationElements;
  }

  /**
   * Adds elements to Navigation. If there is a parentId submitted it will go there as a child.
   */
  public addToNavigation(element: NavigationBaseCollector | NavigationElement, parentId?: string): void {
    if (parentId && _.hasIn(this.navigationElements, parentId) && (this.navigationElements[parentId] instanceof NavigationBaseCollector)) {
      this.addToParent(element, parentId);
    } else {
      this.addToRoot(element);
    }
    this.assignNavigationElementsToNavigation();
    this.sortElementAndChildren(navigation);
  }

  /**
   * Returns first found NavigationElement that matches given searchPath
   * @param {string} searchPath
   * @returns {NavigationItem} or undefined
   */
  public getNavigationElementByPath(searchPath: string): NavigationItem {
    let match: NavigationItem;
    _.forEach(this.navigationElements, (navigationGroup: NavigationGroup) => {
      _.forEach(navigationGroup.children, (child: NavigationElement) => {
        if (match === undefined && child instanceof NavigationItem && child.url === searchPath) {
          match = child;
        }
      });
    });
    return match ? match : undefined;
  }

  /**
   * Unsubscribe from all Subscriptions
   */
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public refreshNavigation(navItem: NavigationItem, navigationGroupId?: string, permission?: Permission): void {
    this.removeFromNavigation(navItem, navigationGroupId);
    if (_.isUndefined(permission) || this.permissionService.hasPermission(permission)) {
      this.addToNavigation(navItem, navigationGroupId);
    }
  }

  public removeFromNavigation(element: NavigationBaseCollector | NavigationElement, parentId?: string): void {
    if (parentId && _.hasIn(this.navigationElements, parentId)) {
      _.pull(this.navigationElements[parentId].children, element);
      this.navigationElements[parentId].hidden = this.navigationElements[parentId].children.length === 0;
    } else {
      _.pull(this.navigationElements[element.id], element);
    }
  }

  /**
   * Is called after login was successfull to reset all Navigation elements.
   */
  public resetNavigationElements(): void {
    _.forEach(this.navigationElements, (element: NavigationElement) => {
      // We want to keep the Groups but hide them and delete all Children
      if (element instanceof NavigationGroup) {
        element.children = [];
      } else {
        delete this.navigationElements[element.id];
      }
    });
  }

  /**
   * Adds element to parent by parentId and makes parent visible
   */
  private addToParent(element: NavigationElement, parent: string): void {
    this.navigationElements[parent].hidden = false;
    this.navigationElements[parent].children.push(element);
  }

  /**
   * We don't want to overwrite so it merges the given element with a current one and adds it to the navigation
   */
  private addToRoot(element: NavigationElement): void {
    this.navigationElements[element.id] = element;
  }

  /**
   * It assigns the values of navigation elements to navigation constant
   */
  private assignNavigationElementsToNavigation(): void {
    Object.assign(navigation, _.values(this.navigationElements));
  }

  /**
   * Sorting 'algorithm'
   */
  private byPriority(a: { priority: number }, b: { priority: number }): number {
    return a.priority - b.priority;
  }

  /**
   * Sorts all elements and child elements
   */
  private sortElementAndChildren(element: Array<NavigationBaseCollector | NavigationElement>): void {
    element.sort(this.byPriority);

    _.forEach(element, (next: NavigationBaseCollector | NavigationElement) => {
      if (next instanceof NavigationBaseCollector) {
        this.sortElementAndChildren(next.children);
      }
    },
    );
  }
}
