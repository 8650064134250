var TenantUpdateRequestDto = /** @class */ (function () {
    function TenantUpdateRequestDto(tenantRequest) {
        this.name = undefined;
        this.description = undefined;
        this.roles = undefined;
        this.providers = undefined;
        this.sensorTypeThreshold = undefined;
        this.mobileSensorCheckInterval = undefined;
        this.stationarySensorCheckInterval = undefined;
        Object.assign(this, tenantRequest);
    }
    return TenantUpdateRequestDto;
}());
export { TenantUpdateRequestDto };
