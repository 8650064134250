var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
import { isValid } from 'date-fns';
var AssetSearchCriteria = /** @class */ (function (_super) {
    __extends(AssetSearchCriteria, _super);
    function AssetSearchCriteria(assetSearchCriteria) {
        var _this = _super.call(this, assetSearchCriteria) || this;
        _this.name = undefined;
        _this.partialName = undefined;
        _this.tags = undefined;
        _this.sensorId = undefined;
        _this.measurementType = undefined;
        _this.parentId = undefined;
        _this.code = undefined;
        _this.thresholdId = undefined;
        _this.dateFrom = undefined;
        _this.dateTo = undefined;
        _this.assetId = undefined;
        Object.assign(_this, assetSearchCriteria);
        return _this;
    }
    AssetSearchCriteria.prototype.toJSON = function () {
        return {
            name: this.name,
            partialName: this.partialName,
            tags: this.tags,
            sensorId: this.sensorId,
            measurementType: this.measurementType,
            parentId: this.parentId,
            code: this.code,
            thresholdId: this.thresholdId,
            //dateFrom: this.dateFrom,
            //dateTo: this.dateTo,
            page: this.page,
            size: this.size,
            assetId: this.assetId,
            dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
            //dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? moment(new Date(this.dateFrom)).toISOString(true) : undefined,
            dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
        };
    };
    return AssetSearchCriteria;
}(SearchCriteriaPageable));
export { AssetSearchCriteria };
