var SensorConfigValueDto = /** @class */ (function () {
    function SensorConfigValueDto(sensor) {
        this.defaultValue = undefined;
        this.key = undefined;
        this.name = undefined;
        this.type = undefined;
        this.value = undefined;
        this.unit = undefined;
        Object.assign(this, sensor);
    }
    return SensorConfigValueDto;
}());
export { SensorConfigValueDto };
