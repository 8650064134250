import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { PhoenixCloneConfirmationDialogData } from './classes/PhoenixCloneConfirmationDialogData';
import { PhoenixCloneConfirmationDialogComponent } from './phoenix-clone-confirmation-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PhoenixCloneConfirmationDialogService = /** @class */ (function () {
    function PhoenixCloneConfirmationDialogService(dialog) {
        this.dialog = dialog;
    }
    PhoenixCloneConfirmationDialogService.prototype.showClonedElementConfirmationSelectorDialog = function (element) {
        var dialogRef = this.dialog.open(PhoenixCloneConfirmationDialogComponent, {
            closeOnNavigation: false,
            autoFocus: false,
            disableClose: false,
            data: new PhoenixCloneConfirmationDialogData({
                rootClonedElement: element,
                title: 'WIZARDS.CLONE.TITLE',
                description: 'WIZARDS.CLONE.DESCRIPTION',
                question: 'WIZARDS.CLONE.QUESTION',
            }),
        });
        return dialogRef.afterClosed();
    };
    PhoenixCloneConfirmationDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixCloneConfirmationDialogService_Factory() { return new PhoenixCloneConfirmationDialogService(i0.inject(i1.MatDialog)); }, token: PhoenixCloneConfirmationDialogService, providedIn: "root" });
    return PhoenixCloneConfirmationDialogService;
}());
export { PhoenixCloneConfirmationDialogService };
