import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Observable, of} from 'rxjs';

import {AssetDto} from '../../../gapicon/asset/dto/asset-dto';

import {PhoenixTenantSelectorDialogData} from './classes/PhoenixTenantSelectorDialogData';
import {PhoenixTenantSelectorDialogComponent} from './phoenix-tenant-selector-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixTenantSelectorDialogService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public showTenantSelectorDialog(tenants: AssetDto[], showCancelButton: boolean = false): Observable<AssetDto[]> {
    if (tenants.length <= 1) {
      return of(tenants);
    }

    const dialogRef: MatDialogRef<PhoenixTenantSelectorDialogComponent> = this.dialog.open(PhoenixTenantSelectorDialogComponent, {
      closeOnNavigation: false,
      autoFocus: false,
      disableClose: !showCancelButton,
      data: new PhoenixTenantSelectorDialogData({
        tenants: tenants,
        cancelButton: showCancelButton,
        title: 'WIZARDS.TENANT.TITLE',
        subtitle: 'WIZARDS.TENANT.SUBTITLE',
      }),
    });

    return dialogRef.afterClosed();
  }
}
