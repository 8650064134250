import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PhoenixAutocompleteEntry } from '../../components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetDto, AssetSearchCriteriaDto, ThresholdDto } from '../../gapicon/dto.module';
import { MeasurementTypeDto } from '../../gapicon/measurement-type/dto/measurement-type-dto';
import { MeasurementTypeApiService } from '../../gapicon/measurement-type/services/measurement-type-api.service';
import { SensorSearchCriteriaDto } from '../../gapicon/sensor/dto/sensor-search-criteria-dto';
import { SensorApiService } from '../../gapicon/sensor/services/sensor-api.service';
import { ThresholdApiService } from '../../gapicon/threshold/services/threshold-api.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixWizardHelperService {
  private _DIALOGWIDTH: string = '448px';
  private _DIALOGWIDTHBIG: string = '550px';

  public constructor(
    private thresholdApi: ThresholdApiService,
    private measurementValueApi: MeasurementTypeApiService,
    private assetApi: AssetApiService,
    private sensorApi: SensorApiService,
  ) {
  }

  public get DIALOGWIDTH(): string {
    return this._DIALOGWIDTH;
  }

  public get DIALOGWIDTHBIG(): string {
    return this._DIALOGWIDTHBIG;
  }

  /**
   * Generates a random string
   */
  public getRandomString(): string {
    return Math.random().toString(36).substring(7);
  }

  /**
   * Gets an AutoComplete array of all available thresholds
   */
  public getAutoCompleteThresholdArray(): Observable<PhoenixAutocompleteEntry<ThresholdDto>[]> {
    return this.thresholdApi.getThresholds().pipe(
      map((thresholds: ThresholdDto[]) => _.map(thresholds, (threshold: ThresholdDto) => new PhoenixAutocompleteEntry<ThresholdDto>({
        label: threshold.name,
        object: threshold,
      }))),
    );
  }

  /**
   * Gets an array of auto complete entries for MeasurementTypes
   */
  public getAutoCompleteMeasurementTypeArray(): Observable<PhoenixAutocompleteEntry<MeasurementTypeDto>[]> {
    return this.measurementValueApi.getMeasurementTypes().pipe(
      map((measurementTypes: MeasurementTypeDto[]) => _.filter(measurementTypes, (measurementType: MeasurementTypeDto) => measurementType.tags.includes(AssetTags.checklist))),
      map((measurementTypes: MeasurementTypeDto[]) =>
        _.map(measurementTypes, (measurementType: MeasurementTypeDto) => new PhoenixAutocompleteEntry<MeasurementTypeDto>({
          label: measurementType.name,
          object: measurementType,
        }))),
    );
  }

  /**
   * Gets an array of auto complete entries for Sensors
   */
  public getAutoCompleteSensorArray(): Observable<PhoenixAutocompleteEntry<AssetDto>[]> {
    return this.sensorApi.getSensors(new SensorSearchCriteriaDto({ isAssigned: false })).pipe(
      map((assetDtos: AssetDto[]) =>
        _.map(assetDtos, (assetDto: AssetDto) => new PhoenixAutocompleteEntry<AssetDto>({
          label: assetDto.name,
          object: assetDto,
        }))),
    );
  }

  public getSensorById(sensorId: string): Observable<PhoenixAutocompleteEntry<AssetDto>> {
    return this.sensorApi.getSensors(new SensorSearchCriteriaDto({ sensorIds: [sensorId] })).pipe(
      map((sensors: AssetDto[]) => {
        const autocompleteEntry: PhoenixAutocompleteEntry<AssetDto> = new PhoenixAutocompleteEntry<AssetDto>();
        if (sensors.length === 1) {
          autocompleteEntry.label = sensors[0].name;
          autocompleteEntry.object = sensors[0];
        }
        return autocompleteEntry;
      }));
  }

  /**
   * Searches for assets
   * @param search part of the name the assets should have
   * @param filterAsset optional filter to filter out assets that have the id of filterAsset or are a child of filterAsset
   * @param tagFilter optional filter to filter out assets that have at least one of the given tags (defaults to measurement points)
   */
  public assetAutocompleteSearchFunction(
    search: string,
    filterAsset?: AssetDto,
    tagFilter: AssetTags[] = [AssetTags.asset, AssetTags.checkpoint],
  ): Observable<PhoenixAutocompleteEntry<AssetDto>[]> {
    return this.assetApi.getAssets(new AssetSearchCriteriaDto({
      partialName: search,
      tags: tagFilter,
      size: 10,
    }), false).pipe(
      // Filter assets matching filterAsset Argument
      map((assets: AssetDto[]) => filterAsset ? _.filter(assets, (asset: AssetDto) => asset.id !== filterAsset.id && asset.parentId !== filterAsset.id) : assets),
      // Convert to Autocomplete Format
      map((assets: AssetDto[]) => _.map(assets, (asset: AssetDto) => new PhoenixAutocompleteEntry<AssetDto>({ object: asset, label: asset.name }))),
    );
  }
}
