import { NavigationType } from '../enums/navigation-type.enum';
/**
 * Class for Item in Navigation. The element can not have children.
 */
var NavigationItem = /** @class */ (function () {
    function NavigationItem(id, priority, hidden, title, urlOrFunction, icon, badge, exactMatch) {
        if (hidden === void 0) { hidden = false; }
        this.hidden = false;
        this.type = NavigationType.ITEM;
        this.id = id;
        this.priority = priority;
        this.hidden = hidden;
        this.title = title;
        this.translate = title;
        this.icon = icon;
        this.badge = badge;
        switch (typeof urlOrFunction) {
            case 'string':
                this.url = urlOrFunction.toString();
                break;
            case 'function':
                this.function = urlOrFunction;
                break;
        }
        this.exactMatch = exactMatch;
    }
    return NavigationItem;
}());
export { NavigationItem };
