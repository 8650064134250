var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixTableLink } from '../../../../components/phoenix-table/classes/phoenix-table-link';
import { PhoenixNavigationService } from '../../../../services/phoenix-navigation-service/phoenix-navigation.service';
import { PhoenixMeasurementTypeInformationDialogComponent } from './phoenix-measurement-type-information-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../services/phoenix-navigation-service/phoenix-navigation.service";
var PhoenixMeasurementTypeInformationDialogService = /** @class */ (function () {
    function PhoenixMeasurementTypeInformationDialogService(translateService, dialog, navigationService) {
        this.translateService = translateService;
        this.dialog = dialog;
        this.navigationService = navigationService;
    }
    PhoenixMeasurementTypeInformationDialogService.prototype.openMeasurementTypeInformationDialog = function (assets) {
        var dialogRef = this.dialog.open(PhoenixMeasurementTypeInformationDialogComponent, {
            autoFocus: false,
            maxWidth: 800,
            data: {
                title: this.translateService.instant('MEASUREMENTTYPEINFORMATIONDIALOG.TITLE'),
                subtitle: 'MEASUREMENTTYPEINFORMATIONDIALOG.SUBTITLE',
                buttons: [
                    new PhoenixDialogButton({ label: 'MEASUREMENTTYPEINFORMATIONDIALOG.CLOSEBUTTON', click: function () { return dialogRef.close(); } }),
                ],
                links: this.mapAssetsToLinks(assets, function () { return dialogRef.close(); }),
            },
        });
        return dialogRef;
    };
    PhoenixMeasurementTypeInformationDialogService.prototype.mapAssetsToLinks = function (assets, closeDialog) {
        var _this = this;
        var linkList = [];
        var _loop_1 = function (asset) {
            linkList.push(new PhoenixTableLink(asset.name, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.navigationService.navigateToAsset(asset)];
                        case 1:
                            _a.sent();
                            closeDialog();
                            return [2 /*return*/];
                    }
                });
            }); }));
        };
        for (var _i = 0, assets_1 = assets; _i < assets_1.length; _i++) {
            var asset = assets_1[_i];
            _loop_1(asset);
        }
        return linkList;
    };
    PhoenixMeasurementTypeInformationDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixMeasurementTypeInformationDialogService_Factory() { return new PhoenixMeasurementTypeInformationDialogService(i0.inject(i1.TranslateService), i0.inject(i2.MatDialog), i0.inject(i3.PhoenixNavigationService)); }, token: PhoenixMeasurementTypeInformationDialogService, providedIn: "root" });
    return PhoenixMeasurementTypeInformationDialogService;
}());
export { PhoenixMeasurementTypeInformationDialogService };
