import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule, MatDialogModule, MatDividerModule, MatProgressSpinnerModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixDialogNewComponent} from './phoenix-dialog-new.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PhoenixDialogNewComponent,
  ],
  exports: [
    PhoenixDialogNewComponent,
  ],
})
export class PhoenixDialogNewModule {
}
