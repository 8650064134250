var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Asset } from './asset';
var DynamicAsset = /** @class */ (function (_super) {
    __extends(DynamicAsset, _super);
    function DynamicAsset(asset) {
        var _this = _super.call(this, asset) || this;
        _this.costCenterCode = undefined;
        _this.costCenterType = undefined;
        _this.language = undefined;
        _this.duration = undefined;
        _this.provider = undefined;
        Object.assign(_this, asset);
        return _this;
    }
    DynamicAsset.prototype.toJSON = function () {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            tags: this.tags,
            tenantId: this.tenantId,
            properties: this.properties,
            code: this.code,
            measurementPoint: this.measurementPoint,
            parentId: this.parentId,
            statistics: this.statistics,
            childStatistics: this.childStatistics,
            lastMeasurement: this.lastMeasurement,
            tenant: this.tenant,
            sensor: this.sensor,
            attachments: this.attachments,
            taskConfigs: this.taskConfigs,
            assignedUserGroups: this.assignedUserGroups,
            timeWindowConfig: this.timeWindowConfig,
            costCenterCode: this.costCenterCode,
            costCenterType: this.costCenterType,
            language: this.language,
            duration: this.duration,
            provider: this.provider
        };
    };
    return DynamicAsset;
}(Asset));
export { DynamicAsset };
