import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PhoenixFormElementType } from '../../../components/phoenix-form/enums/phoenix-form-element-type.enum';
import { PhoenixFormSwitch } from '../../../components/phoenix-form/phoenix-form-switch/classes/phoenix-form-switch';
import { PhoenixFormText } from '../../../components/phoenix-form/phoenix-form-text/classes/phoenix-form-text';
import { PhoenixWizardHelperService } from '../../../services/phoenix-wizards/phoenix-wizard-helper.service';
import { PhoenixSensormanagementDialog } from './classes/phoenix-sensormanagement-dialog';
var PhoenixSensormanagementDialogComponent = /** @class */ (function () {
    function PhoenixSensormanagementDialogComponent(data, translateService, wizardHelper) {
        this.data = data;
        this.translateService = translateService;
        this.wizardHelper = wizardHelper;
        this.elementType = PhoenixFormElementType;
        this.configElements = [];
        this.registrationElements = [];
        this.confirmElements = [];
        this.confirmEditElements = [];
    }
    PhoenixSensormanagementDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.randomString = this.wizardHelper.getRandomString();
        this.updateSensorTypeControls(this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName).value);
        this.sensorTypeSubscription = this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName)
            .valueChanges.subscribe(function (sensorType) { return _this.updateSensorTypeControls(sensorType); });
    };
    PhoenixSensormanagementDialogComponent.prototype.ngOnDestroy = function () {
        if (this.sensorTypeSubscription) {
            this.sensorTypeSubscription.unsubscribe();
        }
    };
    PhoenixSensormanagementDialogComponent.prototype.updateSensorTypeControls = function (sensorType) {
        if (sensorType) {
            this.registrationElements = [];
            this.configElements = [];
            this.confirmElements = [];
            this.confirmEditElements = [];
            var translationKey = 'CONFIGINFO.' + 'CONFIGINFOTEXT.' + sensorType.key.toUpperCase();
            var infoText = this.translateService.instant(translationKey);
            this.sensorTypeConfigInfoText = _.isEqual(infoText, translationKey) ? undefined : infoText;
            if (this.data.showRegistrationParams) {
                this.addSensorConfigControlsToDialog(sensorType.registrationParams, 'REGISTRATIONCONFIG', this.registrationElements);
            }
            this.addSensorConfigControlsToDialog(sensorType.configurations, 'SENSORCONFIG', this.configElements, this.data.sensorConfigPreset);
            if (!this.data.showRegistrationParams) {
                this.addEditControlsToDialog(sensorType.configurations, 'TEST', this.confirmEditElements, this.data.sensorConfigPreset);
            }
        }
    };
    PhoenixSensormanagementDialogComponent.prototype.addConfirmControlsToDialog = function (prefix, elements, preset) {
        var formGroup = new FormGroup({});
        this.data.formGroup.removeControl(prefix);
        this.data.formGroup.addControl(prefix, formGroup);
    };
    PhoenixSensormanagementDialogComponent.prototype.addSensorConfigControlsToDialog = function (sensorConfig, prefix, elements, preset) {
        var _this = this;
        var formGroup = new FormGroup({});
        this.data.formGroup.removeControl(prefix);
        this.data.formGroup.addControl(prefix, formGroup);
        var sensorType = this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName).value;
        var isAutomaticCounter = sensorType ? sensorType.measurementTypes.includes("automaticCounter") : false;
        sensorConfig.forEach(function (sensorConfigValue) {
            var presetValue = preset ? _.find(preset, { key: sensorConfigValue.key }) : undefined;
            if (presetValue !== undefined) {
                if (presetValue.key === 'Offset') {
                    var offsetValue = presetValue.value;
                    var digitOffsetValue = offsetValue.split('.');
                    var firstDigit = void 0;
                    var secoundDigit = void 0;
                    if (digitOffsetValue.length > 1) {
                        firstDigit = digitOffsetValue[0];
                        secoundDigit = digitOffsetValue[1];
                        presetValue.value = firstDigit.concat('.').concat(secoundDigit.substring(0, 2));
                    }
                }
            }
            var translationKey = 'CONFIGINFO.' + 'CONFIGINFOTEXT.' + sensorConfigValue.key.toUpperCase();
            var infoText = _this.translateService.instant(translationKey);
            var sensorCaption = _.isEqual(infoText, translationKey) ? _this.getNameAndUnit(sensorConfigValue) : infoText.concat(_this.getUnit(sensorConfigValue));
            formGroup.addControl(sensorConfigValue.key, new FormControl(presetValue ? presetValue.value : sensorConfigValue.defaultValue));
            /* if (isAutomaticCounter) {
               formGroup.addControl("code", new FormControl(this.randomString))
               formGroup.addControl("confirmCode", new FormControl(null, [Validators.required, Validators.pattern(this.randomString)]))
       
             }*/
            switch (sensorConfigValue.type) {
                case 'STRING':
                case 'DECIMAL':
                case 'NUMBER':
                    elements.push(new PhoenixFormText(formGroup, sensorConfigValue.key, sensorCaption, presetValue ? presetValue.value : sensorConfigValue.defaultValue));
                    break;
                case 'BOOLEAN':
                    elements.push(new PhoenixFormSwitch(formGroup, sensorConfigValue.key, sensorCaption, '', false, (presetValue ? presetValue.value : sensorConfigValue.defaultValue) === '1'));
                    break;
            }
        });
        /* if (isAutomaticCounter) {
           elements.push(new PhoenixFormText(formGroup, 'code', 'WIZARDS.SENSOR.DELETE.DELETECODE', this.randomString, undefined, undefined, undefined, false, true, true))
           elements.push(new PhoenixFormText(formGroup, 'confirmCode', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUT', '', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUTHINT'))
         }*/
    };
    PhoenixSensormanagementDialogComponent.prototype.addEditControlsToDialog = function (sensorConfig, prefix, elements, preset) {
        var formGroup = new FormGroup({});
        this.data.formGroup.removeControl(prefix);
        this.data.formGroup.addControl(prefix, formGroup);
        var sensorType = this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName).value;
        var isAutomaticCounter = sensorType ? sensorType.measurementTypes.includes("automaticCounter") : false;
        if (isAutomaticCounter) {
            formGroup.addControl("code", new FormControl(this.randomString));
            formGroup.addControl("confirmCode", new FormControl(null, [Validators.required, Validators.pattern(this.randomString)]));
        }
        if (isAutomaticCounter) {
            elements.push(new PhoenixFormText(formGroup, 'code', 'WIZARDS.SENSOR.DELETE.DELETECODE', this.randomString, undefined, undefined, undefined, false, true, true));
            elements.push(new PhoenixFormText(formGroup, 'confirmCode', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUT', '', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUTHINT'));
        }
    };
    PhoenixSensormanagementDialogComponent.prototype.getNameAndUnit = function (sensorConfigValue) {
        var s = sensorConfigValue.name;
        s = s.concat(this.getUnit(sensorConfigValue));
        return s;
    };
    PhoenixSensormanagementDialogComponent.prototype.getUnit = function (sensorConfigValue) {
        var s = '';
        if (!_.isNil(sensorConfigValue.unit) && sensorConfigValue.unit.length > 0) {
            s = s.concat(' (', sensorConfigValue.unit, ')');
        }
        return s;
    };
    return PhoenixSensormanagementDialogComponent;
}());
export { PhoenixSensormanagementDialogComponent };
