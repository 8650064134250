var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var TenantSearchCriteria = /** @class */ (function (_super) {
    __extends(TenantSearchCriteria, _super);
    function TenantSearchCriteria(tenantSearchCriteria) {
        var _this = _super.call(this, tenantSearchCriteria) || this;
        _this.name = undefined;
        _this.description = undefined;
        Object.assign(_this, tenantSearchCriteria);
        return _this;
    }
    TenantSearchCriteria.prototype.toJSON = function () {
        return {
            name: this.name,
            description: this.description,
            page: this.page,
            size: this.size,
        };
    };
    return TenantSearchCriteria;
}(SearchCriteriaPageable));
export { TenantSearchCriteria };
