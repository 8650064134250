import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe); // de-DE
var ɵ0 = {
    parse: {},
    display: {
        dateInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
        monthInput: { month: 'long' },
        datetimeInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
        timeInput: { hour: '2-digit', minute: '2-digit' },
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: '2-digit', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
        popupHeaderDateLabel: { weekday: 'short', month: 'short', day: '2-digit' },
    },
};
var PhoenixTaskConfigDialogModule = /** @class */ (function () {
    function PhoenixTaskConfigDialogModule() {
    }
    return PhoenixTaskConfigDialogModule;
}());
export { PhoenixTaskConfigDialogModule };
export { ɵ0 };
