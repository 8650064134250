var Role = /** @class */ (function () {
    function Role(role) {
        this.name = undefined;
        Object.assign(this, role);
    }
    Role.prototype.toJSON = function () {
        return {
            name: this.name,
        };
    };
    return Role;
}());
export { Role };
