import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { FileMapperService } from '../mapper/file-mapper.service';
var FileApiService = /** @class */ (function () {
    function FileApiService(http, sharedService, fileMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.fileMapperService = fileMapperService;
    }
    /**
     * Deletes a file by given fileDto.
     * @returns
     * @param fileItemDto
     */
    FileApiService.prototype.deleteFile = function (fileItemDto) {
        return this.http.delete(this.sharedService.buildApiUrl('files', this.fileMapperService.fileItemDtoToFileItem(fileItemDto).name));
    };
    /**
     * Gets a download token for a file by given path string.
     * @param path
     * @returns
     */
    FileApiService.prototype.getDownloadTokenByPath = function (path) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('downloadToken', path)).pipe(map(function (fileAccessToken) { return _this.fileMapperService.fileAccessTokenDtoToFileAccessToken(fileAccessToken); }));
    };
    /**
     * Gets a download token for a file by given path string.
     * @param path
     * @param token optional, token to authenticate download
     * @returns
     */
    FileApiService.prototype.getDownloadUrlByPath = function (path, token) {
        if (token) {
            return this.sharedService.buildApiUrl('download', path) + '?token=' + token;
        }
        else {
            return this.sharedService.buildApiUrl('download', path);
        }
    };
    /**
     * Gets a file by given path string.
     * @param path
     * @returns
     */
    FileApiService.prototype.getFileByPath = function (path) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('files', path)).pipe(map(function (fileItem) { return _this.fileMapperService.fileItemToFileItemDto(fileItem); }));
    };
    /**
     * Gets the file storage information.
     * @param assetDto
     */
    FileApiService.prototype.getFileStorageInformation = function (assetDto) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl('files', 'assets', assetDto.id, 'attachments', 'storageinformation')).pipe(map(function (fileStorageInformation) { return _this.fileMapperService.fileStorageInformationToFileStorageInformationDto(fileStorageInformation); }));
    };
    /**
     * Overwrites a file by given fileItem and file.
     * @param fileItemDto
     * @param file
     * @returns
     */
    FileApiService.prototype.updateFile = function (fileItemDto, file) {
        return this.upload(fileItemDto, file, 'PUT', this.sharedService.buildApiUrl('files', this.fileMapperService.fileItemDtoToFileItem(fileItemDto).name));
    };
    /**
     * Generic method to upload a file to BLOB-Storage.
     * @param fileItemDto
     * @param file
     * @param method
     * @param path
     * @returns
     */
    FileApiService.prototype.upload = function (fileItemDto, file, method, path) {
        var _this = this;
        return Observable.create(function (observer) {
            var fileItem = _this.fileMapperService.fileItemDtoToFileItem(fileItemDto);
            var data = new FormData();
            data.append('file', file, file.name);
            data.append('contentType', fileItem.contentType);
            data.append('contentEncoding', fileItem.contentEncoding);
            var req = new HttpRequest(method, path, data, {
                reportProgress: true,
            });
            _this.http.request(req)
                .subscribe(function (event) {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        observer.next(event.loaded / event.total);
                        break;
                    case HttpEventType.Response:
                        observer.next(1);
                        observer.complete();
                        break;
                }
            }, observer.error, observer.complete);
        });
    };
    /**
     * Uploads a file by given fileItem and file.
     * @param fileItemDto
     * @param file
     * @returns
     */
    FileApiService.prototype.uploadFile = function (fileItemDto, file) {
        return this.upload(fileItemDto, file, 'POST', this.sharedService.buildApiUrl('files', this.fileMapperService.fileItemDtoToFileItem(fileItemDto).name));
    };
    return FileApiService;
}());
export { FileApiService };
