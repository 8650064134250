var RegistrationConfirmationDto = /** @class */ (function () {
    function RegistrationConfirmationDto(registrationConfirmationDto) {
        this.token = undefined;
        this.password = undefined;
        this.user = undefined;
        Object.assign(this, registrationConfirmationDto);
    }
    return RegistrationConfirmationDto;
}());
export { RegistrationConfirmationDto };
