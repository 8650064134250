var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as i0 from "@angular/core";
var AppInitStorageService = /** @class */ (function () {
    function AppInitStorageService() {
    }
    AppInitStorageService.prototype.getUser = function () {
        return __assign({}, this.user);
    };
    AppInitStorageService.prototype.getAvailableTenants = function () {
        return this.availableTenants ? this.availableTenants.slice() : [];
    };
    AppInitStorageService.prototype.setAvailableTenants = function (availableTenants) {
        this.availableTenants = availableTenants;
    };
    AppInitStorageService.prototype.getAvailableAssetBranches = function () {
        return this.availableAssetBranches ? this.availableAssetBranches.slice() : [];
    };
    AppInitStorageService.prototype.setAvailableAssetBranches = function (availableAssetBranches) {
        this.availableAssetBranches = availableAssetBranches;
    };
    AppInitStorageService.prototype.setUser = function (user) {
        this.user = user;
    };
    AppInitStorageService.ngInjectableDef = i0.defineInjectable({ factory: function AppInitStorageService_Factory() { return new AppInitStorageService(); }, token: AppInitStorageService, providedIn: "root" });
    return AppInitStorageService;
}());
export { AppInitStorageService };
