import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable, of } from 'rxjs';

import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';

import { PhoenixMeasurementPointSelectorDialogData } from './classes/PhoenixMeasurementPointSelectorDialogData';
import { PhoenixMeasurementPointSelectorDialogComponent } from './phoenix-measurementpoint-selector-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixMeasurementPointSelectorDialogService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }
  // TODO this shows dialog with measurementPoints  get from backend  (see showTenantSelectorDialog in tenent dialog)
  public showMeasurementPointSelectorDialog(measurementPoints: AssetDto[], showCancelButton: boolean = true): Observable<AssetDto[]> {

    console.log("showMeasurementPointSelectorDialog");

    if (measurementPoints.length <= 1) {
      return of(measurementPoints);
    }

    const dialogRef: MatDialogRef<PhoenixMeasurementPointSelectorDialogComponent> = this.dialog.open(PhoenixMeasurementPointSelectorDialogComponent, {
      closeOnNavigation: false,
      autoFocus: false,
      disableClose: !showCancelButton,
      data: new PhoenixMeasurementPointSelectorDialogData({
        measurementPoints: measurementPoints,
        cancelButton: showCancelButton,
        title: 'WIZARDS.MEASUREMENTPOINT.TITLE',
        subtitle: 'WIZARDS.MEASUREMENTPOINT.SUBTITLE',
      }),
    });

    return dialogRef.afterClosed();
  }
}
