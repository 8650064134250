import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { SensorType } from '../api/sensor-type';
import { SensorTypeSearchCriteria } from '../api/sensor-type-search-criteria';
import { SensorTypeDto } from '../dto/sensor-type-dto';
import { SensorTypeSearchCriteriaDto } from '../dto/sensor-type-search-criteria-dto';
import { SensorMapperService } from './sensor-mapper.service';
import * as i0 from "@angular/core";
import * as i1 from "./sensor-mapper.service";
var SensorTypeMapperService = /** @class */ (function () {
    function SensorTypeMapperService(sensorMapper) {
        this.sensorMapper = sensorMapper;
    }
    /*
   SensorType <=> SensorTypeDto
  */
    SensorTypeMapperService.prototype.sensorTypeDtoToSensorType = function (sensorTypeDto) {
        var sensorType = plainToClass(SensorType, sensorTypeDto);
        sensorType.configurations = this.sensorMapper.sensorConfigValueDtoArrayToSensorConfigValueArray(sensorTypeDto.configurations);
        return sensorType;
    };
    SensorTypeMapperService.prototype.sensorTypeToSensorTypeDto = function (sensorType) {
        var sensorTypeDto = plainToClass(SensorTypeDto, sensorType);
        sensorTypeDto.configurations = this.sensorMapper.sensorConfigValueArrayToSensorConfigValueDtoArray(sensorType.configurations);
        return sensorTypeDto;
    };
    SensorTypeMapperService.prototype.sensorTypeDtoArrayToSensorTypeArray = function (sensorTypeDtos) {
        var _this = this;
        return _.map(sensorTypeDtos, function (sensorTypeDto) { return _this.sensorTypeDtoToSensorType(sensorTypeDto); });
    };
    SensorTypeMapperService.prototype.sensorTypeArrayToSensorTypeDtoArray = function (sensorTypes) {
        var _this = this;
        return _.forEach(sensorTypes, function (sensorType) { return _this.sensorTypeToSensorTypeDto(sensorType); });
    };
    /*
    SensorTypeSearchCriteria <=> SensorTypeSearchCriteriaDto
   */
    SensorTypeMapperService.prototype.sensorTypeSearchCriteriaDtoToSensorTypeSearchCriteria = function (sensorTypeSearchCriteriaDto) {
        return plainToClass(SensorTypeSearchCriteria, sensorTypeSearchCriteriaDto);
    };
    SensorTypeMapperService.prototype.sensorTypeSearchCriteriaToSensorTypeSearchCriteriaDto = function (sensorTypeSearchCriteria) {
        return plainToClass(SensorTypeSearchCriteriaDto, sensorTypeSearchCriteria);
    };
    SensorTypeMapperService.ngInjectableDef = i0.defineInjectable({ factory: function SensorTypeMapperService_Factory() { return new SensorTypeMapperService(i0.inject(i1.SensorMapperService)); }, token: SensorTypeMapperService, providedIn: "root" });
    return SensorTypeMapperService;
}());
export { SensorTypeMapperService };
