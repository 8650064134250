import {NgModule} from '@angular/core';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';

import {PhoenixBreadcrumbsComponent} from './phoenix-breadcrumbs/phoenix-breadcrumbs.component';
import {PhoenixCenterCardComponent} from './phoenix-center-card/phoenix-center-card.component';
import {PhoenixHelpComponent} from './phoenix-help/phoenix-help.component';
import {PhoenixIconComponent} from './phoenix-icon/phoenix-icon.component';
import {PhoenixPasswordRulesComponent} from './phoenix-password-rules/phoenix-password-rules.component';

@NgModule({
  imports: [
    FuseSharedModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatSelectModule,
    TranslateModule,
    RouterModule,
    MatProgressSpinnerModule,

  ],
  declarations: [
    PhoenixPasswordRulesComponent,
    PhoenixHelpComponent,
    PhoenixCenterCardComponent,
    PhoenixBreadcrumbsComponent,
    PhoenixIconComponent,
  ],
  exports: [
    PhoenixPasswordRulesComponent,
    PhoenixHelpComponent,
    PhoenixCenterCardComponent,
    PhoenixBreadcrumbsComponent,
    PhoenixIconComponent,
  ],
})
export class PhoenixSharedComponentsModule {
}
