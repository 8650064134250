import { Router } from '@angular/router';
import { PermissionService } from './permission.service';
var CanActivateGuard = /** @class */ (function () {
    function CanActivateGuard(permissionService, router) {
        this.permissionService = permissionService;
        this.router = router;
    }
    CanActivateGuard.prototype.canActivate = function (activatedRoute) {
        if (this.permissionService.userIsNotAuthenticated()) {
            this.router.navigate(['/login']);
            return false;
        }
        if (this.permissionService.routeNeedsPermission(activatedRoute)) {
            return this.permissionService.hasPermission(activatedRoute.data.permission);
        }
        // User is authenticated and needs no permission.
        return true;
    };
    return CanActivateGuard;
}());
export { CanActivateGuard };
