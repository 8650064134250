import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {PhoenixDialogButton} from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import {AssetDto} from '../../../gapicon/asset/dto/asset-dto';

import {PhoenixTenantSelectorDialogData} from './classes/PhoenixTenantSelectorDialogData';

@Component({
  selector: 'phoenix-tenant-selector-dialog',
  templateUrl: './phoenix-tenant-selector-dialog.component.html',
  styleUrls: ['./phoenix-tenant-selector-dialog.component.scss'],
})
export class PhoenixTenantSelectorDialogComponent implements OnInit {

  public buttons: PhoenixDialogButton[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixTenantSelectorDialogData,
    private dialogRef: MatDialogRef<PhoenixTenantSelectorDialogComponent>,
  ) {
  }

  public ngOnInit(): void {

    if (this.data.cancelButton) {
      this.buttons = [
        new PhoenixDialogButton({
          label: 'WIZARDS.TENANT.CANCEL',
          click: (): void => this.dialogRef.close(undefined),
        }),
      ];
    }
  }

  public selectTenant(tenant: AssetDto): void {
    this.dialogRef.close([tenant]);
  }

}
