import {FormGroup} from '@angular/forms';

import {PhoenixFormElementType} from '../../enums/phoenix-form-element-type.enum';
import {PhoenixFormElement} from '../../interfaces/phoenix-form-element';

export class PhoenixFormSwitch implements PhoenixFormElement {
  public formGroup: FormGroup;
  public formControlName: string;
  public type: PhoenixFormElementType = PhoenixFormElementType.Switch;

  public label: string;
  public hint: string;
  public disabled: boolean;
  public checked: boolean;


  public constructor(formGroup: FormGroup, formControlName: string, label: string = '', hint: string = '', disabled: boolean = false, checked: boolean = false) {
    this.formGroup = formGroup;
    this.formControlName = formControlName;
    this.label = label;
    this.hint = hint;
    this.disabled = disabled;
    this.checked = checked;
  }
}
