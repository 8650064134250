import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSnackBarModule} from '@angular/material';

import {PhoenixButtonModule} from '../phoenix-button/phoenix-button.module';

import {PhoenixSnackbarComponent} from './phoenix-snackbar.component';
import {PhoenixSnackbarService} from './phoenix-snackbar.service';


@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    PhoenixButtonModule,
    FlexLayoutModule,
  ],
  providers: [
    PhoenixSnackbarService,
  ],
  declarations: [
    PhoenixSnackbarComponent,
  ],
  exports: [
    PhoenixSnackbarComponent,
  ],
  entryComponents: [
    PhoenixSnackbarComponent,
  ],
})
export class PhoenixSnackbarModule {
}
