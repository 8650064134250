import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
var SearchCriteria = /** @class */ (function () {
    function SearchCriteria(searchCriteria) {
        Object.assign(this, searchCriteria);
    }
    SearchCriteria.prototype.getRequestQueryParams = function () {
        var json = this.toJSON();
        var params = new HttpParams();
        for (var _i = 0, _a = Object.keys(json); _i < _a.length; _i++) {
            var key = _a[_i];
            var value = json[key];
            if (_.isArray(value)) {
                value = value.join(',');
            }
            if (!_.isNil(value)) {
                params = params.append(key, value);
            }
        }
        return params;
    };
    return SearchCriteria;
}());
export { SearchCriteria };
