<ng-container [formGroup]="form">
  <mat-form-field>
    <textarea
      [maxlength]="phoenixFormControl.options['maxlength'] ? phoenixFormControl.options['maxlength'] : undefined"
      matInput placeholder="{{phoenixFormControl.label | translate}}" [formControlName]="phoenixFormControl.key"
      [rows]="phoenixFormControl.options['maxlength'] ? phoenixFormControl.options['maxlength'] / 60 : 5"></textarea>
    <mat-hint align="end" *ngIf="phoenixFormControl.options['maxlength']">
      {{form.get(phoenixFormControl.key).value?.length || 0}}/{{ phoenixFormControl.options['maxlength'] }}
    </mat-hint>
    <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
      <span translate data-translate="DIALOG.FORM.INVALID"></span>
    </mat-error>
  </mat-form-field>
</ng-container>