import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

import {Role} from '../api/role';
import {RoleSearchCriteria} from '../api/role-search-criteria';
import {RoleDto} from '../dto/role-dto';
import {RoleSearchCriteriaDto} from '../dto/role-search-criteria-dto';

@Injectable({
  providedIn: 'root',
})
export class RoleMapperService {

  public constructor() {
  }

  public roleSearchCriteriaDtoToRoleSearchCriteria(roleSearchCriteriaDto: RoleSearchCriteriaDto): RoleSearchCriteria {
    return plainToClass(RoleSearchCriteria, roleSearchCriteriaDto);
  }

  public roleToRoleDto(role: Role): RoleDto {
    return plainToClass(RoleDto, role);
  }

  public roleDtoToRole(roleDto: RoleDto): Role {
    return plainToClass(Role, roleDto);
  }

  public roleArrayToRoleDtoArray(roles: Role[]): RoleDto[] {
    return _.map(roles, (role: Role) => this.roleToRoleDto(role));
  }

  public roleDtoArrayToRoleArray(roleDtos: RoleDto[]): Role[] {
    return _.map(roleDtos, (roleDto: RoleDto) => this.roleDtoToRole(roleDto));
  }
}
