var MeasurementPointDto = /** @class */ (function () {
    function MeasurementPointDto(measurementPointDto) {
        this.sensorId = undefined;
        this.type = undefined;
        this.threshold = undefined;
        Object.assign(this, measurementPointDto);
    }
    return MeasurementPointDto;
}());
export { MeasurementPointDto };
