import { OnDestroy } from '@angular/core';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { PermissionService } from '../guards/permission.service';
import { navbarGroups } from '../layout/components/navbar/navbar-groups/navbar-groups.module';
import { NavigationBaseCollector } from './classes/navigation-base-collector';
import { NavigationGroup } from './classes/navigation-group';
import { NavigationItem } from './classes/navigation-item';
import { navigation } from './navigation';
/**
 * Cointains methods to administrate navigation entries
 */
var NavigationService = /** @class */ (function () {
    function NavigationService(communicationService, permissionService) {
        var _this = this;
        this.communicationService = communicationService;
        this.permissionService = permissionService;
        this._navigationElements = navbarGroups;
        this.ngUnsubscribe = new Subject();
        this.communicationService.getObservable(PhoenixCommunicationSubject.UserLogout)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (success) {
            if (success.value) {
                _this.resetNavigationElements();
            }
        });
    }
    Object.defineProperty(NavigationService.prototype, "navigationElements", {
        get: function () {
            return this._navigationElements;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds elements to Navigation. If there is a parentId submitted it will go there as a child.
     */
    NavigationService.prototype.addToNavigation = function (element, parentId) {
        if (parentId && _.hasIn(this.navigationElements, parentId) && (this.navigationElements[parentId] instanceof NavigationBaseCollector)) {
            this.addToParent(element, parentId);
        }
        else {
            this.addToRoot(element);
        }
        this.assignNavigationElementsToNavigation();
        this.sortElementAndChildren(navigation);
    };
    /**
     * Returns first found NavigationElement that matches given searchPath
     * @param {string} searchPath
     * @returns {NavigationItem} or undefined
     */
    NavigationService.prototype.getNavigationElementByPath = function (searchPath) {
        var match;
        _.forEach(this.navigationElements, function (navigationGroup) {
            _.forEach(navigationGroup.children, function (child) {
                if (match === undefined && child instanceof NavigationItem && child.url === searchPath) {
                    match = child;
                }
            });
        });
        return match ? match : undefined;
    };
    /**
     * Unsubscribe from all Subscriptions
     */
    NavigationService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NavigationService.prototype.refreshNavigation = function (navItem, navigationGroupId, permission) {
        this.removeFromNavigation(navItem, navigationGroupId);
        if (_.isUndefined(permission) || this.permissionService.hasPermission(permission)) {
            this.addToNavigation(navItem, navigationGroupId);
        }
    };
    NavigationService.prototype.removeFromNavigation = function (element, parentId) {
        if (parentId && _.hasIn(this.navigationElements, parentId)) {
            _.pull(this.navigationElements[parentId].children, element);
            this.navigationElements[parentId].hidden = this.navigationElements[parentId].children.length === 0;
        }
        else {
            _.pull(this.navigationElements[element.id], element);
        }
    };
    /**
     * Is called after login was successfull to reset all Navigation elements.
     */
    NavigationService.prototype.resetNavigationElements = function () {
        var _this = this;
        _.forEach(this.navigationElements, function (element) {
            // We want to keep the Groups but hide them and delete all Children
            if (element instanceof NavigationGroup) {
                element.children = [];
            }
            else {
                delete _this.navigationElements[element.id];
            }
        });
    };
    /**
     * Adds element to parent by parentId and makes parent visible
     */
    NavigationService.prototype.addToParent = function (element, parent) {
        this.navigationElements[parent].hidden = false;
        this.navigationElements[parent].children.push(element);
    };
    /**
     * We don't want to overwrite so it merges the given element with a current one and adds it to the navigation
     */
    NavigationService.prototype.addToRoot = function (element) {
        this.navigationElements[element.id] = element;
    };
    /**
     * It assigns the values of navigation elements to navigation constant
     */
    NavigationService.prototype.assignNavigationElementsToNavigation = function () {
        Object.assign(navigation, _.values(this.navigationElements));
    };
    /**
     * Sorting 'algorithm'
     */
    NavigationService.prototype.byPriority = function (a, b) {
        return a.priority - b.priority;
    };
    /**
     * Sorts all elements and child elements
     */
    NavigationService.prototype.sortElementAndChildren = function (element) {
        var _this = this;
        element.sort(this.byPriority);
        _.forEach(element, function (next) {
            if (next instanceof NavigationBaseCollector) {
                _this.sortElementAndChildren(next.children);
            }
        });
    };
    return NavigationService;
}());
export { NavigationService };
