import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import _ from 'lodash';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicFormControlArrayComponent = /** @class */ (function () {
    function PhoenixDynamicFormControlArrayComponent() {
        this.pushIndex = undefined;
        this.topMargin = false;
    }
    PhoenixDynamicFormControlArrayComponent.prototype.ngOnInit = function () {
        if (this.phoenixFormControl
            && _.has(this.phoenixFormControl.options, 'push') && _.isNumber(this.phoenixFormControl.options['push'])
            && _.has(this.phoenixFormControl.options, 'formControls')) {
            var push = this.phoenixFormControl.options["push"];
            var items = this.phoenixFormControl.options["formControls"];
            if (push > 0 && push < items.length) {
                this.pushIndex = push;
            }
        }
        if (this.phoenixFormControl
            && _.has(this.phoenixFormControl.options, 'topMargin') && _.isBoolean(this.phoenixFormControl.options['topMargin'])) {
            this.topMargin = this.phoenixFormControl.options['topMargin'];
        }
    };
    PhoenixDynamicFormControlArrayComponent.prototype.getLayoutAlign = function () {
        if (this.phoenixFormControl
            && _.has(this.phoenixFormControl.options, 'layoutAlign') && _.isString(this.phoenixFormControl.options['layoutAlign'])) {
            return this.phoenixFormControl.options['layoutAlign'];
        }
        else {
            return 'center center';
        }
    };
    return PhoenixDynamicFormControlArrayComponent;
}());
export { PhoenixDynamicFormControlArrayComponent };
