import { HttpClient } from '@angular/common/http';
import { map, reduce } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { RoleSearchCriteriaDto } from '../dto/role-search-criteria-dto';
import { RoleMapperService } from '../mapper/role-mapper.service';
var RoleApiService = /** @class */ (function () {
    /**
     * @param http
     * @param sharedService
     * @param roleMapperService
     * @param userMapperService
     */
    function RoleApiService(http, sharedService, roleMapperService, userMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.roleMapperService = roleMapperService;
        this.userMapperService = userMapperService;
        this._role = 'role';
        this._roles = 'roles';
        this._user = 'user';
        this._users = 'users';
        this._tenant = 'tenant';
    }
    RoleApiService.prototype.addRoleToUser = function (userDto, roleDto) {
        return this.addRoleToUserById(userDto.id, roleDto.name);
    };
    RoleApiService.prototype.addRoleToUserById = function (userId, roleId) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._users, this._user, userId, this._role, roleId), undefined).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    /**
     * Gets a roleDto by given roleDto.
     * @param roleDto
     * @returns
     */
    RoleApiService.prototype.getRole = function (roleDto) {
        return this.getRoleById(roleDto.name);
    };
    /**
     * Gets a role by given id.
     * @param id
     * @returns
     */
    RoleApiService.prototype.getRoleById = function (id) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._roles, this._role, id)).pipe(map(function (role) { return _this.roleMapperService.roleToRoleDto(role); }));
    };
    /**
     * Gets all roles matching the search criteria.
     * @param filter
     * @param withFurtherPages
     * @returns
     */
    RoleApiService.prototype.getRoles = function (filter, withFurtherPages) {
        var _this = this;
        if (filter === void 0) { filter = new RoleSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._roles), this.roleMapperService.roleSearchCriteriaDtoToRoleSearchCriteria(filter), withFurtherPages).pipe(map(function (roles) { return _this.roleMapperService.roleArrayToRoleDtoArray(roles); }), reduce(function (all, current) { return all.concat(current); }));
    };
    RoleApiService.prototype.removeRoleFromUser = function (userDto, roleDto) {
        return this.removeRoleFromUserById(userDto.id, roleDto.name);
    };
    RoleApiService.prototype.removeRoleFromUserById = function (userId, roleId) {
        var _this = this;
        return this.http.delete(this.sharedService.buildApiUrl(this._users, this._user, userId, this._role, roleId)).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    RoleApiService.prototype.removeRolesFromUserById = function (userId) {
        var _this = this;
        return this.http.delete(this.sharedService.buildApiUrl(this._users, this._user, userId, this._tenant)).pipe(map(function (user) { return _this.userMapperService.userToUserDto(user); }));
    };
    RoleApiService.prototype.updateUsersRoles = function (updateUsersRolesRequest, groupId) {
        var url = this.sharedService.buildApiUrl(this._users, this._roles, groupId.toString());
        return this.http.put(url, updateUsersRolesRequest);
    };
    return RoleApiService;
}());
export { RoleApiService };
