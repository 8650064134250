/**
 * Base Collector class
 */
var NavigationBaseCollector = /** @class */ (function () {
    function NavigationBaseCollector() {
        this.children = [];
    }
    return NavigationBaseCollector;
}());
export { NavigationBaseCollector };
