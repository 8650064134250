var Email = /** @class */ (function () {
    function Email(email) {
        this.receiver = undefined;
        this.subject = undefined;
        this.text = undefined;
        Object.assign(this, email);
    }
    Email.prototype.toJSON = function () {
        return {
            receiver: this.receiver,
            subject: this.subject,
            text: this.text,
        };
    };
    return Email;
}());
export { Email };
