/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-page-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./phoenix-page-header.component";
var styles_PhoenixPageHeaderComponent = [i0.styles];
var RenderType_PhoenixPageHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixPageHeaderComponent, data: {} });
export { RenderType_PhoenixPageHeaderComponent as RenderType_PhoenixPageHeaderComponent };
export function View_PhoenixPageHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "header p-24"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); }, null); }
export function View_PhoenixPageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-page-header", [], null, null, null, View_PhoenixPageHeaderComponent_0, RenderType_PhoenixPageHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.PhoenixPageHeaderComponent, [], null, null)], null, null); }
var PhoenixPageHeaderComponentNgFactory = i1.ɵccf("phoenix-page-header", i4.PhoenixPageHeaderComponent, View_PhoenixPageHeaderComponent_Host_0, {}, {}, ["*"]);
export { PhoenixPageHeaderComponentNgFactory as PhoenixPageHeaderComponentNgFactory };
