var User = /** @class */ (function () {
    function User(user) {
        this.accountInfo = undefined;
        this.company = undefined;
        this.configurations = [];
        this.email = undefined;
        this.firstName = undefined;
        this.id = undefined;
        this.lastName = undefined;
        this.properties = undefined;
        this.roles = undefined;
        Object.assign(this, user);
    }
    User.prototype.toJSON = function () {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            company: this.company,
            accountInfo: this.accountInfo,
            properties: this.properties,
            roles: this.roles,
            configurations: this.configurations,
        };
    };
    return User;
}());
export { User };
