import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import _ from 'lodash';

import {PhoenixDialogButton} from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import {ExtendedInformationDto} from '../../../../gapicon/dto.module';

import {PhoenixMeasurementTypeExtendedInformationsDialog} from './phoenix-measurement-type-extended-informations-dialog';
import {PhoenixMeasurementTypeExtendedInformationsDialogComponent} from './phoenix-measurement-type-extended-informations-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixMeasurementTypeExtendedInformationsDialogService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public openDialog(extendedInformationDtos: ExtendedInformationDto[], checklistElementKey: string): MatDialogRef<PhoenixMeasurementTypeExtendedInformationsDialogComponent> {
    const formArray: FormArray = new FormArray(
      _.map(extendedInformationDtos, (dto: ExtendedInformationDto) => {
        return new FormGroup({
          key: new FormControl(dto.key, Validators.required),
          name: new FormControl(dto.name, Validators.required),
          type: new FormControl(dto.type, Validators.required),
          value: new FormControl(dto.value, Validators.required),
        });
      }),
    );
    const formGroup: FormGroup = new FormGroup({
      extendedInformations: formArray,
    });

    const dialogRef: MatDialogRef<PhoenixMeasurementTypeExtendedInformationsDialogComponent> = this.dialog.open(PhoenixMeasurementTypeExtendedInformationsDialogComponent, {
      width: '550px',
      autoFocus: false,
      data: <PhoenixMeasurementTypeExtendedInformationsDialog>{
        title: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.' + (extendedInformationDtos.length > 0 ? 'EDIT.' : '') + 'HEADLINE',
        subtitle: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.SUBHEADLINE',
        buttons: [
          new PhoenixDialogButton({
            label: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.CANCEL',
            click: (): void => dialogRef.close(),
          }),
          new PhoenixDialogButton({
            label: 'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.SAVE',
            click: (): void => dialogRef.close(this.save(formArray)),
            disabled: (): boolean => formGroup.invalid,
            color: 'accent',
            raised: true,
          })],
        formGroup: formGroup,
        extendedInformationForms: formArray,
        checklistElementKey: checklistElementKey,
      },
    });
    return dialogRef;
  }

  private save(formArray: FormArray): ExtendedInformationDto[] {
    return _.map(formArray.controls, (control: FormControl) => new ExtendedInformationDto(<ExtendedInformationDto>control.value));
  }
}
