var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';
var TenantSearchCriteriaDto = /** @class */ (function (_super) {
    __extends(TenantSearchCriteriaDto, _super);
    function TenantSearchCriteriaDto(tenantSearchCriteriaDto) {
        var _this = _super.call(this, tenantSearchCriteriaDto) || this;
        _this.name = undefined;
        _this.description = undefined;
        Object.assign(_this, tenantSearchCriteriaDto);
        return _this;
    }
    return TenantSearchCriteriaDto;
}(SearchCriteriaPageableDto));
export { TenantSearchCriteriaDto };
