import { HttpClient } from '@angular/common/http';
import { map, reduce } from 'rxjs/operators';
import { AssetMapperService } from '../../asset/mapper/asset-mapper.service';
import { SharedService } from '../../shared/services/shared.service';
import { SensorSearchCriteriaDto } from '../dto/sensor-search-criteria-dto';
import { SensorTypeSearchCriteriaDto } from '../dto/sensor-type-search-criteria-dto';
import { SensorMapperService } from '../mapper/sensor-mapper.service';
import { SensorRegistrationMapperService } from '../mapper/sensor-registration-mapper.service';
import { SensorTypeMapperService } from '../mapper/sensor-type-mapper.service';
var SensorApiService = /** @class */ (function () {
    /**
     *
     * @param http
     * @param sharedService
     * @param sensorMapper
     */
    function SensorApiService(http, sharedService, sensorMapper, sensorRegistrationMapper, sensorTypeMapper, assetMapper) {
        this.http = http;
        this.sharedService = sharedService;
        this.sensorMapper = sensorMapper;
        this.sensorRegistrationMapper = sensorRegistrationMapper;
        this.sensorTypeMapper = sensorTypeMapper;
        this.assetMapper = assetMapper;
        this._sensors = 'sensors';
        this._sensor = 'sensor';
        this._sensorType = 'sensorType';
    }
    /**
     * Gets all sensors matching the search criteria
     * @param sensorSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    SensorApiService.prototype.getSensors = function (sensorSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (sensorSearchCriteriaDto === void 0) { sensorSearchCriteriaDto = new SensorSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._sensors), this.sensorMapper.sensorSearchCriteriaDtoToSensorSearchCriteria(sensorSearchCriteriaDto), withFurtherPages).pipe(map(function (sensors) { return _this.assetMapper.assetArrayToAssetDtoArray(sensors); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Creates an sensor by given SensorDto.
     * @returns
     * @param sensorRegistrationDto
     */
    SensorApiService.prototype.registerSensor = function (sensorRegistrationDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._sensors, this._sensor), this.sensorRegistrationMapper.sensorRegistrationDtoToSensorRegistration(sensorRegistrationDto)).pipe(map(function (sensor) { return _this.assetMapper.assetToAssetDto(sensor); }));
    };
    /**
     * Deletes a sensor by given AssetDto
     * @param sensorDto
     * @returns
     */
    SensorApiService.prototype.deleteSensor = function (sensorDto) {
        return this.deleteSensorById(sensorDto.id);
    };
    /**
     * Deletes a sensor by given sensor ID.
     * @param id
     * @returns
     */
    SensorApiService.prototype.deleteSensorById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._sensors, this._sensor, id));
    };
    /**
     * Gets a sensor by given ID.
     * @param id
     * @returns
     */
    SensorApiService.prototype.getSensorById = function (id) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._sensors, this._sensor, id)).pipe(map(function (sensor) { return _this.assetMapper.assetToAssetDto(sensor); }));
    };
    /**
     * Gets a sensor by given AssetDto.
     * @param sensorDto
     * @returns
     */
    SensorApiService.prototype.getSensor = function (sensorDto) {
        return this.getSensorById(sensorDto.id);
    };
    /**
     * Updates a sensor by given AssetDto.
     * @param sensorDto
     * @returns
     */
    SensorApiService.prototype.updateSensor = function (sensorDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._sensors, this._sensor, sensorDto.id), this.assetMapper.assetDtoToAsset(sensorDto)).pipe(map(function (sensor) { return _this.assetMapper.assetToAssetDto(sensor); }));
    };
    /**
     * Gets all sensor types matching the search criteria
     * @param sensorTypeSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    SensorApiService.prototype.getSensorTypes = function (sensorTypeSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (sensorTypeSearchCriteriaDto === void 0) { sensorTypeSearchCriteriaDto = new SensorTypeSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._sensors, this._sensorType), this.sensorTypeMapper.sensorTypeSearchCriteriaDtoToSensorTypeSearchCriteria(sensorTypeSearchCriteriaDto), withFurtherPages).pipe(map(function (sensorsTypes) { return _this.sensorTypeMapper.sensorTypeArrayToSensorTypeDtoArray(sensorsTypes); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Gets an sensor type by given ID.
     * @param sensorTypeId
     * @returns
     */
    SensorApiService.prototype.getSensorTypeById = function (sensorTypeId) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._sensors, this._sensorType, sensorTypeId)).pipe(map(function (sensorType) { return _this.sensorTypeMapper.sensorTypeToSensorTypeDto(sensorType); }));
    };
    /**
     * Gets an sensor type by given SensorTypeDto.
     * @param sensorTypeDto
     * @returns
     */
    SensorApiService.prototype.getSensorType = function (sensorTypeDto) {
        return this.getSensorTypeById(sensorTypeDto.id);
    };
    /**
     * Creates an sensor type by given SensorTypeDto.
     * @param sensorTypeDto
     * @returns
     */
    SensorApiService.prototype.createSensorType = function (sensorTypeDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._sensors, this._sensorType), this.sensorTypeMapper.sensorTypeDtoToSensorType(sensorTypeDto)).pipe(map(function (sensorType) { return _this.sensorTypeMapper.sensorTypeToSensorTypeDto(sensorType); }));
    };
    /**
     * Updates an sensor type by given SensorDto.
     * @param sensorTypeDto
     * @returns
     */
    SensorApiService.prototype.updateSensorType = function (sensorTypeDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._sensors, this._sensorType, sensorTypeDto.id), this.sensorTypeMapper.sensorTypeDtoToSensorType(sensorTypeDto)).pipe(map(function (sensorType) { return _this.sensorTypeMapper.sensorTypeToSensorTypeDto(sensorType); }));
    };
    /**
     * Deletes an sensor type by given sensorTypeDto
     * @param sensorTypeDto
     * @returns
     */
    SensorApiService.prototype.deleteSensorType = function (sensorTypeDto) {
        return this.deleteSensorTypeById(sensorTypeDto.id);
    };
    /**
     * Deletes an sensor type by given sensor type ID.
     * @param id
     * @returns
     */
    SensorApiService.prototype.deleteSensorTypeById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._sensors, this._sensorType, id.toString()));
    };
    return SensorApiService;
}());
export { SensorApiService };
