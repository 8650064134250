var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';
var UserSearchCriteriaDto = /** @class */ (function (_super) {
    __extends(UserSearchCriteriaDto, _super);
    function UserSearchCriteriaDto(userSearchCriteriaDto) {
        var _this = _super.call(this, userSearchCriteriaDto) || this;
        _this.firstname = undefined;
        _this.lastname = undefined;
        _this.email = undefined;
        _this.company = undefined;
        _this.role = undefined;
        _this.name = undefined;
        Object.assign(_this, userSearchCriteriaDto);
        return _this;
    }
    return UserSearchCriteriaDto;
}(SearchCriteriaPageableDto));
export { UserSearchCriteriaDto };
