var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isValid } from 'date-fns';
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var MeasurementSearchCriteria = /** @class */ (function (_super) {
    __extends(MeasurementSearchCriteria, _super);
    function MeasurementSearchCriteria(measurementSearchCriteria) {
        var _this = _super.call(this, measurementSearchCriteria) || this;
        _this.measurementPointType = undefined;
        _this.sensorId = undefined;
        _this.assetId = undefined;
        _this.dateFrom = undefined;
        _this.dateTo = undefined;
        _this.type = undefined;
        _this.userIds = undefined;
        _this.assetIds = undefined;
        _this.thresholdIds = undefined;
        _this.ascendingSort = undefined;
        _this.ids = undefined;
        Object.assign(_this, measurementSearchCriteria);
        return _this;
    }
    MeasurementSearchCriteria.prototype.toJSON = function () {
        return {
            measurementPointType: this.measurementPointType,
            sensorId: this.sensorId,
            assetId: this.assetId,
            dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
            dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
            type: this.type,
            userIds: this.userIds,
            assetIds: this.assetIds,
            thresholdIds: this.thresholdIds,
            page: this.page,
            size: this.size,
            ascendingSort: this.ascendingSort,
            ids: this.ids,
        };
    };
    return MeasurementSearchCriteria;
}(SearchCriteriaPageable));
export { MeasurementSearchCriteria };
