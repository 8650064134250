import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

import {ThresholdMapperService} from '../../../../threshold/mapper/threshold-mapper.service';
import {MeasurementPoint} from '../api/measurement-point';
import {MeasurementPointDto} from '../dto/measurement-point-dto';

@Injectable({
  providedIn: 'root',
})
export class MeasurementPointMapperService {

  public constructor(
    private thresholdMapperService: ThresholdMapperService,
  ) {
  }

  public measurementPointDtoToMeasurementPoint(measurementPointDto: MeasurementPointDto): MeasurementPoint {
    const measurementPoint: MeasurementPoint = plainToClass(MeasurementPoint, measurementPointDto);
    measurementPoint.threshold = measurementPointDto.threshold ? this.thresholdMapperService.thresholdDtoToThreshold(measurementPointDto.threshold) : undefined;
    return measurementPoint;
  }

  public measurementPointToMeasurementPointDto(measurementPoint: MeasurementPoint): MeasurementPointDto {
    const measurementPointDto: MeasurementPointDto = plainToClass(MeasurementPointDto, measurementPoint);
    measurementPointDto.threshold = measurementPoint.threshold ? this.thresholdMapperService.thresholdToThresholdDto(measurementPoint.threshold) : undefined;
    return measurementPointDto;
  }

  public measurementPointArrayToMeasurementPointDtoArray(measurementPoints: MeasurementPoint[]): MeasurementPointDto[] {
    return _.map(measurementPoints, (measurementPoint: MeasurementPoint) => this.measurementPointToMeasurementPointDto(measurementPoint));
  }

  public measurementPointDtoArrayToMeasurementPointArray(measurementPointDtos: MeasurementPointDto[]): MeasurementPoint[] {
    return _.map(measurementPointDtos, (measurementPointDto: MeasurementPointDto) => this.measurementPointDtoToMeasurementPoint(measurementPointDto));
  }
}
