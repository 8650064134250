import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { PhoenixAssetBranchSelectorDialogData } from './classes/phoenix-asset-branchselector-dialog-data';
import { PhoenixAsseBranchSelectorDialogComponent } from './phoenix-asset-branchselector-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var PhoenixAssetBranchselectorDialogService = /** @class */ (function () {
    function PhoenixAssetBranchselectorDialogService(dialog) {
        this.dialog = dialog;
    }
    PhoenixAssetBranchselectorDialogService.prototype.showAssetBranchSelectorDialog = function (branches, showCancelButton) {
        if (showCancelButton === void 0) { showCancelButton = false; }
        if (branches.length <= 1) {
            return of(branches);
        }
        var dialogRef = this.dialog.open(PhoenixAsseBranchSelectorDialogComponent, {
            closeOnNavigation: false,
            autoFocus: false,
            disableClose: !showCancelButton,
            data: new PhoenixAssetBranchSelectorDialogData({
                assetBranchRootElement: branches,
                cancelButton: showCancelButton,
                title: 'WIZARDS.ASSETBRANCH.TITLE',
                subtitle: 'WIZARDS.ASSETBRANCH.SUBTITLE',
            }),
        });
        return dialogRef.afterClosed();
    };
    PhoenixAssetBranchselectorDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixAssetBranchselectorDialogService_Factory() { return new PhoenixAssetBranchselectorDialogService(i0.inject(i1.MatDialog)); }, token: PhoenixAssetBranchselectorDialogService, providedIn: "root" });
    return PhoenixAssetBranchselectorDialogService;
}());
export { PhoenixAssetBranchselectorDialogService };
