var PhoenixButton = /** @class */ (function () {
    function PhoenixButton(label, icon, click, classes, tooltip, disabled, spinner, badge) {
        this._disabled = undefined;
        this._spinner = false;
        this._click = click;
        this._icon = icon;
        this._classes = classes;
        this._label = label;
        this._tooltip = tooltip;
        this._disabled = disabled;
        this._spinner = spinner;
        this._badge = badge;
    }
    Object.defineProperty(PhoenixButton.prototype, "badge", {
        get: function () {
            return this._badge;
        },
        set: function (value) {
            this._badge = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "tooltip", {
        get: function () {
            return this._tooltip;
        },
        set: function (value) {
            this._tooltip = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "click", {
        // tslint:disable-next-line
        get: function () {
            return this._click;
        },
        // tslint:disable-next-line
        set: function (value) {
            this._click = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "icon", {
        get: function () {
            return this._icon;
        },
        set: function (value) {
            this._icon = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "classes", {
        get: function () {
            return this._classes;
        },
        set: function (value) {
            this._classes = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "label", {
        get: function () {
            return this._label;
        },
        set: function (value) {
            this._label = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PhoenixButton.prototype, "spinner", {
        get: function () {
            return this._spinner;
        },
        set: function (value) {
            this._spinner = value;
        },
        enumerable: true,
        configurable: true
    });
    return PhoenixButton;
}());
export { PhoenixButton };
