<mat-form-field [formGroup]="form">
  <mat-select *ngIf="phoenixFormControl.options['multiple']" placeholder="{{phoenixFormControl.label | translate}}"
              [formControlName]="phoenixFormControl.key" multiple>
    <mat-select-trigger>
      {{(form.get(phoenixFormControl.key).value ? getElementLabel(form.get(phoenixFormControl.key).value[0]) : '') |
      translate}}
      <span *ngIf="form.get(phoenixFormControl.key).value?.length > 1">
        (+{{form.get(phoenixFormControl.key).value.length - 1}} {{ 'DIALOG.FORM.DROPDOWN.OTHERS' | translate }})
      </span>
    </mat-select-trigger>
    <mat-option *ngIf="phoenixFormControl.options['selectedButtons']"
                disabled="disabled"
                class="filter-option">
      <button *ngIf="phoenixFormControl.options['selectedButtons']['selectAll']"
              mat-raised-button
              class="primary fill text-sm"
              (click)="selectAllForTheGivenDropdownObject(form, phoenixFormControl.key, phoenixFormControl.options['options'])">
        <label>{{'DROPDOWN.SELECTALL' | translate}}</label>
      </button>
      <button *ngIf="phoenixFormControl.options['selectedButtons']['deselectAll']"
              mat-raised-button
              class="accent fill text-sm"
              (click)="selectAllForTheGivenDropdownObject(form,phoenixFormControl.key,[])">
        <label>{{'DROPDOWN.DESELECTALL' | translate}}</label>
      </button>
    </mat-option>
    <mat-option *ngFor="let entry of phoenixFormControl.options['options']" [value]="entry.key">{{ entry.label |
      translate }}
    </mat-option>
  </mat-select>

  <mat-select *ngIf="!phoenixFormControl.options['multiple']" placeholder="{{phoenixFormControl.label | translate}}"
              [formControlName]="phoenixFormControl.key">
    <mat-select-trigger>
      {{(form.get(phoenixFormControl.key).value ? getElementLabel(form.get(phoenixFormControl.key).value) : '') |
      translate}}
    </mat-select-trigger>
    <mat-option *ngFor="let entry of phoenixFormControl.options['options']" [value]="entry.key">{{ entry.label |
      translate}}
    </mat-option>
  </mat-select>

  <!--Error bzgl ExpresionChangedAfterItHasBeenCheckedError: https://github.com/angular/angular/issues/23657-->
  <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
    <span translate data-translate="DIALOG.FORM.INVALID"></span>
  </mat-error>
</mat-form-field>
