import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-textarea',
  templateUrl: './phoenix-dynamic-textarea.component.html',
  styleUrls: ['./phoenix-dynamic-textarea.component.scss'],
})
export class PhoenixDynamicTextareaComponent {
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;

  @Input()
  public form: FormGroup;
}
