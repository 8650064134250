import { OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { PhoenixSharedService } from '../../../../services/phoenix-shared/phoenix-shared.service';
import { VariableValidator } from '../../../../Validators/variable-validator';
import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';
var PhoenixDynamicPasswordComponent = /** @class */ (function () {
    function PhoenixDynamicPasswordComponent(phoenixShared) {
        this.phoenixShared = phoenixShared;
        this.passwordFocus = false;
        this.hidePassword = true;
        this.passwordValidity = {
            isPasswordValid: false,
        };
    }
    PhoenixDynamicPasswordComponent.prototype.ngOnInit = function () {
        var validators = [];
        if (this.phoenixFormControl.required) {
            validators.push(Validators.required);
        }
        if (_.get(this.phoenixFormControl.options, 'passwordRules', false)) {
            validators.push(VariableValidator.Validator(this.passwordValidity, 'isPasswordValid', 'rules', 'password'));
        }
        this.form.get(this.phoenixFormControl.key).setValidators(validators);
    };
    return PhoenixDynamicPasswordComponent;
}());
export { PhoenixDynamicPasswordComponent };
