import { HttpClient } from '@angular/common/http';
import { map, reduce } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { NotificationConfigSearchCriteriaDto } from '../dto/notification-config-search-criteria-dto';
import { NotificationConfigMapperService } from '../mapper/notification-config-mapper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/shared.service";
import * as i3 from "../mapper/notification-config-mapper.service";
var NotificationConfigApiService = /** @class */ (function () {
    /**
     *
     * @param http
     * @param sharedService
     * @param notificationConfigMapperService
     */
    function NotificationConfigApiService(http, sharedService, notificationConfigMapperService) {
        this.http = http;
        this.sharedService = sharedService;
        this.notificationConfigMapperService = notificationConfigMapperService;
        this._notificationConfigs = 'notificationConfigs';
        this._newVersion = 'newVersion';
        this._notificationConfig = 'notificationConfig';
    }
    /**
     * Gets all notificationConfigs matching the search criteria
     * @param notificationConfigSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    NotificationConfigApiService.prototype.getNotificationConfigs = function (notificationConfigSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (notificationConfigSearchCriteriaDto === void 0) { notificationConfigSearchCriteriaDto = new NotificationConfigSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._notificationConfigs), this.notificationConfigMapperService.notificationConfigSearchCriteriaDtoToNotificationConfigSearchCriteria(notificationConfigSearchCriteriaDto), withFurtherPages).pipe(map(function (notificationConfigs) { return _this.notificationConfigMapperService.notificationConfigArrayToNotificationConfigDtoArray(notificationConfigs); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Create an notification config by given NotificationConfigDto.
     * @param notificationConfigDto
     * @returns
     */
    NotificationConfigApiService.prototype.createNotificationConfig = function (notificationConfigDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._notificationConfigs, this._notificationConfig), this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto)).pipe(map(function (notificationConfig) { return _this.notificationConfigMapperService.notificationConfigToNotificationConfigDto(notificationConfig); }));
    };
    /**
     * Update an notification config by given NotificationConfigDto.
     * @param notificationConfigDto
     * @returns
     */
    NotificationConfigApiService.prototype.updateNotificationConfig = function (notificationConfigDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._notificationConfigs, this._notificationConfig, notificationConfigDto.id), this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto)).pipe(map(function (notificationConfig) { return _this.notificationConfigMapperService.notificationConfigToNotificationConfigDto(notificationConfig); }));
    };
    /**
     * Update an notification config by given NotificationConfigDto.
     * @param notificationConfigDto
     * @returns
     */
    NotificationConfigApiService.prototype.updateNotificationConfigKeepKeepVersionChanges = function (notificationConfigDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._notificationConfigs, this._newVersion, this._notificationConfig, notificationConfigDto.id), this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto)).pipe(map(function (notificationConfig) { return _this.notificationConfigMapperService.notificationConfigToNotificationConfigDto(notificationConfig); }));
    };
    //TODO not used functionality probably can be deleted
    /**
     * Delete an notification config by given NotificationConfigDto.
     * @returns
     * @param notificationConfigDto
     */
    NotificationConfigApiService.prototype.deleteNotificationConfig = function (notificationConfigDto) {
        return this.deleteNotificationConfigById(this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto).id.toString());
    };
    /**
     * Delete an notification config by given NotificationConfig ID.
     * @param id
     * @returns
     */
    NotificationConfigApiService.prototype.deleteNotificationConfigById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._notificationConfigs, this._notificationConfig, id.toString()));
    };
    NotificationConfigApiService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationConfigApiService_Factory() { return new NotificationConfigApiService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService), i0.inject(i3.NotificationConfigMapperService)); }, token: NotificationConfigApiService, providedIn: "root" });
    return NotificationConfigApiService;
}());
export { NotificationConfigApiService };
