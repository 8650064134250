import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
var PhoenixPasswordRulesComponent = /** @class */ (function () {
    function PhoenixPasswordRulesComponent() {
        this.rules = [
            {
                label: 'PASSWORDRULES.UPPERCASE',
                status: false,
                regex: /[A-Z]/,
            }, {
                label: 'PASSWORDRULES.LOWERCASE',
                status: false,
                regex: /[a-z]/,
            }, {
                label: 'PASSWORDRULES.NUMBERS',
                status: false,
                regex: /\d/,
            }, {
                label: 'PASSWORDRULES.SPECCHAR',
                status: false,
                regex: /[!§$%&?#*]/,
            }, {
                label: 'PASSWORDRULES.LENGTH',
                status: false,
                regex: /^.{8,64}$/,
                mandatory: true,
            },
        ];
        this.checkResult = new EventEmitter();
    }
    Object.defineProperty(PhoenixPasswordRulesComponent.prototype, "password", {
        get: function () {
            return this._password;
        },
        set: function (str) {
            this._password = str ? str : '';
            this.checkPassword();
            this.checkResult.emit(this.checkRules());
        },
        enumerable: true,
        configurable: true
    });
    PhoenixPasswordRulesComponent.prototype.checkPassword = function () {
        var _this = this;
        this.rules.forEach(function (rule) {
            rule.status = rule.regex.test(_this.password);
        });
    };
    PhoenixPasswordRulesComponent.prototype.checkRules = function () {
        var requiredMatchingRules = 4;
        var countMatchingRules = _.countBy(this.rules, function (element) {
            return element.status;
        });
        var countMandatoryRules = _.countBy(this.rules, function (element) {
            if (element.mandatory) {
                return element.status;
            }
        });
        return !countMandatoryRules.false && countMatchingRules.true >= requiredMatchingRules;
    };
    return PhoenixPasswordRulesComponent;
}());
export { PhoenixPasswordRulesComponent };
