/* tslint:disable */
import { ElementRef, Renderer2 } from '@angular/core';
var NavbarComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     * @param {Renderer2} _renderer
     */
    function NavbarComponent(_elementRef, _renderer) {
        this._elementRef = _elementRef;
        this._renderer = _renderer;
        // Set the private defaults
        this._variant = 'vertical-style-1';
    }
    Object.defineProperty(NavbarComponent.prototype, "variant", {
        // -----------------------------------------------------------------------------------------------------
        // @ Accessors
        // -----------------------------------------------------------------------------------------------------
        /**
         * Variant
         */
        get: function () {
            return this._variant;
        },
        set: function (value) {
            // Remove the old class name
            this._renderer.removeClass(this._elementRef.nativeElement, this.variant);
            // Store the variant value
            this._variant = value;
            // Add the new class name
            this._renderer.addClass(this._elementRef.nativeElement, value);
        },
        enumerable: true,
        configurable: true
    });
    return NavbarComponent;
}());
export { NavbarComponent };
