import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

import {ExtendedInformation} from '../api/extended-information';
import {ExtendedInformationType} from '../api/extended-information-type.enum';
import {MeasurementType} from '../api/measurement-type';
import {MeasurementTypeSearchCriteria} from '../api/measurement-type-search-criteria';
import {MeasurementValueType} from '../api/measurement-value-type';
import {ValueType} from '../api/value-type.enum';
import {ExtendedInformationDto} from '../dto/extended-information-dto';
import {ExtendedInformationTypeDto} from '../dto/extended-information-type-dto.enum';
import {MeasurementTypeDto} from '../dto/measurement-type-dto';
import {MeasurementTypeSearchCriteriaDto} from '../dto/measurement-type-search-criteria-dto';
import {MeasurementValueTypeDto} from '../dto/measurement-value-type-dto';
import {ValueTypeDto} from '../dto/value-type-dto.enum';

@Injectable({
  providedIn: 'root',
})
export class MeasurementTypeMapperService {

  public constructor() {
  }

  public measurementTypeArrayToMeasurementTypeDtoArray(measurementTypes: MeasurementType[]): MeasurementTypeDto[] {
    return _.map(measurementTypes, (measurementType: MeasurementType) => this.measurementTypeToMeasurementTypeDto(measurementType));
  }

  public measurementTypeDtoToMeasurementType(measurementTypeDto: MeasurementTypeDto): MeasurementType {
    const measurementType: MeasurementType = plainToClass(MeasurementType, measurementTypeDto);
    measurementType.valueTypes = this.measurementValueTypeDtoArrayToMeasurementValueTypeArray(measurementTypeDto.valueTypes);
    measurementType.extendedInformations = this.extendedInformationDtoArrayToExtendedInformationArray(measurementTypeDto.extendedInformations);
    return measurementType;
  }

  public measurementTypeSearchCriteriaDtoToMeasurementTypeSearchCriteria(measurementTypeSearchCriteriaDto: MeasurementTypeSearchCriteriaDto): MeasurementTypeSearchCriteria {
    return plainToClass(MeasurementTypeSearchCriteria, measurementTypeSearchCriteriaDto);
  }

  public measurementTypeToMeasurementTypeDto(measurementType: MeasurementType): MeasurementTypeDto {
    const measurementTypeDto: MeasurementTypeDto = plainToClass(MeasurementTypeDto, measurementType);
    measurementTypeDto.valueTypes = this.measurementValueTypeArrayToMeasurementValueTypeDtoArray(measurementType.valueTypes);
    measurementTypeDto.extendedInformations = this.extendedInformationArrayToExtendedInformationDtoArray(measurementType.extendedInformations);
    return measurementTypeDto;
  }

  private extendedInformationArrayToExtendedInformationDtoArray(informations: ExtendedInformation[]): ExtendedInformationDto[] {
    return _.map(informations, (information: ExtendedInformation) => this.extendedInformationToExtendedInformationDto(information));
  }

  private extendedInformationDtoArrayToExtendedInformationArray(informationDtos: ExtendedInformationDto[]): ExtendedInformation[] {
    return _.map(informationDtos, (informationDto: ExtendedInformationDto) => this.extendedInformationDtoToExtendedInformation(informationDto));
  }

  private extendedInformationDtoToExtendedInformation(informationDto: ExtendedInformationDto): ExtendedInformation {
    const extendedInformation: ExtendedInformation = plainToClass(ExtendedInformation, informationDto);
    extendedInformation.type = this.extendedInformationTypeDtoToExtendedInformationType(informationDto.type);
    return extendedInformation;
  }

  private extendedInformationToExtendedInformationDto(information: ExtendedInformation): ExtendedInformationDto {
    const extendedInformationDto: ExtendedInformationDto = plainToClass(ExtendedInformationDto, information);
    extendedInformationDto.type = this.extendedInformationTypeToExtendedInformationTypeDto(information.type);
    return extendedInformationDto;
  }

  private extendedInformationTypeDtoToExtendedInformationType(typeDto: ExtendedInformationTypeDto): ExtendedInformationType {
    return typeDto ? ExtendedInformationType[typeDto.toUpperCase()] : undefined;
  }

  private extendedInformationTypeToExtendedInformationTypeDto(type: ExtendedInformationType): ExtendedInformationTypeDto {
    return type ? ExtendedInformationTypeDto[type.toUpperCase()] : undefined;
  }

  private measurementValueTypeArrayToMeasurementValueTypeDtoArray(measurementValueTypes: MeasurementValueType[]): MeasurementValueTypeDto[] {
    return _.map(measurementValueTypes, (measurementValueType: MeasurementValueType) => this.measurementValueTypeToMeasurementValueTypeDto(measurementValueType));
  }

  private measurementValueTypeDtoArrayToMeasurementValueTypeArray(measurementValueTypeDtos: MeasurementValueTypeDto[]): MeasurementValueType[] {
    return _.map(measurementValueTypeDtos, (measurementValueTypeDto: MeasurementValueTypeDto) => this.measurementValueTypeDtoToMeasurementValueType(measurementValueTypeDto));
  }

  private measurementValueTypeDtoToMeasurementValueType(measurementValueTypeDto: MeasurementValueTypeDto): MeasurementValueType {
    const measurementValueType: MeasurementValueType = plainToClass(MeasurementValueType, measurementValueTypeDto);
    measurementValueType.children = this.measurementValueTypeDtoArrayToMeasurementValueTypeArray(measurementValueTypeDto.children);
    measurementValueType.type = this.valueTypeDtoToValueType(measurementValueTypeDto.type);
    return measurementValueType;
  }

  private measurementValueTypeToMeasurementValueTypeDto(measurementValueType: MeasurementValueType): MeasurementValueTypeDto {
    const measurementValueTypeDto: MeasurementValueTypeDto = plainToClass(MeasurementValueTypeDto, measurementValueType);
    measurementValueTypeDto.children = this.measurementValueTypeArrayToMeasurementValueTypeDtoArray(measurementValueType.children);
    measurementValueTypeDto.type = this.valueTypeToValueTypeDto(measurementValueType.type);
    return measurementValueTypeDto;
  }

  private valueTypeDtoToValueType(valueTypeDto: ValueTypeDto): ValueType {
    return valueTypeDto ? ValueType[valueTypeDto.toUpperCase()] : undefined;
  }

  private valueTypeToValueTypeDto(valueType: ValueType): ValueTypeDto {
    return valueType ? ValueTypeDto[valueType.toUpperCase()] : undefined;
  }
}
