import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Property } from '../api/property';
import { PropertyDto } from '../dto/property-dto';
import * as i0 from "@angular/core";
var PropertyMapperService = /** @class */ (function () {
    function PropertyMapperService() {
    }
    PropertyMapperService.prototype.propertyToPropertyDto = function (property) {
        return plainToClass(PropertyDto, property);
    };
    PropertyMapperService.prototype.propertyDtoToProperty = function (propertyDto) {
        return plainToClass(Property, propertyDto);
    };
    PropertyMapperService.prototype.propertyArrayToPropertyDtoArray = function (properties) {
        var _this = this;
        return _.map(properties, function (property) { return _this.propertyToPropertyDto(property); });
    };
    PropertyMapperService.prototype.propertyDtoArrayToPropertyArray = function (propertyDtos) {
        var _this = this;
        return _.map(propertyDtos, function (propertyDto) { return _this.propertyDtoToProperty(propertyDto); });
    };
    PropertyMapperService.ngInjectableDef = i0.defineInjectable({ factory: function PropertyMapperService_Factory() { return new PropertyMapperService(); }, token: PropertyMapperService, providedIn: "root" });
    return PropertyMapperService;
}());
export { PropertyMapperService };
