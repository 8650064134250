import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, Observer } from 'rxjs';

import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixFormElement } from '../../../components/phoenix-form/interfaces/phoenix-form-element';
import { PhoenixFormIconButton } from '../../../components/phoenix-form/phoenix-form-button/classes/phoenix-form-icon-button';
import { PhoenixFormSwitch } from '../../../components/phoenix-form/phoenix-form-switch/classes/phoenix-form-switch';
import { PhoenixFormText } from '../../../components/phoenix-form/phoenix-form-text/classes/phoenix-form-text';
import { PhoenixFormTextarea } from '../../../components/phoenix-form/phoenix-form-textarea/classes/phoenix-form-textarea';
import { PhoenixSnackbarService } from '../../../components/phoenix-snackbar/phoenix-snackbar.service';
import {
  ExtendedInformationDto,
  MeasurementTypeDto,
  MeasurementTypeSearchCriteriaDto,
  MeasurementValueTypeDto,
  NotificationConfigDto,
  NotificationConfigSearchCriteriaDto,
  NotificationTypeDto,
  ValueTypeDto,
} from '../../../gapicon/dto.module';
import { MeasurementTypeApiService } from '../../../gapicon/measurement-type/services/measurement-type-api.service';
import { NotificationConfigApiService } from '../../../gapicon/notification-config/services/notification-config-api.service';
import { PhoenixCommunicationSubject } from '../../../services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixSharedService } from '../../../services/phoenix-shared/phoenix-shared.service';
import { PhoenixWizardHelperService } from '../../../services/phoenix-wizards/phoenix-wizard-helper.service';

import { PhoenixMeasurementTypeDialog } from './classes/phoenix-measurement-type-dialog';
import { PhoenixMeasurementTypeDialogData } from './classes/phoenix-measurement-type-dialog-data';
import { PhoenixMeasurementTypeFormData } from './classes/phoenix-measurement-type-form-data';
import { PhoenixMeasurementTypeDialogComponent } from './phoenix-measurement-type-dialog.component';
import Timer = NodeJS.Timer;
import { VariableValidator } from '@phoenix/Validators/variable-validator';
@Injectable({
  providedIn: 'root',
})
export class PhoenixMeasurementTypeDialogService {
  private debouncer: Timer;
  private formGroup: FormGroup;

  public constructor(private dialog: MatDialog,
    private measurementTypeApi: MeasurementTypeApiService,
    private notificationsConfigApi: NotificationConfigApiService,
    private communicationService: PhoenixCommunicationService,
    private wizardHelper: PhoenixWizardHelperService,
    private snackbarService: PhoenixSnackbarService,
    private translateService: TranslateService,
    private phoenixShared: PhoenixSharedService,
  ) {
  }

  public get checklistelementsForms(): FormArray {
    return this.formGroup.get('checklistelements') as FormArray;
  }

  public async openCreateMeasurementTypeDialog(): Promise<MatDialogRef<PhoenixMeasurementTypeDialogComponent>> {
    return await this.openMeasurementTypeDialog();
  }

  public async openEditMeasurementTypeDialog(measurementType: MeasurementTypeDto, measurementTypeIsUsed: boolean, disableEditing: boolean = false): Promise<MatDialogRef<PhoenixMeasurementTypeDialogComponent>> {
    return await this.openMeasurementTypeDialog(measurementType, measurementTypeIsUsed, disableEditing);
  }

  public async openMeasurementTypeDialog(
    measurementType?: MeasurementTypeDto,
    measurementTypeIsUsed: boolean = false,
    disableEditing: boolean = false): Promise<MatDialogRef<PhoenixMeasurementTypeDialogComponent>> {
    let formData: PhoenixMeasurementTypeDialogData;
    let notificationConfig: NotificationConfigDto;
    if (measurementType) {
      notificationConfig = await this.getNotificationConfigForMeasurementType(measurementType);
      formData = this.convertMeasurementTypeToFormValues(measurementType, notificationConfig, disableEditing);
    }

    this.formGroup = new FormGroup({
      name: new FormControl(formData ? { value: formData.name, disabled: disableEditing } : {
        value: '',
        disabled: disableEditing
      },
        [Validators.required, VariableValidator.noWhiteSpaceValidator, Validators.maxLength(64)],
        this.forbiddenNameValidator(_.get(formData, 'name'))),
      description: new FormControl(formData ? { value: formData.description, disabled: disableEditing } : {
        value: '',
        disabled: disableEditing,
      }, [Validators.maxLength(1024)]),
      freetext: new FormControl(formData ? formData.freetext : true),
      freetextNotification: new FormControl(formData ? formData.freetextNotification : true),
      photo: new FormControl(formData ? formData.photo : true),
      checklistelements: new FormArray(formData ? formData.checklistelements : [this.createChecklistElement()]),
    });

    const dialogRef: MatDialogRef<PhoenixMeasurementTypeDialogComponent> = this.dialog.open(PhoenixMeasurementTypeDialogComponent, {
      width: '550px',
      autoFocus: false,
      data: <PhoenixMeasurementTypeDialog>{
        title: 'WIZARDS.MEASUREMENTTYPE' + (measurementType ? '.EDIT' : '') + '.HEADLINE',
        subtitle: 'WIZARDS.MEASUREMENTTYPE.SUBHEADLINE',
        buttons: [
          new PhoenixDialogButton({
            label: 'WIZARDS.MEASUREMENTTYPE.CANCEL',
            click: (): void => dialogRef.close(),
          }),
          new PhoenixDialogButton({
            label: 'WIZARDS.MEASUREMENTTYPE.SAVE',
            click: async (): Promise<void> => dialogRef.close(await this.save(measurementType, measurementTypeIsUsed, notificationConfig)),
            disabled: (): boolean => this.formGroup.invalid || this.formGroup.pending,
            color: 'accent',
            raised: true,
          })],
        formGroup: this.formGroup,
        elements: this.getElements(disableEditing),
        checklistelementsForms: this.checklistelementsForms,
        disableEditing: disableEditing,

      },
    });
    return dialogRef;
  }

  public async clone(
    measurementType: MeasurementTypeDto): Promise<{ measurementType: MeasurementTypeDto, notificationConfig: NotificationConfigDto }> {
    const notificationConfig: NotificationConfigDto = await this.getNotificationConfigForMeasurementType(measurementType);

    const newMeasurementType: MeasurementTypeDto = await this.measurementTypeApi.cloneMeasurementType(measurementType).toPromise();

    let newNotificationConfig: NotificationConfigDto = notificationConfig;
    newNotificationConfig.id = notificationConfig ? notificationConfig.id : undefined;
    newNotificationConfig.measurementType = newMeasurementType.key;
    newNotificationConfig.name = newMeasurementType.name;

    newNotificationConfig = await this.notificationsConfigApi.createNotificationConfig(newNotificationConfig).toPromise();
    this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.CREATE.SUCCESS'));

    this.communicationService.emit(PhoenixCommunicationSubject.ReloadMeasurementTypePage);
    return { measurementType: newMeasurementType, notificationConfig: newNotificationConfig };
  }

  private addChecklistElement(
    key: string = this.phoenixShared.generateKey(),
    name: string = '',
    notification: boolean = true,
    type: ValueTypeDto = ValueTypeDto.BOOLEAN,
    extendedInformations: ExtendedInformationDto[] = [],
  ): void {
    this.checklistelementsForms.push(this.createChecklistElement(key, name, notification, type, false, extendedInformations));
  }

  private convertFormValuesToMeasurementType(values: PhoenixMeasurementTypeFormData): MeasurementTypeDto {
    const measurementTypeDto: MeasurementTypeDto = new MeasurementTypeDto({
      name: values.name,
      description: values.description,
      valueTypes: [],
    });

    if (values.freetext) {
      values.checklistelements.push({
        key: 'Comment',
        name: 'Comment',
        notification: values.freetextNotification,
        type: ValueTypeDto.STRING,
      });
    }
    if (values.photo) {
      values.checklistelements.push({
        key: 'Photo',
        name: 'Photo',
        type: ValueTypeDto.ATTACHMENT,
      });
    }

    _.forEach(values.checklistelements, (element: { key: string, name: string, notification: boolean, type: ValueTypeDto, extendedInformations: ExtendedInformationDto[] }) => {
      const measurementValueTypeDto: MeasurementValueTypeDto = new MeasurementValueTypeDto({
        key: element.key,
        name: element.name,
        type: element.type,
      });
      measurementTypeDto.valueTypes.push(measurementValueTypeDto);
      measurementTypeDto.extendedInformations = _.union(measurementTypeDto.extendedInformations, element.extendedInformations);
    });

    return measurementTypeDto;
  }

  private convertFormValuesToNotificationConfigExpression(values: PhoenixMeasurementTypeFormData): NotificationConfigDto {
    const notificationConfig: NotificationConfigDto = new NotificationConfigDto({
      notificationType: NotificationTypeDto.ALARM,
    });
    _.forEach(values.checklistelements, (element: { key: string, name: string, notification: boolean, type: ValueTypeDto, extendedInformations: ExtendedInformationDto[] }) => {
      if (element.notification) {
        console.log('element', element)
        console.log('notificationConfig.expression before', notificationConfig.expression)
        //notificationConfig.expression = ''

        switch (element.type) {
          case 'STRING':
            notificationConfig.expression = `${notificationConfig.expression}||''.equals(#${element.key})`
            break;
          case 'NUMBER':
          case 'DECIMAL':
            notificationConfig.expression = `${notificationConfig.expression}||#${element.key} == null||!(#${element.key} matches '^[+-]?((\d+(\.\d*)?)|(\.\d+))$')||#${element.key} eq ''`
            break;
          case 'BOOLEAN':
          default:
            notificationConfig.expression = `${notificationConfig.expression}||!#${element.key}`
            break;
        }
        //|| (#"+mvt.getKey(}+" eq 'null')"


      }
    });
    notificationConfig.expression = _.trim(notificationConfig.expression, '|');
    return notificationConfig;
  }

  private convertMeasurementTypeToFormValues(dto: MeasurementTypeDto, notificationConfig: NotificationConfigDto, disableEditing: boolean): PhoenixMeasurementTypeDialogData {
    const returnData: PhoenixMeasurementTypeDialogData = {
      name: dto.name,
      description: dto.description,
      checklistelements: [],
      freetextNotification: false,
      freetext: false,
      photo: false,
    };

    const activeNotificationExpressionParts: string[] = this.parseNotificationExpression(notificationConfig.expression);
    console.log('activeNotificationExpressionParts', activeNotificationExpressionParts)
    const commentElement: MeasurementValueTypeDto = _.find(dto.valueTypes, (valueType: MeasurementValueTypeDto) => {
      return valueType.type === ValueTypeDto.STRING && valueType.key === 'Comment';
    });
    if (commentElement) {
      returnData.freetext = true;
      returnData.freetextNotification = activeNotificationExpressionParts.includes('Comment');
    }

    const photoElement: MeasurementValueTypeDto = _.find(dto.valueTypes, (valueType: MeasurementValueTypeDto) => {
      return valueType.type === ValueTypeDto.ATTACHMENT && valueType.key === 'Photo';
    });
    if (photoElement) {
      returnData.photo = true;
    }

    _.forEach(dto.valueTypes, (element: MeasurementValueTypeDto) => {
      if (!(element.name === 'Photo' || element.name === 'Comment')) {
        returnData.checklistelements.push(
          this.createChecklistElement(
            element.key,
            element.name,
            activeNotificationExpressionParts.includes(element.key),
            element.type,
            disableEditing,
            _.filter(dto.extendedInformations, { key: element.key }),
          ));
      }
    });

    return returnData;
  }

  private createAddChecklistElementButton(isDisabled?: boolean): PhoenixFormIconButton {
    return new PhoenixFormIconButton(this.formGroup, '', 'add', (): boolean => {
      return this.checklistelementsForms.length >= 20 || isDisabled;
    }, (): void => {
      this.addChecklistElement();
    });
  }



  private createChecklistElement(
    key: string = this.phoenixShared.generateKey(),
    name: string = '',
    notification: boolean = true,
    type: ValueTypeDto = ValueTypeDto.BOOLEAN,
    disabled: boolean = false,
    extendedInformations: ExtendedInformationDto[] = [],
  ): FormGroup {
    return new FormGroup({
      key: new FormControl(key),
      name: new FormControl({ value: name, disabled: disabled }, [Validators.required, VariableValidator.noWhiteSpaceValidator]),
      notification: new FormControl(notification),
      type: new FormControl(type),
      extendedInformations: new FormControl(extendedInformations),
    });
  }

  private createDescriptionField(isDisabled: boolean): PhoenixFormTextarea {
    return new PhoenixFormTextarea(
      this.formGroup,
      'description',
      'WIZARDS.MEASUREMENTTYPE.DESCRIPTION',
      '',
      'WIZARDS.MEASUREMENTTYPE.DESCRIPTIONHINT',
      0,
      1024,
      false,
      isDisabled);
  }

  private createFreetextNotificationSwitch(): PhoenixFormSwitch {
    return new PhoenixFormSwitch(this.formGroup, 'freetextNotification', 'WIZARDS.MEASUREMENTTYPE.NOTIFICATION', 'WIZARDS.MEASUREMENTTYPE.NOTIFICATIONHINT', false, false);
  }

  private createFreetextSwitch(isDisabled?: boolean): PhoenixFormSwitch {
    return new PhoenixFormSwitch(this.formGroup, 'freetext', 'WIZARDS.MEASUREMENTTYPE.FREETEXT', 'WIZARDS.MEASUREMENTTYPE.FREETEXTHINT', isDisabled, true);
  }

  private createNameField(isDisabled?: boolean): PhoenixFormText {
    return new PhoenixFormText(
      this.formGroup,
      'name',
      'WIZARDS.MEASUREMENTTYPE.NAME',
      '',
      'WIZARDS.MEASUREMENTTYPE.NAMEHINT',
      1,
      64,
      true,
      isDisabled);
  }

  private createPhotoSwitch(isDisabled?: boolean): PhoenixFormSwitch {
    return new PhoenixFormSwitch(this.formGroup, 'photo', 'WIZARDS.MEASUREMENTTYPE.PHOTO', 'WIZARDS.MEASUREMENTTYPE.PHOTOHINT', isDisabled, true);
  }

  private createRemoveChecklistElementButton(isDisabled?: boolean): PhoenixFormIconButton {
    return new PhoenixFormIconButton(this.formGroup, '', 'remove', (): boolean => {
      return this.checklistelementsForms.controls.length <= 1 || isDisabled;
    }, (): void => {
      this.deleteChecklistElement(-1);
    });
  }

  private deleteChecklistElement(i: number): void {
    this.checklistelementsForms.removeAt(i);
  }

  private forbiddenNameValidator(ownName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      clearTimeout(this.debouncer);
      return Observable.create((observer: Observer<ValidationErrors | null>) => {
        this.debouncer = setTimeout(() => {
          const sc: MeasurementTypeSearchCriteriaDto = new MeasurementTypeSearchCriteriaDto();
          sc.name = control.value;
          this.measurementTypeApi.getMeasurementTypes(sc).subscribe((next: MeasurementTypeDto[]) => {
            const isOwnName: MeasurementTypeDto = _.find(next, { name: ownName });
            // tslint:disable-next-line
            const result = next.length === 0 || !_.isUndefined(isOwnName) ? null : { 'forbiddenName': { value: control.value } };
            if (!_.isNull(result)) {
              this.snackbarService.openPhoenixSnackbar(4000, 'right', 'top', this.translateService.instant('WIZARDS.MEASUREMENTTYPE.INVALIDNAME'), 'red-bg');
            }
            observer.next(result);
            observer.complete();
          });
        }, 1000);
      });
    };
  }

  private getElements(isDisabled?: boolean): PhoenixFormElement[][] {
    const name: PhoenixFormText = this.createNameField(isDisabled);
    const description: PhoenixFormTextarea = this.createDescriptionField(isDisabled);
    /* TODO: Der Teil muss generalisiert werden und sollte wie ein Checklistelement erstellt werden. Aktuell ist es so gefordert. */
    const freetext: PhoenixFormSwitch = this.createFreetextSwitch(isDisabled);
    const freetextNotification: PhoenixFormSwitch = this.createFreetextNotificationSwitch();
    const photo: PhoenixFormSwitch = this.createPhotoSwitch(isDisabled);
    /* /TODO */
    const removeChecklistElementButton: PhoenixFormIconButton = this.createRemoveChecklistElementButton(isDisabled);
    const addChecklistElementButton: PhoenixFormIconButton = this.createAddChecklistElementButton(isDisabled);
    return [
      [name],
      [description],
      [freetext, freetextNotification],
      [photo],
      [removeChecklistElementButton, addChecklistElementButton],
    ];
  }

  private async getNotificationConfigForMeasurementType(measurementType: MeasurementTypeDto): Promise<NotificationConfigDto> {
    const notificationsConfigSearchCriteria: NotificationConfigSearchCriteriaDto = new NotificationConfigSearchCriteriaDto();
    notificationsConfigSearchCriteria.measurementTypeKey = measurementType.key;
    return _.first(await this.notificationsConfigApi.getNotificationConfigs(notificationsConfigSearchCriteria).toPromise());
  }

  private parseNotificationExpression(expression: string): string[] {
    const returnValue: string[] = [];
    console.log('part', JSON.stringify(expression.split('||')))
    _.forEach(expression.split('||'), (part: string) => {

      part = part.trim()
      console.log('part', JSON.stringify(part))
      if (part.startsWith('\'\'.equals(#')) {
        returnValue.push(part.substr(11, part.length - 12).trim());
      } else if (part.startsWith('!#')) {
        returnValue.push(part.substr(2, 34));
      } else if (part.startsWith('!(#')) {
        returnValue.push(part.substr(3, 34).trim());
      }
    });
    console.log('returnValue', returnValue)

    return returnValue;
  }

  private async save(
    measurementType: MeasurementTypeDto,
    measurementTypeIsUsed: boolean,
    notificationConfig: NotificationConfigDto): Promise<{ measurementType: MeasurementTypeDto, notificationConfig: NotificationConfigDto }> {
    let newMeasurementType: MeasurementTypeDto = this.convertFormValuesToMeasurementType(this.formGroup.value);
    newMeasurementType.id = measurementType ? measurementType.id : undefined;
    newMeasurementType.key = measurementType ? measurementType.key : undefined;
    newMeasurementType.tags = measurementType ? measurementType.tags : ['checklist'];
    //just for test version should be availabe when upadated item which is in used and the version should go to the original item before editiong
    //newMeasurementType.version = new Date().toISOString();


    if (measurementType) {
      newMeasurementType = await this.updateMeasurementType(newMeasurementType, measurementTypeIsUsed);
    } else {
      newMeasurementType = await this.measurementTypeApi.createMeasurementType(newMeasurementType).toPromise();
    }

    //update or create notification configs
    let newNotificationConfig: NotificationConfigDto = this.convertFormValuesToNotificationConfigExpression(this.formGroup.value);
    newNotificationConfig.id = notificationConfig ? notificationConfig.id : undefined;
    newNotificationConfig.measurementType = newMeasurementType.key;
    newNotificationConfig.name = newMeasurementType.name;
    console.log('newNotificationConfig.expression', newNotificationConfig.expression)

    if (measurementType) {
      newNotificationConfig = await this.updateNotifications(newNotificationConfig, measurementTypeIsUsed);
    } else {
      newNotificationConfig = await this.notificationsConfigApi.createNotificationConfig(newNotificationConfig).toPromise();
      this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.CREATE.SUCCESS'));
    }

    this.communicationService.emit(PhoenixCommunicationSubject.ReloadMeasurementTypePage);
    return { measurementType: newMeasurementType, notificationConfig: newNotificationConfig };
  }

  private async updateMeasurementType(measurementType: MeasurementTypeDto, measurementTypeIsUsed: boolean): Promise<MeasurementTypeDto> {

    if (!measurementTypeIsUsed) { // not used
      measurementType = await this.measurementTypeApi.updateMeasurementType(measurementType).toPromise();
    } else {  //keep also old data
      measurementType = await this.measurementTypeApi.updateMeasurementTypeKeepVersionChanges(measurementType).toPromise();
    }

    return measurementType;
  }

  private async updateNotifications(notificationConfig: NotificationConfigDto, measurementTypeIsUsed: boolean): Promise<NotificationConfigDto> {

    if (!measurementTypeIsUsed) { // not used
      notificationConfig = await this.notificationsConfigApi.updateNotificationConfig(notificationConfig).toPromise();
      this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.EDIT.SUCCESS'));
    } else {  //keep also old data
      notificationConfig = await this.notificationsConfigApi.updateNotificationConfigKeepKeepVersionChanges(notificationConfig).toPromise();
      this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTTYPE.EDIT.SUCCESS'));
    }

    return notificationConfig;
  }

}
