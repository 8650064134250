import {UserDto} from '../../user/dto/user-dto';

export class RegistrationConfirmationDto {
  public token: string = undefined;
  public password: string = undefined;
  public user: UserDto = undefined;

  public constructor(registrationConfirmationDto?: Partial<RegistrationConfirmationDto>) {
    Object.assign(this, registrationConfirmationDto);
  }
}
