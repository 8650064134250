var MeasurementType = /** @class */ (function () {
    function MeasurementType(measurementType) {
        this.description = undefined;
        this.extendedInformations = undefined;
        this.id = undefined;
        this.key = undefined;
        this.name = undefined;
        this.tags = undefined;
        this.valueTypes = undefined;
        this.version = undefined;
        Object.assign(this, measurementType);
    }
    MeasurementType.prototype.toJSON = function () {
        return {
            id: this.id,
            key: this.key,
            name: this.name,
            description: this.description,
            valueTypes: this.valueTypes,
            tags: this.tags,
            extendedInformations: this.extendedInformations,
            version: this.version,
        };
    };
    return MeasurementType;
}());
export { MeasurementType };
