var ThresholdViolationStatisticsDto = /** @class */ (function () {
    function ThresholdViolationStatisticsDto(thresholdViolationStatisticsDto) {
        this.violationCount = undefined;
        this.positiveCount = undefined;
        this.totalCount = undefined;
        Object.assign(this, thresholdViolationStatisticsDto);
    }
    return ThresholdViolationStatisticsDto;
}());
export { ThresholdViolationStatisticsDto };
