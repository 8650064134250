import { format } from 'date-fns';
import * as _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { MeasurementValueDto } from '../../gapicon/dto.module';
import { ValueTypeDto } from '../../gapicon/measurement-type/dto/value-type-dto.enum';
import { MeasurementCorrection } from '../../gapicon/measurement/dto/measurement-correction.enum';
import { SharedService } from '../../gapicon/shared/services/shared.service';
import { PhoenixSection } from "../../components/phoenix-detail-view/classes/phoenix-section";
var PhoenixSharedService = /** @class */ (function () {
    function PhoenixSharedService(gapiconSharedService) {
        this.gapiconSharedService = gapiconSharedService;
    }
    PhoenixSharedService.prototype.convertMeasurementObjectToStringWithUnit = function (measurementValueDto) {
        return "" + measurementValueDto.value + measurementValueDto.unit;
    };
    PhoenixSharedService.prototype.convertMeasurementValueToCorrectionString = function (measurementValues, i18nPrefix) {
        return this.convertActionsToString(MeasurementCorrection, measurementValues, i18nPrefix);
    };
    PhoenixSharedService.prototype.generateFileName = function (date, asset) {
        var assetName = asset ? asset.name : 'root';
        return format(date, 'YYYY-MM-DD') + "_" + assetName;
    };
    PhoenixSharedService.prototype.generateFileNameWithCurrentName = function (date, currentName) {
        var assetName = currentName ? currentName : 'root';
        return format(date, 'YYYY-MM-DD') + "_" + assetName;
    };
    PhoenixSharedService.prototype.generateKey = function () {
        return _.replace('mt' + uuid(), /-/g, '');
    };
    PhoenixSharedService.prototype.getMeasurementValueDtoByKey = function (measurement, key) {
        return _.find(measurement.measurementValues, function (val) {
            return val.key === key;
        });
    };
    /**
     * Checks a formControl for errors of type required, rules or equal
     * @param {AbstractControl} formControl
     * @param {string} i18nBase base string for i18n ids
     * @param {string} requiredError i18n postfix that should be used if control has required error
     * @returns {string | boolean} string with i18n code or false if control has no errors
     */
    PhoenixSharedService.prototype.getMostRecentErrorText = function (formControl, i18nBase, requiredError) {
        if (!i18nBase.endsWith('.')) {
            i18nBase += '.';
        }
        if (formControl.hasError('required')) {
            return i18nBase + requiredError;
        }
        else if (formControl.hasError('rules')) {
            return i18nBase + 'PASSWORDRULES';
        }
        else if (formControl.hasError('email')) {
            return i18nBase + 'EMAILINVALID';
        }
        else if (formControl.hasError('equal')) {
            return i18nBase + 'PASSWORDSDIFFERENT';
        }
        else {
            return false;
        }
    };
    /**
     * Returns key/label pairs of booleanMeasurementTypeDtos
     * @param measurementType
     */
    PhoenixSharedService.prototype.getSectionsFromBooleanMeasurementValueTypeDto = function (measurementType) {
        return _.map(this.gapiconSharedService.getBooleanMeasurementValueTypeDtos(measurementType), function (measurementValueTypeDto) {
            return new PhoenixSection({
                key: measurementValueTypeDto.key,
                label: measurementValueTypeDto.name,
            });
        });
    };
    /**
     * Calculates a string that can be displayed as a measurement value.
     * @param measurementDto the measurement that should be displayed
     * @param measurementTypeDto the corresponding measurement type (for checklist measurements)
     * @param assetDto optional provide an specific assetDto if the measurement has no measurement. If not provided the asset from the measurement will be used
     */
    PhoenixSharedService.prototype.mapMeasurementValuesToMeasurementValue = function (measurementDto, measurementTypeDto, assetDto) {
        if (assetDto === void 0) { assetDto = measurementDto.asset; }
        var measurementValueDto = new MeasurementValueDto();
        if (_.includes(assetDto.tags, AssetTags.checklist)) {
            var checklistMeasurements = _.filter(measurementDto.measurementValues, function (m) {
                return m.unit === ValueTypeDto.BOOLEAN || m.unit === 'bool' || m.unit === ValueTypeDto.DECIMAL || m.unit === ValueTypeDto.NUMBER;
            });
            var expectedNumberOfMeasurements = _.sumBy(measurementTypeDto.valueTypes, function (measurementValueTypeDto) {
                return (measurementValueTypeDto.type === ValueTypeDto.BOOLEAN || measurementValueTypeDto.type === ValueTypeDto.NUMBER
                    || measurementValueTypeDto.type === ValueTypeDto.DECIMAL) ? 1 : 0;
            });
            measurementValueDto.unit = '/' + expectedNumberOfMeasurements.toString();
            measurementValueDto.value = _.sumBy(checklistMeasurements, function (value) {
                return value.unit === ValueTypeDto.BOOLEAN ? parseInt(value.value, 0) : (value.value != null && value.value != '' ? 1 : 0);
            }).toString();
        }
        else {
            if (assetDto.measurementPoint.type == "automaticCounter") {
                //measurementValueDto = _.find(measurementDto.measurementValues, { key: 'totalValue' }) || measurementDto.measurementValues[2];
                measurementValueDto = _.find(measurementDto.measurementValues, { key: 'counterValue' }) || measurementDto.measurementValues[0];
            }
            else {
                measurementValueDto = _.find(measurementDto.measurementValues, { key: 'temperatureValue' }) || measurementDto.measurementValues[0];
            }
        }
        return measurementValueDto;
    };
    /**
     * Checks validity of all controls of given FormGroup
     * @param {FormGroup} formGroup that has to be checked
     */
    PhoenixSharedService.prototype.validateAllFormGroupMembers = function (formGroup) {
        _.forEach(formGroup.controls, function (control) {
            control.updateValueAndValidity();
        });
    };
    /**
     * Generates a alphanumeric random password
     */
    PhoenixSharedService.prototype.generatePassword = function () {
        var password = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 16; i++) {
            password += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return password;
    };
    PhoenixSharedService.prototype.isSafari = function () {
        return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    };
    PhoenixSharedService.prototype.isIOS = function () {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };
    PhoenixSharedService.prototype.convertActionsToString = function (measurementEnum, measurementValues, i18nPrefix) {
        var val = _.find(measurementValues, function (measurementValue) {
            return measurementEnum.hasOwnProperty(measurementValue.key) && measurementValue.value === '1';
        });
        return val ? "" + i18nPrefix + val.key : undefined;
    };
    return PhoenixSharedService;
}());
export { PhoenixSharedService };
