import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixTableLink } from '../../../../components/phoenix-table/classes/phoenix-table-link';
import { AssetDto } from '../../../../gapicon/asset/dto/asset-dto';
import { PhoenixNavigationService } from '../../../../services/phoenix-navigation-service/phoenix-navigation.service';
import { PhoenixMeasurementTypeInformationDialog } from '../classes/phoenix-measurement-type-information-dialog';

import { PhoenixMeasurementTypeInformationDialogComponent } from './phoenix-measurement-type-information-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixMeasurementTypeInformationDialogService {

  public constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private navigationService: PhoenixNavigationService,
  ) {
  }

  public openMeasurementTypeInformationDialog(assets: AssetDto[]): MatDialogRef<PhoenixMeasurementTypeInformationDialogComponent> {
    const dialogRef: MatDialogRef<PhoenixMeasurementTypeInformationDialogComponent> = this.dialog.open(PhoenixMeasurementTypeInformationDialogComponent, {
      autoFocus: false,
      maxWidth: 800,
      data: <PhoenixMeasurementTypeInformationDialog>{
        title: this.translateService.instant('MEASUREMENTTYPEINFORMATIONDIALOG.TITLE'),
        subtitle: 'MEASUREMENTTYPEINFORMATIONDIALOG.SUBTITLE',
        buttons: [
          new PhoenixDialogButton({ label: 'MEASUREMENTTYPEINFORMATIONDIALOG.CLOSEBUTTON', click: (): void => dialogRef.close() }),
        ],
        links: this.mapAssetsToLinks(assets, (): void => dialogRef.close()),
      },
    });
    return dialogRef;
  }

  private mapAssetsToLinks(assets: AssetDto[], closeDialog: () => void): PhoenixTableLink[] {
    const linkList: PhoenixTableLink[] = [];
    for (const asset of assets) {
      linkList.push(new PhoenixTableLink(asset.name, async (): Promise<void> => {
        await this.navigationService.navigateToAsset(asset);
        closeDialog();
      }));
    }
    return linkList;
  }
}
