import {OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

export abstract class MainModule implements OnDestroy {
  public ngUnsubscribe: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
