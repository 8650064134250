var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseDto } from '../../shared/classes/base-dto';
var ThresholdDto = /** @class */ (function (_super) {
    __extends(ThresholdDto, _super);
    function ThresholdDto(thresholdDto) {
        var _this = _super.call(this, thresholdDto) || this;
        _this.valueMin = undefined;
        _this.valueMax = undefined;
        _this.alarmMin = undefined;
        _this.alarmMax = undefined;
        _this.assetsCount = "0";
        _this.alarmConfig = 'FIRST';
        Object.assign(_this, thresholdDto);
        return _this;
    }
    return ThresholdDto;
}(BaseDto));
export { ThresholdDto };
