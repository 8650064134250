var MeasurementValueTypeDto = /** @class */ (function () {
    function MeasurementValueTypeDto(measurementValueTypeDto) {
        this.id = undefined;
        this.key = undefined;
        this.name = undefined;
        this.type = undefined;
        this.defaultValue = undefined;
        this.unit = undefined;
        this.children = undefined;
        Object.assign(this, measurementValueTypeDto);
    }
    return MeasurementValueTypeDto;
}());
export { MeasurementValueTypeDto };
