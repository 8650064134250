import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PhoenixButton } from '../phoenix-button/phoenix-button';
import { PhoenixDynamicFormControl } from '../phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarComponent } from './phoenix-snackbar.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
var PhoenixSnackbarService = /** @class */ (function () {
    function PhoenixSnackbarService(snackBar, dialog, translateService) {
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.translateService = translateService;
    }
    PhoenixSnackbarService.prototype.openPhoenixSnackbar = function (duration, horizontalPosition, verticalPosition, text, panelClass, buttons) {
        var configObject = {};
        configObject.duration = duration;
        configObject.horizontalPosition = horizontalPosition;
        configObject.verticalPosition = verticalPosition;
        configObject.panelClass = panelClass;
        var snackbarRef = this.snackBar.openFromComponent(PhoenixSnackbarComponent, configObject);
        snackbarRef.instance.buttons = buttons;
        snackbarRef.instance.text = text;
        return snackbarRef;
    };
    PhoenixSnackbarService.prototype.openPhoenixDefaultErrorSnackbar = function (text) {
        return this.openPhoenixDefaultSnackbar(text, 'red-bg');
    };
    PhoenixSnackbarService.prototype.openPhoenixDefaultSnackbar = function (text, panelClass) {
        var configObject = new MatSnackBarConfig();
        configObject.duration = 4000;
        configObject.horizontalPosition = 'right';
        configObject.verticalPosition = 'top';
        configObject.panelClass = panelClass ? panelClass : 'green-bg';
        var snackbarRef = this.snackBar.openFromComponent(PhoenixSnackbarComponent, configObject);
        snackbarRef.instance.text = text;
        return snackbarRef;
    };
    PhoenixSnackbarService.prototype.openPhoenixHttpErrorSnackbar = function (error) {
        var _this = this;
        var okButton = new PhoenixButton('SNACKBAR.ERROR.BUTTONLABEL.OK', '', function () {
            snackbarRef.dismiss();
        });
        var showDetailsButton = new PhoenixButton('SNACKBAR.ERROR.BUTTONLABEL.SHOWDETAILS', '', function () {
            _this.openPhoenixDialog('450px', 'SNACKBAR.ERROR.DIALOG.HEADLINE', 'SNACKBAR.ERROR.DIALOG.SUBHEADLINE', 'warn', _this.createErrorTextFormControls(error));
        });
        var snackbarRef = this.snackBar.openFromComponent(PhoenixSnackbarComponent, {
            duration: 10000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'red-bg',
        });
        if (error) {
            snackbarRef.instance.text = this.translateService.instant('HTTP.ERROR.CODE.' + error.error.errorCode);
        }
        snackbarRef.instance.buttons = [okButton, showDetailsButton];
        return snackbarRef;
    };
    PhoenixSnackbarService.prototype.openPhoenixDialog = function (width, headline, subHeadline, color, formControlls) {
        this.dialog.open(PhoenixDialogComponent, {
            width: width,
            autoFocus: false,
            data: {
                headline: headline,
                subHeadline: subHeadline,
                buttons: {
                    ok: true,
                },
                color: color,
                formControls: [
                    formControlls,
                ],
            },
        });
    };
    PhoenixSnackbarService.prototype.createErrorTextFormControls = function (error) {
        var hintTextOptions = {
            classes: {
                'font-size-14': true,
                'primary-300-fg': true,
            },
        };
        var formControls = [];
        var textToDisplay = [
            this.translateService.instant('HTTP.ERROR.DIALOG.STATUS') + ': ' + error.status,
            this.translateService.instant('HTTP.ERROR.DIALOG.ERRORCODE') + ': ' + error.error.errorCode,
            this.translateService.instant('HTTP.ERROR.DIALOG.ERRORMSG') + ': ' + this.translateService.instant('HTTP.ERROR.CODE.' + error.error.errorCode),
            this.translateService.instant('HTTP.ERROR.DIALOG.CORRELATIONID') + ': ' + error.error.correlationId,
        ];
        _.forEach(textToDisplay, function (text) {
            formControls.push(new PhoenixDynamicFormControl('text', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, text));
        });
        if (_.get(error, 'error.errors', []).length > 0) {
            formControls.push(new PhoenixDynamicFormControl('text', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, this.translateService.instant('HTTP.ERROR.DIALOG.RAWERRORTEXTS') + ': '));
            _.forEach(error.error.errors, function (text) {
                formControls.push(new PhoenixDynamicFormControl('text', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, text));
            });
        }
        return formControls;
    };
    PhoenixSnackbarService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixSnackbarService_Factory() { return new PhoenixSnackbarService(i0.inject(i1.MatSnackBar), i0.inject(i2.MatDialog), i0.inject(i3.TranslateService)); }, token: PhoenixSnackbarService, providedIn: "root" });
    return PhoenixSnackbarService;
}());
export { PhoenixSnackbarService };
