var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseDto } from '../../shared/classes/base-dto';
var AssetDto = /** @class */ (function (_super) {
    __extends(AssetDto, _super);
    //public sensorTypeThreshold: string = undefined;
    function AssetDto(assetDto) {
        var _this = _super.call(this, assetDto) || this;
        _this.attachments = undefined;
        _this.childStatistics = undefined;
        _this.code = undefined;
        _this.lastMeasurement = undefined;
        _this.measurementPoint = undefined;
        _this.parentId = undefined;
        _this.sensor = undefined;
        _this.statistics = undefined;
        _this.taskConfigs = [];
        _this.assignedUserGroups = [];
        _this.tenant = undefined;
        _this.timeWindowConfig = undefined;
        _this.costCenterCode = undefined;
        _this.costCenterType = undefined;
        _this.language = undefined;
        _this.timestamp = undefined;
        _this.provider = undefined;
        _this.providers = [];
        Object.assign(_this, assetDto);
        return _this;
    }
    return AssetDto;
}(BaseDto));
export { AssetDto };
