var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PermissionService } from '../../../app/guards/permission.service';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixUserConfigType } from '../../enums/phoenix-user-config-type.enum';
import { AccountApiService } from '../../gapicon/account/services/account-api.service';
import { UserConfigDto } from '../../gapicon/dto.module';
import { Roles } from '../../gapicon/role/api/roles.enum';
import { UserApiService } from '../../gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../gapicon/account/services/account-api.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../phoenix-communication-service/phoenix-communication.service";
import * as i5 from "./phoenix-wizard-helper.service";
import * as i6 from "../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i7 from "../../../app/guards/permission.service";
import * as i8 from "../../gapicon/user/services/user-api.service";
var PhoenixUserWizardService = /** @class */ (function () {
    function PhoenixUserWizardService(dialog, accountApiService, translateService, communicationService, phoenixWizardHelper, phoenixSnackbarService, permissionService, userApiService) {
        this.dialog = dialog;
        this.accountApiService = accountApiService;
        this.translateService = translateService;
        this.communicationService = communicationService;
        this.phoenixWizardHelper = phoenixWizardHelper;
        this.phoenixSnackbarService = phoenixSnackbarService;
        this.permissionService = permissionService;
        this.userApiService = userApiService;
    }
    PhoenixUserWizardService.prototype.openEditUserWizard = function (userDto) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userApiService.getUserById(userDto.id).toPromise()];
                    case 1:
                        userDto = _a.sent();
                        this.editUserDto = userDto;
                        return [4 /*yield*/, this.dialog.open(PhoenixDialogComponent, {
                                width: this.phoenixWizardHelper.DIALOGWIDTH,
                                autoFocus: false,
                                data: {
                                    headline: userDto.firstName + ' ' + userDto.lastName,
                                    subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.PROFILESETTINGS',
                                    onClose: function (dialogResult) { return _this.onEditUserCloseCallback(dialogResult); },
                                    buttons: {
                                        cancel: true,
                                        save: true,
                                    },
                                    formControls: [
                                        this.editUserWizardColumn(userDto, 'WIZARDS.SELECTOR.TEXTBOX.FIRSTNAME', 'WIZARDS.SELECTOR.TEXTBOX.LASTNAME', 'WIZARDS.SELECTOR.TEXTBOX.EMAIL', 'WIZARDS.SELECTOR.DESCRIPTION.EMAIL', 'WIZARDS.SELECTOR.SWITCH.ALARM', 'WIZARDS.SELECTOR.DESCRIPTION.ALARM', 'WIZARDS.SELECTOR.TEXTBOX.COMPANY', 'WIZARDS.SELECTOR.TEXTBOX.ROLE'),
                                    ],
                                },
                            }).afterClosed().toPromise()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixUserWizardService.prototype.convertTenantRolesToChips = function (roles) {
        var _this = this;
        var rolesChips = [];
        _.forEach(roles, function (tenants, role) {
            if (role === Roles.EnvironmentAdmin) {
                rolesChips.push(role);
            }
            else {
                _.forEach(_this.permissionService.availableTenants, function (tenant) {
                    if (_.includes(tenants, tenant.id)) {
                        rolesChips.push(role + ':' + tenant.name);
                    }
                });
            }
        });
        return rolesChips;
    };
    PhoenixUserWizardService.prototype.createDynamicFormControlChips = function (key, label, value, required, options) {
        return new PhoenixDynamicFormControl(key, PhoenixDynamicFormControlType.Chips, label, required, options, value);
    };
    PhoenixUserWizardService.prototype.createDynamicFormControlSwitch = function (key, label, value, required, options) {
        return new PhoenixDynamicFormControl(key, PhoenixDynamicFormControlType.Switch, label, required, options, value);
    };
    PhoenixUserWizardService.prototype.createDynamicFormControlText = function (key, label, value, required, options) {
        return new PhoenixDynamicFormControl(key, PhoenixDynamicFormControlType.Text, label, required, options, value);
    };
    PhoenixUserWizardService.prototype.createDynamicFormControlTextBox = function (key, label, value, required, options) {
        return new PhoenixDynamicFormControl(key, PhoenixDynamicFormControlType.TextBox, label, required, options, value);
    };
    PhoenixUserWizardService.prototype.editUserWizardColumn = function (userDto, firstName, lastName, email, emailHint, notifications, notificationsDescription, company, role) {
        var formControlRow = [];
        formControlRow.push(this.createDynamicFormControlTextBox('firstNameProfileSettings', firstName, userDto.firstName, true, {}));
        formControlRow.push(this.createDynamicFormControlTextBox('lastNameProfileSettings', lastName, userDto.lastName, true, {}));
        formControlRow.push(this.createDynamicFormControlText('emailProfileSettings', email, userDto.email, true, { 'hint': emailHint }));
        var hintSwitch = { 'hint': notificationsDescription };
        formControlRow.push(this.createDynamicFormControlSwitch('notificationProfileSettings', notifications, this.getUserNotificationConfig(userDto), false, hintSwitch));
        formControlRow.push(this.createDynamicFormControlTextBox('companyProfileSettings', company, userDto.company, false, {}));
        //  formControlRow.push(this.createDynamicFormControlChips('rolesProfileSettings', role, this.convertTenantRolesToChips(userDto.roles), true, {}));
        return formControlRow;
    };
    PhoenixUserWizardService.prototype.getUserConfiguration = function (userDto, type) {
        var config;
        if (userDto && userDto.configurations) {
            _.forEach(userDto.configurations, function (c) {
                if (c.type === type) {
                    config = c;
                }
            });
        }
        return config;
    };
    PhoenixUserWizardService.prototype.getUserNotificationConfig = function (userDto) {
        var notification = false;
        if (userDto && userDto.configurations) {
            _.forEach(userDto.configurations, function (config) {
                if (config.type === PhoenixUserConfigType.Notification) {
                    notification = (config.value === 'true');
                }
            });
        }
        return notification;
    };
    PhoenixUserWizardService.prototype.onEditUserCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 2];
                        this.editUserDto.firstName = dialogResult.formData.firstNameProfileSettings;
                        this.editUserDto.lastName = dialogResult.formData.lastNameProfileSettings;
                        this.setNotificationStatus(this.editUserDto, dialogResult.formData.notificationProfileSettings);
                        this.editUserDto.company = dialogResult.formData.companyProfileSettings;
                        return [4 /*yield*/, this.updateUser(this.editUserDto)];
                    case 1:
                        _a.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.USER.EDIT.SUCCESS'));
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadUserPage);
                        _a.label = 2;
                    case 2:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixUserWizardService.prototype.setNotificationStatus = function (userDto, notification) {
        var config = this.getUserConfiguration(userDto, PhoenixUserConfigType.Notification);
        if (!config) {
            config = new UserConfigDto();
            config.type = PhoenixUserConfigType.Notification;
            if (!userDto.configurations) {
                userDto.configurations = [];
            }
            userDto.configurations.push(config);
        }
        config.value = String(notification);
    };
    PhoenixUserWizardService.prototype.updateUser = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var userDto;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userApiService.updateUser(user).toPromise()];
                    case 1:
                        userDto = _a.sent();
                        this.communicationService.emit(PhoenixCommunicationSubject.UserUpdated, userDto);
                        return [2 /*return*/, userDto];
                }
            });
        });
    };
    PhoenixUserWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixUserWizardService_Factory() { return new PhoenixUserWizardService(i0.inject(i1.MatDialog), i0.inject(i2.AccountApiService), i0.inject(i3.TranslateService), i0.inject(i4.PhoenixCommunicationService), i0.inject(i5.PhoenixWizardHelperService), i0.inject(i6.PhoenixSnackbarService), i0.inject(i7.PermissionService), i0.inject(i8.UserApiService)); }, token: PhoenixUserWizardService, providedIn: "root" });
    return PhoenixUserWizardService;
}());
export { PhoenixUserWizardService };
