import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import { PhoenixFormElementType } from '../../../components/phoenix-form/enums/phoenix-form-element-type.enum';
import { ValueTypeDto } from '../../../gapicon/dto.module';
import { PhoenixMeasurementTypeDialog } from './classes/phoenix-measurement-type-dialog';
import { PhoenixMeasurementTypeExtendedInformationsDialogService } from './phoenix-measurement-type-extended-informations-dialog/phoenix-measurement-type-extended-informations-dialog.service';
var PhoenixMeasurementTypeDialogComponent = /** @class */ (function () {
    function PhoenixMeasurementTypeDialogComponent(data, extendedInformationsDialogService) {
        this.data = data;
        this.extendedInformationsDialogService = extendedInformationsDialogService;
        this.elementType = PhoenixFormElementType;
        this.measurementType = _.map(ValueTypeDto, function (element) {
            return element;
        }).filter(function (element) {
            return (element != ValueTypeDto.STRING && element != ValueTypeDto.ATTACHMENT);
        });
    }
    ;
    PhoenixMeasurementTypeDialogComponent.prototype.ngOnInit = function () {
    };
    PhoenixMeasurementTypeDialogComponent.prototype.openExtendedInformationDialog = function (formGroup) {
        this.extendedInformationsDialogService.openDialog(formGroup.value.extendedInformations, formGroup.value.key)
            .afterClosed()
            .subscribe(function (result) {
            if (result) {
                formGroup.patchValue({ extendedInformations: result });
            }
        });
    };
    return PhoenixMeasurementTypeDialogComponent;
}());
export { PhoenixMeasurementTypeDialogComponent };
