var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { PhoenixCloneConfirmationDialogService } from '../../../@phoenix/templates/phoenix-dialog/phoenix-clone-confirmation-dialog/phoenix-clone-confirmation-dialog.service';
import { PhoenixAutocompleteEntry } from '../../components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetDto, MeasurementPointDto, ThresholdDto } from '../../gapicon/dto.module';
import { NotificationConfigApiService } from '../../gapicon/notification-config/services/notification-config-api.service';
import { PhoenixMeasurementTypeDialogService } from '../../templates/phoenix-dialog/phoenix-measurement-type-dialog/phoenix-measurement-type-dialog.service';
import { PhoenixSensormanagementDialogService } from '../../templates/phoenix-dialog/phoenix-sensormanagement-dialog/phoenix-sensormanagement-dialog.service';
import { PhoenixTaskConfigDialogService } from '../../templates/phoenix-dialog/phoenix-task-config-dialog/phoenix-task-config-dialog.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixThresholdWizardService } from './phoenix-threshold-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import { GroupApiService } from "../../gapicon/group/services/group-api.service";
import { PhoenixTimeWindowConfigWizardService } from './phoenix-time-window-config-wizard.service';
import { TimeWindowConfigDto } from '@phoenix/gapicon/asset/dto/time-window-config-dto';
import { Permission, permissionIds } from 'app/guards/permission';
import { PermissionService } from 'app/guards/permission.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../gapicon/asset/services/asset-api.service";
import * as i3 from "../../gapicon/group/services/group-api.service";
import * as i4 from "./phoenix-threshold-wizard.service";
import * as i5 from "./phoenix-time-window-config-wizard.service";
import * as i6 from "../phoenix-communication-service/phoenix-communication.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./phoenix-wizard-helper.service";
import * as i9 from "../../components/phoenix-snackbar/phoenix-snackbar.service";
import * as i10 from "../../gapicon/notification-config/services/notification-config-api.service";
import * as i11 from "../../templates/phoenix-dialog/phoenix-measurement-type-dialog/phoenix-measurement-type-dialog.service";
import * as i12 from "../../templates/phoenix-dialog/phoenix-sensormanagement-dialog/phoenix-sensormanagement-dialog.service";
import * as i13 from "../../templates/phoenix-dialog/phoenix-task-config-dialog/phoenix-task-config-dialog.service";
import * as i14 from "../../templates/phoenix-dialog/phoenix-clone-confirmation-dialog/phoenix-clone-confirmation-dialog.service";
import * as i15 from "../../../app/guards/permission.service";
var PhoenixMeasurementPointWizardService = /** @class */ (function () {
    function PhoenixMeasurementPointWizardService(dialog, assetApiService, userGroupsApiService, thresholdWizardService, timeWindowConfigWizardService, communicationService, translateService, phoenixWizardHelper, phoenixSnackbarService, notificationConfigApiService, measurementTypeDialogService, sensorDialogService, taskConfigService, clonedElementConfirmDialogService, permissionService) {
        this.dialog = dialog;
        this.assetApiService = assetApiService;
        this.userGroupsApiService = userGroupsApiService;
        this.thresholdWizardService = thresholdWizardService;
        this.timeWindowConfigWizardService = timeWindowConfigWizardService;
        this.communicationService = communicationService;
        this.translateService = translateService;
        this.phoenixWizardHelper = phoenixWizardHelper;
        this.phoenixSnackbarService = phoenixSnackbarService;
        this.notificationConfigApiService = notificationConfigApiService;
        this.measurementTypeDialogService = measurementTypeDialogService;
        this.sensorDialogService = sensorDialogService;
        this.taskConfigService = taskConfigService;
        this.clonedElementConfirmDialogService = clonedElementConfirmDialogService;
        this.permissionService = permissionService;
        this.measurementPoint = undefined;
        this.openElementWizard = undefined;
        this.taskConfigForNewMeasurementPoint = [];
        this.createThresholdPermission = new Permission(permissionIds.TENANTADMINISTRATION.THRESHOLD.write);
        this.newChecklistPermission = new Permission(permissionIds.TENANTADMINISTRATION.MEASUREMENTTYPE.write);
        this.registerSensorPermission = new Permission(permissionIds.TENANTADMINISTRATION.SENSORMANAGEMENT.write);
    }
    PhoenixMeasurementPointWizardService.prototype.openCreateMeasurementPointWizard = function (measurementPoint, parent, openElementWizard) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.openElementWizard = openElementWizard;
                        return [4 /*yield*/, this.openMeasurementPointWizard(measurementPoint, function (dialogResult) { return _this.onNewMeasurementPointCloseCallback(dialogResult); }, 'WIZARDS.SELECTOR.TITLE.MEASUREMENTPOINT', parent)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.openDeleteMeasurementPointWizard = function (measurementPoint) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var randomString, hintTextOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.measurementPoint = measurementPoint;
                        randomString = this.phoenixWizardHelper.getRandomString();
                        hintTextOptions = {
                            classes: {
                                'font-size-12': true,
                                'hint-text': true,
                                'primary-300-fg': true,
                                'mt-4': true,
                                'mb-4': true,
                            },
                        };
                        return [4 /*yield*/, this.dialog.open(PhoenixDialogComponent, {
                                width: this.phoenixWizardHelper.DIALOGWIDTH,
                                autoFocus: false,
                                data: {
                                    headline: 'WIZARDS.MEASUREMENTPOINTS.DELETE.TITLE',
                                    subHeadline: measurementPoint.name,
                                    buttons: {
                                        cancel: true,
                                        delete: 'WIZARDS.MEASUREMENTPOINTS.DELETE.SUBMIT',
                                    },
                                    onClose: function (r) { return _this.onDeleteCloseCallback(r); },
                                    translationParams: {
                                        deleteCode: randomString,
                                    },
                                    color: 'warn',
                                    formControls: [[
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE1'),
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE2'),
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE3'),
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE4'),
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE5'),
                                            new PhoenixDynamicFormControl('assetName', PhoenixDynamicFormControlType.Text, undefined, false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.CONFIRMATION'),
                                            new PhoenixDynamicFormControl('delete-code', PhoenixDynamicFormControlType.Text, 'WIZARDS.MEASUREMENTPOINTS.DELETE.DELETECODE', false, undefined, randomString),
                                            new PhoenixDynamicFormControl('delete', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.MEASUREMENTPOINTS.DELETE.DELETECODEINPUT', true, { hint: 'WIZARDS.MEASUREMENTPOINTS.DELETE.DELETECODEINPUTHINT' }, undefined, undefined, [Validators.required, Validators.pattern(randomString)]),
                                        ]],
                                },
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.openEditMeasurementPointWizard = function (measurementPoint) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.openElementWizard = undefined;
                        return [4 /*yield*/, this.openMeasurementPointWizard(measurementPoint, function (dialogResult) { return _this.onEditMeasurementPointCloseCallback(dialogResult); }, 'WIZARDS.MEASUREMENTPOINTS.EDIT.TITLE')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.openCloneCheckListElementsDialog = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var cloneElements, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.openElementWizard = undefined;
                        return [4 /*yield*/, this.clonedElementConfirmDialogService.showClonedElementConfirmationSelectorDialog(asset).toPromise()];
                    case 1:
                        cloneElements = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        if (!(cloneElements !== false)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.cloneAssetHierarchy(asset)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.cloneAssetHierarchy = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.assetApiService.cloneAssetsHierarchy(asset).toPromise()];
                    case 1:
                        _a.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.COMMON.CLONE.SUCCESS'));
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.createElementSelectionWizardColumn = function (key, icon, iconLabel, text, buttonLabel, flipIcon) {
        return [
            new PhoenixDynamicFormControl('icon' + key, PhoenixDynamicFormControlType.Icon, iconLabel, false, {
                size: 60,
                body: text,
                flip: flipIcon,
            }, icon),
            new PhoenixDynamicFormControl('button' + key, PhoenixDynamicFormControlType.Button, buttonLabel, false, {
                style: 'raised',
                color: 'basic',
            }),
        ];
    };
    PhoenixMeasurementPointWizardService.prototype.createMeasurementPoint = function (measurementPoint, dialogResult) {
        measurementPoint.tags = [AssetTags.measurementPoint];
        measurementPoint.name = dialogResult.formData['txtName'];
        measurementPoint.description = dialogResult.formData['txtDescription'];
        // Bugfix Steuerzeichen
        if (!_.isNil(measurementPoint.description) && measurementPoint.description.match('\u0000')) {
            measurementPoint.description = undefined;
        }
        measurementPoint.parentId = _.get(dialogResult.formData, 'txtParent.object.id', undefined);
        if (!measurementPoint.id) {
            measurementPoint.taskConfigs = this.taskConfigForNewMeasurementPoint;
        }
        switch (dialogResult.formData['switcherType']) {
            case 'automatic':
                measurementPoint.measurementPoint = new MeasurementPointDto({
                    type: 'automaticTemperature',
                    sensorId: _.get(dialogResult.formData, 'mpAutoTextSensorID.object.sensor.sensorId', undefined),
                    threshold: _.get(dialogResult.formData, 'mpThreshold.object', undefined),
                });
                measurementPoint.tags.push(AssetTags.automatic);
                break;
            case 'counter':
                measurementPoint.measurementPoint = new MeasurementPointDto({
                    type: 'automaticCounter',
                    sensorId: _.get(dialogResult.formData, 'mpAutoTextSensorID.object.sensor.sensorId', undefined),
                });
                measurementPoint.tags.push(AssetTags.automatic);
                break;
            case 'manual':
                measurementPoint.measurementPoint = new MeasurementPointDto({
                    type: 'mobileTemperature',
                    threshold: _.get(dialogResult.formData, 'mpThreshold.object', undefined),
                });
                measurementPoint.tags.push(AssetTags.manual);
                break;
            case 'checklist':
                measurementPoint.measurementPoint = new MeasurementPointDto({
                    type: _.get(dialogResult.formData, 'measurementType.object.key', undefined),
                });
                measurementPoint.tags.push(AssetTags.checklist);
                break;
        }
        measurementPoint.timeWindowConfig = _.get(dialogResult.formData, 'dataTimeWindowValue', undefined);
        return measurementPoint;
    };
    PhoenixMeasurementPointWizardService.prototype.createMeasurementPointWizardColumn = function (measurementPoint, parent) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var thresholdAutocompleteData, _a, _b, thresholdFormControl, createThresholdButtonFormControl, measurementTypeAutocompleteData, _c, _d, defaultMeasurementType, measurementTypeFormControl, measurementTypeButtonFormControl, sensorAutocompleteData, _e, _f, sensorId, defaultSensor, _g, sensorFormControl, registerSensorButtonFormControl, registerCounterButtonFormControl, dataTimeWindowButtonFormControl, dataTimeWindowHintFormControl, dataTimeWindowValueFormControl, manualHintFormControl, automaticHintFormControl, measurementTypeHintFormControl, manualFormControls, automaticFormControls, counterFormControls, measurementTypeFormControls;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _b = (_a = _).sortBy;
                        return [4 /*yield*/, this.phoenixWizardHelper.getAutoCompleteThresholdArray().toPromise()];
                    case 1:
                        thresholdAutocompleteData = _b.apply(_a, [_h.sent(),
                            [function (element) { return element.label.toLowerCase(); }]]);
                        thresholdFormControl = new PhoenixDynamicFormControl('mpThreshold', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SELECTOR.SWITCHER.THRESHOLD', false, { autocomplete: function () { return of(thresholdAutocompleteData); } }, _.hasIn(measurementPoint, 'measurementPoint.threshold.name') ?
                            {
                                object: measurementPoint.measurementPoint.threshold,
                                label: measurementPoint.measurementPoint.threshold.name,
                            } : undefined);
                        createThresholdButtonFormControl = new PhoenixDynamicFormControl('thresholdButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.NEWTHRESHOLD2', false, {
                            style: 'raised',
                            color: 'basic',
                            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, this.showThresholdDialog(form, thresholdAutocompleteData)];
                            }); }); },
                        });
                        _d = (_c = _).sortBy;
                        return [4 /*yield*/, this.phoenixWizardHelper.getAutoCompleteMeasurementTypeArray().toPromise()];
                    case 2:
                        measurementTypeAutocompleteData = _d.apply(_c, [_h.sent(),
                            [function (element) { return element.label.toLowerCase(); }]]);
                        defaultMeasurementType = _.find(measurementTypeAutocompleteData, function (measurementType) {
                            return measurementType.object.key === _.get(measurementPoint, 'measurementPoint.type', undefined);
                        });
                        measurementTypeFormControl = new PhoenixDynamicFormControl('measurementType', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SELECTOR.SWITCHER.MEASUREMENTTYPE', false, { autocomplete: function () { return of(measurementTypeAutocompleteData); } }, defaultMeasurementType);
                        measurementTypeButtonFormControl = new PhoenixDynamicFormControl('measurementTypeButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.MEASUREMENTTYPE', false, {
                            style: 'raised',
                            color: 'basic',
                            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, this.showMeasurementTypeDialog(form, measurementTypeAutocompleteData)];
                            }); }); },
                        });
                        _f = (_e = _).sortBy;
                        return [4 /*yield*/, this.phoenixWizardHelper.getAutoCompleteSensorArray().toPromise()];
                    case 3:
                        sensorAutocompleteData = _f.apply(_e, [_h.sent(),
                            [function (element) { return element.label.toLowerCase(); }]]);
                        sensorId = _.get(measurementPoint, 'measurementPoint.sensorId', undefined);
                        if (!sensorId) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.phoenixWizardHelper.getSensorById(sensorId).toPromise()];
                    case 4:
                        _g = _h.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        _g = undefined;
                        _h.label = 6;
                    case 6:
                        defaultSensor = _g;
                        sensorFormControl = new PhoenixDynamicFormControl('mpAutoTextSensorID', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SELECTOR.TEXTBOX.ID', false, { autocomplete: function () { return of(sensorAutocompleteData); } }, defaultSensor);
                        registerSensorButtonFormControl = new PhoenixDynamicFormControl('sensorButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.NEWSENSOR', false, {
                            style: 'raised',
                            color: 'basic',
                            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, this.showSensorDialog(form, sensorAutocompleteData)];
                            }); }); },
                        });
                        registerCounterButtonFormControl = new PhoenixDynamicFormControl('sensorButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.NEWCOUNTER', false, {
                            style: 'raised',
                            color: 'basic',
                            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, this.showSensorDialog(form, sensorAutocompleteData)];
                            }); }); },
                        });
                        dataTimeWindowButtonFormControl = new PhoenixDynamicFormControl('dataTimeWindowButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.EDITTIMEWINDOW', false, {
                            style: 'raised',
                            color: 'basic',
                            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/, this.showTimeWindowConfigDialog(form, measurementPoint)];
                            }); }); },
                        });
                        dataTimeWindowHintFormControl = new PhoenixDynamicFormControl('editDataTimeWindowHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.EDITTIMEWINDOWHINT');
                        dataTimeWindowValueFormControl = new PhoenixDynamicFormControl('dataTimeWindowValue', PhoenixDynamicFormControlType.Text, '', false, { classes: { 'hidden': true }, hiddenData: true }, _.get(measurementPoint, 'timeWindowConfig', undefined));
                        manualHintFormControl = new PhoenixDynamicFormControl('mpManualHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.HINT');
                        automaticHintFormControl = new PhoenixDynamicFormControl('mpAutoHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.HINT');
                        measurementTypeHintFormControl = new PhoenixDynamicFormControl('measurementTypeHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.MEASUREMENTTYPEHINT');
                        manualFormControls = [];
                        manualFormControls.push(thresholdFormControl);
                        if (this.permissionService.hasPermission(this.createThresholdPermission)) {
                            manualFormControls.push(createThresholdButtonFormControl);
                        }
                        manualFormControls.push(manualHintFormControl);
                        automaticFormControls = [];
                        automaticFormControls.push(sensorFormControl);
                        if (this.permissionService.hasPermission(this.registerSensorPermission)) {
                            automaticFormControls.push(registerSensorButtonFormControl);
                        }
                        automaticFormControls.push(thresholdFormControl);
                        if (this.permissionService.hasPermission(this.createThresholdPermission)) {
                            automaticFormControls.push(createThresholdButtonFormControl);
                        }
                        //automaticFormControls.push(alarmConfigFormControl);
                        automaticFormControls.push(automaticHintFormControl);
                        automaticFormControls.push(dataTimeWindowButtonFormControl);
                        automaticFormControls.push(dataTimeWindowHintFormControl);
                        automaticFormControls.push(dataTimeWindowValueFormControl);
                        counterFormControls = [];
                        counterFormControls.push(sensorFormControl);
                        if (this.permissionService.hasPermission(this.registerSensorPermission)) {
                            counterFormControls.push(registerCounterButtonFormControl);
                        }
                        measurementTypeFormControls = [];
                        measurementTypeFormControls.push(measurementTypeFormControl);
                        if (this.permissionService.hasPermission(this.newChecklistPermission)) {
                            measurementTypeFormControls.push(measurementTypeButtonFormControl);
                        }
                        measurementTypeFormControls.push(measurementTypeHintFormControl);
                        return [2 /*return*/, [
                                new PhoenixDynamicFormControl('txtName', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SELECTOR.TEXTBOX.NAME', true, {}, _.get(measurementPoint, 'name', undefined), undefined, [Validators.required]),
                                new PhoenixDynamicFormControl('txtDescription', PhoenixDynamicFormControlType.TextArea, 'WIZARDS.SELECTOR.TEXTBOX.DESCRIPTION', false, {}, _.get(measurementPoint, 'description', undefined)),
                                new PhoenixDynamicFormControl('txtParent', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.SELECTOR.TEXTBOX.PARENT', false, {
                                    hint: 'WIZARDS.FORMFIELDS.PARENTHINT',
                                    autocomplete: function (search) {
                                        return _this.phoenixWizardHelper.assetAutocompleteSearchFunction(search, _this.measurementPoint);
                                    },
                                }, parent ? new PhoenixAutocompleteEntry({ object: parent, label: parent.name }) : undefined),
                                new PhoenixDynamicFormControl('taskConfigButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.TASKCONFIG', false, {
                                    style: 'raised',
                                    color: 'basic',
                                    callback: function (form) { return __awaiter(_this, void 0, void 0, function () {
                                        var _this = this;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!this.measurementPoint) return [3 /*break*/, 2];
                                                    return [4 /*yield*/, this.taskConfigService.openTaskConfigDialog(this.measurementPoint.taskConfigs)];
                                                case 1:
                                                    (_a.sent()).afterClosed().subscribe(function (result) {
                                                        if (result) {
                                                            _this.measurementPoint.taskConfigs = result;
                                                        }
                                                    });
                                                    return [3 /*break*/, 5];
                                                case 2: return [4 /*yield*/, this.taskConfigService.openTaskConfigDialog([])];
                                                case 3: return [4 /*yield*/, (_a.sent()).afterClosed().subscribe(function (result) {
                                                        if (result) {
                                                            _this.taskConfigForNewMeasurementPoint = result;
                                                        }
                                                    })];
                                                case 4:
                                                    _a.sent();
                                                    _a.label = 5;
                                                case 5: return [2 /*return*/];
                                            }
                                        });
                                    }); },
                                }),
                                new PhoenixDynamicFormControl('switcherType', PhoenixDynamicFormControlType.TabSwitcher, 'WIZARDS.SELECTOR.TABSWITCHER.TYPE', false, {
                                    tabs: [
                                        {
                                            key: 'manual',
                                            label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELMANUAL',
                                            content: 'WIZARDS.SELECTOR.TABSWITCHER.MANUAL',
                                            formControls: manualFormControls,
                                        },
                                        {
                                            key: 'automatic',
                                            label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELAUTO',
                                            content: 'WIZARDS.SELECTOR.TABSWITCHER.AUTO',
                                            formControls: automaticFormControls,
                                        },
                                        {
                                            key: 'checklist',
                                            label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELCHECKLIST',
                                            content: 'WIZARDS.SELECTOR.TABSWITCHER.CHECKLIST',
                                            formControls: measurementTypeFormControls,
                                        },
                                        {
                                            key: 'counter',
                                            label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELCOUNTER',
                                            content: 'WIZARDS.SELECTOR.TABSWITCHER.COUNTER',
                                            formControls: counterFormControls,
                                        },
                                    ],
                                }, this.getSwitchTab(_.get(measurementPoint, 'measurementPoint.type', 'mobileTemperature')), measurementPoint !== undefined),
                            ]];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.getSwitchTab = function (measurementType) {
        switch (measurementType) {
            case 'automaticCounter':
                return 'counter';
            case 'automaticTemperature':
                return 'automatic';
            case 'mobileTemperature':
                return 'manual';
            default:
                return 'checklist';
        }
    };
    PhoenixMeasurementPointWizardService.prototype.onDeleteCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'delete')) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.assetApiService.deleteAsset(this.measurementPoint).toPromise()];
                    case 2:
                        _a.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTPOINTS.DELETE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        _a.label = 5;
                    case 5:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.onEditMeasurementPointCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var measurementPoint, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 5];
                        measurementPoint = this.createMeasurementPoint(this.measurementPoint, dialogResult);
                        measurementPoint.id = this.measurementPoint.id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.assetApiService.updateAsset(measurementPoint).toPromise()];
                    case 2:
                        _a.sent();
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTPOINTS.EDIT.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 4];
                    case 4:
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        _a.label = 5;
                    case 5:
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.onFollowingCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (dialogResult.formData['buttonClicked'].value) {
                    case 'buttonDuplicateMeasurementPoint':
                        this.openCreateMeasurementPointWizard(this.measurementPoint, undefined, this.openElementWizard);
                        break;
                    case 'buttonNewMeasurementPoint':
                        this.openCreateMeasurementPointWizard(undefined, undefined, this.openElementWizard);
                        break;
                }
                dialogResult.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.onNewMeasurementPointCloseCallback = function (dialogResult) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var measurementPoint, mp_1, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(dialogResult.result === 'save')) return [3 /*break*/, 5];
                        measurementPoint = this.createMeasurementPoint(new AssetDto(), dialogResult);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.assetApiService.createAsset(measurementPoint).toPromise()];
                    case 2:
                        mp_1 = _a.sent();
                        this.measurementPoint = mp_1;
                        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
                        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTPOINTS.CREATE.SUCCESS'));
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 4];
                    case 4:
                        this.openElementWizard = function () { return _this.openFollowingMeasurementPointWizard(mp_1); };
                        this.openFollowingMeasurementPointWizard(mp_1);
                        _a.label = 5;
                    case 5:
                        if (dialogResult.result === 'previous' && this.openElementWizard !== undefined) {
                            this.openElementWizard();
                        }
                        dialogResult.dialogRef.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.openFollowingMeasurementPointWizard = function (measurementPointDto) {
        var _this = this;
        this.dialog.open(PhoenixDialogComponent, {
            width: '600px',
            autoFocus: false,
            data: {
                headline: 'WIZARDS.SELECTOR.TITLE.FOLLOWINGMEASUREMENTPOINT',
                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.FOLLOWING',
                buttons: {
                    exit: true,
                },
                translationParams: {
                    measurementPointName: measurementPointDto.name,
                },
                onClose: function (dialogResult) { return _this.onFollowingCloseCallback(dialogResult); },
                formControls: [
                    this.createElementSelectionWizardColumn('DuplicateMeasurementPoint', PhoenixIcon.REPEAT, 'WIZARDS.COMMON.DUPLICATEMEASUREMENTPOINT', 'WIZARDS.SELECTOR.DESCRIPTION.DUPLICATEMEASUREMENTPOINT', 'WIZARDS.SELECTOR.BUTTON.DUPLICATEMEASUREMENTPOINT'),
                    this.createElementSelectionWizardColumn('NewMeasurementPoint', PhoenixIcon.PANORAMA_FISH_EYE, 'WIZARDS.COMMON.NEWMEASUREMENTPOINT', 'WIZARDS.SELECTOR.DESCRIPTION.NEWMEASUREMENTPOINT', 'WIZARDS.SELECTOR.BUTTON.NEWMEASUREMENTPOINT'),
                ],
            },
        });
    };
    PhoenixMeasurementPointWizardService.prototype.openMeasurementPointWizard = function (measurementPoint, onClose, title, parent) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        this.taskConfigForNewMeasurementPoint = [];
                        if (!(!parent && measurementPoint && measurementPoint.parentId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.assetApiService.getAssetById(measurementPoint.parentId).toPromise()];
                    case 1:
                        parent = _f.sent();
                        _f.label = 2;
                    case 2:
                        this.measurementPoint = measurementPoint;
                        _b = (_a = this.dialog).open;
                        _c = [PhoenixDialogComponent];
                        _d = {
                            width: this.phoenixWizardHelper.DIALOGWIDTHBIG,
                            autoFocus: false
                        };
                        _e = {
                            headline: title,
                            subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.MEASUREMENTPOINT',
                            buttons: {
                                cancel: true,
                                previous: !!this.openElementWizard,
                                save: true,
                            },
                            onClose: onClose
                        };
                        return [4 /*yield*/, this.createMeasurementPointWizardColumn(measurementPoint, parent)];
                    case 3:
                        _b.apply(_a, _c.concat([(_d.data = (_e.formControls = [
                                _f.sent()
                            ],
                                _e),
                                _d)]));
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.showMeasurementTypeDialog = function (form, measurementTypeAutocompleteData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.measurementTypeDialogService.openCreateMeasurementTypeDialog()];
                    case 1:
                        (_a.sent()).afterClosed().subscribe(function (values) {
                            var entry = new PhoenixAutocompleteEntry({
                                object: values.measurementType,
                                label: values.measurementType.name
                            });
                            measurementTypeAutocompleteData.push(entry);
                            form.get('measurementType').setValue(entry);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.showThresholdDialog = function (form, thresholdAutocompleteData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.thresholdWizardService.openCreateThresholdWizard(undefined, undefined, function (threshold) {
                            var entry = new PhoenixAutocompleteEntry({
                                object: threshold,
                                label: threshold.name
                            });
                            thresholdAutocompleteData.push(entry);
                            form.get('mpThreshold').setValue(entry);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.showSensorDialog = function (form, sensorAutocompleteData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sensorDialogService.openSensorDialog()];
                    case 1:
                        (_a.sent()).afterClosed().subscribe(function (sensor) {
                            var entry = new PhoenixAutocompleteEntry({
                                label: sensor.name, object: sensor
                            });
                            sensorAutocompleteData.push(entry);
                            form.get('mpAutoTextSensorID').setValue(entry);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.prototype.showTimeWindowConfigDialog = function (form, measurementPoint) {
        return __awaiter(this, void 0, void 0, function () {
            var timeWindowConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        timeWindowConfig = new TimeWindowConfigDto();
                        if (measurementPoint) {
                            timeWindowConfig = measurementPoint.timeWindowConfig;
                        }
                        else {
                            timeWindowConfig = form.get('dataTimeWindowValue').value;
                        }
                        return [4 /*yield*/, this.timeWindowConfigWizardService.openEditTimeWindowConfigWizard(timeWindowConfig, undefined, function (timeWindowConfig) {
                                form.get('dataTimeWindowValue').setValue(timeWindowConfig);
                                if (measurementPoint) {
                                    measurementPoint.timeWindowConfig = timeWindowConfig;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhoenixMeasurementPointWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixMeasurementPointWizardService_Factory() { return new PhoenixMeasurementPointWizardService(i0.inject(i1.MatDialog), i0.inject(i2.AssetApiService), i0.inject(i3.GroupApiService), i0.inject(i4.PhoenixThresholdWizardService), i0.inject(i5.PhoenixTimeWindowConfigWizardService), i0.inject(i6.PhoenixCommunicationService), i0.inject(i7.TranslateService), i0.inject(i8.PhoenixWizardHelperService), i0.inject(i9.PhoenixSnackbarService), i0.inject(i10.NotificationConfigApiService), i0.inject(i11.PhoenixMeasurementTypeDialogService), i0.inject(i12.PhoenixSensormanagementDialogService), i0.inject(i13.PhoenixTaskConfigDialogService), i0.inject(i14.PhoenixCloneConfirmationDialogService), i0.inject(i15.PermissionService)); }, token: PhoenixMeasurementPointWizardService, providedIn: "root" });
    return PhoenixMeasurementPointWizardService;
}());
export { PhoenixMeasurementPointWizardService };
