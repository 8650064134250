import { HttpClient } from '@angular/common/http';
import { map, reduce } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { MeasurementTypeSearchCriteriaDto } from '../dto/measurement-type-search-criteria-dto';
import { MeasurementTypeMapperService } from '../mapper/measurement-type-mapper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/shared.service";
import * as i3 from "../mapper/measurement-type-mapper.service";
var MeasurementTypeApiService = /** @class */ (function () {
    function MeasurementTypeApiService(http, sharedService, measurementTypeMapper) {
        this.http = http;
        this.sharedService = sharedService;
        this.measurementTypeMapper = measurementTypeMapper;
        this._measurementTypes = 'measurementTypes';
        this._versions = 'versions';
        this._newVersion = 'newVersion';
        this._measurementType = 'measurementType';
        this._cloneElementPostfix = 'clone';
    }
    MeasurementTypeApiService.prototype.getMeasurementTypes = function (measurementTypeSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (measurementTypeSearchCriteriaDto === void 0) { measurementTypeSearchCriteriaDto = new MeasurementTypeSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._measurementTypes), this.measurementTypeMapper.measurementTypeSearchCriteriaDtoToMeasurementTypeSearchCriteria(measurementTypeSearchCriteriaDto), withFurtherPages).pipe(map(function (measurementTypes) { return _this.measurementTypeMapper.measurementTypeArrayToMeasurementTypeDtoArray(measurementTypes); }), reduce(function (all, current) { return all.concat(current); }));
    };
    MeasurementTypeApiService.prototype.getMeasurementTypesVersions = function (measurementTypeSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (measurementTypeSearchCriteriaDto === void 0) { measurementTypeSearchCriteriaDto = new MeasurementTypeSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._measurementTypes, this._versions), this.measurementTypeMapper.measurementTypeSearchCriteriaDtoToMeasurementTypeSearchCriteria(measurementTypeSearchCriteriaDto), withFurtherPages).pipe(map(function (measurementTypes) { return _this.measurementTypeMapper.measurementTypeArrayToMeasurementTypeDtoArray(measurementTypes); }), reduce(function (all, current) { return all.concat(current); }));
    };
    MeasurementTypeApiService.prototype.createMeasurementType = function (measurementTypeDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType), this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto)).pipe(map(function (measurementType) { return _this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType); }));
    };
    MeasurementTypeApiService.prototype.cloneMeasurementType = function (measurementTypeDto) {
        var _this = this;
        return this.http.post(this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType, this._cloneElementPostfix), this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto)).pipe(map(function (measurementType) { return _this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType); }));
    };
    MeasurementTypeApiService.prototype.updateMeasurementType = function (measurementTypeDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType, measurementTypeDto.id), this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto)).pipe(map(function (measurementType) { return _this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType); }));
    };
    MeasurementTypeApiService.prototype.updateMeasurementTypeKeepVersionChanges = function (measurementTypeDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._measurementTypes, this._newVersion, this._measurementType, measurementTypeDto.id), this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto)).pipe(map(function (measurementType) { return _this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType); }));
    };
    MeasurementTypeApiService.prototype.deleteMeasurementType = function (measurementTypeDto) {
        return this.http.delete(this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType, measurementTypeDto.id));
    };
    MeasurementTypeApiService.ngInjectableDef = i0.defineInjectable({ factory: function MeasurementTypeApiService_Factory() { return new MeasurementTypeApiService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService), i0.inject(i3.MeasurementTypeMapperService)); }, token: MeasurementTypeApiService, providedIn: "root" });
    return MeasurementTypeApiService;
}());
export { MeasurementTypeApiService };
