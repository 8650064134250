var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MatDialog } from '@angular/material';
import { GroupApiService } from '@phoenix/gapicon/group/services/group-api.service';
import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixTaskConfigDialogComponent } from './phoenix-task-config-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../gapicon/group/services/group-api.service";
var PhoenixTaskConfigDialogService = /** @class */ (function () {
    function PhoenixTaskConfigDialogService(dialog, userGroupsApiService) {
        this.dialog = dialog;
        this.userGroupsApiService = userGroupsApiService;
    }
    PhoenixTaskConfigDialogService.prototype.openTaskConfigDialog = function (taskConfigs) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var userGroups, dialogRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userGroupsApiService.getGroups(false).toPromise()];
                    case 1:
                        userGroups = _a.sent();
                        dialogRef = this.dialog.open(PhoenixTaskConfigDialogComponent, {
                            minWidth: '900px',
                            minHeight: '300px',
                            autoFocus: false,
                            data: {
                                title: 'TASKCONFIG.HEADLINE',
                                subtitle: 'TASKCONFIG.SUBHEADLINE',
                                buttons: [
                                    new PhoenixDialogButton({
                                        label: 'TASKCONFIG.CANCEL',
                                        click: function () { return dialogRef.close(undefined); },
                                    }),
                                    new PhoenixDialogButton({
                                        label: 'TASKCONFIG.APPLY',
                                        click: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                dialogRef.close(taskConfigs);
                                                return [2 /*return*/];
                                            });
                                        }); },
                                        color: 'accent',
                                        raised: true,
                                    })
                                ],
                                taskConfigs: taskConfigs,
                                usergroups: userGroups
                            },
                        });
                        return [2 /*return*/, dialogRef];
                }
            });
        });
    };
    PhoenixTaskConfigDialogService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixTaskConfigDialogService_Factory() { return new PhoenixTaskConfigDialogService(i0.inject(i1.MatDialog), i0.inject(i2.GroupApiService)); }, token: PhoenixTaskConfigDialogService, providedIn: "root" });
    return PhoenixTaskConfigDialogService;
}());
export { PhoenixTaskConfigDialogService };
