var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixDynamicFormControl } from '@phoenix/components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '@phoenix/components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogComponent } from '@phoenix/components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '@phoenix/components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import { TimeWindowConfigDayDto } from '@phoenix/gapicon/asset/dto/time-window-config-day-dto';
import { TimeWindowConfigIntervallDto } from "@phoenix/gapicon/asset/dto/time-window-config-intervall-dto";
import _ from 'lodash';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../phoenix-communication-service/phoenix-communication.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./phoenix-wizard-helper.service";
import * as i5 from "../../components/phoenix-snackbar/phoenix-snackbar.service";
var PhoenixTimeWindowConfigDayWizardService = /** @class */ (function () {
    function PhoenixTimeWindowConfigDayWizardService(dialog, communicationService, translateService, phoenixWizardHelper, phoenixSnackbarService) {
        this.dialog = dialog;
        this.communicationService = communicationService;
        this.translateService = translateService;
        this.phoenixWizardHelper = phoenixWizardHelper;
        this.phoenixSnackbarService = phoenixSnackbarService;
        this.dialogTimeWindowConfigDay = undefined;
        this.mpTimeWindowConfigDay = undefined;
        this.dayStatusIconFormControl = undefined;
        this.dialogFormControls = [];
    }
    PhoenixTimeWindowConfigDayWizardService.prototype.openEditTimeWindowConfigDayWizard = function (timeWindowConfigDay, dayIndex, afterTimeWindowConfigDayEditedCallBack) {
        var _this = this;
        this.mpTimeWindowConfigDay = timeWindowConfigDay;
        this.dialogTimeWindowConfigDay = _.cloneDeep(timeWindowConfigDay);
        this.dialogTimeWindowConfigDay = !this.dialogTimeWindowConfigDay ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfigDay;
        this.openTimeWindowConfigDayWizard(function (dialogResult) { return _this.onEditTimeWindowConfigDayCloseCallback(dialogResult, afterTimeWindowConfigDayEditedCallBack); }, dayIndex, 'WIZARDS.SELECTOR.TITLE.TIMEWINDOWDAY');
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.openTimeWindowConfigDayWizard = function (onClose, dayIndex, title) {
        this.dialog.open(PhoenixDialogComponent, {
            width: this.phoenixWizardHelper.DIALOGWIDTHBIG,
            autoFocus: false,
            data: {
                headline: title,
                subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.TIMEWINDOWDAY',
                onClose: onClose,
                buttons: {
                    cancel: true,
                    ok: true
                },
                formControls: [
                    this.createTimeWindowConfigDayFormControl(dayIndex),
                ],
            },
        });
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.onEditTimeWindowConfigDayCloseCallback = function (dialogResult, afterTimeWindowConfigDayEditedCallBack) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (dialogResult.result === 'ok') {
                    this.mpTimeWindowConfigDay = this.dialogTimeWindowConfigDay;
                }
                if (afterTimeWindowConfigDayEditedCallBack) {
                    afterTimeWindowConfigDayEditedCallBack(this.mpTimeWindowConfigDay);
                }
                dialogResult.dialogRef.close();
                return [2 /*return*/];
            });
        });
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createTimeWindowConfigDayFormControl = function (dayIndex) {
        this.dialogFormControls = [];
        this.dialogFormControls.push(this.createTimeWindowConfigDayFirstRowFormControl(dayIndex));
        if (this.dialogTimeWindowConfigDay.intervalls) {
            for (var i = 0; i < this.dialogTimeWindowConfigDay.intervalls.length; i++) {
                this.dialogFormControls[i + 1] = this.createIntervalFormControl(i);
            }
        }
        this.dialogFormControls.push(this.createAddButonFormControl());
        this.dialogFormControls.push(this.createInvalidIntervalsHintFormControl());
        return this.dialogFormControls;
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createTimeWindowConfigDayFirstRowFormControl = function (dayIndex) {
        this.dayStatusIconFormControl = this.createDayStatusIconFormControl();
        this.setDayStatusIcon();
        return new PhoenixDynamicFormControl('timeWindowConfigDayFirstRowArray', PhoenixDynamicFormControlType.FormControlArray, undefined, false, {
            layoutAlign: 'start center',
            topMargin: false,
            formControls: [
                this.dayStatusIconFormControl,
                this.createDayLabelFormControl(dayIndex)
            ]
        });
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createDayStatusIconFormControl = function () {
        return new PhoenixDynamicFormControl('dayStatusIcon', PhoenixDynamicFormControlType.Icon, '', false, {}, PhoenixIcon.SCHEDULE);
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.setDayStatusIcon = function () {
        if (this.dialogTimeWindowConfigDay) {
            if (!this.dialogTimeWindowConfigDay.enable) {
                this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.POSITIVE;
                this.dayStatusIconFormControl.value = PhoenixIcon.LENS;
            }
            else if (this.dialogTimeWindowConfigDay.intervalls && this.dialogTimeWindowConfigDay.intervalls.length >= 1 &&
                !(this.dialogTimeWindowConfigDay.intervalls[0].startTime == '0:00' && this.dialogTimeWindowConfigDay.intervalls[0].endTime == '0:00')) {
                this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.POSITIVE;
                this.dayStatusIconFormControl.value = PhoenixIcon.INCOMPLETE_CIRCLE;
            }
            else {
                this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.NEGATIVE;
                this.dayStatusIconFormControl.value = PhoenixIcon.INCOMPLETE_CIRCLE;
            }
        }
        else {
            this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.POSITIVE;
            this.dayStatusIconFormControl.value = PhoenixIcon.LENS;
        }
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createDayLabelFormControl = function (dayIndex) {
        return new PhoenixDynamicFormControl('dayLabel', PhoenixDynamicFormControlType.Text, '', false, { fontSize: 24 }, this.translateService.instant('WIZARDS.TIMEWINDOW.WEEKDAYS.' + this.getWeekdayName(dayIndex).toUpperCase()), true);
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.getWeekdayName = function (dayIndex) {
        switch (dayIndex) {
            case 0:
                return 'monday';
            case 1:
                return 'tuesday';
            case 2:
                return 'wednesday';
            case 3:
                return 'thursday';
            case 4:
                return 'friday';
            case 5:
                return 'saturday';
            case 6:
                return 'sunday';
            default:
                return 'monday';
        }
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createIntervalFormControl = function (intervalIndex) {
        var _this = this;
        if (this.dialogTimeWindowConfigDay && this.dialogTimeWindowConfigDay.intervalls
            && intervalIndex >= 0 && intervalIndex < this.dialogTimeWindowConfigDay.intervalls.length) {
            return new PhoenixDynamicFormControl('timeWindowConfigDayInterval' + intervalIndex, PhoenixDynamicFormControlType.TimeWindowInterval, undefined, true, {
                deleteCallback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/, this.deleteInterval(form, 'timeWindowConfigDayInterval' + intervalIndex)];
                }); }); },
                onChangeCallback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/, this.intervalChanged(form)];
                }); }); }
            }, this.dialogTimeWindowConfigDay.intervalls[intervalIndex], intervalIndex == 0);
        }
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createAddButonFormControl = function () {
        var _this = this;
        return new PhoenixDynamicFormControl('timeWindowConfigDayAddButton', PhoenixDynamicFormControlType.Button, undefined, true, {
            style: "mini-fab-icon",
            callback: function (form) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, this.addInterval(form)];
            }); }); },
        }, PhoenixIcon.ADD);
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createInvalidIntervalsHintFormControl = function () {
        return new PhoenixDynamicFormControl('timeWindowConfigDayInvalidIntervalssHint', PhoenixDynamicFormControlType.Text, '', false, { fontSize: 15, classes: { hidden: true } }, this.translateService.instant('WIZARDS.TIMEWINDOW.HINT.INVALIDINTERVALS'), true);
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.deleteInterval = function (form, key) {
        return __awaiter(this, void 0, void 0, function () {
            var intervalIndex, i;
            return __generator(this, function (_a) {
                intervalIndex = undefined;
                for (i = 1; i < this.dialogFormControls.length; i++) {
                    if (this.dialogFormControls[i].key == key) {
                        intervalIndex = i - 1;
                    }
                }
                this.dialogTimeWindowConfigDay.intervalls.splice(intervalIndex, 1);
                this.dialogFormControls.splice(intervalIndex + 1, 1);
                this.setDayStatusIcon();
                if (!this.isValid()) {
                    form.disable();
                    if (this.dialogFormControls && this.dialogFormControls.length > 1) {
                        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: false };
                    }
                }
                else {
                    form.enable();
                    if (this.dialogFormControls && this.dialogFormControls.length > 1) {
                        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: true };
                    }
                }
                form.updateValueAndValidity();
                return [2 /*return*/];
            });
        });
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.addInterval = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            var intervalFormControl;
            return __generator(this, function (_a) {
                this.dialogTimeWindowConfigDay.intervalls.push(new TimeWindowConfigIntervallDto({ startTime: '0:00', endTime: '0:00' }));
                intervalFormControl = this.createIntervalFormControl(this.dialogTimeWindowConfigDay.intervalls.length - 1);
                this.dialogFormControls.splice(this.dialogFormControls.length - 2, 0, intervalFormControl);
                this.setDayStatusIcon();
                if (!this.isValid()) {
                    form.disable();
                    if (this.dialogFormControls && this.dialogFormControls.length > 1) {
                        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: false };
                    }
                }
                else {
                    form.enable();
                    if (this.dialogFormControls && this.dialogFormControls.length > 1) {
                        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: true };
                    }
                }
                form.updateValueAndValidity();
                return [2 /*return*/];
            });
        });
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.intervalChanged = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setDayStatusIcon();
                if (!this.isValid()) {
                    form.disable();
                    if (this.dialogFormControls && this.dialogFormControls.length > 1) {
                        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: false };
                    }
                }
                else {
                    form.enable();
                    if (this.dialogFormControls && this.dialogFormControls.length > 1) {
                        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: true };
                    }
                }
                form.updateValueAndValidity();
                return [2 /*return*/];
            });
        });
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.isValid = function () {
        if (this.dialogTimeWindowConfigDay && this.dialogTimeWindowConfigDay.intervalls) {
            for (var i = 0; i < this.dialogTimeWindowConfigDay.intervalls.length; i++) {
                var starti = this.createDate(this.dialogTimeWindowConfigDay.intervalls[i].startTime);
                var endi = this.createDate(this.dialogTimeWindowConfigDay.intervalls[i].endTime);
                if (starti.format('H:mm') == '0:00' && endi.format('H:mm') == '0:00' && this.dialogTimeWindowConfigDay.intervalls.length > 1) {
                    return false;
                }
                else if (starti.format('H:mm') != '0:00' && endi.format('H:mm') != '0:00' && starti > endi) {
                    return false;
                }
                for (var j = i + 1; j < this.dialogTimeWindowConfigDay.intervalls.length; j++) {
                    var startj = this.createDate(this.dialogTimeWindowConfigDay.intervalls[j].startTime);
                    var endj = this.createDate(this.dialogTimeWindowConfigDay.intervalls[j].endTime);
                    if (startj.format('H:mm') != '0:00' && endj.format('H:mm') != '0:00' && startj > endj) {
                        return false;
                    }
                    else if (starti <= startj && (startj < endi || endi.format('H:mm') == '0:00')) {
                        return false;
                    }
                    else if (startj <= starti && (starti < endj || endj.format('H:mm') == '0:00')) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
    PhoenixTimeWindowConfigDayWizardService.prototype.createDate = function (time) {
        // only time is matter
        var hour = +time.split(':')[0];
        var minute = +time.split(':')[1];
        var date = moment().hours(hour).minutes(minute).seconds(0);
        return date;
    };
    PhoenixTimeWindowConfigDayWizardService.ngInjectableDef = i0.defineInjectable({ factory: function PhoenixTimeWindowConfigDayWizardService_Factory() { return new PhoenixTimeWindowConfigDayWizardService(i0.inject(i1.MatDialog), i0.inject(i2.PhoenixCommunicationService), i0.inject(i3.TranslateService), i0.inject(i4.PhoenixWizardHelperService), i0.inject(i5.PhoenixSnackbarService)); }, token: PhoenixTimeWindowConfigDayWizardService, providedIn: "root" });
    return PhoenixTimeWindowConfigDayWizardService;
}());
export { PhoenixTimeWindowConfigDayWizardService };
