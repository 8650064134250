var Property = /** @class */ (function () {
    function Property(property) {
        this.id = undefined;
        this.name = undefined;
        this.description = undefined;
        this.value = undefined;
        this.type = undefined;
        Object.assign(this, property);
    }
    Property.prototype.toJSON = function () {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            value: this.value,
            type: this.type,
        };
    };
    return Property;
}());
export { Property };
