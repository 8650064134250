var Permission = /** @class */ (function () {
    function Permission(id) {
        this.id = id;
    }
    return Permission;
}());
export { Permission };
export var permissionIds = {
    DASHBOARD: {
        read: 0,
    },
    CHECKPOINTS: {
        read: 1,
        write: 100,
    },
    NOTIFICATIONS: {
        read: 2,
    },
    TENANTADMINISTRATION: {
        THRESHOLD: {
            read: 3,
            write: 300,
        },
        USERMANAGEMENT: {
            read: 4,
            write: 400,
        },
        SETUP: {
            read: 6,
            write: 600,
        },
        ALARM: {
            read: 7,
            write: 700,
        },
        MEASUREMENTTYPE: {
            read: 8,
            write: 800,
        },
        SENSORMANAGEMENT: {
            read: 10,
            write: 1000,
        },
    },
    HELP: {
        read: 5,
    },
    DIALOG: {
        ATTACHMENT: {
            read: 11,
            write: 1100,
        },
    },
    ENVIRONMENTADMINISTRATION: {
        USERMANAGEMENT: {
            read: 12,
            write: 1200,
        },
        TENANTMANAGEMENT: {
            read: 13,
            write: 1300,
        },
        DEVICEMANAGEMENT: {
            read: 14,
            write: 1400,
        },
        TENANTJOBMANAGEMENT: {
            read: 16,
            write: 1600,
        },
    },
    TASKS: {
        read: 15,
        write: 1500,
    },
};
export var permissionRole = {
    ADMIN: [
        permissionIds.DASHBOARD.read,
        permissionIds.CHECKPOINTS.read,
        permissionIds.CHECKPOINTS.write,
        permissionIds.NOTIFICATIONS.read,
        permissionIds.HELP.read,
        permissionIds.DIALOG.ATTACHMENT.read,
        permissionIds.DIALOG.ATTACHMENT.write,
        permissionIds.TASKS.read,
        permissionIds.TASKS.write,
    ],
    USER: [
        permissionIds.DASHBOARD.read,
        permissionIds.CHECKPOINTS.read,
        permissionIds.NOTIFICATIONS.read,
        permissionIds.HELP.read,
        permissionIds.DIALOG.ATTACHMENT.read,
        permissionIds.TASKS.read,
    ],
    ENVIRONMENT_ADMIN: [
        permissionIds.ENVIRONMENTADMINISTRATION.USERMANAGEMENT.read,
        permissionIds.ENVIRONMENTADMINISTRATION.USERMANAGEMENT.write,
        permissionIds.ENVIRONMENTADMINISTRATION.TENANTMANAGEMENT.read,
        permissionIds.ENVIRONMENTADMINISTRATION.TENANTMANAGEMENT.write,
        permissionIds.ENVIRONMENTADMINISTRATION.DEVICEMANAGEMENT.read,
        permissionIds.ENVIRONMENTADMINISTRATION.DEVICEMANAGEMENT.write,
        permissionIds.ENVIRONMENTADMINISTRATION.TENANTJOBMANAGEMENT.read,
        permissionIds.ENVIRONMENTADMINISTRATION.TENANTJOBMANAGEMENT.write,
    ],
    DEFAULT_MANAGEMENT_ADMIN: [
        permissionIds.DASHBOARD.read,
        permissionIds.CHECKPOINTS.read,
        permissionIds.CHECKPOINTS.write,
        permissionIds.NOTIFICATIONS.read,
        permissionIds.HELP.read,
        permissionIds.TENANTADMINISTRATION.THRESHOLD.read,
        permissionIds.TENANTADMINISTRATION.THRESHOLD.write,
        permissionIds.TENANTADMINISTRATION.USERMANAGEMENT.read,
        permissionIds.TENANTADMINISTRATION.SETUP.read,
        permissionIds.TENANTADMINISTRATION.SETUP.write,
        permissionIds.TENANTADMINISTRATION.MEASUREMENTTYPE.read,
        permissionIds.TENANTADMINISTRATION.MEASUREMENTTYPE.write,
        //permissionIds.TENANTADMINISTRATION.DEVICEMANAGEMENT.read,
        //permissionIds.TENANTADMINISTRATION.DEVICEMANAGEMENT.write,
        permissionIds.TENANTADMINISTRATION.SENSORMANAGEMENT.read,
        permissionIds.TENANTADMINISTRATION.SENSORMANAGEMENT.write,
        permissionIds.DIALOG.ATTACHMENT.read,
        permissionIds.DIALOG.ATTACHMENT.write,
        permissionIds.TASKS.read,
        permissionIds.TASKS.write,
    ],
};
