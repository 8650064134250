/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-form-icon-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./phoenix-form-icon-button.component";
var styles_PhoenixFormIconButtonComponent = [i0.styles];
var RenderType_PhoenixFormIconButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixFormIconButtonComponent, data: {} });
export { RenderType_PhoenixFormIconButtonComponent as RenderType_PhoenixFormIconButtonComponent };
export function View_PhoenixFormIconButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.element.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.element.disabled(); _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.element.icon; _ck(_v, 4, 0, currVal_4); }); }
export function View_PhoenixFormIconButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-form-icon-button", [], null, null, null, View_PhoenixFormIconButtonComponent_0, RenderType_PhoenixFormIconButtonComponent)), i1.ɵdid(1, 49152, null, 0, i9.PhoenixFormIconButtonComponent, [], null, null)], null, null); }
var PhoenixFormIconButtonComponentNgFactory = i1.ɵccf("phoenix-form-icon-button", i9.PhoenixFormIconButtonComponent, View_PhoenixFormIconButtonComponent_Host_0, { element: "element" }, {}, []);
export { PhoenixFormIconButtonComponentNgFactory as PhoenixFormIconButtonComponentNgFactory };
