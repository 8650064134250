var AlarmHistoryDto = /** @class */ (function () {
    function AlarmHistoryDto(alarmHistoryDto) {
        this.status = undefined;
        this.timestamp = undefined;
        this.comment = undefined;
        this.user = undefined;
        Object.assign(this, alarmHistoryDto);
    }
    return AlarmHistoryDto;
}());
export { AlarmHistoryDto };
