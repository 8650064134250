import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { map, reduce } from 'rxjs/operators';

import { FileItemDto, LatestMeasurementFilterDto, MeasurementDto, MeasurementSearchCriteriaDto } from '../../dto.module';
import { FileItem } from '../../file/api/file-item';
import { FileMapperService } from '../../file/mapper/file-mapper.service';
import { FileApiService } from '../../file/services/file-api.service';
import { SharedService } from '../../shared/services/shared.service';
import { Measurement } from '../api/measurement';
import { MeasurementMapperService } from '../mapper/measurement-mapper.service';
import { MeasurementAggregateDto } from '../dto/measurement-aggregate-dto';

@Injectable()
export class MeasurementApiService {

  private _files: string = 'files';
  private _latest: string = 'latest';
  private _measurement: string = 'measurement';
  private _measurements: string = 'measurements';
  private _pages: string = 'pages';
  private _aggregateMeasurements: string = 'measurements/aggregate';

  public constructor(
    private http: HttpClient,
    private shared: SharedService,
    private fileApi: FileApiService,
    private measurementMapper: MeasurementMapperService,
    private fileMapper: FileMapperService,
  ) {
  }

  /**
   * Creates a measurement by given measurementDto.
   * @param measurementDto
   * @returns
   */
  public createMeasurement(measurementDto: MeasurementDto): Observable<MeasurementDto> {
    return this.http.post<{ measurement: Measurement }>(
      this.shared.buildApiUrl(this._measurements, this._measurement),
      this.measurementMapper.measurementDtoToMeasurement(measurementDto),
    ).pipe(
      map((data: { measurement: Measurement }) => this.measurementMapper.measurementToMeasurementDto(data.measurement)),
    );
  }

  /**
   * Deletes a file from a measurement by given filename.
   * @param measurementDto
   * @param filename
   * @returns
   */
  public deleteFileFromMeasurement(measurementDto: MeasurementDto, filename: string): Observable<void> {
    return this.http.delete<void>(
      this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id, this._files, filename),
    );
  }

  /**
   * Deletes a measurement by given measurementDto.
   * @param measurementDto
   * @returns
   */
  public deleteMeasurement(measurementDto: MeasurementDto): Observable<void> {
    return this.http.delete<void>(
      this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id),
    );
  }

  /**
   * Deletes a measurement by given id.
   * @param id
   * @returns
   */
  public deleteMeasurementById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.shared.buildApiUrl(this._measurements, this._measurement, id.toString()),
    );
  }

  /**
   * Gets a file from a measurement by given measurementDto and filename.
   * @param measurementDto
   * @param filename
   * @returns
   */
  public getFileFromMeasurement(measurementDto: MeasurementDto, filename: string): Observable<FileItemDto> {
    return this.http.get<{ fileItem: FileItem }>(
      this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id, this._files, filename),
    ).pipe(
      map((fileItem: { fileItem: FileItem }) => this.fileMapper.fileItemToFileItemDto(fileItem.fileItem)),
    );
  }

  /**
   * Gets the latest measurements of defined measurementpoints
   * @param latestMeasurementFilterDto
   * @param withFurtherPages
   * @returns Observable<MeasurementDto[]>
   */
  public getLatestMeasurement(
    latestMeasurementFilterDto: LatestMeasurementFilterDto = new LatestMeasurementFilterDto(),
    withFurtherPages: boolean = true)
    : Observable<MeasurementDto[]> {
    const url: string = this.shared.buildApiUrl(this._measurements, this._latest);
    return this.shared.httpGetWithPagination<Measurement>(
      url,
      this.measurementMapper.latestMeasurementFilterDtoToLatestMeasurementFilter(latestMeasurementFilterDto),
      withFurtherPages,
    ).pipe(
      map((measurements: Measurement[]) => this.measurementMapper.measurementArrayToMeasurementDtoArray(measurements)),
    );
  }

  /**
   * Gets a measurement by given measurementDto.
   * @param measurementDto
   * @returns
   */
  public getMeasurement(measurementDto: MeasurementDto): Observable<MeasurementDto> {
    return this.http.get<{ measurement: Measurement }>(
      this.shared.buildApiUrl(this._measurements, this._measurement, measurementDto.id.toString()),
    ).pipe(
      map((response: { measurement: Measurement }) => this.measurementMapper.measurementToMeasurementDto(response.measurement)),
    );
  }

  /**
   * Gets a measurement by given id.
   * @param measurementId
   * @returns
   */
  public getMeasurementById(measurementId: string): Observable<MeasurementDto> {
    return this.http.get<{ measurement: Measurement }>(
      this.shared.buildApiUrl(this._measurements, this._measurement, measurementId.toString()),
    ).pipe(
      map((response: { measurement: Measurement }) => this.measurementMapper.measurementToMeasurementDto(response.measurement)),
    );
  }

  /**
   * Gets all measurements matching the search criteria.
   * @param measurementSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getMeasurements(
    measurementSearchCriteriaDto: MeasurementSearchCriteriaDto = new MeasurementSearchCriteriaDto(),
    withFurtherPages: boolean = true,
  ): Observable<MeasurementDto[]> {
    return this.shared.httpGetWithPagination<{ _embedded: { measurementResourceList: { measurement: Measurement }[] } }>(
      this.shared.buildApiUrl(this._measurements),
      this.measurementMapper.measurementSearchCriteriaDtoToMeasurementSearchCriteria(measurementSearchCriteriaDto),
      withFurtherPages,
      (partial: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }) =>
        _.hasIn(partial, '_embedded.measurementResourceList') ? partial._embedded.measurementResourceList.length : 0,
    ).pipe(
      map((measurements: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }) => this.convertResponseToMeasurementDtoArray(measurements)),
      reduce((all: MeasurementDto[], current: MeasurementDto[]) => all.concat(current)),
    );
  }

  /**
  * Gets all measurements matching the search criteria.
  * @param measurementSearchCriteriaDto
  * @param withFurtherPages
  * @returns
  */
  public getAggregateMeasurements(
    measurementAggregateDto: MeasurementAggregateDto = new MeasurementAggregateDto(),
    withFurtherPages: boolean = true,
  ): Observable<MeasurementDto[]> {
    return this.shared.httpGetWithPagination<{ _embedded: { measurementResourceList: { measurement: Measurement }[] } }>(
      this.shared.buildApiUrl(this._measurements),
      this.measurementMapper.measurementAggregateDtoToMeasurementSearchCriteria(measurementAggregateDto),
      withFurtherPages,
      (partial: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }) =>
        _.hasIn(partial, '_embedded.measurementResourceList') ? partial._embedded.measurementResourceList.length : 0,
    ).pipe(
      map((measurements: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }) => this.convertResponseToMeasurementDtoArray(measurements)),
      reduce((all: MeasurementDto[], current: MeasurementDto[]) => all.concat(current)),
    );
  }

  /**
   * Updates/Replaces a file of a measurement
   * @param measurement
   * @param fileItemDto
   * @param file
   * @returns
   */
  public updateFileOfMeasurement(measurement: MeasurementDto, fileItemDto: FileItemDto, file: File): Observable<number> {
    return this.fileApi.upload(
      fileItemDto,
      file,
      'PUT',
      this.shared.buildApiUrl(this._measurements, this._measurement, measurement.id, this._files, this.fileMapper.fileItemDtoToFileItem(fileItemDto).name),
    );
  }

  /**
   * Uploads a file to measurement
   * @param measurement
   * @param fileItemDto
   * @param file
   * @returns
   */
  public uploadFileToMeasurement(measurement: MeasurementDto, fileItemDto: FileItemDto, file: File): Observable<number> {
    return this.fileApi.upload(
      fileItemDto,
      file,
      'POST',
      this.shared.buildApiUrl(this._measurements, this._measurement, measurement.id, this._files, fileItemDto.name),
    );
  }

  private convertResponseToMeasurementDtoArray(response: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }): MeasurementDto[] {
    if (response._embedded) {
      return _.map(
        response._embedded.measurementResourceList,
        (measurement: { measurement: Measurement }) => this.measurementMapper.measurementToMeasurementDto(measurement.measurement),
      );
    } else {
      return [];
    }
  }


  /**
 * Aggregate measurements matching the search criteria.
 * @param measurementSearchCriteriaDto
 * @param withFurtherPages
 * @returns
 */
  public aggregateMeasurements(
    //measurementSearchCriteriaDto: MeasurementSearchCriteriaDto = new MeasurementSearchCriteriaDto(),
    measurementAggregateDto: MeasurementAggregateDto = new MeasurementAggregateDto(),
    withFurtherPages: boolean = true,
  ): Observable<MeasurementDto[]> {
    return this.shared.httpGetWithPagination<{ _embedded: { measurementResourceList: { measurement: Measurement }[] } }>(
      this.shared.buildApiUrl(this._aggregateMeasurements),
      this.measurementMapper.measurementAggregateDtoToMeasurementSearchCriteria(measurementAggregateDto),
      withFurtherPages,
      (partial: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }) =>
        _.hasIn(partial, '_embedded.measurementResourceList') ? partial._embedded.measurementResourceList.length : 0,
    ).pipe(
      map((measurements: { _embedded: { measurementResourceList: { measurement: Measurement }[] } }) => this.convertResponseToMeasurementDtoArray(measurements)),
      reduce((all: MeasurementDto[], current: MeasurementDto[]) => all.concat(current)),
    );
  }

  /**
  * Calculate total pages.
  * @returns
  */
  public calcualetTotalPages(): Observable<Number> {
    return this.http.get<Number>(
      this.shared.buildApiUrl(this._measurements, this._pages),
    ).pipe();
  }

}
