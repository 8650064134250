var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var MeasurementTypeSearchCriteria = /** @class */ (function (_super) {
    __extends(MeasurementTypeSearchCriteria, _super);
    function MeasurementTypeSearchCriteria(measurementTypeSearchCriteria) {
        var _this = _super.call(this, measurementTypeSearchCriteria) || this;
        _this.id = undefined;
        _this.key = undefined;
        _this.name = undefined;
        _this.partialName = undefined;
        _this.tags = undefined;
        Object.assign(_this, measurementTypeSearchCriteria);
        return _this;
    }
    MeasurementTypeSearchCriteria.prototype.toJSON = function () {
        return {
            id: this.id,
            key: this.key,
            name: this.name,
            partialName: this.partialName,
            tags: this.tags,
            page: this.page,
            size: this.size,
        };
    };
    return MeasurementTypeSearchCriteria;
}(SearchCriteriaPageable));
export { MeasurementTypeSearchCriteria };
