var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseDto } from '../../shared/classes/base-dto';
var TaskDto = /** @class */ (function (_super) {
    __extends(TaskDto, _super);
    function TaskDto(taskDto) {
        var _this = _super.call(this, taskDto) || this;
        _this.begin = undefined;
        _this.createdDate = undefined;
        _this.deadline = undefined;
        _this.status = undefined;
        _this.targetAssetId = undefined;
        _this.taskConfigId = undefined;
        _this.comment = undefined;
        _this.commentTimestamp = undefined;
        _this.commentUser = undefined;
        Object.assign(_this, taskDto);
        return _this;
    }
    return TaskDto;
}(BaseDto));
export { TaskDto };
