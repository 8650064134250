import { format, isValid } from 'date-fns';
var AlarmHistory = /** @class */ (function () {
    function AlarmHistory(alarmHistory) {
        this.status = undefined;
        this.timestamp = undefined;
        this.comment = undefined;
        this.user = undefined;
        Object.assign(this, alarmHistory);
    }
    AlarmHistory.prototype.toJSON = function () {
        return {
            status: this.status,
            timestamp: (this.timestamp && isValid(this.timestamp)) ? format(this.timestamp) : undefined,
            comment: this.comment,
            user: this.user,
        };
    };
    return AlarmHistory;
}());
export { AlarmHistory };
