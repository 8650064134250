var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
var LatestMeasurementFilter = /** @class */ (function (_super) {
    __extends(LatestMeasurementFilter, _super);
    function LatestMeasurementFilter(latestMeasurementFilter) {
        var _this = _super.call(this, latestMeasurementFilter) || this;
        _this.objectIds = undefined;
        _this.objectType = undefined;
        Object.assign(_this, latestMeasurementFilter);
        return _this;
    }
    LatestMeasurementFilter.prototype.toJSON = function () {
        return {
            objectType: this.objectType,
            objectIds: this.objectIds,
            page: this.page,
            size: this.size,
        };
    };
    return LatestMeasurementFilter;
}(SearchCriteriaPageable));
export { LatestMeasurementFilter };
