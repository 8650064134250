import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-switch',
  templateUrl: './phoenix-dynamic-switch.component.html',
  styleUrls: ['./phoenix-dynamic-switch.component.scss'],
})
export class PhoenixDynamicSwitchComponent {
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<boolean>;

  @Input()
  public form: FormGroup;
}
