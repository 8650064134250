/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/vertical/layout-1/layout-1.component.ngfactory";
import * as i3 from "./layout/vertical/layout-1/layout-1.component";
import * as i4 from "../@fuse/services/config.service";
import * as i5 from "./layout/vertical/layout-2/layout-2.component.ngfactory";
import * as i6 from "./layout/vertical/layout-2/layout-2.component";
import * as i7 from "./layout/vertical/layout-3/layout-3.component.ngfactory";
import * as i8 from "./layout/vertical/layout-3/layout-3.component";
import * as i9 from "./layout/horizontal/layout-1/layout-1.component.ngfactory";
import * as i10 from "./layout/horizontal/layout-1/layout-1.component";
import * as i11 from "../@fuse/components/progress-bar/progress-bar.component.ngfactory";
import * as i12 from "../@fuse/components/progress-bar/progress-bar.component";
import * as i13 from "../@fuse/components/progress-bar/progress-bar.service";
import * as i14 from "@angular/common";
import * as i15 from "./app.component";
import * as i16 from "../@fuse/components/navigation/navigation.service";
import * as i17 from "../@fuse/components/sidebar/sidebar.service";
import * as i18 from "../@fuse/services/splash-screen.service";
import * as i19 from "../@fuse/services/translation-loader.service";
import * as i20 from "@ngx-translate/core";
import * as i21 from "@angular/cdk/platform";
import * as i22 from "./guards/permission.service";
import * as i23 from "../@phoenix/services/phoenix-communication-service/phoenix-communication.service";
import * as i24 from "../@phoenix/gapicon/auth/services/auth-api.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i2.View_VerticalLayout1Component_0, i2.RenderType_VerticalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i3.VerticalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-2", [], null, null, null, i5.View_VerticalLayout2Component_0, i5.RenderType_VerticalLayout2Component)), i1.ɵdid(2, 245760, null, 0, i6.VerticalLayout2Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-3", [], null, null, null, i7.View_VerticalLayout3Component_0, i7.RenderType_VerticalLayout3Component)), i1.ɵdid(2, 245760, null, 0, i8.VerticalLayout3Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "horizontal-layout-1", [], null, null, null, i9.View_HorizontalLayout1Component_0, i9.RenderType_HorizontalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i10.HorizontalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i11.View_FuseProgressBarComponent_0, i11.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i12.FuseProgressBarComponent, [i13.FuseProgressBarService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(9, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.fuseConfig.layout.style === "vertical-layout-1"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.fuseConfig.layout.style === "vertical-layout-2"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.fuseConfig.layout.style === "vertical-layout-3"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.fuseConfig.layout.style === "horizontal-layout-1"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, [["document", "click"], ["document", "keydown"], ["document", "mousemove"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).refreshLoginState($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).refreshLoginState($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousemove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).refreshLoginState($event.target) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i15.AppComponent, [i14.DOCUMENT, i4.FuseConfigService, i16.FuseNavigationService, i17.FuseSidebarService, i18.FuseSplashScreenService, i19.FuseTranslationLoaderService, i20.TranslateService, i21.Platform, i22.PermissionService, i23.PhoenixCommunicationService, i24.AuthApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
