/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phoenix-password-rules.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./phoenix-password-rules.component";
var styles_PhoenixPasswordRulesComponent = [i0.styles];
var RenderType_PhoenixPasswordRulesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhoenixPasswordRulesComponent, data: {} });
export { RenderType_PhoenixPasswordRulesComponent as RenderType_PhoenixPasswordRulesComponent };
function View_PhoenixPasswordRulesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["radio_button_unchecked"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_PhoenixPasswordRulesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "notranslate mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["check_circle"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_PhoenixPasswordRulesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixPasswordRulesComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixPasswordRulesComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.status; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.status; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.label)); _ck(_v, 5, 0, currVal_2); }); }
export function View_PhoenixPasswordRulesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "font-weight-400"], ["data-translate", "PASSWORDRULES.TITLE"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "font-weight-100 line-height-20"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhoenixPasswordRulesComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "PASSWORDRULES.TITLE"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.rules; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PhoenixPasswordRulesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "phoenix-password-rules", [], null, null, null, View_PhoenixPasswordRulesComponent_0, RenderType_PhoenixPasswordRulesComponent)), i1.ɵdid(1, 49152, null, 0, i6.PhoenixPasswordRulesComponent, [], null, null)], null, null); }
var PhoenixPasswordRulesComponentNgFactory = i1.ɵccf("phoenix-password-rules", i6.PhoenixPasswordRulesComponent, View_PhoenixPasswordRulesComponent_Host_0, { password: "password" }, { checkResult: "checkResult" }, []);
export { PhoenixPasswordRulesComponentNgFactory as PhoenixPasswordRulesComponentNgFactory };
