import { HttpClient } from '@angular/common/http';
import { map, reduce } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { AlarmSearchCriteriaDto } from '../dto/alarm-search-criteria-dto';
import { AlarmMapperService } from '../mapper/alarm-mapper.service';
var AlarmApiService = /** @class */ (function () {
    /**
     *
     * @param http
     * @param sharedService
     * @param alarmMapperService
     */
    function AlarmApiService(http, sharedService, alarmMapperService, httpClient) {
        this.http = http;
        this.sharedService = sharedService;
        this.alarmMapperService = alarmMapperService;
        this.httpClient = httpClient;
        this._alarm = 'alarm';
        this._alarms = 'alarms';
        this._aggregation = 'aggregation';
    }
    /**
     * Gets all alarms matching the search criteria
     * @param alarmSearchCriteriaDto
     * @param withFurtherPages
     * @returns
     */
    AlarmApiService.prototype.getAlarms = function (alarmSearchCriteriaDto, withFurtherPages) {
        var _this = this;
        if (alarmSearchCriteriaDto === void 0) { alarmSearchCriteriaDto = new AlarmSearchCriteriaDto(); }
        if (withFurtherPages === void 0) { withFurtherPages = true; }
        return this.sharedService.httpGetWithPagination(this.sharedService.buildApiUrl(this._alarms), this.alarmMapperService.alarmSearchCriteriaDtoToAlarmSearchCriteria(alarmSearchCriteriaDto), withFurtherPages).pipe(map(function (alarms) { return _this.alarmMapperService.alarmArrayToAlarmDtoArray(alarms); }), reduce(function (all, current) { return all.concat(current); }));
    };
    /**
     * Gets an alarm by given ID.
     * @param alarmId
     * @returns
     */
    AlarmApiService.prototype.getAlarmById = function (alarmId) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmId)).pipe(map(function (alarm) { return _this.alarmMapperService.alarmToAlarmDto(alarm); }));
    };
    /**
     * Gets an alarm by given alarmDto.
     * @param alarmDto
     * @returns
     */
    AlarmApiService.prototype.getAlarm = function (alarmDto) {
        var _this = this;
        return this.http.get(this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmDto.id)).pipe(map(function (alarm) { return _this.alarmMapperService.alarmToAlarmDto(alarm); }));
    };
    /**
     * Creates an alarm by given alarmDto.
     * @param alarmDto
     * @returns
     */
    AlarmApiService.prototype.createAlarm = function (alarmDto) {
        return this.http.post(this.sharedService.buildApiUrl(this._alarms, this._alarm), this.alarmMapperService.alarmDtoToAlarm(alarmDto));
    };
    /**
     * Updates an alarm by given alarmDto.
     * @param alarmDto
     * @returns
     */
    AlarmApiService.prototype.updateAlarm = function (alarmDto) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmDto.id), this.alarmMapperService.alarmDtoToAlarm(alarmDto)).pipe(map(function (alarm) { return _this.alarmMapperService.alarmToAlarmDto(alarm); }));
    };
    /**
     * Updates alarms by given alarmDto[].
     * @param alarmDtos
     * @returns
    */
    AlarmApiService.prototype.updateAlarms = function (alarmDtos) {
        var _this = this;
        return this.http.put(this.sharedService.buildApiUrl(this._alarms), this.alarmMapperService.alarmDtoArrayToAlarmArray(alarmDtos)).pipe(map(function (alarms) { return _this.alarmMapperService.alarmArrayToAlarmDtoArray(alarms); }));
    };
    /**
     * Deletes an alarm by given alarmDto
     * @param alarmDto
     * @returns
     */
    AlarmApiService.prototype.deleteAlarm = function (alarmDto) {
        return this.http.delete(this.sharedService.buildApiUrl(this._alarms, this._alarm, alarmDto.id));
    };
    /**
     * Deletes an alarm by given alarm ID.
     * @param id
     * @returns
     */
    AlarmApiService.prototype.deleteAlarmById = function (id) {
        return this.http.delete(this.sharedService.buildApiUrl(this._alarms, this._alarm, id));
    };
    /**
     * Gets Alarms by a given search criteria
     * @returns
     */
    AlarmApiService.prototype.aggregateAlarmsForLast12Months = function () {
        var _this = this;
        return this.httpClient.get(this.sharedService.buildApiUrl(this._alarms, this._aggregation)).pipe(map(function (alarmCounts) { return _this.alarmMapperService.alarmCountArrayToAlarmCountDtoArray(alarmCounts); }), reduce(function (all, current) { return all.concat(current); }));
    };
    return AlarmApiService;
}());
export { AlarmApiService };
