var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthApiService } from '@phoenix/gapicon/auth/services/auth-api.service';
import { UserApiService } from '@phoenix/gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AssetApiService } from '../../@phoenix/gapicon/asset/services/asset-api.service';
import { Roles } from '../../@phoenix/gapicon/role/api/roles.enum';
import { PhoenixTenantSelectorDialogService } from '../../@phoenix/templates/phoenix-dialog/phoenix-tenantselector-dialog/phoenix-tenant-selector-dialog.service';
import { AppInitStorageService } from '../services/app-init-storage.service';
import { permissionRole } from './permission';
import { PhoenixAssetBranchselectorDialogService } from '../../@phoenix/templates/phoenix-dialog/phoenix-asset-branchselector-dialog/phoenix-asset-branchselector-dialog.service';
import { TenantApiService } from "../../@phoenix/gapicon/tenant/services/tenant-api.service";
var PermissionService = /** @class */ (function () {
    function PermissionService(authApi, userApi, assetApi, router, communicationService, tenantSelectorDialogService, assetBranchSelectorDialogService, appInitStorageService, tenantApi, location) {
        var _this = this;
        this.authApi = authApi;
        this.userApi = userApi;
        this.assetApi = assetApi;
        this.router = router;
        this.communicationService = communicationService;
        this.tenantSelectorDialogService = tenantSelectorDialogService;
        this.assetBranchSelectorDialogService = assetBranchSelectorDialogService;
        this.appInitStorageService = appInitStorageService;
        this.tenantApi = tenantApi;
        this.location = location;
        this.tokenExpirationTimer = null;
        // autoLogout after 10h from last user action(period of time in seconds)
        this.autoLogoutAfter = 60 * 60 * 10;
        this._defaultUrl = "/dashboard";
        this.communicationService.getObservable(PhoenixCommunicationSubject.UserUpdated)
            .subscribe(function (userDto) { return _this.user = userDto; });
    }
    Object.defineProperty(PermissionService.prototype, "availableTenants", {
        get: function () {
            return this._availableTenants;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PermissionService.prototype, "availableAssetRootBranches", {
        get: function () {
            return this._availableAssetRootBranches;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PermissionService.prototype, "tenants", {
        get: function () {
            return this._tenants;
        },
        set: function (value) {
            this._tenants = value;
            // save current selected tenant to local storage;
            localStorage.setItem('selectedTenants', JSON.stringify(value.map(function (tenant) { return tenant.id; })));
            this.authApi.setCurrentTenants(value.map(function (tenant) { return tenant.id; }));
            // this.communicationService.emit(PhoenixCommunicationSubject.TenantChanged, this.tenants);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PermissionService.prototype, "assetBranches", {
        get: function () {
            return this._assetBranches;
        },
        set: function (value) {
            // save current selected assetBranch to local storage;
            this._assetBranches = value;
            localStorage.setItem('selectedAssetBranches', JSON.stringify(value.map(function (asset) { return asset.id; })));
            //this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this._assetBranches);
            //this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.tenants);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PermissionService.prototype, "user", {
        get: function () {
            return this._user;
        },
        set: function (value) {
            this._user = value;
            this.communicationService.emit(PhoenixCommunicationSubject.UserChanged, this.user);
        },
        enumerable: true,
        configurable: true
    });
    PermissionService.prototype.getAvailableTenantIds = function () {
        if (this.user) {
            return Array.from(new Set(this.authApi.getTenantsIdsFromGroupRole(this.user.roles.admin)
                .concat(this.authApi.getTenantsIdsFromGroupRole(this.user.roles.user))));
        }
        return [];
    };
    PermissionService.prototype.getAvailableTenants = function () {
        var _this = this;
        var tenantIds = this.getAvailableTenantIds();
        if (tenantIds.length > 0) {
            this.authApi.setCurrentTenants(tenantIds);
            return this.tenantApi.getAllTenantsForUserWithId(this.user.id).pipe(tap(function () { return _this.authApi.setCurrentTenants(tenantIds); }));
        }
        else {
            return of([]);
        }
    };
    PermissionService.prototype.refreshBranchesForSelectedTenant = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, newAssetBranch;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getAvailableAssetBranchesForSelectedTenant().toPromise()];
                    case 1:
                        _a._availableAssetRootBranches = _b.sent();
                        return [4 /*yield*/, this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise()];
                    case 2:
                        newAssetBranch = _b.sent();
                        if (Array.isArray(newAssetBranch) && newAssetBranch.length) {
                            this.assetBranches = newAssetBranch;
                            this.updateGroupAssetPairs();
                            this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PermissionService.prototype.getTenantIds = function () {
        return _.map(this.tenants, 'id');
    };
    PermissionService.prototype.hasPermission = function (permission) {
        var _this = this;
        var permissionGranted;
        if ((this.tenants && this.tenants.length > 0) && (this.assetBranches && this.assetBranches.length > 0)) {
            var selectedTenant_1 = this.tenants[0];
            var selectedAssetBranch = this.assetBranches[0];
            // get groups ids assigned to assets selected by user
            var userGroupIdsAssignedToUser_1 = selectedAssetBranch.assignedUserGroups
                .filter(function (group) { return group.assignedUsersIds.includes(_this.user.id); })
                .map(function (group) { return group.id; });
            // get groups names assigned to assets selected by user
            var hasDefaultManagementAdminRole = selectedAssetBranch.assignedUserGroups
                .filter(function (group) { return group.assignedUsersIds.includes(_this.user.id) && group.role == "admin" && group.name == "Default management admins"; })
                .length > 0;
            // check if user has admin role for particular groups assigned to selected root asset branch
            var hasUserAdminRole = this.user.roles.admin ? this.user.roles.admin
                .findIndex(function (role) { return userGroupIdsAssignedToUser_1.includes(role.split('.')[0]); }) !== -1 : false;
            // check if user has user role for particular groups assigned to selected root asset branch
            var hasUserUserRole = this.user.roles.user ? this.user.roles.user
                .findIndex(function (role) { return userGroupIdsAssignedToUser_1.includes(role.split('.')[0]); }) !== -1 : false;
            if (hasDefaultManagementAdminRole) {
                permissionGranted = permissionRole['DEFAULT_MANAGEMENT_ADMIN'].includes(permission.id);
            }
            else if (hasUserAdminRole) {
                permissionGranted = permissionRole['ADMIN'].includes(permission.id);
            }
            else if (hasUserUserRole) {
                permissionGranted = permissionRole['USER'].includes(permission.id);
            }
            else {
                _.forEach(this.user.roles, function (tenants, role) {
                    permissionGranted = _.includes(permissionRole[role.toUpperCase()], permission.id) && _.includes(tenants, selectedTenant_1.id);
                    if (permissionGranted) {
                        // End of for Each
                        return false;
                    }
                });
            }
        }
        if (!permissionGranted) {
            //console.log("++++ this.user:", this.user);
            permissionGranted = _.includes(permissionRole[Roles.EnvironmentAdmin.toUpperCase()], permission.id) && this.user.roles.hasOwnProperty(Roles.EnvironmentAdmin);
        }
        return permissionGranted;
    };
    PermissionService.prototype.login = function (email, password) {
        return __awaiter(this, void 0, void 0, function () {
            var isLoggedin, _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        isLoggedin = false;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, , 3, 11]);
                        return [4 /*yield*/, this.authApi.login(email, password).toPromise()];
                    case 2:
                        isLoggedin = _f.sent();
                        this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);
                        return [3 /*break*/, 11];
                    case 3:
                        if (!isLoggedin) return [3 /*break*/, 9];
                        _a = this;
                        return [4 /*yield*/, this.userApi.getUserById(this.authApi.getAuth().userInfo.id).toPromise()];
                    case 4:
                        _a.user = _f.sent();
                        _b = this;
                        return [4 /*yield*/, this.getAvailableTenants().toPromise()];
                    case 5:
                        _b._availableTenants = _f.sent();
                        localStorage.setItem('availableTenants', JSON.stringify(this._availableTenants.map(function (tenant) { return tenant.id; })));
                        _c = this;
                        return [4 /*yield*/, this.tenantSelectorDialogService.showTenantSelectorDialog(this.availableTenants).toPromise()];
                    case 6:
                        _c.tenants = _f.sent();
                        _d = this;
                        return [4 /*yield*/, this.getAvailableAssetBranchesForSelectedTenant().toPromise()];
                    case 7:
                        _d._availableAssetRootBranches = _f.sent();
                        _e = this;
                        return [4 /*yield*/, this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise()];
                    case 8:
                        _e.assetBranches = _f.sent();
                        this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
                        // get assigned groups
                        this.updateGroupAssetPairs();
                        this.findUserRolesFromAvailableTenants();
                        this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: isLoggedin });
                        this.router.navigate(['']);
                        return [3 /*break*/, 10];
                    case 9:
                        this.logout();
                        _f.label = 10;
                    case 10: return [7 /*endfinally*/];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    PermissionService.prototype.autoLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentUrl, _a, _b, currentTenants_1, filteredTenants, _c, currentAssetBranch_1, _d, filteredAssetBranches, e_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        currentUrl = this.location.path();
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 8, , 9]);
                        _a = this;
                        return [4 /*yield*/, this.userApi.getUserById(this.authApi.getAuth().userInfo.id).toPromise()];
                    case 2:
                        _a.user = _e.sent();
                        _b = this;
                        return [4 /*yield*/, this.getAvailableTenants().toPromise()];
                    case 3:
                        _b._availableTenants = _e.sent();
                        currentTenants_1 = JSON.parse(localStorage.getItem('selectedTenants'));
                        filteredTenants = this._availableTenants.filter(function (tenant) { return currentTenants_1[0] === tenant.id; });
                        if (filteredTenants && filteredTenants.length > 0) {
                            this.tenants = filteredTenants;
                        }
                        _c = this;
                        return [4 /*yield*/, this.getAvailableAssetBranchesForSelectedTenant().toPromise()];
                    case 4:
                        _c._availableAssetRootBranches = _e.sent();
                        currentAssetBranch_1 = JSON.parse(localStorage.getItem('selectedAssetBranches'));
                        if (!(currentAssetBranch_1 && currentAssetBranch_1.length === 0)) return [3 /*break*/, 6];
                        _d = this;
                        return [4 /*yield*/, this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise()];
                    case 5:
                        _d.assetBranches = _e.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        if (currentAssetBranch_1) {
                            filteredAssetBranches = this._availableAssetRootBranches.filter(function (branch) { return currentAssetBranch_1[0] === branch.id; });
                            if (filteredAssetBranches && filteredAssetBranches.length > 0) {
                                this.assetBranches = filteredAssetBranches;
                                this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
                            }
                        }
                        _e.label = 7;
                    case 7:
                        // get assigned groups
                        this.updateGroupAssetPairs();
                        this.findUserRolesFromAvailableTenants();
                        this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: true });
                        this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);
                        if (currentUrl === '') {
                            this.router.navigate([this._defaultUrl]);
                        }
                        else {
                            this.router.navigate([currentUrl]);
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _e.sent();
                        console.log('autoLogin error', e_1);
                        this.logout();
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    PermissionService.prototype.logout = function () {
        this.authApi.logout();
        this.router.navigate(['/login']);
        // is true when logout manually
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        // this.communicationService.emit(PhoenixCommunicationSubject.UserLogout, {value: true});
    };
    PermissionService.prototype.autoLogoutAfterPeriodTime = function (expirationDuration) {
        var _this = this;
        if (this.tokenExpirationTimer) {
            clearInterval(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = setTimeout(function () {
            _this.logout();
        }, expirationDuration);
    };
    PermissionService.prototype.startNewAutoLoginCountDown = function () {
        if (!this.userIsNotAuthenticated()) {
            this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);
        }
    };
    PermissionService.prototype.routeNeedsPermission = function (route) {
        return _.hasIn(route, 'data.permission');
    };
    PermissionService.prototype.userIsNotAuthenticated = function () {
        return !Boolean(this.authApi.getAuth());
    };
    PermissionService.prototype.updateGroupAssetPairs = function () {
        var _this = this;
        if (this.assetBranches && this._assetBranches.length > 0) {
            var groupAssetPairs = this.assetBranches[0].assignedUserGroups
                .filter(function (assignedGroup) { return assignedGroup.assignedUsersIds.includes(_this.user.id); })
                .map(function (assignedGroup) { return assignedGroup.id + ":" + _this.assetBranches[0].id; });
            this.authApi.setGroupAssetPairs(groupAssetPairs);
        }
    };
    PermissionService.prototype.findUserRolesFromAvailableTenants = function () {
        this.rolesWithTenantNames = {};
        // _.forEach(this.user.roles, (tenants: string[], role: string) => {
        //   this.rolesWithTenantNames[role] = _.map(tenants, (tenant: string) => {
        //     return _.get(_.find(this.availableTenants, {id: tenant}), 'name');
        //   });
        //
        //   _.remove(this.rolesWithTenantNames[role], _.isUndefined);
        // });
    };
    PermissionService.prototype.getAvailableAssetBranchesForSelectedTenant = function () {
        if (this.user) {
            return this.assetApi.getAssetBranches(this.user.id);
        }
        return of([]);
    };
    PermissionService.prototype.isAdmin = function () {
        var _this = this;
        var selectedTenant = this.tenants[0];
        var selectedAssetBranch = this.assetBranches[0];
        // get groups ids assigned to assets selected by user
        var userGroupIdsAssignedToUser = selectedAssetBranch.assignedUserGroups
            .filter(function (group) { return group.assignedUsersIds.includes(_this.user.id); })
            .map(function (group) { return group.id; });
        // get groups names assigned to assets selected by user
        var hasDefaultManagementAdminRole = selectedAssetBranch.assignedUserGroups
            .filter(function (group) { return group.assignedUsersIds.includes(_this.user.id) && group.role == "admin" && group.name == "Default management admins"; })
            .length > 0;
        // check if user has admin role for particular groups assigned to selected root asset branch
        var hasUserAdminRole = this.user.roles.admin ? this.user.roles.admin
            .findIndex(function (role) { return userGroupIdsAssignedToUser.includes(role.split('.')[0]); }) !== -1 : false;
        return hasDefaultManagementAdminRole; //|| hasUserAdminRole;
    };
    return PermissionService;
}());
export { PermissionService };
