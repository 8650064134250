var UserConfig = /** @class */ (function () {
    function UserConfig(userConfig) {
        this.type = undefined;
        this.value = undefined;
        Object.assign(this, userConfig);
    }
    UserConfig.prototype.toJSON = function () {
        return {
            type: this.type,
            value: this.value,
        };
    };
    return UserConfig;
}());
export { UserConfig };
