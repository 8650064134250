import { format, isValid } from 'date-fns';
var Alarm = /** @class */ (function () {
    function Alarm(alarm) {
        this.status = undefined;
        this.measurement = undefined;
        this.id = undefined;
        this.timestamp = undefined;
        this.comment = undefined;
        this.history = undefined;
        this.updateTimestamp = undefined;
        this.updateUser = undefined;
        Object.assign(this, alarm);
    }
    Alarm.prototype.toJSON = function () {
        return {
            status: this.status,
            measurement: this.measurement,
            id: this.id,
            timestamp: (this.timestamp && isValid(this.timestamp)) ? format(this.timestamp) : undefined,
            comment: this.comment,
            history: this.history,
            updateTimestamp: (this.updateTimestamp && isValid(this.updateTimestamp)) ? format(this.updateTimestamp) : undefined,
            updateUser: this.updateUser,
        };
    };
    return Alarm;
}());
export { Alarm };
