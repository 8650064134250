// tslint:disable:max-file-line-count
import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';

import { PhoenixCloneConfirmationDialogService } from '../../../@phoenix/templates/phoenix-dialog/phoenix-clone-confirmation-dialog/phoenix-clone-confirmation-dialog.service';
import { PhoenixAutocompleteEntry } from '../../components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { PhoenixDialogResult } from '../../components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { TaskConfigDto } from '../../gapicon/asset/dto/task-config-dto';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetDto, MeasurementPointDto, ThresholdDto, UserDto } from '../../gapicon/dto.module';
import { MeasurementTypeDto } from '../../gapicon/measurement-type/dto/measurement-type-dto';
import { NotificationConfigDto } from '../../gapicon/notification-config/dto/notification-config-dto';
import { NotificationConfigApiService } from '../../gapicon/notification-config/services/notification-config-api.service';
import { PhoenixMeasurementTypeDialogService } from '../../templates/phoenix-dialog/phoenix-measurement-type-dialog/phoenix-measurement-type-dialog.service';
import { PhoenixSensormanagementDialogService } from '../../templates/phoenix-dialog/phoenix-sensormanagement-dialog/phoenix-sensormanagement-dialog.service';
import { PhoenixTaskConfigDialogService } from '../../templates/phoenix-dialog/phoenix-task-config-dialog/phoenix-task-config-dialog.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';

import { PhoenixThresholdWizardService } from './phoenix-threshold-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import { UserGroupDto } from "../../gapicon/group/dto/user-group-dto";
import { AddUserGroupToAssetDialogResult } from "../../templates/phoenix-dialog/phoenix-measurement-type-dialog/classes/phoenix-measurement-type-assigngroup-dialog-data";
import { GroupApiService } from "../../gapicon/group/services/group-api.service";
import { PhoenixTimeWindowConfigWizardService } from './phoenix-time-window-config-wizard.service';
import { TimeWindowConfigDto } from '@phoenix/gapicon/asset/dto/time-window-config-dto';
import { TimeWindowConfigDayDto } from '@phoenix/gapicon/asset/dto/time-window-config-day-dto';
import { Permission, permissionIds } from 'app/guards/permission';
import { PermissionService } from 'app/guards/permission.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixMeasurementPointWizardService {

  private measurementPoint: AssetDto = undefined;
  private openElementWizard: Function = undefined;
  private taskConfigForNewMeasurementPoint: TaskConfigDto[] = [];
  private createThresholdPermission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.THRESHOLD.write);
  private newChecklistPermission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.MEASUREMENTTYPE.write);
  private registerSensorPermission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.SENSORMANAGEMENT.write);

  public constructor(
    private dialog: MatDialog,
    private assetApiService: AssetApiService,
    private userGroupsApiService: GroupApiService,
    private thresholdWizardService: PhoenixThresholdWizardService,
    private timeWindowConfigWizardService: PhoenixTimeWindowConfigWizardService,
    private communicationService: PhoenixCommunicationService,
    private translateService: TranslateService,
    private phoenixWizardHelper: PhoenixWizardHelperService,
    private phoenixSnackbarService: PhoenixSnackbarService,
    private notificationConfigApiService: NotificationConfigApiService,
    private measurementTypeDialogService: PhoenixMeasurementTypeDialogService,
    private sensorDialogService: PhoenixSensormanagementDialogService,
    private taskConfigService: PhoenixTaskConfigDialogService,
    private clonedElementConfirmDialogService: PhoenixCloneConfirmationDialogService,
    private permissionService: PermissionService
  ) {
  }

  public async openCreateMeasurementPointWizard(measurementPoint: AssetDto,
    parent?: AssetDto,
    openElementWizard?: Function,
  ): Promise<void> {
    this.openElementWizard = openElementWizard;
    await this.openMeasurementPointWizard(
      measurementPoint,
      (dialogResult: PhoenixDialogResult): Promise<void> => this.onNewMeasurementPointCloseCallback(dialogResult),
      'WIZARDS.SELECTOR.TITLE.MEASUREMENTPOINT',
      parent,
    );
  }

  public async openDeleteMeasurementPointWizard(measurementPoint: AssetDto): Promise<void> {
    this.measurementPoint = measurementPoint;
    const randomString: string = this.phoenixWizardHelper.getRandomString();
    const hintTextOptions: { classes: { [key: string]: boolean } } = {
      classes: {
        'font-size-12': true,
        'hint-text': true,
        'primary-300-fg': true,
        'mt-4': true,
        'mb-4': true,
      },
    };

    await this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.MEASUREMENTPOINTS.DELETE.TITLE',
        subHeadline: measurementPoint.name,
        buttons: {
          cancel: true,
          delete: 'WIZARDS.MEASUREMENTPOINTS.DELETE.SUBMIT',
        },
        onClose: (r: PhoenixDialogResult): Promise<void> => this.onDeleteCloseCallback(r),
        translationParams: {
          deleteCode: randomString,
        },
        color: 'warn',
        formControls: [[
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE1',
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE2',
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE3',
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE4',
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.LINE5',
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.MEASUREMENTPOINTS.DELETE.CONFIRMATION',
          ),
          new PhoenixDynamicFormControl<string>(
            'delete-code', PhoenixDynamicFormControlType.Text, 'WIZARDS.MEASUREMENTPOINTS.DELETE.DELETECODE',
            false, undefined, randomString,
          ),
          new PhoenixDynamicFormControl<string>(
            'delete', PhoenixDynamicFormControlType.TextBox, 'WIZARDS.MEASUREMENTPOINTS.DELETE.DELETECODEINPUT',
            true,
            { hint: 'WIZARDS.MEASUREMENTPOINTS.DELETE.DELETECODEINPUTHINT' },
            undefined,
            undefined,
            [Validators.required, Validators.pattern(randomString)],
          ),
        ]],
      },
    });
  }



  public async openEditMeasurementPointWizard(measurementPoint: AssetDto): Promise<void> {
    this.openElementWizard = undefined;
    await this.openMeasurementPointWizard(
      measurementPoint,
      (dialogResult: PhoenixDialogResult): Promise<void> => this.onEditMeasurementPointCloseCallback(dialogResult),
      'WIZARDS.MEASUREMENTPOINTS.EDIT.TITLE',
    );
  }


  public async openCloneCheckListElementsDialog(asset: AssetDto): Promise<void> {
    this.openElementWizard = undefined;
    const cloneElements: Boolean = await this.clonedElementConfirmDialogService.showClonedElementConfirmationSelectorDialog(asset).toPromise();
    try {
      if (cloneElements !== false) {
        await this.cloneAssetHierarchy(asset);
      }
    } catch (e) {
      console.error(e);
    }
  }


  private async cloneAssetHierarchy(asset: AssetDto): Promise<void> {
    await this.assetApiService.cloneAssetsHierarchy(asset).toPromise();
    this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.COMMON.CLONE.SUCCESS'));
    this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
  }

  private createElementSelectionWizardColumn(
    key: string,
    icon: string,
    iconLabel: string,
    text: string,
    buttonLabel: string,
    flipIcon?: boolean,
  ): PhoenixDynamicFormControl<string | boolean>[] {
    return [
      new PhoenixDynamicFormControl<string>(
        'icon' + key,
        PhoenixDynamicFormControlType.Icon,
        iconLabel,
        false,
        {
          size: 60,
          body: text,
          flip: flipIcon,
        },
        icon,
      ),
      new PhoenixDynamicFormControl<boolean>(
        'button' + key,
        PhoenixDynamicFormControlType.Button,
        buttonLabel,
        false,
        {
          style: 'raised',
          color: 'basic',
        },
      ),
    ];
  }

  private createMeasurementPoint(measurementPoint: AssetDto, dialogResult: PhoenixDialogResult): AssetDto {
    measurementPoint.tags = [AssetTags.measurementPoint];
    measurementPoint.name = dialogResult.formData['txtName'];
    measurementPoint.description = dialogResult.formData['txtDescription'];
    // Bugfix Steuerzeichen
    if (!_.isNil(measurementPoint.description) && measurementPoint.description.match('\u0000')) {
      measurementPoint.description = undefined;
    }
    measurementPoint.parentId = _.get(dialogResult.formData, 'txtParent.object.id', undefined);
    if (!measurementPoint.id) {
      measurementPoint.taskConfigs = this.taskConfigForNewMeasurementPoint;
    }
    switch (dialogResult.formData['switcherType']) {
      case 'automatic':
        measurementPoint.measurementPoint = new MeasurementPointDto({
          type: 'automaticTemperature',
          sensorId: _.get(dialogResult.formData, 'mpAutoTextSensorID.object.sensor.sensorId', undefined),
          threshold: _.get(dialogResult.formData, 'mpThreshold.object', undefined),
        });
        measurementPoint.tags.push(AssetTags.automatic);
        break;
      case 'counter':
        measurementPoint.measurementPoint = new MeasurementPointDto({
          type: 'automaticCounter',
          sensorId: _.get(dialogResult.formData, 'mpAutoTextSensorID.object.sensor.sensorId', undefined),
          //threshold: _.get(dialogResult.formData, 'mpThreshold.object', undefined),
        });
        measurementPoint.tags.push(AssetTags.automatic);
        break;
      case 'manual':
        measurementPoint.measurementPoint = new MeasurementPointDto({
          type: 'mobileTemperature',
          threshold: _.get(dialogResult.formData, 'mpThreshold.object', undefined),
        });
        measurementPoint.tags.push(AssetTags.manual);
        break;
      case 'checklist':
        measurementPoint.measurementPoint = new MeasurementPointDto({
          type: _.get(dialogResult.formData, 'measurementType.object.key', undefined),
        });
        measurementPoint.tags.push(AssetTags.checklist);
        break;
    }
    measurementPoint.timeWindowConfig = _.get(dialogResult.formData, 'dataTimeWindowValue', undefined);
    return measurementPoint;
  }

  private async createMeasurementPointWizardColumn(measurementPoint: AssetDto, parent: AssetDto):
    Promise<PhoenixDynamicFormControl<string | boolean | PhoenixAutocompleteEntry<ThresholdDto | AssetDto>>[]> {

    const thresholdAutocompleteData: PhoenixAutocompleteEntry<ThresholdDto>[] =
      _.sortBy(
        await this.phoenixWizardHelper.getAutoCompleteThresholdArray().toPromise(),
        [(element: PhoenixAutocompleteEntry<ThresholdDto>): string => element.label.toLowerCase()]);
    const thresholdFormControl: PhoenixDynamicFormControl<PhoenixAutocompleteEntry<ThresholdDto>> =
      new PhoenixDynamicFormControl<PhoenixAutocompleteEntry<ThresholdDto>>(
        'mpThreshold', PhoenixDynamicFormControlType.TextBox,
        'WIZARDS.SELECTOR.SWITCHER.THRESHOLD',
        false,
        { autocomplete: (): Observable<PhoenixAutocompleteEntry<ThresholdDto>[]> => of(thresholdAutocompleteData) },
        _.hasIn(measurementPoint, 'measurementPoint.threshold.name') ?
          {
            object: measurementPoint.measurementPoint.threshold,
            label: measurementPoint.measurementPoint.threshold.name,
          } : undefined,
      );
    const createThresholdButtonFormControl: PhoenixDynamicFormControl<string> =
      new PhoenixDynamicFormControl<string>('thresholdButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.NEWTHRESHOLD2', false, {
        style: 'raised',
        color: 'basic',
        callback: async (form: FormGroup): Promise<void> => this.showThresholdDialog(form, thresholdAutocompleteData),
      });

    const measurementTypeAutocompleteData: PhoenixAutocompleteEntry<MeasurementTypeDto>[] =
      _.sortBy(
        await this.phoenixWizardHelper.getAutoCompleteMeasurementTypeArray().toPromise(),
        [(element: PhoenixAutocompleteEntry<MeasurementTypeDto>): string => element.label.toLowerCase()]);
    const defaultMeasurementType: PhoenixAutocompleteEntry<MeasurementTypeDto> =
      _.find(measurementTypeAutocompleteData, (measurementType: PhoenixAutocompleteEntry<MeasurementTypeDto>) => {
        return measurementType.object.key === _.get(measurementPoint, 'measurementPoint.type', undefined);
      });

    const measurementTypeFormControl: PhoenixDynamicFormControl<PhoenixAutocompleteEntry<MeasurementTypeDto>> =
      new PhoenixDynamicFormControl<PhoenixAutocompleteEntry<MeasurementTypeDto>>(
        'measurementType',
        PhoenixDynamicFormControlType.TextBox,
        'WIZARDS.SELECTOR.SWITCHER.MEASUREMENTTYPE',
        false,
        { autocomplete: (): Observable<PhoenixAutocompleteEntry<MeasurementTypeDto>[]> => of(measurementTypeAutocompleteData) },
        defaultMeasurementType,
      );
    const measurementTypeButtonFormControl: PhoenixDynamicFormControl<string> =
      new PhoenixDynamicFormControl<string>('measurementTypeButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.MEASUREMENTTYPE', false, {
        style: 'raised',
        color: 'basic',
        callback: async (form: FormGroup): Promise<void> => this.showMeasurementTypeDialog(form, measurementTypeAutocompleteData),
      });

    const sensorAutocompleteData: PhoenixAutocompleteEntry<AssetDto>[] =
      _.sortBy(
        await this.phoenixWizardHelper.getAutoCompleteSensorArray().toPromise(),
        [(element: PhoenixAutocompleteEntry<AssetDto>): string => element.label.toLowerCase()]);
    const sensorId: string = _.get(measurementPoint, 'measurementPoint.sensorId', undefined);
    const defaultSensor: PhoenixAutocompleteEntry<AssetDto> = sensorId ? await this.phoenixWizardHelper.getSensorById(sensorId).toPromise() : undefined;
    const sensorFormControl: PhoenixDynamicFormControl<PhoenixAutocompleteEntry<AssetDto>> =
      new PhoenixDynamicFormControl<PhoenixAutocompleteEntry<AssetDto>>(
        'mpAutoTextSensorID',
        PhoenixDynamicFormControlType.TextBox,
        'WIZARDS.SELECTOR.TEXTBOX.ID',
        false,
        { autocomplete: (): Observable<PhoenixAutocompleteEntry<AssetDto>[]> => of(sensorAutocompleteData) },
        defaultSensor,
      );


    const registerSensorButtonFormControl: PhoenixDynamicFormControl<string> =
      new PhoenixDynamicFormControl<string>('sensorButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.NEWSENSOR', false, {
        style: 'raised',
        color: 'basic',
        callback: async (form: FormGroup): Promise<void> => this.showSensorDialog(form, sensorAutocompleteData),
      });

    const registerCounterButtonFormControl: PhoenixDynamicFormControl<string> =
      new PhoenixDynamicFormControl<string>('sensorButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.NEWCOUNTER', false, {
        style: 'raised',
        color: 'basic',
        callback: async (form: FormGroup): Promise<void> => this.showSensorDialog(form, sensorAutocompleteData),
      });

    const dataTimeWindowButtonFormControl: PhoenixDynamicFormControl<string> =
      new PhoenixDynamicFormControl<string>('dataTimeWindowButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.EDITTIMEWINDOW', false, {
        style: 'raised',
        color: 'basic',
        callback: async (form: FormGroup): Promise<void> => this.showTimeWindowConfigDialog(form, measurementPoint),
      });
    const dataTimeWindowHintFormControl: PhoenixDynamicFormControl<string> =
      new PhoenixDynamicFormControl<string>('editDataTimeWindowHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.EDITTIMEWINDOWHINT');
    const dataTimeWindowValueFormControl: PhoenixDynamicFormControl<TimeWindowConfigDto> =
      new PhoenixDynamicFormControl<TimeWindowConfigDto>('dataTimeWindowValue', PhoenixDynamicFormControlType.Text, '', false, { classes: { 'hidden': true }, hiddenData: true }, _.get(measurementPoint, 'timeWindowConfig', undefined));

    const manualHintFormControl: PhoenixDynamicFormControl<string> = new PhoenixDynamicFormControl<string>('mpManualHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.HINT');
    const automaticHintFormControl: PhoenixDynamicFormControl<string> = new PhoenixDynamicFormControl<string>('mpAutoHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.HINT');
    //const counterHintFormControl: PhoenixDynamicFormControl<string> = new PhoenixDynamicFormControl<string>('mpCounterHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.HINT');
    const measurementTypeHintFormControl: PhoenixDynamicFormControl<string> = new PhoenixDynamicFormControl<string>('measurementTypeHint', PhoenixDynamicFormControlType.Text, '', false, undefined, 'WIZARDS.SELECTOR.TABSWITCHER.MEASUREMENTTYPEHINT');

    // manual tab form controls
    let manualFormControls: PhoenixDynamicFormControl<any>[] = [];
    manualFormControls.push(thresholdFormControl);
    if (this.permissionService.hasPermission(this.createThresholdPermission)) {
      manualFormControls.push(createThresholdButtonFormControl);
    }
    manualFormControls.push(manualHintFormControl);

    // automatic tab form controls
    let automaticFormControls: PhoenixDynamicFormControl<any>[] = [];
    automaticFormControls.push(sensorFormControl);
    if (this.permissionService.hasPermission(this.registerSensorPermission)) {
      automaticFormControls.push(registerSensorButtonFormControl);
    }
    automaticFormControls.push(thresholdFormControl);
    if (this.permissionService.hasPermission(this.createThresholdPermission)) {
      automaticFormControls.push(createThresholdButtonFormControl);
    }
    //automaticFormControls.push(alarmConfigFormControl);

    automaticFormControls.push(automaticHintFormControl);
    automaticFormControls.push(dataTimeWindowButtonFormControl);
    automaticFormControls.push(dataTimeWindowHintFormControl);
    automaticFormControls.push(dataTimeWindowValueFormControl);


    // counter tab form controls
    let counterFormControls: PhoenixDynamicFormControl<any>[] = [];
    counterFormControls.push(sensorFormControl);
    if (this.permissionService.hasPermission(this.registerSensorPermission)) {
      counterFormControls.push(registerCounterButtonFormControl);
    }
    //counterFormControls.push(counterHintFormControl);
    /*
    automaticFormControls.push(dataTimeWindowButtonFormControl);
    automaticFormControls.push(dataTimeWindowHintFormControl);
    automaticFormControls.push(dataTimeWindowValueFormControl);
    */

    // checklist tab form control
    let measurementTypeFormControls: PhoenixDynamicFormControl<any>[] = [];
    measurementTypeFormControls.push(measurementTypeFormControl);
    if (this.permissionService.hasPermission(this.newChecklistPermission)) {
      measurementTypeFormControls.push(measurementTypeButtonFormControl);
    }
    measurementTypeFormControls.push(measurementTypeHintFormControl);

    return [
      new PhoenixDynamicFormControl<string>(
        'txtName', PhoenixDynamicFormControlType.TextBox,
        'WIZARDS.SELECTOR.TEXTBOX.NAME',
        true,
        {},
        _.get(measurementPoint, 'name', undefined),
        undefined,
        [Validators.required],
      ),
      new PhoenixDynamicFormControl<string>(
        'txtDescription',
        PhoenixDynamicFormControlType.TextArea,
        'WIZARDS.SELECTOR.TEXTBOX.DESCRIPTION',
        false,
        {},
        _.get(measurementPoint, 'description', undefined),
      ),
      new PhoenixDynamicFormControl<PhoenixAutocompleteEntry<AssetDto>>(
        'txtParent', PhoenixDynamicFormControlType.TextBox,
        'WIZARDS.SELECTOR.TEXTBOX.PARENT',
        false,
        {
          hint: 'WIZARDS.FORMFIELDS.PARENTHINT',
          autocomplete: (search: string): Observable<PhoenixAutocompleteEntry<AssetDto>[]> =>
            this.phoenixWizardHelper.assetAutocompleteSearchFunction(search, this.measurementPoint),
        },
        parent ? new PhoenixAutocompleteEntry<AssetDto>({ object: parent, label: parent.name }) : undefined,
      ),
      new PhoenixDynamicFormControl<string>('taskConfigButton', PhoenixDynamicFormControlType.Button, 'WIZARDS.SELECTOR.BUTTON.TASKCONFIG', false, {
        style: 'raised',
        color: 'basic',
        callback: async (form: FormGroup): Promise<void> => {
          if (this.measurementPoint) {
            (await this.taskConfigService.openTaskConfigDialog(this.measurementPoint.taskConfigs)).afterClosed().subscribe((result: TaskConfigDto[]) => {
              if (result) {
                this.measurementPoint.taskConfigs = result;
              }
            });
          } else {
            await (await this.taskConfigService.openTaskConfigDialog([])).afterClosed().subscribe((result: TaskConfigDto[]) => {
              if (result) {
                this.taskConfigForNewMeasurementPoint = result;
              }
            });
          }
        },
      }),
      new PhoenixDynamicFormControl<string>(
        'switcherType',
        PhoenixDynamicFormControlType.TabSwitcher,
        'WIZARDS.SELECTOR.TABSWITCHER.TYPE',
        false,
        {
          tabs: [
            {
              key: 'manual',
              label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELMANUAL',
              content: 'WIZARDS.SELECTOR.TABSWITCHER.MANUAL',
              formControls: manualFormControls,
            },
            {
              key: 'automatic',
              label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELAUTO',
              content: 'WIZARDS.SELECTOR.TABSWITCHER.AUTO',
              formControls: automaticFormControls,
            },
            {
              key: 'checklist',
              label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELCHECKLIST',
              content: 'WIZARDS.SELECTOR.TABSWITCHER.CHECKLIST',
              formControls: measurementTypeFormControls,
            },
            {
              key: 'counter',
              label: 'WIZARDS.SELECTOR.TABSWITCHER.LABELCOUNTER',
              content: 'WIZARDS.SELECTOR.TABSWITCHER.COUNTER',
              formControls: counterFormControls,
            },

          ],
        },
        this.getSwitchTab(_.get(measurementPoint, 'measurementPoint.type', 'mobileTemperature')),
        measurementPoint !== undefined,
      ),
    ];
  }

  private getSwitchTab(measurementType: string): string {
    switch (measurementType) {
      case 'automaticCounter':
        return 'counter';
      case 'automaticTemperature':
        return 'automatic';
      case 'mobileTemperature':
        return 'manual';
      default:
        return 'checklist';
    }
  }

  private async onDeleteCloseCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'delete') {
      try {
        await this.assetApiService.deleteAsset(this.measurementPoint).toPromise();
        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTPOINTS.DELETE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }
      this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
    }
    dialogResult.dialogRef.close();
  }

  private async onEditMeasurementPointCloseCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'save') {
      const measurementPoint: AssetDto = this.createMeasurementPoint(this.measurementPoint, dialogResult);
      measurementPoint.id = this.measurementPoint.id;
      try {
        await this.assetApiService.updateAsset(measurementPoint).toPromise();
        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTPOINTS.EDIT.SUCCESS'));
      } catch (e) {
        console.error(e);
      }
      this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
    }
    dialogResult.dialogRef.close();
  }

  private async onFollowingCloseCallback(
    dialogResult: PhoenixDialogResult,
  ): Promise<void> {
    switch (dialogResult.formData['buttonClicked'].value) {
      case 'buttonDuplicateMeasurementPoint':
        this.openCreateMeasurementPointWizard(this.measurementPoint, undefined, this.openElementWizard);
        break;
      case 'buttonNewMeasurementPoint':
        this.openCreateMeasurementPointWizard(undefined, undefined, this.openElementWizard);
        break;
    }
    dialogResult.dialogRef.close();
  }

  private async onNewMeasurementPointCloseCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'save') {
      const measurementPoint: AssetDto = this.createMeasurementPoint(new AssetDto(), dialogResult);
      let mp: AssetDto;
      try {
        mp = await this.assetApiService.createAsset(measurementPoint).toPromise();
        this.measurementPoint = mp;
        this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.MEASUREMENTPOINTS.CREATE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }
      this.openElementWizard = (): void => this.openFollowingMeasurementPointWizard(mp);
      this.openFollowingMeasurementPointWizard(mp);
    }
    if (dialogResult.result === 'previous' && this.openElementWizard !== undefined) {
      this.openElementWizard();
    }
    dialogResult.dialogRef.close();
  }

  private openFollowingMeasurementPointWizard(measurementPointDto: AssetDto): void {
    this.dialog.open(PhoenixDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.SELECTOR.TITLE.FOLLOWINGMEASUREMENTPOINT',
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.FOLLOWING',
        buttons: {
          exit: true,
        },
        translationParams: {
          measurementPointName: measurementPointDto.name,
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onFollowingCloseCallback(dialogResult),
        formControls: [
          this.createElementSelectionWizardColumn(
            'DuplicateMeasurementPoint',
            PhoenixIcon.REPEAT,
            'WIZARDS.COMMON.DUPLICATEMEASUREMENTPOINT',
            'WIZARDS.SELECTOR.DESCRIPTION.DUPLICATEMEASUREMENTPOINT',
            'WIZARDS.SELECTOR.BUTTON.DUPLICATEMEASUREMENTPOINT',
          ),
          this.createElementSelectionWizardColumn(
            'NewMeasurementPoint',
            PhoenixIcon.PANORAMA_FISH_EYE,
            'WIZARDS.COMMON.NEWMEASUREMENTPOINT',
            'WIZARDS.SELECTOR.DESCRIPTION.NEWMEASUREMENTPOINT',
            'WIZARDS.SELECTOR.BUTTON.NEWMEASUREMENTPOINT',
          ),
        ],
      },
    });
  }

  private async openMeasurementPointWizard(
    measurementPoint: AssetDto,
    onClose: Function,
    title: string,
    parent?: AssetDto,
  ): Promise<void> {
    this.taskConfigForNewMeasurementPoint = [];
    if (!parent && measurementPoint && measurementPoint.parentId) {
      parent = await this.assetApiService.getAssetById(measurementPoint.parentId).toPromise();
    }

    this.measurementPoint = measurementPoint;
    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTHBIG,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: title,
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.MEASUREMENTPOINT',
        buttons: {
          cancel: true,
          previous: !!this.openElementWizard,
          save: true,
        },
        onClose: onClose,
        formControls: [
          await this.createMeasurementPointWizardColumn(measurementPoint, parent),
        ],
      },
    });
  }

  private async showMeasurementTypeDialog(form: FormGroup, measurementTypeAutocompleteData: PhoenixAutocompleteEntry<MeasurementTypeDto>[]): Promise<void> {
    (await this.measurementTypeDialogService.openCreateMeasurementTypeDialog()).afterClosed().subscribe(
      (values: { measurementType: MeasurementTypeDto, notificationConfig: NotificationConfigDto }) => {
        const entry: PhoenixAutocompleteEntry<MeasurementTypeDto> = new PhoenixAutocompleteEntry<MeasurementTypeDto>({
          object: values.measurementType,
          label: values.measurementType.name
        });
        measurementTypeAutocompleteData.push(entry);
        form.get('measurementType').setValue(entry);
      });
  }

  private async showThresholdDialog(form: FormGroup, thresholdAutocompleteData: PhoenixAutocompleteEntry<ThresholdDto>[]): Promise<void> {
    await this.thresholdWizardService.openCreateThresholdWizard(undefined, undefined, (threshold: ThresholdDto) => {
      const entry: PhoenixAutocompleteEntry<ThresholdDto> = new PhoenixAutocompleteEntry<ThresholdDto>({
        object: threshold,
        label: threshold.name
      });
      thresholdAutocompleteData.push(entry);
      form.get('mpThreshold').setValue(entry);
    });
  }


  private async showSensorDialog(form: FormGroup, sensorAutocompleteData: PhoenixAutocompleteEntry<AssetDto>[]): Promise<void> {
    (await this.sensorDialogService.openSensorDialog()).afterClosed().subscribe(
      (sensor: AssetDto) => {
        const entry: PhoenixAutocompleteEntry<AssetDto> = new PhoenixAutocompleteEntry<AssetDto>({
          label: sensor.name, object: sensor
        });
        sensorAutocompleteData.push(entry);
        form.get('mpAutoTextSensorID').setValue(entry);
      });
  }

  private async showTimeWindowConfigDialog(form: FormGroup, measurementPoint: AssetDto): Promise<void> {
    var timeWindowConfig = new TimeWindowConfigDto()
    if (measurementPoint) {
      timeWindowConfig = measurementPoint.timeWindowConfig;
    } else {
      timeWindowConfig = form.get('dataTimeWindowValue').value;
    }
    await this.timeWindowConfigWizardService.openEditTimeWindowConfigWizard(timeWindowConfig, undefined, (timeWindowConfig: TimeWindowConfigDto) => {
      form.get('dataTimeWindowValue').setValue(timeWindowConfig);
      if (measurementPoint) {
        measurementPoint.timeWindowConfig = timeWindowConfig;
      }
    });
  }

}
